import { Advance, AdvanceRequest, AdvanceStatus } from '../../schemas';
import { BaseDataService } from '../BaseDataService/BaseDataService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;

const clientAdvanceBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/advances`;
const clientCancelAdvanceBasePath = (divisionUuid: string, advanceUuid: string) =>
  `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/advances/${advanceUuid}`;

/**
 * Service for interacting with advance API endpoints.
 */
export class AdvanceService extends BaseDataService<Advance> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, clientAdvanceBasePath);
  }

  /**
   * get advance data.
   * @param divisionUuid
   */
  async getAdvance(divisionUuid: string): Promise<Advance | null> {
    let response = await this.query(divisionUuid, {
      status: {
        in: [AdvanceStatus.PENDING, AdvanceStatus.INITIATED, AdvanceStatus.AUTHORIZED],
      },
    });
    return response.data[0] ?? null;
  }

  /**
   * post a request for funds
   * @param divisionUuid
   * @param advanceAmount
   * @param transferMethod
   * @param status
   */
  async submitRequest(request: AdvanceRequest): Promise<Advance> {
    let response;
    response = await this.httpService.post<Advance>(clientAdvanceBasePath(request.divisionUuid), {
      data: request,
    });
    return response.data;
  }

  /**
   * post a cancelation of funds
   * @param divisionUuid
   * @param advanceAmount
   * @param transferMethod
   * @param status
   */
  async cancelRequest(request: AdvanceRequest): Promise<Advance | null> {
    let response;
    if (request && request.uuid) {
      response = await this.httpService.put<Advance>(clientCancelAdvanceBasePath(request.divisionUuid, request.uuid), {
        data: request,
      });
      return response.data;
    }
    return null;
  }
}
