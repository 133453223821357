import React, { ReactNode } from 'react';
import { AsyncButton } from '../Async/AsyncButton';
import { ClientInvoiceUploadSummary, InvoiceUploadError, InvoiceUploadErrorStatus } from '../../schemas';
import { Divider, Grid, TypeBase, TypeSectionHeader, makeStyles } from '@c2fo/react-components';
import { ErrorRows } from './InvoiceUploadErrorRows';
import { InvoiceUploadDialog, InvoiceUploadDialogContent } from './InvoiceUploadDialog';
import { InvoiceUploadSuccessGraphic } from './InvoiceUploadSuccessGraphic';
import { ProductType } from '../../schemas';
import { toCurrency } from '../../i18n/currency';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

let useStyles = makeStyles((theme) => ({
  /**
   * Yes, this could have been done with some nice display: grid
   *
   * Unfortunately, we support IE11, and so need to use
   * css table.
   */
  countsContainer: {
    display: 'table',
  },
  countRow: {
    display: 'table-row',
  },
  count: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  countTypography: {
    fontSize: 2 * theme.typography.fontSize,
    [theme.breakpoints.down('md')]: {
      fontSize: 1.6 * theme.typography.fontSize,
    },
  },
  description: {
    display: 'table-cell',
    paddingLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
}));

function CountsRow(props: { testid?: string; count: number; children: ReactNode }): JSX.Element | null {
  let classes = useStyles();
  if (props.count < 1) {
    return null;
  }
  return (
    <div className={classes.countRow}>
      <div className={classes.count} data-testid={props.testid + '-count'} data-cy={props.testid + '-count'}>
        <TypeBase isEmphasis isTabular align={'trailing'}>
          <span className={classes.countTypography}>{props.count}</span>
        </TypeBase>
      </div>
      <div className={classes.description} data-testid={props.testid + '-description'}>
        <TypeBase>{props.children}</TypeBase>
      </div>
    </div>
  );
}

export function InvoiceUploadComplete(props: {
  summary: ClientInvoiceUploadSummary;
  errors: InvoiceUploadError[];
  onAcknowledgeSuccess: () => Promise<void>;
  onClose: () => void;
}): JSX.Element {
  const classes = useStyles();

  const { summary } = props;
  const { invoiceUploadService } = useServices();
  const productType = useProductType();

  return (
    <InvoiceUploadDialog
      open={true}
      variant="informational"
      onClose={props.onClose}
      closeButtonTestId="invoice-upload-dialog-close-button"
      actionsContent={
        <AsyncButton
          variant={'outlined'}
          color={'secondary'}
          action={props.onAcknowledgeSuccess}
          data-testid={'acknowledge-success-button'}
          data-cy={'acknowledge-success'}
        >
          {productType === ProductType.Factoring && summary.successCount > 0
            ? 'View and Approve'
            : invoiceUploadService.shouldShowWhatsNext()
            ? 'What Happens Next'
            : 'OK'}
        </AsyncButton>
      }
    >
      <InvoiceUploadDialogContent padding={0}>
        <Grid container spacing={2}>
          <Grid item container justifyContent={'center'}>
            <TypeBase>
              <InvoiceUploadSuccessGraphic summary={summary} />
            </TypeBase>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <TypeSectionHeader align={'center'}>
              <strong data-testid={'title'}>
                {summary.successAmount > 0
                  ? `Successfully Uploaded ${toCurrency(summary.successAmount)}`
                  : summary.unmappedCount > 0
                  ? 'UPLOAD SUCCESSFUL!'
                  : 'Nothing new to upload!'}
              </strong>
            </TypeSectionHeader>
          </Grid>
          <Grid item>
            <div className={classes.countsContainer}>
              <CountsRow testid={'upload-success'} count={summary.successCount}>
                invoices were uploaded successfully.
              </CountsRow>
              <CountsRow testid={'unmapped'} count={summary.unmappedCount}>
                invoices had customer IDs that require C2FO to review before approval.
              </CountsRow>
              <CountsRow testid={'identical'} count={summary.identicalCount}>
                invoices were ignored as existing with no updates.
              </CountsRow>
            </div>
          </Grid>

          {props.errors.length > 0 && (
            <>
              <Grid item style={{ width: '100%' }}>
                <Divider />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <TypeSectionHeader align={'center'}>We couldn't add some invoices because:</TypeSectionHeader>
              </Grid>
              <ul>
                {props.errors.map((error) => (
                  <li key={error.status} data-testid={`error-${error.status}`}>
                    <TypeBase>
                      {error.rows.length} invoice(s){' '}
                      {error.status === InvoiceUploadErrorStatus.LOCKED && 'have already been approved.'}
                      {error.status === InvoiceUploadErrorStatus.INELIGIBLE &&
                        'have customers that are not associated to your account.'}
                      {error.status === InvoiceUploadErrorStatus.FUTURE_DATED_INVOICE &&
                        'have invoice dates too far in the future.'}
                      {error.status === InvoiceUploadErrorStatus.FUTURE_DUE_DATE_INVOICE &&
                        'have due dates too far in the future.'}
                      {error.status === InvoiceUploadErrorStatus.PAST_DUE_INVOICE && 'are past due.'}
                      {error.status === InvoiceUploadErrorStatus.INVOICE_INSTRUCTIONS &&
                        'contain invoice instructions.'}{' '}
                      (<ErrorRows rows={error.rows} />)
                    </TypeBase>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Grid>
      </InvoiceUploadDialogContent>
    </InvoiceUploadDialog>
  );
}
