import React, { ReactNode } from 'react';
import background from './cloud_loading.svg';
import { DigitalData } from '../../../lib';
import { Link, TypeSectionHeader, TypeSubsectionHeader, makeStyles } from '@c2fo/react-components';
import { Route, Switch } from 'react-router-dom';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 430,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export function PageNotFound() {
  DigitalData.Hooks.usePageTitle('Not Found');

  let classes = useStyles();
  let { redirectService } = useServices();
  return (
    <div className={classes.root} data-testid={'page-not-found'}>
      <TypeSectionHeader>Whoops, we couldn't find that page...</TypeSectionHeader>
      <TypeSubsectionHeader>
        Go back to your{' '}
        <Link color={'secondary'} href={redirectService.getSignedEarlyPayMarketsUrl()} data-testid={'markets-url'}>
          markets?
        </Link>
      </TypeSubsectionHeader>
    </div>
  );
}

export function PageNotFoundSwitch(props: { children: ReactNode }) {
  return (
    <Switch>
      {props.children}
      <Route component={PageNotFound} />
    </Switch>
  );
}
