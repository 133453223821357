import { env } from '../../lib/env/env';

export const capfinOnboardingApiConfig = {
  CAPFIN_ONBOARDING_URL: env.getString('CAPFIN_ONBOARDING_URL') || 'http://localhost:8080',
  LOS_ENVIRONMENT_KEY: env.getString('LOS_ENVIRONMENT_KEY') || '',
  LOS_SCRIPT_URL: env.getString('LOS_SCRIPT_URL') || 'https://sandbox-widget.lendflow.com/js/lendflow.js',
  LOS_BRAND_ID: env.getString('LOS_BRAND_ID') || 2,
  LOS_EMAIL_TEMPLATE_ID: env.getString('LOS_EMAIL_TEMPLATE_ID') || 1,
  LOS_WORKFLOW_TEMPLATE_ID: env.getString('LOS_WORKFLOW_TEMPLATE_ID') || '74e3d81d-1fb0-4942-8992-14edac86aec7',
  LOS_WORKFLOW: env.getString('LOS_WORKFLOW') || 5,
  LOS_PRODUCT: env.getString('LOS_PRODUCT') || 'leadfunding',
  LOS_VIEW_PRODUCT: env.getString('LOS_VIEW_PRODUCT') || 24,
  LOS_MODE: env.getString('LOS_MODE') || 'live',
};
