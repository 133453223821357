import React, { ReactNode } from 'react';
import SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio';
import { SplitFactory, useTreatments } from '@splitsoftware/splitio-react';
import { splitIOConfig } from '../../../config';

/**
 * Treatments available from split. Should be used to manage our feature flags as we integrate into split.io
 * into the new experience.
 */
export enum TREATMENTS {
  SHOW_CAPFIN_TEST_BANNER = 'capfin_showTestBanner',
  ENABLE_REBRAND_2023_CAPFIN = 'ENABLE_REBRAND_2023_CAPFIN',
  SHOW_THEME_SWITCHER = 'capfin_enableRebrandSwitch_ui',
  SHOW_RF_MONTH_END_REPORTS = 'capfin_enableMonthEndRFReports_ui',
}

export interface SplitProps {
  children: ReactNode;
  uniqueId?: string;
}

export const SplitProvider: React.FC<SplitProps> = ({ children, uniqueId }) => {
  const key = uniqueId || 'default';
  if (key && children) {
    const sdkConfig: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: splitIOConfig.SPLIT_IO_KEY,
        key,
      },
      features: {
        [TREATMENTS.SHOW_CAPFIN_TEST_BANNER]: 'on',
        [TREATMENTS.ENABLE_REBRAND_2023_CAPFIN]: 'on',
        [TREATMENTS.SHOW_THEME_SWITCHER]: 'on',
        [TREATMENTS.SHOW_RF_MONTH_END_REPORTS]: 'on',
      },
    };

    // the updateOnSDK allows realtime changes
    // we might not actually want this...
    return (
      <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
        <div data-testid="split-provider">{children}</div>
      </SplitFactory>
    );
  }

  return <>{children}</>;
};

/**
 * Check if a Split treatment's value is on. This helper method saves the need to import
 * from two different modules and using two lines to check for the 'on' value, while still
 * remaining type-safe.
 * @param treatment The treatment to check. Import the TREATMENTS enum and pass in the
 * value you want to check.
 * @param attributes Additional addributes
 */
export const useFeature = (treatment: TREATMENTS, attributes?: SplitIO.Attributes) => {
  const treat = useTreatments([treatment], attributes);
  const { treatment: feature } = treat[treatment];
  return feature === 'on';
};
