import React from 'react';
import { Link, Theme, TypeBase, makeStyles } from '@c2fo/react-components';

let currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: `${theme.spacing(4)}px 0`,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.50rem',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        textAlign: 'center',
      },
    },
  },
}));

export function Footer(): JSX.Element {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.item}>
        <TypeBase customColor="white">&copy; {currentYear} Pollen, Inc. All rights reserved | Patents Pending</TypeBase>
      </div>
      <div className={classes.item}>
        <Link
          color="inherit"
          underline="always"
          href="https://c2fo.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Link>
      </div>
      <div className={classes.item}>
        <Link
          color="inherit"
          underline="always"
          href="https://c2fo.com/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </Link>
      </div>
      <div className={classes.item}>
        <a
          href="https://privacy.truste.com/privacy-seal/validation?rid=a41b67e3-9019-43e2-b132-477799b785d9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="site-footer__app-store-badge site-footer__app-store-badge--truste"
            src="https://privacy-policy.truste.com/privacy-seal/seal?rid=a41b67e3-9019-43e2-b132-477799b785d9"
            alt="Pollen Inc. Truste Policy Seal"
            title="Pollen Inc. Truste Policy Seal"
          />
        </a>
      </div>
    </footer>
  );
}
