import React, { ReactNode } from 'react';
import { ClientBalance } from '../../schemas';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { RFAvailabilityCalculation } from './RFAccountOverview';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import { SummaryCardAccountOverviewGroup } from '../SummaryCard/SummaryCardAccountOverview';
import { SummaryCardHeader, SummaryCardHeaderSection } from '../SummaryCard/SummaryCardHeader';
import { Theme, TypeBase, makeStyles } from '@c2fo/react-components';

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 500,
    maxWidth: '250px',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  list: {
    margin: 0,
  },
}));

interface AlertStylesProps {
  variant: 'warning' | 'info';
}

const useAlertStyles = makeStyles((theme) => ({
  root: {
    background: (props: AlertStylesProps) => {
      if (props.variant === 'warning') {
        return theme.palette.warning.main;
      }
      return theme.palette.grey['200'];
    },
    padding: '0 .5rem',
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    boxShadow: theme.shadows[1],
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      top: 0,
      right: 0,
      left: 0,
      boxShadow: 'none',
      marginTop: 0,
      borderRadius: 0,
      textAlign: 'left',
      padding: '.75rem 1rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0.7rem',
      margin: '1rem 0.2rem 0 0.2rem',
    },
  },
}));

export function SummaryCardCreditLimitAlert(props: { children: ReactNode; variant?: 'warning' | 'info' }): JSX.Element {
  let classes = useAlertStyles({ variant: props.variant ?? 'info' });
  return (
    <div className={classes.root}>
      <TypeBase component={'span'} isEmphasis>
        {props.children}
      </TypeBase>
    </div>
  );
}

export function RFSummaryCardCreditLimit(props: {
  header?: ReactNode;
  accountOverviewStats: ReactNode;
  balance: ClientBalance;
}): JSX.Element {
  const classes = useStyles({});

  return (
    <SummaryCard data-testid="RFSummaryOverAdvancedCard">
      {props.header}
      <SummaryCardHeader isInverse={false} padding={4}>
        <SummaryCardHeaderSection>
          <TypeBase component={'div'}>
            <h3 className={classes.title} data-cy={'credit-limit-reached-heading'}>
              No Invoices to Draw From at This Time
            </h3>
          </TypeBase>
          <TypeBase spacingBottom={4}>
            How is availability calculated? &nbsp;
            <InfoTooltip name="rfSummaryCard.HowIsAvailabilityCalculated">
              <RFAvailabilityCalculation balance={props.balance} />
            </InfoTooltip>
          </TypeBase>
          <TypeBase>How To Add More</TypeBase>
          <ul className={classes.list}>
            <li>Upload invoices to increase your availability</li>
            <li>Follow up with customers who are late with payment</li>
          </ul>
        </SummaryCardHeaderSection>
        <SummaryCardAccountOverviewGroup>{props.accountOverviewStats}</SummaryCardAccountOverviewGroup>
      </SummaryCardHeader>
    </SummaryCard>
  );
}
