/**
 * A way to get the path of many routes,
 * useful for linking directly to things
 */
import { ProductType } from './schemas/enums.schema';

export const routes = {
  division: (divisionUuid: string) => ({
    basePath: `/${divisionUuid}`,
    product: (product: ProductType) => {
      let segment = '';
      if (product === ProductType.Factoring) {
        segment = 'ar-purchase';
      } else if (product === ProductType.ReceivablesFinance) {
        segment = 'receivable-finance';
      }
      let productTypePath = `/${divisionUuid}/${segment}`;
      let divisionUuidPath = `/${divisionUuid}`;
      /**
       * returns a union of both product type links: it's up to
       * consumers to know if the page exists for RF / Factoring
       */
      return {
        basePath: productTypePath,
        home: `${productTypePath}/home`,
        invoices: `${productTypePath}/invoice`,
        transactionHistory: `${productTypePath}/transaction-history`,
        customerPayments: `${productTypePath}/customer-payments`,
        bankAccount: `${divisionUuidPath}/setup/bank-account`,
      };
    },
  }),
};
