import Joyride, { ACTIONS, CallBackProps, STATUS, Step, TooltipRenderProps } from 'react-joyride';
import React, { useCallback } from 'react';
import {
  CloseIcon,
  PrimaryButton,
  SecondaryButton,
  TypeWidgetHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { useBooleanQueryParam } from '../components/QueryParams/useQueryParam';

const useTooltipStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

function Tooltip(props: TooltipRenderProps) {
  const { backProps, index, isLastStep, primaryProps, step, tooltipProps } = props;
  const { content, title } = step;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let styles = step.styles!;
  let classes = useTooltipStyles();
  let theme = useTheme();

  return (
    <div style={styles.tooltip} {...tooltipProps}>
      <div style={styles.tooltipContainer}>
        {title && (
          <TypeWidgetHeader align="center" style={styles.tooltipTitle}>
            {title}
          </TypeWidgetHeader>
        )}
        <div className={classes.content}>{content}</div>
      </div>
      <div className={classes.buttonContainer}>
        {index > 0 && (
          <SecondaryButton {...backProps} variant="text" className={classes.leftButton} size="small">
            Back
          </SecondaryButton>
        )}
        <PrimaryButton {...primaryProps} size="small">
          {isLastStep ? 'Done' : 'Next'}
        </PrimaryButton>
      </div>
      <button
        style={{
          ...styles.buttonClose,
          padding: theme.spacing(2),
          right: theme.spacing(2),
        }}
        {...props.closeProps}
      >
        <CloseIcon color="secondary" height={16} width={16} />
      </button>
    </div>
  );
}

/**
 * Wraps the normal Joyride with a query-param enabled one.
 *
 * The default key is showJoyride
 */
export function QueryParamEnabledJoyride(props: { steps: Step[]; queryParam?: string }) {
  let [showJoyride, setShowJoyride] = useBooleanQueryParam(props.queryParam ?? 'showJoyride');
  let handleCallback = useCallback(
    (state: CallBackProps) => {
      if (state.status === STATUS.FINISHED || state.action === ACTIONS.CLOSE) {
        setShowJoyride(null);
      }
    },
    [setShowJoyride],
  );

  return (
    <Joyride
      continuous
      run={showJoyride ?? false}
      steps={props.steps}
      callback={handleCallback}
      tooltipComponent={Tooltip}
    />
  );
}
