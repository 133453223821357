import React from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    c2foData: any;
  }
}

window.c2foData = window.c2foData || {};

interface LooseObject {
  [key: string]: any;
}

function setNested(obj: LooseObject, path: string, value: any) {
  let schema = obj;
  const pList = path.split('.');
  const len = pList.length;

  for (let i = 0; i < len - 1; i += 1) {
    let elem = pList[i];
    if (!(elem in schema)) {
      schema[elem] = {};
    }
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

export function set(key: string, value: any): void {
  setNested(window, `c2foData.digitalData.${key}`, value);
}

export function setCurrentURL(url: string): void {
  set(`currentURL`, url);
}

export function setPageTitle(title: string): void {
  set('pageTitle', title);
}

export function setSection(section: string, subSection?: string): void {
  set('section', section);
  if (subSection) {
    set('subSection', subSection);
  }
}

export function setSite(site: string): void {
  set('site', site);
}

/**
 * Sets the userType on the digital data object.
 * @param {string} type
 *
 * @example
 * DigitalData.setUserType('internal');
 */
export function setUserType(type: string): void {
  set('userType', type);
}

/**
 * Loads a given js file based on the window's hostname.
 *
 * Use the 'default' key to load a script if nothing else matches.
 *
 * @param {Map<string, string>} mapping - A mapping of domain names to the asset to load for each.
 */
export function loadScriptForHostname(mapping: Map<string, string>) {
  let { hostname } = window.location;
  let src: string | undefined;

  if (mapping.has(hostname)) {
    src = mapping.get(hostname);
  } else if (mapping.has('default')) {
    src = mapping.get('default');
  }

  if (src) {
    let script = document.createElement('script');
    script.async = true;
    script.src = src;
    document.body.append(script);
  }
}

// React Hooks follow
export const Hooks = {
  /**
   * A React hook which sets the given key/value pair on the digital data object.
   *
   * @param {string} key   - The key to set on the window.c2foData.digitalData object.
   * @param {string} value - The value to set for the supplied key.
   *
   * @example
   * // sets window.c2foData.digitalData.foo = bar
   * function Component() {
   *   DigitalData.Hooks.set('foo', 'bar');
   * }
   */
  useSet(key: string, value: any) {
    React.useEffect(() => set(key, value));
  },

  /**
   * A React hook to set the current URL on the digital data object whenever the URL changes changes.
   *
   * @example
   * // sets window.c2foData.digitalData.currentURL to the current URL.
   * function Component() {
   *   DigitalData.Hooks.useLocation();
   * }
   */
  useCurrentURL() {
    let location = useLocation();
    React.useEffect(() => {
      setCurrentURL(location.pathname);
    }, [location]);
  },

  /**
   * React hook to set the page title on the digital data object.
   * @param {string} title - The title of the page
   *
   * @example
   * function Component() {
   *   DigitalData.Hooks.usePageTitle("Home Page");
   * }
   */
  usePageTitle(title: string) {
    React.useEffect(() => {
      setPageTitle(title);
      // cleanup for when the component unmounts so we don't bleed sections to where
      // they aren't applicable.
      return function cleanup() {
        setPageTitle('');
      };
    }, [title]);
  },

  /**
   * React hook to set the section and optionally subSection on the digital data object.
   * @param {string} section
   * @param {string?} subSection
   *
   * @example
   * function Component() {
   *   DigitalData.Hooks.section("MySection", "My Sub Section");
   * }
   */
  useSection(section: string, subSection?: string) {
    React.useEffect(() => {
      setSection(section, subSection);
      // cleanup for when the component unmounts so we don't bleed sections to where
      // they aren't applicable.
      return function cleanup() {
        setSection('', '');
      };
    }, [section, subSection]);
  },

  /**
   * React hook to set the site property on the digital data object.
   * @param {string} site - The name of the site to distinguish it from other apps.
   *
   * @example
   * function Component() {
   *   DigitalData.Hooks.useSite('capfin');
   * }
   */
  useSite(site: string) {
    React.useEffect(() => setSite(site));
  },
};
