import {
  DateFilter,
  DatePreset,
  DateRange,
  RelativeDatePreset,
  deriveDateRangeFromPreset,
  useDateFilter,
} from '../Filters/useDateFilter';
import {
  FactoringInvoice,
  InvoiceSupplierStatus,
  ProductType,
  Report,
  ReportDisplayField,
  ReportQueryConstraint,
} from '../../schemas';
import { MultiselectFilter, useMultiselectFilter } from '../Filters/useMultiselectFilter';
import { useState } from 'react';

import { format, parseISO } from 'date-fns';
import { initialValueAdapter } from '../Filters/Filter';

const INITIAL_DATE_RANGE: DateRange = {
  preset: null,
  start: null,
  end: null,
};

/**
 * Transform a DateRange to ReportQueryConstraints as the reporting endpoints expect them.
 * @param range - the DateRange to transform
 * @param schemaField - the string key mapping to a date value in the report filters
 */
export function dateQueryConstraintsFromRange<T>(range: DateRange, schemaField: keyof T): ReportQueryConstraint<T>[] {
  let dateConstraints: ReportQueryConstraint<T>[] = [];
  if (range.preset && range.preset !== DatePreset.Custom) {
    // lazy logic.  the value of range.preset will map to constraint.value:
    dateConstraints = [
      {
        comparison: 'LAZY_DATE',
        field: schemaField,
        value: range.preset,
      },
    ];
  } else if (range.preset && range.preset === DatePreset.Custom) {
    if (!range.start || !range.end) {
      throw new Error('Illegal State: Custom date query constraint does not have start and end bounds');
    }
    dateConstraints = [
      {
        comparison: 'GTE',
        field: schemaField,
        value: format(range.start, 'yyyy-MM-dd'),
      },
      {
        comparison: 'LTE',
        field: schemaField,
        value: format(range.end, 'yyyy-MM-dd'),
      },
    ];
  } else {
    dateConstraints = [];
  }

  return dateConstraints;
}

/**
 * Transform date query constraints from the database into the appropriate DateRange
 * based on provided date field and queryconstraints
 * @param dateField - the date-based key on object T corresponding to a response from the db, e.g. "dueDate"
 * @param queryConstraints - the list of all queryconstraints returned for a given existing report.
 */
export function dateRangeFromQueryConstraints<T>(
  dateField: keyof T,
  queryConstraints: ReportQueryConstraint<T>[],
): DateRange {
  /**
   * Nobody likes a weird-looking reduce, but what we do is loop through constraints that match the field
   * we're looking for and:
   * 1.  inspect queryconstraint.comparsion.  It can be one of two categories ('LAZY_DATE' or 'GTE'+'LTE')
   *     with our current date filters:
   *     A. If it's 'LAZY_DATE', this means a relative range that hasn't been evaluated yet. Evaluate the
   *        field into a valid DateRange using `deriveDateRangeFromPreset`, to keep conformity with all our frontend
   *        date range options
   *     B.  If it's 'GTE'+'LTE', this means we have a static date filter (i.e. DatePreset.Custom).  This one
   *         is slightly tricky because we normalize this in the DB, so we need to use reduce to merge what will be
   *         two list items into one DateRange.  In the unlikely event that we persisted only
   *         an LTE or a GTE and not both, the cast here would fail, but that points to
   *         data corruption on the backend and should never happen.
   * 4.  Cast the reducer memo/accumulator to DateRange.
   */
  return queryConstraints
    .filter((q) => q.field === dateField)
    .reduce((acc: any, query: ReportQueryConstraint<T>) => {
      if (query.comparison === 'LAZY_DATE') {
        return deriveDateRangeFromPreset(query.value as RelativeDatePreset);
      }

      // the following two comparisons will _both_ get hit for a static date filter, reducing to
      // one DateRange object.  Additionally, we need to parse here from string to date.
      if (query.comparison.toUpperCase() === 'GTE') {
        return {
          ...acc,
          preset: DatePreset.Custom,
          start: parseISO(query.value),
        };
      }
      if (query.comparison.toUpperCase() === 'LTE') {
        return {
          ...acc,
          preset: DatePreset.Custom,
          end: parseISO(query.value),
        };
      }
      return acc;
    }, INITIAL_DATE_RANGE) as DateRange;
}

export interface ExistingInvoiceReportValues {
  statuses: InvoiceSupplierStatus[];
  debtors: string[];
  dueDateRange: DateRange;
  invoiceDateRange: DateRange;
  // the below filter should remove undefineds, but i couldn't convince typescript.
  columnFilters: ReportDisplayField<FactoringInvoice>[];
  nameAndDescription: { name: string; description: string };
}

/**
 * Prepare to "hydrate" a form from existing report values for edit or clone.
 * The return values will be used by `useInvoiceReportBuilder` to initialize the various form
 * persistence accordingly.
 * @param report - the report to base values off of.
 * @param displayFieldOptions - the list of available columns for the invoice report.  Varies only by product type.
 */
export function existingReportToBuilder(
  report: Report<FactoringInvoice>,
  displayFieldOptions: ReportDisplayField<FactoringInvoice>[],
): ExistingInvoiceReportValues {
  const statuses = report.queryConstraints
    .filter((q) => q.field === 'supplierDisplayStatus')
    .map((q) => q.value as InvoiceSupplierStatus);

  const debtors = report.queryConstraints.filter((q) => q.field === 'debtorName').map((q) => q.value);
  const dueDateRange = dateRangeFromQueryConstraints('dueDate', report.queryConstraints);
  const invoiceDateRange = dateRangeFromQueryConstraints('transactionDate', report.queryConstraints);

  // typescript apparently likes this better than map -> find -> filter :|
  const columnFilters: ReportDisplayField<FactoringInvoice>[] = [];
  report.columnFields.forEach((column) => {
    const foundField = displayFieldOptions.find((f) => f.name === column);
    if (foundField) {
      columnFilters.push(foundField);
    }
  });

  const nameAndDescription = { name: report.reportName, description: report.description };
  return {
    statuses,
    debtors,
    dueDateRange,
    invoiceDateRange,
    columnFilters,
    nameAndDescription,
  };
}

export interface BuilderNameAndDescription {
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
}

/**
 * Object for interacting with invoice reports as they are built.
 */
export interface InvoiceReportBuilder {
  statusFilters: MultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>;
  debtorFilters: MultiselectFilter<string, string>;
  dueDateFilter: DateFilter;
  invoiceDateFilter: DateFilter;
  columnFilters: MultiselectFilter<ReportDisplayField<FactoringInvoice>>;
  reportName: BuilderNameAndDescription;
  toPayload: (divisionUuid: string, productType: ProductType) => Report<FactoringInvoice>;
}

/**
 * Hook for interacting with invoice report building wizard.  Initializes persistence using our
 * higher-up abstractions for filters and potential existing report values (for edit/clone).
 * @param invoiceMetadata - metadata for available columns per product type.
 * @param isCloning - should we treat this as a clone operation, in which case we don't bother copying uuid
 * @param startingReport - optional beginning report to base this one off of.
 */
export function useInvoiceReportBuilder(
  invoiceMetadata: ReportDisplayField<FactoringInvoice>[],
  isCloning: boolean,
  startingReport?: Report<FactoringInvoice> | null | undefined,
): InvoiceReportBuilder {
  let existingValues: ExistingInvoiceReportValues | undefined;
  if (startingReport) {
    existingValues = existingReportToBuilder(startingReport, invoiceMetadata);
  }

  // ==== first step in wizard: ====
  const statusFilters = useMultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>(
    (k) => k,
    initialValueAdapter((existingValues && existingValues.statuses) ?? null),
  );

  const debtorFilters = useMultiselectFilter<string, string>(
    (d) => d,
    initialValueAdapter((existingValues && existingValues.debtors) ?? null),
  );

  let dueDateFilter = useDateFilter(initialValueAdapter(existingValues?.dueDateRange ?? null));
  let invoiceDateFilter = useDateFilter(initialValueAdapter(existingValues?.invoiceDateRange ?? null));

  // ==== second step in wizard. ====
  const columnFilters = useMultiselectFilter<ReportDisplayField<FactoringInvoice>, string>(
    (k) => k.name,
    initialValueAdapter(() => {
      if (existingValues) {
        return existingValues.columnFilters;
      }
      // we default an empty column choice to at least selecting invoiceId.
      const invoiceIdField = invoiceMetadata.find((field) => field.name === 'invoiceId');
      return invoiceIdField ? [invoiceIdField] : [];
    }),
  );

  // ==== third step: name and description ====
  const [reportNameState, setReportNameState] = useState<{ name: string; description: string }>(() => {
    if (existingValues && !isCloning) {
      return existingValues.nameAndDescription;
    }
    return {
      name: '',
      description: '',
    };
  });

  const reportName: BuilderNameAndDescription = {
    name: reportNameState.name,
    description: reportNameState.description,
    setName: (name) => setReportNameState({ ...reportNameState, name }),
    setDescription: (description) => setReportNameState({ ...reportNameState, description }),
  };

  /**
   * convert the builder object to an acceptable API payload.
   * TODO: would this benefit from useCallback, or useMemo on "payload" value? don't want to overoptimize.
   * @param divisionUuid - divisionUuid of current client.
   */
  const toPayload = (divisionUuid: string, productType: ProductType): Report<FactoringInvoice> => {
    // get any invoice filters
    const invoiceStatuses: ReportQueryConstraint<FactoringInvoice>[] = statusFilters.items.map((status) => ({
      comparison: 'IN',
      field: 'supplierDisplayStatus',
      value: status,
    }));
    // map debtors.
    const debtors: ReportQueryConstraint<FactoringInvoice>[] = debtorFilters.items.map((d) => ({
      comparison: 'IN',
      field: 'debtorName',
      value: d,
    }));

    const dueDateComparisons = dateQueryConstraintsFromRange<FactoringInvoice>(dueDateFilter.range, 'dueDate');
    const invoiceDateComparisons = dateQueryConstraintsFromRange<FactoringInvoice>(
      invoiceDateFilter.range,
      'transactionDate',
    );

    const allConstraints = invoiceStatuses.concat(debtors, dueDateComparisons, invoiceDateComparisons);
    const entityName = productType === ProductType.ReceivablesFinance ? 'invoice' : 'factoring-invoice';

    const payload = {
      divisionUuid,
      // needs empty string protection / ensure we don't create when no name.  it's our indicator of save.
      description: reportName.description,
      reportName: reportName.name,
      columnFields: columnFilters.items.map((item) => item.name),
      entityName,
      queryConstraints: allConstraints,
    } as Report<FactoringInvoice>;
    if (startingReport && !isCloning) {
      payload.uuid = startingReport.uuid;
    }
    return payload;
  };

  return {
    statusFilters,
    debtorFilters,
    dueDateFilter,
    invoiceDateFilter,
    columnFilters,
    reportName,
    toPayload,
  };
}
