import React from 'react';
import clsx from 'clsx';
import { ApprovalStatus } from '../../schemas/fileManagement.schema';
import {
  Chip,
  ColorManipulator,
  TableCell,
  TableCellProps,
  Theme,
  TypeBase,
  createStyles,
  makeStyles,
} from '@c2fo/react-components';

type Props = { status: ApprovalStatus };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'fit-content',
      textTransform: 'capitalize',
      color: (props: Props) => {
        switch (props.status) {
          case 'REJECTED':
            return ColorManipulator.darken(theme.palette.red[500] ?? theme.palette.common.black, 0.6);
          default:
            return theme.palette.grey[800];
        }
      },
      border: (props: Props) => {
        switch (props.status) {
          case 'REJECTED':
            return `solid 1px ${theme.palette.red[500]}`;
          default:
            return '';
        }
      },
      backgroundColor: (props: Props) => {
        switch (props.status) {
          case 'APPROVED':
            return theme.palette.green[50];
          case 'REJECTED':
            return theme.palette.red[50];
          default:
            return '';
        }
      },
    },
  }),
);

export function FileStatusPill(props: Props): JSX.Element {
  const classes = useStyles(props);
  if (props.status !== null) {
    return <Chip variant={'outlined'} className={classes.root} label={props.status.toLowerCase()} />;
  } else {
    return <Chip variant={'outlined'} className={classes.root} label="pending" />;
  }
}

const useCellStyles = makeStyles({
  root: {
    /**
     * Set a constant width so that when the user goes
     * between different pages of the grid, we
     * show a constant width, making the transition less
     * "noisy"
     *
     * If you change this width, please verify in the storybook
     * that everything looks good for each status
     *
     * We use both minWidth and maxWidth here because the browser
     * seems to respect that more than just width. For some reason,
     * the browser feels that it can increase the width if need be. This
     * ensures that doesn't happen
     */
    minWidth: 160,
    maxWidth: 160,
  },
});

export function FileStatusPillCell(props: Props & TableCellProps): JSX.Element {
  let { status, ...bodyCellProps } = props;
  let classes = useCellStyles();
  return (
    <TableCell {...bodyCellProps} align={'center'} className={clsx(classes.root, bodyCellProps.className)}>
      <TypeBase component={'span'} align={'center'}>
        <FileStatusPill status={status} />
      </TypeBase>
    </TableCell>
  );
}
