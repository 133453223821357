import React from 'react';
import { ClientInvoiceUploadSummary } from '../../schemas';

function liquiditySuccess(): JSX.Element {
  return (
    <svg width="140" height="140" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4717_29487)">
        <path
          d="M42.5883 29.6384C42.2189 29.269 41.6209 29.269 41.2515 29.6384L32.2682 38.6217L28.9821 35.3356C28.6126 34.9662 28.0147 34.9662 27.6452 35.3356C27.2758 35.7051 27.2758 36.303 27.6452 36.6724L31.6119 40.6148C31.7918 40.7947 32.0348 40.887 32.2779 40.887C32.5209 40.887 32.7543 40.7947 32.9487 40.6099L42.5834 30.9752C42.9529 30.6058 42.9529 30.0079 42.5834 29.6384H42.5883Z"
          fill="#06A76C"
        />
        <path
          d="M21.7974 15.1669C22.2252 14.8753 22.3419 14.2871 22.0502 13.8593C21.7585 13.4267 21.1801 13.31 20.7377 13.6017C20.3051 13.8933 20.1884 14.4718 20.4801 14.9044C20.6599 15.1815 20.9565 15.3322 21.2627 15.3322C21.4377 15.3322 21.6176 15.2787 21.7926 15.1718L21.7974 15.1669Z"
          fill="#2C324F"
        />
        <path
          d="M18.1564 16.8196C17.7869 16.4501 17.189 16.4501 16.8196 16.8196C16.4501 17.189 16.4501 17.7869 16.8196 18.1564C16.9994 18.3362 17.2425 18.4286 17.4855 18.4286C17.7286 18.4286 17.9765 18.3314 18.1515 18.1564C18.521 17.7869 18.521 17.189 18.1515 16.8196H18.1564Z"
          fill="#2C324F"
        />
        <path
          d="M26.44 11.7106C26.2455 11.2342 25.6865 11.0058 25.2052 11.2002C24.7289 11.3946 24.5004 11.9537 24.6948 12.4349C24.8455 12.7946 25.1955 13.0134 25.565 13.0134C25.6816 13.0134 25.808 12.9891 25.9296 12.9405C26.4011 12.746 26.6344 12.187 26.4351 11.7058L26.44 11.7106Z"
          fill="#2C324F"
        />
        <path
          d="M14.9142 20.4847C14.4816 20.193 13.8934 20.3097 13.6017 20.7472C13.3149 21.175 13.4267 21.7583 13.8642 22.05H13.8691C14.0149 22.1521 14.1996 22.2007 14.3892 22.2007C14.6955 22.2007 14.9968 22.05 15.1767 21.7826C15.4684 21.35 15.3468 20.7618 14.9191 20.4847H14.9142Z"
          fill="#2C324F"
        />
        <path
          d="M12.4301 24.6948C11.9585 24.4955 11.4092 24.7191 11.2002 25.2052C11.0058 25.6962 11.2342 26.2455 11.7106 26.4399C11.8321 26.4885 11.9585 26.5128 12.0752 26.5128C12.4446 26.5128 12.7995 26.2892 12.9453 25.9344C13.1398 25.458 12.9162 24.9038 12.4301 24.6996V24.6948Z"
          fill="#2C324F"
        />
        <path
          d="M31.1602 10.4805C31.0581 9.97012 30.5622 9.63956 30.0518 9.74165C29.5414 9.84373 29.2109 10.3347 29.3129 10.8451C29.4102 11.2875 29.8039 11.5986 30.2414 11.5986C30.2949 11.5986 30.3435 11.5937 30.4213 11.584C30.4213 11.584 30.4261 11.584 30.431 11.584C30.9365 11.4771 31.2574 10.9861 31.1553 10.4805H31.1602Z"
          fill="#2C324F"
        />
        <path
          d="M10.8356 29.3271C10.3251 29.225 9.8293 29.5556 9.72722 30.066C9.62514 30.5861 9.95083 31.0771 10.4515 31.184C10.539 31.1938 10.5974 31.2035 10.6411 31.2035C11.0786 31.2035 11.4724 30.8924 11.5696 30.4403C11.6717 29.9299 11.3411 29.4389 10.8307 29.3368L10.8356 29.3271Z"
          fill="#2C324F"
        />
        <path
          d="M53.4238 16.8197C48.5384 11.9294 42.0391 9.23633 35.1217 9.23633C34.6065 9.23633 34.1787 9.65924 34.1787 10.1794C34.1787 10.6995 34.6016 11.1224 35.1217 11.1224C48.3634 11.1224 59.1211 21.8849 59.1211 35.1217C59.1211 48.3586 48.3586 59.1211 35.1217 59.1211C21.8849 59.1211 11.1224 48.3634 11.1224 35.1217C11.1224 34.6065 10.6995 34.1787 10.1794 34.1787C9.65924 34.1787 9.23633 34.6016 9.23633 35.1217C9.23633 42.0391 11.9294 48.5384 16.8197 53.4238C21.7051 58.3092 28.2044 61.0072 35.1217 61.0072C42.0391 61.0072 48.5384 58.3141 53.4238 53.4238C58.3092 48.5384 61.0072 42.0391 61.0072 35.1217C61.0072 28.2044 58.3141 21.7051 53.4238 16.8197Z"
          fill="#2C324F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4717_29487">
          <rect width="51.7708" height="51.7708" fill="white" transform="translate(9.23633 9.23633)" />
        </clipPath>
      </defs>
    </svg>
  );
}

function liquidityNothingToDo(): JSX.Element {
  return (
    <svg width="140" height="140" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4717_29444)">
        <path
          d="M61.7357 25.5601C61.7357 24.5976 60.9531 23.8101 59.9857 23.8101H50.0447C49.7482 23.8101 49.4954 24.0628 49.4954 24.3594C49.4954 24.6559 49.7482 24.9087 50.0447 24.9087H59.9857C60.3454 24.9087 60.642 25.2003 60.642 25.5649V53.6427L49.301 39.7059C48.9656 39.2927 48.4697 39.0594 47.9399 39.0594L10.0184 39.0788C9.33781 39.0788 8.73017 39.4628 8.43364 40.0802C8.14197 40.6927 8.22461 41.4073 8.65725 41.942L19.3663 55.1059C19.7017 55.5191 20.1975 55.7524 20.7274 55.7524H41.5524C41.8489 55.7524 42.1017 55.4996 42.1017 55.2031C42.1017 54.9066 41.8489 54.6538 41.5524 54.6538H20.7274C20.5232 54.6538 20.3385 54.5663 20.2121 54.4059L9.50308 41.242C9.27461 40.9649 9.36697 40.6635 9.41558 40.5517C9.49822 40.3621 9.69753 40.1628 10.0184 40.1628H47.9496C48.1538 40.1628 48.3385 40.2503 48.4649 40.4108L60.0441 54.6441H45.451C45.1545 54.6441 44.9017 54.8969 44.9017 55.1934C44.9017 55.4899 45.1545 55.7427 45.451 55.7427H61.1864C61.3274 55.7427 61.4732 55.6795 61.5802 55.5677C61.6822 55.4656 61.7357 55.3295 61.7357 55.1983V25.5601Z"
          fill="#2C324F"
        />
        <path
          d="M52.879 50.4778L50.1519 46.8951C49.8505 46.5014 49.3741 46.2632 48.8783 46.2632H38.2325C37.8193 46.2632 37.4547 46.4917 37.27 46.8562C37.0901 47.2257 37.1338 47.6535 37.3818 47.9792L40.1137 51.5618C40.4151 51.9555 40.8915 52.1937 41.3873 52.1937H52.0332C52.4366 52.1937 52.8061 51.9653 52.9957 51.5958C53.1707 51.2312 53.1318 50.7986 52.8838 50.4729L52.879 50.4778ZM41.3727 51.1C41.222 51.1 41.0762 51.0319 40.9741 50.9055V50.8958L38.2665 47.3424H48.8734C49.0241 47.3424 49.17 47.4104 49.272 47.5368L51.9797 51.0951H41.3727V51.1Z"
          fill="#2C324F"
        />
        <path
          d="M34.9999 51.1001H30.1631C29.8665 51.1001 29.6138 51.3529 29.6138 51.6494C29.6138 51.9459 29.8665 52.1987 30.1631 52.1987H34.9999C35.2964 52.1987 35.5492 51.9459 35.5492 51.6494C35.5492 51.3529 35.2964 51.1001 34.9999 51.1001Z"
          fill="#2C324F"
        />
        <path
          d="M33.2354 48.4751H31.1792C30.8827 48.4751 30.6299 48.7279 30.6299 49.0244C30.6299 49.3209 30.8827 49.5737 31.1792 49.5737H33.2354C33.532 49.5737 33.7847 49.3209 33.7847 49.0244C33.7847 48.7279 33.532 48.4751 33.2354 48.4751Z"
          fill="#2C324F"
        />
        <path
          d="M39.1607 24.9182H46.1462C46.4427 24.9182 46.6955 24.6655 46.6955 24.3689C46.6955 24.0724 46.4427 23.8196 46.1462 23.8196H39.1607C38.801 23.8196 38.5045 23.528 38.5045 23.1634L38.5142 21.4766C38.5142 20.5141 37.7316 19.7266 36.7642 19.7266L21.3545 19.7363C20.392 19.7363 19.6045 20.5189 19.6045 21.4863V33.8092C19.6045 34.1057 19.8573 34.3585 20.1538 34.3585C20.4503 34.3585 20.7031 34.1057 20.7031 33.8092V21.4863C20.7031 21.1266 20.9948 20.83 21.3594 20.83L36.7691 20.8203C37.1288 20.8203 37.4253 21.112 37.4253 21.4766L37.4156 23.1634C37.4156 24.1259 38.2031 24.9134 39.1656 24.9134L39.1607 24.9182Z"
          fill="#2C324F"
        />
        <path
          d="M24.3198 37.46C24.0233 37.46 23.7705 37.7127 23.7705 38.0093V38.5877H24.8643V38.0093C24.8643 37.7079 24.6163 37.46 24.315 37.46H24.3198Z"
          fill="#BDBDBD"
        />
        <path
          d="M55.5478 45.0288C55.2513 45.0288 54.9985 45.2816 54.9985 45.5781V46.0302L55.8541 47.0802C55.9999 46.9781 56.0923 46.8128 56.0923 46.633V45.5781C56.0923 45.2816 55.8395 45.0288 55.543 45.0288H55.5478Z"
          fill="#06A76C"
        />
        <path
          d="M24.3198 32.5063C24.0233 32.5063 23.7705 32.7591 23.7705 33.0557V35.039C23.7705 35.3404 24.0136 35.5883 24.3198 35.5883C24.6261 35.5883 24.8691 35.3355 24.8691 35.039V33.0557C24.8691 32.7591 24.6163 32.5063 24.3198 32.5063Z"
          fill="#06A76C"
        />
        <path
          d="M24.3198 27.5532C24.0233 27.5532 23.7705 27.806 23.7705 28.1025V30.0859C23.7705 30.3824 24.0233 30.6352 24.3198 30.6352C24.6163 30.6352 24.8691 30.3824 24.8691 30.0859V28.1025C24.8691 27.806 24.6163 27.5532 24.3198 27.5532Z"
          fill="#06A76C"
        />
        <path
          d="M24.3198 22.5996C24.0233 22.5996 23.7705 22.8524 23.7705 23.1489V25.1322C23.7705 25.4288 24.0233 25.6816 24.3198 25.6816C24.6163 25.6816 24.8691 25.4288 24.8691 25.1322V23.1489C24.8691 22.8524 24.6163 22.5996 24.3198 22.5996Z"
          fill="#06A76C"
        />
        <path
          d="M24.3198 17.6362C24.0233 17.6362 23.7705 17.889 23.7705 18.1855V20.1689C23.7705 20.4703 24.0136 20.7182 24.3198 20.7182C24.6261 20.7182 24.8691 20.4654 24.8691 20.1689V18.1855C24.8691 17.889 24.6163 17.6362 24.3198 17.6362Z"
          fill="#06A76C"
        />
        <path
          d="M25.924 14.1606C25.924 13.8592 25.6809 13.6113 25.3747 13.6113H24.3198C24.0233 13.6113 23.7705 13.8641 23.7705 14.1606V15.2155C23.7705 15.512 24.0233 15.7648 24.3198 15.7648C24.6163 15.7648 24.8691 15.512 24.8691 15.2155V14.7099H25.3747C25.6712 14.7099 25.924 14.4572 25.924 14.1606Z"
          fill="#06A76C"
        />
        <path
          d="M40.9741 13.6113H38.8984C38.6019 13.6113 38.3491 13.8641 38.3491 14.1606C38.3491 14.4572 38.6019 14.7099 38.8984 14.7099H40.9741C41.2706 14.7099 41.5234 14.4572 41.5234 14.1606C41.5234 13.8641 41.2706 13.6113 40.9741 13.6113Z"
          fill="#06A76C"
        />
        <path
          d="M30.5713 13.6113H28.4956C28.1991 13.6113 27.9463 13.8641 27.9463 14.1606C27.9463 14.4572 28.1991 14.7099 28.4956 14.7099H30.5713C30.8678 14.7099 31.1206 14.4572 31.1206 14.1606C31.1206 13.8641 30.8775 13.6113 30.5713 13.6113Z"
          fill="#06A76C"
        />
        <path
          d="M35.7773 13.6113H33.7016C33.4051 13.6113 33.1523 13.8641 33.1523 14.1606C33.1523 14.4572 33.4051 14.7099 33.7016 14.7099H35.7773C36.0739 14.7099 36.3266 14.4572 36.3266 14.1606C36.3266 13.8641 36.0739 13.6113 35.7773 13.6113Z"
          fill="#06A76C"
        />
        <path
          d="M46.1704 13.6113H44.0947C43.7982 13.6113 43.5454 13.8641 43.5454 14.1606C43.5454 14.4572 43.7982 14.7099 44.0947 14.7099H46.1704C46.4669 14.7099 46.7197 14.4572 46.7197 14.1606C46.7197 13.8641 46.4767 13.6113 46.1704 13.6113Z"
          fill="#06A76C"
        />
        <path
          d="M51.377 13.6113H49.3013C49.0047 13.6113 48.752 13.8641 48.752 14.1606C48.752 14.4572 49.0047 14.7099 49.3013 14.7099H51.377C51.6735 14.7099 51.9263 14.4572 51.9263 14.1606C51.9263 13.8641 51.6735 13.6113 51.377 13.6113Z"
          fill="#06A76C"
        />
        <path
          d="M55.5475 15.7599C55.8441 15.7599 56.0968 15.5072 56.0968 15.2106V14.1606C56.0968 13.8641 55.8441 13.6113 55.5475 13.6113H54.4927C54.1961 13.6113 53.9434 13.8641 53.9434 14.1606C53.9434 14.4572 54.1961 14.7099 54.4927 14.7099H54.9982V15.2155C54.9982 15.512 55.251 15.7648 55.5475 15.7648V15.7599Z"
          fill="#06A76C"
        />
        <path
          d="M55.5478 34.1836C55.2513 34.1836 54.9985 34.4364 54.9985 34.7329V36.9058C54.9985 37.2023 55.2513 37.4551 55.5478 37.4551C55.8444 37.4551 56.0971 37.2023 56.0971 36.9058V34.7329C56.0971 34.4364 55.8444 34.1836 55.5478 34.1836Z"
          fill="#06A76C"
        />
        <path
          d="M55.5478 28.7681C55.2513 28.7681 54.9985 29.0208 54.9985 29.3174V31.4903C54.9985 31.7868 55.2513 32.0396 55.5478 32.0396C55.8444 32.0396 56.0971 31.7868 56.0971 31.4903V29.3174C56.0971 29.0208 55.8444 28.7681 55.5478 28.7681Z"
          fill="#06A76C"
        />
        <path
          d="M55.5478 17.9233C55.2513 17.9233 54.9985 18.1761 54.9985 18.4726V20.6456C54.9985 20.9421 55.2513 21.1949 55.5478 21.1949C55.8444 21.1949 56.0971 20.9421 56.0971 20.6456V18.4726C56.0971 18.1761 55.8444 17.9233 55.5478 17.9233Z"
          fill="#06A76C"
        />
        <path
          d="M55.5478 39.6133C55.2513 39.6133 54.9985 39.8661 54.9985 40.1626V42.3355C54.9985 42.6369 55.2416 42.8848 55.5478 42.8848C55.8541 42.8848 56.0971 42.632 56.0971 42.3355V40.1626C56.0971 39.8661 55.8444 39.6133 55.5478 39.6133Z"
          fill="#06A76C"
        />
        <path
          d="M55.5478 23.3384C55.2513 23.3384 54.9985 23.5912 54.9985 23.8877V26.0606C54.9985 26.3571 55.2513 26.6099 55.5478 26.6099C55.8444 26.6099 56.0971 26.3571 56.0971 26.0606V23.8877C56.0971 23.5912 55.8444 23.3384 55.5478 23.3384Z"
          fill="#06A76C"
        />
      </g>
      <defs>
        <clipPath id="clip0_4717_29444">
          <rect width="53.4722" height="42.1361" fill="white" transform="translate(8.26367 13.6113)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function InvoiceUploadSuccessGraphic(props: { summary: ClientInvoiceUploadSummary }): JSX.Element {
  if (props.summary.unmappedCount > 0) {
    return liquiditySuccess();
  }
  return liquidityNothingToDo();
}
