import { AxiosResponse } from 'axios';
import { LosApplication } from '../../schemas/los.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { capfinOnboardingApiConfig } from '../../../config';

const basePath = `${capfinOnboardingApiConfig.CAPFIN_ONBOARDING_URL}/capfin-onboard/public/v1`;

export class CapfinOnboardingService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  async getApplication(): Promise<AxiosResponse<LosApplication>> {
    return this.httpService.get<LosApplication>(basePath + '/application', {});
  }

  async createApplicant(trackingTokens: string[]): Promise<AxiosResponse<any>> {
    return this.httpService.post<LosApplication>(basePath + '/applicant', {
      data: JSON.stringify({
        trackingTokens,
      }),
    });
  }
}
