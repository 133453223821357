import React, { Dispatch, useReducer } from 'react';
import { ALERT_VARIANTS } from '@c2fo/react-components/dist/lib/design/AlertVariants';
import { ProductType, TransferMethodPreference } from '../../schemas';
import { RFHomeActions, rfHomeReducer } from './reducers';
import { RFHomeState } from './RFHomeState';

const initialState = {
  isLoading: false,
  client: {
    divisionUuid: '',
    isPreQualificationFormReviewed: false,
    isFinancialFilesPreQualificationComplete: false,
    isFinancialFilesComplete: false,
    uuid: '',
    productType: ProductType.ReceivablesFinance,
    isClosed: false,
    wireFee: 0,
    transferMethodPreference: TransferMethodPreference.ACH,
  },
  advance: null,
  upload: null,
  balance: {
    uuid: '',
    divisionUuid: '',
    accruedInterestAmount: 0,
    advanceAmount: 0,
    arAdjustments: 0,
    outstandingAdjustments: 0,
    totalReceiptAmount: 0,
    netInvoiceAmount: 0,
    outstandingBalance: 0,
    arBalance: 0,
    marginalCollateralBalance: 0,
    availabilityBalance: 0,
    clientAvailableCredit: 0,
    clientCreditLimit: 0,
  },
  requestStatus: null,
  message: {
    isOpen: false,
    severity: 'success' as keyof typeof ALERT_VARIANTS,
    message: '',
  },
};

const RFHomeContext = React.createContext<{ state: RFHomeState; dispatch: Dispatch<RFHomeActions> }>({
  state: initialState,
  dispatch: () => {},
});

const RFHomeStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(rfHomeReducer, initialState);

  return <RFHomeContext.Provider value={{ state, dispatch }}>{children}</RFHomeContext.Provider>;
};

export { RFHomeStateProvider, RFHomeContext };
