import { BaseBalanceService } from '../BaseBalanceService/BaseBalanceService';
import { ClientBalance } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

const clientBalanceBasePath = (divisionUuid: string) => `/v2/tpf-api/divisions/${divisionUuid}/balances`;

/**
 * Service for interacting with client balance API endpoints.
 */
export class ClientBalanceService extends BaseBalanceService<ClientBalance> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, clientBalanceBasePath);
  }
}
