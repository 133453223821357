import { HttpService } from '../../../lib/http/HttpService';
import { User } from '../../schemas/user.schema';
import { legacyC2foApiConfig } from '../../../config/legacyC2foApiConfig/legacyC2foApiConfig';

export class EarlyPayService {
  constructor(private httpService: HttpService) {}

  async getUser(): Promise<User | undefined> {
    const response = await this.httpService.get<User>(`${legacyC2foApiConfig.LEGACY_C2FO_UI_BASE_URL}/user/me`);
    return response.data;
  }
}
