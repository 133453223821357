import React, { useState } from 'react';
import { Client } from '../../schemas';
import {
  ClientInvoiceUploadSummary,
  CustomerMapping,
  FactorInvoiceDraftTotals,
  FactoringUploadDraftTotals,
  FactoringUploadFinalizeDto,
  InvoiceUploadError,
  InvoiceUploadUnmapped,
} from '../../schemas/invoiceUpload.schema';
import { InvoiceUploadComplete } from './InvoiceUploadComplete';
import { InvoiceUploadFactoringApproval } from './InvoiceUploadFactoringApproval';
import { InvoiceUploadInterstitial } from './InvoiceUploadInterstitial';
import { InvoiceUploadMapping } from './InvoiceUploadMapping';
import { InvoiceUploadWhatsNext } from './InvoiceUploadWhatsNext';
import { ProductType } from '../../schemas/enums.schema';
import { UploadCard } from './UploadCard';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

/**
 * Invoice Upload is effectively a state machine, albeit one the user
 * doesn't deal with much directly.
 *
 * For reference on all the states,
 * please see the invoice upload flow image.
 *
 * Many, many apologies to potential readers who have difficulty seeing.
 */

type State =
  /**
   * The initial state. We are displaying the card, but the user
   * has not selected a file yet.
   */
  | {
      state: 'START';
    }
  /**
   * The user has selected a file, and we are processing it in the background.
   *
   * This is the state where we display the loading spinner and the intersitial messages
   */
  | {
      state: 'VERIFY';
    }
  | {
      state: 'SHOW_ERRORS';
      errors: InvoiceUploadError[];
    }
  | {
      state: 'MAPPING_REQUIRED';
      summary: ClientInvoiceUploadSummary;
      unmapped: InvoiceUploadUnmapped[];
      errors: InvoiceUploadError[];
      handleSubmitMappings: (mappings: CustomerMapping[]) => Promise<void>;
    }
  | {
      state: 'UPLOAD_COMPLETE';
      summary: ClientInvoiceUploadSummary;
      errors: InvoiceUploadError[];
    }
  | {
      state: 'FACTORING_REVIEW';
      totals: FactoringUploadDraftTotals;
    }
  | {
      state: 'WHATS_NEXT';
    };

export function InvoiceUpload(props: {
  onComplete: () => void;
  onClose: () => void;
  factoringDraftInvoiceTotals: FactorInvoiceDraftTotals | null;
  client: Client;
}) {
  let [state, setState] = useState<State>(() => {
    if (props.factoringDraftInvoiceTotals?.totalCount) {
      return {
        state: 'FACTORING_REVIEW',
        totals: props.factoringDraftInvoiceTotals,
      };
    }
    return {
      state: 'START',
    };
  });
  let { invoiceUploadService } = useServices();
  let divisionUuid = useCurrentDivision();
  let productType = useProductType();

  const handleFileSelect = async (file: File) => {
    setState({
      state: 'VERIFY',
    });
    let response = await invoiceUploadService.uploadInvoiceFile(divisionUuid, file, productType);
    if (response.hardErrors) {
      setState({
        state: 'SHOW_ERRORS',
        errors: response.hardErrors,
      });
    } else if (response.unmapped.length > 0) {
      let { summary, softErrors } = response;
      setState({
        state: 'MAPPING_REQUIRED',
        summary,
        unmapped: response.unmapped,
        errors: softErrors,
        handleSubmitMappings: async (mappings) => {
          let updatedSummary = await invoiceUploadService.addMappings(
            divisionUuid,
            productType,
            summary.uuid,
            mappings,
          );
          setState({
            state: 'UPLOAD_COMPLETE',
            errors: softErrors,
            summary: updatedSummary,
          });
        },
      });
    } else {
      setState({
        state: 'UPLOAD_COMPLETE',
        summary: response.summary,
        errors: response.softErrors,
      });
    }
  };

  const goToWhatsNext = () => {
    if (invoiceUploadService.shouldShowWhatsNext()) {
      setState({
        state: 'WHATS_NEXT',
      });
    } else {
      return props.onComplete();
    }
  };

  const handleAcknowledgeSuccess = async () => {
    /* istanbul ignore next */
    if (state.state !== 'UPLOAD_COMPLETE') {
      throw new Error('unreachable');
    }
    if (productType === ProductType.Factoring && state.summary.successCount > 0) {
      let totals = await invoiceUploadService.getFactoringUploadDraftTotals(divisionUuid);
      setState({
        state: 'FACTORING_REVIEW',
        totals,
      });
    } else {
      return goToWhatsNext();
    }
  };

  const handleWhatsNextOk = ({ dontShowAgain }: { dontShowAgain: boolean }) => {
    if (dontShowAgain === true) {
      invoiceUploadService.dontShowWhatsNext();
    }
    return props.onComplete();
  };

  const handleFactoringFinalize = async (data: FactoringUploadFinalizeDto) => {
    await invoiceUploadService.finalizeFactoringUpload(divisionUuid, data);
    return goToWhatsNext();
  };

  return (
    <>
      {state.state === 'START' && <UploadCard onFileSelect={handleFileSelect} onClose={props.onClose} />}
      {state.state === 'VERIFY' && <InvoiceUploadInterstitial />}
      {state.state === 'SHOW_ERRORS' && (
        <UploadCard onFileSelect={handleFileSelect} errors={state.errors} onClose={props.onClose} />
      )}
      {state.state === 'MAPPING_REQUIRED' && (
        <InvoiceUploadMapping unmapped={state.unmapped} onSubmit={state.handleSubmitMappings} onClose={props.onClose} />
      )}
      {state.state === 'UPLOAD_COMPLETE' && (
        <InvoiceUploadComplete
          summary={state.summary}
          errors={state.errors}
          onAcknowledgeSuccess={handleAcknowledgeSuccess}
          onClose={props.onClose}
        />
      )}
      {state.state === 'WHATS_NEXT' && <InvoiceUploadWhatsNext onOk={handleWhatsNextOk} onClose={props.onClose} />}
      {state.state === 'FACTORING_REVIEW' && (
        <InvoiceUploadFactoringApproval
          totals={state.totals}
          onFinalize={handleFactoringFinalize}
          client={props.client}
        />
      )}
    </>
  );
}
