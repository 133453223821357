import React, { useState } from 'react';
import { AdvanceRequestExceedsAvailabilityError } from '../../schemas';
import {
  Alert,
  BaseSelect,
  CTAButton,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  PrimaryButton,
  SecondaryButton,
  TextField,
  Theme,
  TypeBase,
  TypeLegalese,
  TypeSubsectionHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { AxiosError } from 'axios';
import { Client, ClientBalance, TransferMethodPreference } from '../../schemas';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { InterestThisMonth, OutstandingBalance, RFAvailabilityCalculation } from './RFAccountOverview';
import { MaskedInput } from '../Form/MaskedInput';
import { SummaryCard, SummaryCardLargeAmount } from '../SummaryCard/SummaryCard';
import { SummaryCardAccountOverviewGroup } from '../SummaryCard/SummaryCardAccountOverview';
import { SummaryCardChevron } from '../SummaryCard/SummaryCardChevron';
import { SummaryCardHeader, SummaryCardHeaderSection } from '../SummaryCard/SummaryCardHeader';
import { toCurrency } from '../../i18n/currency';
import { useServices } from '../../services';

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    '&.open': {
      top: '-1rem',
    },
    transition: '1s ease all',
    background: '#FFF',
    maxWidth: '95%',
    margin: '0 auto',
    padding: '1rem',
    borderRadius: '5px',
    zIndex: 1,
    top: '-10.8rem',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      maxWidth: '100%',
      top: 0,
      padding: '1rem 2rem',
      borderRadius: 0,
    },
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
  },
  modalTitle: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  subtitle: {
    margin: 0,
  },
  amount: {
    fontSize: '2.5rem',
    margin: 0,
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '4rem',
      margin: 0,
    },
  },
  input: {
    width: '100%',
    margin: '1rem auto',
    [theme.breakpoints.up('md')]: {
      height: '56px',
      paddingRight: '.5rem',
    },
  },
  selectInput: {
    width: '100%',
    margin: '1rem auto',
    [theme.breakpoints.up('md')]: {
      width: '95%',
    },
  },
  button: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      height: '56px',
      marginTop: '1rem',
    },
  },
  title: {
    padding: theme.spacing(5, 0),
    width: '100%',
  },
  buttonGroup: {
    '& > button': {
      margin: `auto ${theme.spacing(1)}px`,
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
  legalText: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

interface RFSummaryRequestCardProps {
  client: Client;
  balance: ClientBalance;
  onConfirmRequest: (amount: number, transactionType: TransferMethodPreference) => Promise<void>;
  isReadOnlyUser: boolean;
}

export const RFSummaryRequestCard: React.FC<RFSummaryRequestCardProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles({});
  const [openCard, setOpenCard] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [requestAmt, setRequestAmt] = React.useState<string>('');
  const [transactionType, setTransactionType] = React.useState<TransferMethodPreference>(
    props.client.transferMethodPreference,
  );

  const { tokenService, analyticsService } = useServices();

  const handleTransactionType = (event: any) => {
    setTransactionType(event.target.value);
  };

  const closeDialog = () => {
    setOpenModal(false);
  };

  const openDialog = () => {
    setOpenModal(true);
  };

  const confirmRequest = () => {
    const currentUserUUID: string = tokenService.getTokenContent()?.payload.user.uuid || '';
    if (currentUserUUID) {
      analyticsService.track('capfin:capfin_app:request_advance:submit', currentUserUUID);
    }
    const numberAmount = Number(requestAmt.replace('$', '').replace(/,/g, ''));
    props.onConfirmRequest(numberAmount, transactionType);
    setOpenModal(false);
  };

  function toggleCard() {
    setOpenCard(!openCard);
  }

  const requestExceedsAvailability = Number(requestAmt) > props.balance.availabilityBalance;

  return (
    <div data-testid="RFSummaryRequestCard">
      {/* Start Confirmation Modal */}
      <Modal
        open={openModal}
        variant="informational"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        modalTitle={
          <div className={classes.title}>
            <TypeSubsectionHeader customColor={theme.palette.secondary.main} align={'center'}>
              Confirm Your Advance Request for{' '}
              <span data-cy={'confirm-advance-amount'}>{requestAmt && toCurrency(requestAmt)}</span>
            </TypeSubsectionHeader>
          </div>
        }
        actionsContent={
          <div className={classes.buttonGroup}>
            <SecondaryButton onClick={closeDialog}>Cancel</SecondaryButton>
            <PrimaryButton onClick={confirmRequest} data-cy={'confirm-advance'} data-testid={'confirm-advance'}>
              Request Advance
            </PrimaryButton>
          </div>
        }
      >
        <div className={classes.legalText}>
          <TypeLegalese>
            By clicking on Request Advance, I hereby certify, on behalf of the Client/Borrower, that (1) no Event of
            Default exists or would result from the requested advance and (2) the representations and warranties made by
            the Client/Borrower in the Agreement are true and correct on today’s date except to the extent such
            representations and warranties relate solely to an earlier date.
          </TypeLegalese>
        </div>
      </Modal>
      {/* End Confirmation Modal */}
      <SummaryCard>
        <SummaryCardHeader>
          <SummaryCardHeaderSection>
            <SummaryCardLargeAmount
              isInverse
              description={
                <>
                  Available to Request an Advance &nbsp;
                  <InfoTooltip isInverse={false} placement="bottom" name="rfSummaryRequest.AvailableToRequestAnAdvance">
                    <RFAvailabilityCalculation balance={props.balance} />
                  </InfoTooltip>
                </>
              }
              value={props.balance.availabilityBalance}
              data-cy="availability-balance"
            />
            <SummaryCardChevron onClick={toggleCard} isOpen={openCard} />
          </SummaryCardHeaderSection>
          <SummaryCardAccountOverviewGroup>
            <OutstandingBalance isInverse balance={props.balance} />
            <InterestThisMonth isInverse balance={props.balance} />
          </SummaryCardAccountOverviewGroup>
        </SummaryCardHeader>

        <Grid
          className={`${classes.footer} ${openCard === false ? '' : 'open'}`}
          container
          spacing-xs={0}
          spacing-md={3}
          spacing-lg={3}
        >
          <Grid item xs={12} md={6} lg={5}>
            <FormControl variant={'outlined'} className={classes.input} error={requestExceedsAvailability}>
              <InputLabel shrink htmlFor="advance-request-input" error={requestExceedsAvailability}>
                <span style={{ color: 'black' }}>Advance Request</span>
              </InputLabel>
              <MaskedInput
                label={'Advance Request'}
                id="advance-request-input"
                mask={'currency'}
                notched={true}
                error={requestExceedsAvailability}
                onUpdate={(value) => {
                  setRequestAmt(value);
                }}
                allowMinus={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <FormControl variant={'outlined'} className={classes.input}>
              <InputLabel shrink htmlFor="transactionType" style={{ color: 'black' }}>
                Transfer Method
              </InputLabel>
              <BaseSelect
                fullWidth={true}
                id="transactionType"
                options={[
                  {
                    label: `Wire Transfer (+${toCurrency(props.client.wireFee)} Fee)`,
                    value: TransferMethodPreference.WIRE,
                  },
                  {
                    label: 'ACH (+$0 Fee)',
                    value: TransferMethodPreference.ACH,
                  },
                ]}
                onChange={handleTransactionType}
                defaultValue={TransferMethodPreference.ACH}
                value={transactionType}
                label={'Transfer Method'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <CTAButton
              onClick={openDialog}
              className={classes.button}
              data-cy={'request-advance'}
              data-testid={'request-advance'}
              disabled={
                !requestAmt || Number.isNaN(Number(requestAmt)) || requestExceedsAvailability || props.isReadOnlyUser
              }
            >
              Submit
            </CTAButton>
          </Grid>
          {requestExceedsAvailability && (
            <Grid item xs={12}>
              <TypeBase isError>
                Request cannot exceed availability ({toCurrency(props.balance.availabilityBalance)}).
              </TypeBase>
            </Grid>
          )}
        </Grid>
      </SummaryCard>
    </div>
  );
};
