import React from 'react';
import { BuilderNameAndDescription } from './hooks';
import { TextField, Theme, TypeLabel, TypeSubsectionHeader, makeStyles } from '@c2fo/react-components';
import { useId } from '@reach/auto-id';

const useStyles = makeStyles((theme: Theme) => ({
  formStyles: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function InvoiceReportSaveStep(props: {
  reportNameState: BuilderNameAndDescription;
  optionalCloneName?: string | null;
}) {
  const { reportNameState } = props;
  const classes = useStyles();
  const reportNameId = useId();
  const reportDescriptionId = useId();

  const handleNameChange = (event: any) => {
    reportNameState.setName(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    reportNameState.setDescription(event.target.value);
  };

  return (
    <>
      <TypeSubsectionHeader data-cy="customReportSubHeading">Step 3 of 3: Save and Download</TypeSubsectionHeader>
      <TypeLabel>
        If you’d like to save this report, please enter a name. Otherwise, you can just download your one time custom
        report.
      </TypeLabel>
      <form className={classes.formStyles}>
        <TextField
          id={reportNameId}
          size="small"
          variant="outlined"
          label="Report Name"
          data-cy="reportNameInput"
          placeholder={props.optionalCloneName ? `Copy of ${props.optionalCloneName}` : undefined}
          helperText="Optional"
          color={'primary'}
          value={reportNameState.name}
          onChange={handleNameChange}
        />
        <TextField
          id={reportDescriptionId}
          size="small"
          variant="outlined"
          label="Description"
          data-cy="reportDescriptionInput"
          color={'primary'}
          multiline
          rows="4"
          value={reportNameState.description}
          onChange={handleDescriptionChange}
        />
      </form>
    </>
  );
}
