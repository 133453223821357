import React from 'react';
import joyrideSteps from '../../joyrides/HomepageV2Factoring';
import { ARPSummaryCard } from '../../components/ARPSummaryCard/ARPSummaryCard';
import { Client } from '../../schemas';
import { DigitalData } from '../../../lib';
import { Grid, Hidden } from '@c2fo/react-components';
import { HomepageInvoiceUploadCard } from '../../components/HomepageInvoiceUploadCard/HomepageInvoiceUploadCard';
import { HomepageInvoiceUploadCardSkeleton } from '../../components/HomepageInvoiceUploadCard/HomepageInvoiceUploadCardSkeleton';
import { PageTitle } from '../../components/PageWrapper/PageTitle';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { ProductType } from '../../schemas/enums.schema';
import { QueryParamEnabledJoyride } from '../../joyrides/ShepherdJoyride';
import { SummaryCardSkeleton } from '../../components/SummaryCard/SummaryCardSkeleton';
import { useAccountStatus } from '../../hooks/accountStatus';
import { useAsync } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';

export const FactoringHomePage: React.FC<{ isReadOnlyUser: boolean }> = (props) => {
  DigitalData.Hooks.usePageTitle('Factoring - Home');
  DigitalData.Hooks.useSection('product', 'home');

  const divisionUuid = useCurrentDivision();

  const {
    clientService,
    invoiceUploadService,
    arpBalanceService,
    customerPaymentService,
    unsettledBalanceReportService,
  } = useServices();
  const productType = ProductType.Factoring;

  const arpBalance = useAsync(async () => arpBalanceService.getBalance(divisionUuid));

  const customerPayment = useAsync(async () => customerPaymentService.getLatestCustomerPayment(divisionUuid));

  const latestInvoiceUpload = useAsync(async () => invoiceUploadService.getLatestUpload(divisionUuid, productType));

  const draftInvoiceTotals = useAsync(async () => invoiceUploadService.getFactoringUploadDraftTotals(divisionUuid));

  const asyncClientState = useAsync<Client>(async () => clientService.getClient(divisionUuid));

  const unsettledBalanceReport = useAsync(async () => unsettledBalanceReportService.getUnsettledReport(divisionUuid));

  let isClosedAccount = asyncClientState.value?.isClosed ?? false;
  let summaryCardWidth: 9 | 12 = isClosedAccount ? 12 : 9;

  useAccountStatus({ isClosed: isClosedAccount, isOverdrawn: false });

  let summaryCard =
    arpBalance.value && !customerPayment.loading ? (
      <ARPSummaryCard
        balance={arpBalance.value}
        lastCustomerPayment={customerPayment.value ?? null}
        lastUpload={latestInvoiceUpload.value ?? null}
        unsettledReport={unsettledBalanceReport.value ?? null}
      />
    ) : (
      <SummaryCardSkeleton />
    );
  let isMobile = useIsMobile();
  if (isMobile) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {summaryCard}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {arpBalance.value && !customerPayment.loading && <QueryParamEnabledJoyride steps={joyrideSteps} />}
      <PageWrapper>
        <PageTitle>Balance Summary</PageTitle>

        <Grid container style={{ paddingTop: '2rem' }} spacing={3}>
          <Grid item sm={summaryCardWidth}>
            {summaryCard}
          </Grid>
          {!isClosedAccount && (
            <Hidden smDown>
              <Grid item sm={3}>
                {draftInvoiceTotals.loading ? (
                  <HomepageInvoiceUploadCardSkeleton />
                ) : (
                  <HomepageInvoiceUploadCard
                    productType={productType}
                    lastUpload={latestInvoiceUpload.value ?? null}
                    draftInvoiceTotal={draftInvoiceTotals.value?.totalPurchasePrice ?? null}
                    isReadOnlyUser={props.isReadOnlyUser}
                  />
                )}
              </Grid>
            </Hidden>
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};
