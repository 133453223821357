import { AxiosResponse } from 'axios';
import {
  ErrorResponse,
  FileUploadResponse,
  FinancialFile,
  LabelCategoriesObj,
  RequiredDocuments,
} from '../../schemas/fileManagement.schema';
import { PageableResponse, Query } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
export const fileManagementBasePath = (clientsUuid: string) =>
  `${apiUrl}/v2/tpf-api/clients/${clientsUuid}/labeled-financial-files`;
const requiredDocumentBasePath = (clientsUuid: string) =>
  `${apiUrl}/v2/tpf-api/clients/${clientsUuid}/client-required-documents`;
const labelCategoryBasePath = `${apiUrl}/v2/tpf-api/label-categories/quarterly-financial-upload-labels`;
const isClientUploaded = true;

/**
 * Service for interacting with client API endpoints.
 */
export class FileManagementService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  /**
   * get uploaded files
   * @param clientUuid
   */
  async getUploadedFiles(clientUuid: string, params: Query<FinancialFile>): Promise<PageableResponse<FinancialFile>> {
    try {
      let response = await this.httpService.get<PageableResponse<FinancialFile>>(
        fileManagementBasePath(clientUuid) + `?isClientUploaded=${isClientUploaded}&deleted=false`,
        {
          params: this.httpService.serializeQueryParams(params),
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(`Uploaded files get error debug: ${error}`);
    }
  }

  /**
   * uploads financial file on s3
   * @param clientUuid
   * @param fileName
   * @param isClientUploaded
   */
  async uploadFinancialFile(
    clientUuid: string,
    file: File,
    fileName: string,
    categoryEnum: string,
    labelUuid: string,
  ): Promise<AxiosResponse<FileUploadResponse | ErrorResponse>> {
    let searchParam = new URLSearchParams();
    if (categoryEnum) {
      searchParam.append('categoryEnum', categoryEnum);
    }
    if (labelUuid) {
      searchParam.append('labelUuid', labelUuid);
    }
    searchParam.append('isClientUploaded', String(isClientUploaded));
    try {
      const response = await this.httpService.post<FileUploadResponse>(
        fileManagementBasePath(clientUuid) + `/${fileName}?${searchParam.toString()}`,
        {
          data: file,
        },
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  /**
   * get urgently required documents
   * @param clientUuid
   */
  async getUrgentlyRequiredDocuments(clientUuid: string): Promise<PageableResponse<RequiredDocuments>> {
    const response = await this.httpService.get<PageableResponse<RequiredDocuments>>(
      requiredDocumentBasePath(clientUuid),
    );
    return response.data;
  }

  /**
   * deletes a financial file uploaded by the client
   * @param clientUuid
   * @param fileUuid
   */
  async deleteFile(clientUuid: string, fileUuid: string): Promise<AxiosResponse<any>> {
    return this.httpService.delete(fileManagementBasePath(clientUuid) + `/${fileUuid}`);
  }

  /**
   * rename financial file
   * @param clientUuid
   */
  async renameFinancialFile(clientUuid: string, fileObj: FinancialFile): Promise<FinancialFile> {
    const response = await this.httpService.put<FinancialFile>(
      fileManagementBasePath(clientUuid) + `/${fileObj.uuid}`,
      {
        data: fileObj,
      },
    );
    return response.data;
  }

  /**
   * download file
   * @param clientUuid
   */
  downloadFile(clientUuid: string, fileUuid: string): Promise<AxiosResponse<Record<string, string>>> {
    return this.httpService.get<Record<string, string>>(fileManagementBasePath(clientUuid) + `/${fileUuid}`);
  }

  /**
   * get all labels associated with QUARTERLY_FINANCIAL_UPLOAD category
   */
  async getAllLabels(): Promise<Array<LabelCategoriesObj>> {
    try {
      let response = await this.httpService.get<Array<LabelCategoriesObj>>(labelCategoryBasePath);
      return response.data;
    } catch (error) {
      throw new Error(`Labels fetching gets error debug: ${error}`);
    }
  }
}
