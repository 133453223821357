export enum ActionsAllowed {
  RENAME = 'RENAME',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
}
export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface FinancialFile extends Record<string, unknown> {
  uuid: string;
  fileName: string;
  clientUuid: string;
  tpfRequiredDocumentUuid: string;
  clientUrgentlyRequired: boolean;
  filePath: string;
  isClientUploaded: boolean;
  approvalStatus: ApprovalStatus;
  actionsAllowed: ActionsAllowed[];
  reviewComments: string;
  created: string;
}
export interface RequiredDocuments extends Record<string, unknown> {
  uuid: string;
  name: string;
  tpfRequiredDocumentUuid: string;
  clientUrgentlyRequired: boolean;
}
export interface LabelCategoriesObj extends Record<string, unknown> {
  uuid: string;
  categoryEnum: string;
  labelName: string;
  labelDescription: string;
}
export interface ErrorResponse extends Record<string, string> {
  message: string;
  code: string;
}
export interface FileUploadResponse extends Record<string, unknown> {
  response: FinancialFile;
  message: string;
}
