import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  CloseIcon,
  Divider,
  Drawer,
  IconButton,
  SecondaryButton,
  Link as StyledLink,
  TypeBase,
  TypeSectionHeader,
  TypeSubsectionHeader,
  makeStyles,
  styled,
} from '@c2fo/react-components';
import { MouseoverTooltipPopover, tooltipPositionBottomCenter } from '../Tooltips/MouseoverTooltip';
import { ProductType } from '../../schemas';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../routes';
import { useAsyncQuery } from '../Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  newFeaturesButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  newFeaturesButton: {
    borderRadius: 10,
    borderWidth: '1px',
    padding: `0px ${theme.spacing(1)}px`,
  },
}));

const DotNotification = styled('span')(({ theme }) => ({
  height: '0.5em',
  width: '0.5em',
  marginLeft: theme.spacing(1) / 2,
  background: theme.palette.warning.main,
  borderRadius: '100%',
  display: 'inline-block',
  verticalAlign: 'middle',
}));

export function NewFeaturesDrawer(props: Record<string, unknown>): JSX.Element {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const divisionUuid = useCurrentDivision();

  const productType = useProductType();
  const { userNewFeaturesService } = useServices();

  const newFeaturesAsync = useAsyncQuery(() => userNewFeaturesService.query(divisionUuid, {}), [divisionUuid]);

  const hasUnseenFeatures = newFeaturesAsync.response?.data.some((feature) => feature.isSeen === false);

  const handleOpenDialog = async () => {
    setIsOpen(true);
    await Promise.all(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newFeaturesAsync
        .response!.data.filter((feature) => feature.isSeen === false)
        .map((feature) => userNewFeaturesService.markFeatureAsSeen(feature.newUiFeaturesUuid)),
    );
    await newFeaturesAsync.refetch();
  };

  const [newFeaturesPopoverEl, setNewFeaturesPopoverEl] = useState<HTMLButtonElement | null>(null);
  const newFeaturesButtonRef = useRef<HTMLButtonElement>(null);
  const newFeaturesChipRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    /**
     * If the user has unseen features, we display a
     * dialog to say that they have new features.
     */
    if (hasUnseenFeatures) {
      setNewFeaturesPopoverEl(newFeaturesButtonRef.current);
    } else {
      setNewFeaturesPopoverEl(null);
    }
  }, [hasUnseenFeatures]);

  return (
    <>
      <div className={classes.newFeaturesButtonContainer}>
        <MouseoverTooltipPopover anchorEl={newFeaturesPopoverEl} position={tooltipPositionBottomCenter}>
          We've released new features to make your tasks easier
        </MouseoverTooltipPopover>
        <Chip
          innerRef={newFeaturesChipRef}
          data-testid={'new-features-button'}
          onClick={handleOpenDialog}
          size={'small'}
          label={'New Features'}
        />
        {hasUnseenFeatures && <DotNotification data-testid={'has-unseen-features'} />}
      </div>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor={'right'} PaperProps={{ style: { width: '30vw' } }}>
        <Box padding={3} display={'flex'} justifyContent={'space-between'}>
          <TypeSectionHeader data-testid={'new-features-modal'}>New Features</TypeSectionHeader>
          <IconButton onClick={() => setIsOpen(false)} color={'secondary'}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box padding={3}>
          <TypeSubsectionHeader>New Dashboard</TypeSubsectionHeader>
          <TypeBase component={'div'}>
            We've made changes to the homepage to bring more clarity and flexibility to the experience:
            {productType === ProductType.Factoring ? (
              <ul>
                <li>Track the most recent payment received by your customers at a glance.</li>
                <li>Monitor discount fees applied to invoices purchased this month.</li>
                <li>View the date of your last invoice upload to quickly identify new invoices to upload.</li>
              </ul>
            ) : (
              <ul>
                <li>Choose between ACH or wire transfer when requesting an advance.</li>
                <li>
                  Monitor interest accrued during the month. Interest is charged to your account at the end of the
                  month.
                </li>
                <li>View the date of your last invoice upload to quickly identify new invoices to upload.</li>
              </ul>
            )}
            As always, if you have any questions or feedback please don’t hesitate to reach out to your account manager.
          </TypeBase>
          <TypeBase>
            <StyledLink
              component={RouterLink}
              data-testid={'homepage-v2-link'}
              to={routes.division(divisionUuid).product(productType).home + '?showJoyride=true'}
              onClick={() => {
                setIsOpen(false);
              }}
              color={'secondary'}
            >
              Walk me through the changes
            </StyledLink>
          </TypeBase>
        </Box>
      </Drawer>
    </>
  );
}
