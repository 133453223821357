import React from 'react';
import { ClientBalance } from '../../schemas';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { InterestThisMonth, OutstandingBalance } from './RFAccountOverview';
import { RFSummaryCardCreditLimit, SummaryCardCreditLimitAlert } from './RFSummaryCardCreditLimit';
import { makeStyles } from '@c2fo/react-components';
import { toCurrency } from '../../i18n/currency';

interface RFSummaryOverAdvancedCardProps {
  balance: ClientBalance;
  isInverse?: boolean;
}

let useStyles = makeStyles((theme) => ({
  alertContent: {
    display: 'flex',
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

export const RFSummaryOverAdvancedCard: React.FC<RFSummaryOverAdvancedCardProps> = (props) => {
  const classes = useStyles();
  return (
    <>
      <RFSummaryCardCreditLimit
        balance={props.balance}
        header={
          <SummaryCardCreditLimitAlert variant={'warning'}>
            <div data-cy={'over-advanced-alert'} className={classes.alertContent}>
              <span data-cy={'over-advanced-alert-tooltip'}>
                <InfoTooltip isInverse={false} name="rfAccountOverview.NegativeAvailablityBalance">
                  Please contact your C2FO Account Manager to discuss options to correct the negative balance.
                </InfoTooltip>
                &nbsp;
              </span>
              Over advanced by {toCurrency(Math.max(0, -props.balance.availabilityBalance))}
            </div>
          </SummaryCardCreditLimitAlert>
        }
        accountOverviewStats={
          <>
            <OutstandingBalance balance={props.balance} isOverAdvanced={true} />
            <InterestThisMonth balance={props.balance} />
          </>
        }
      />
    </>
  );
};
