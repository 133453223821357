import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface QueryParamSerializer<T> {
  fromString: (value: string) => T | null;
  toString: (value: T) => string;
}

export type QueryParam<T> = [T | null, (value: T | null) => void];

// intentionally keepting this private: we should create useTypeQueryParam for each type.
function useQueryParam<T>(key: string, { fromString, toString }: QueryParamSerializer<T>): QueryParam<T> {
  let location = useLocation();
  let history = useHistory();

  /**
   * We do a lot of useMemo /
   * useCallback things here. This is less
   * for performance reason, and more to avoid unecessary
   * re-renders, and unnecessary useEffect runs
   */
  let paramRawValue = useMemo(() => {
    let queryParams = qs.parse(location.search, { ignoreQueryPrefix: true, strictNullHandling: true });
    let param = queryParams[key] || null;
    if (Array.isArray(param)) {
      return null;
    }
    return param;
  }, [key, location.search]);

  let paramValue = useMemo(() => {
    if (paramRawValue && typeof paramRawValue === 'string') {
      return fromString(paramRawValue);
    }
    return null;
  }, [paramRawValue, fromString]);

  const setQueryParam = useCallback(
    (value: T | null) => {
      /**
       * queryParams is not memoized in any way,
       * and returns a fresh object on every call,
       * so it's result is safe to mutate in-place.
       */
      let queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true, strictNullHandling: true });
      if (value === null) {
        delete queryParams[key];
      } else {
        queryParams[key] = toString(value);
      }
      history.push({
        search: qs.stringify(queryParams, { strictNullHandling: true }),
      });
    },
    [history, key, toString],
  );
  return [paramValue, setQueryParam];
}

const BOOLEAN_SERIALIZER: QueryParamSerializer<boolean> = {
  fromString(value) {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  },
  toString(value) {
    return String(value);
  },
};

export function useBooleanQueryParam(key: string): QueryParam<boolean> {
  return useQueryParam(key, BOOLEAN_SERIALIZER);
}
