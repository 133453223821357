import React from 'react';
import { Grid, Theme, makeStyles } from '@c2fo/react-components';
import { LoadingSpinner } from '../LoadingScreen/LoadingSpinner';
import { SummaryCard } from './SummaryCard';

const useStyles = makeStyles<Theme>((theme) => ({
  '@keyframes fill-in': {
    '0%': {
      height: '0%',
    },
    '15%': {
      height: '20%',
    },
    '50%': {
      height: '60%',
    },
    '100%': {
      height: '100%',
    },
  },
  header: {
    color: '#FFF',
    position: 'relative',
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
    },
    '&:before': {
      width: '100%',
      content: '""',
      animationName: `$fill-in`,
      animationDuration: '1.5s',
      animationFillMode: 'forwards',
      backgroundColor: theme.palette.primary.main,
    },
  },
  text: {
    fontSize: '4rem',
    textAlign: 'center',
  },
}));

export const SummaryCardLoading: React.FC = (props) => {
  const classes = useStyles({});

  return (
    <SummaryCard>
      <Grid className={classes.header} container spacing={0}>
        <Grid item xs={12} md={12}>
          <LoadingSpinner />
        </Grid>
      </Grid>
    </SummaryCard>
  );
};
