import React from 'react';
import { Theme, styled } from '@c2fo/react-components';

export interface SummaryCardChevronProps {
  isOpen: boolean;
}

const InnerComponent = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & SummaryCardChevronProps,
) => {
  let { isOpen, ...rest } = props;
  return <span {...rest} />;
};

export const SummaryCardChevron = styled(InnerComponent)(
  ({ theme, isOpen }: SummaryCardChevronProps & { theme: Theme }) => ({
    position: 'absolute',
    right: '1rem',
    top: '2.2rem',
    '&::before': {
      borderStyle: 'solid',
      borderWidth: '.15rem 0.15rem 0 0',
      content: '""',
      display: 'block',
      height: '1rem',
      left: '0.15rem',
      position: 'relative',
      top: '0.15rem',
      color: 'white',
      transform: isOpen ? 'rotate(-45deg)' : 'rotate(135deg)',
      transition: 'all .5s ease',
      verticalAlign: 'top',
      width: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
);
