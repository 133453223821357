import React, { ReactNode } from 'react';
import {
  AddIcon,
  BaseChip as Chip,
  PrimaryButton,
  SecondaryButton,
  TypeBase,
  TypographySystem,
  styled,
} from '@c2fo/react-components';
import { DateFilter } from './useDateFilter';
import { MultiselectFilter } from './useMultiselectFilter';
import { format } from 'date-fns';

export function MultiselectChips<T, TKey extends string>(props: {
  filter: MultiselectFilter<T, TKey>;
  children: ReactNode;
  render?: (item: TKey) => ReactNode;
}): JSX.Element {
  const render = props.render || ((val) => val);
  return (
    <>
      {props.filter.items.map((item) => {
        const key = props.filter.key(item);
        return (
          <Chip
            key={key}
            variant="outlined"
            color="secondary"
            onDelete={() => props.filter.remove(key)}
            data-cy={`filterPill-${key}`}
            data-testid={`filterPill-${key}`}
            label={
              <>
                {props.children}: {render(key)}
              </>
            }
          />
        );
      })}
    </>
  );
}

export function DateChips(props: { filter: DateFilter; children: ReactNode; fieldName: string }): JSX.Element | null {
  const { filter } = props;
  if (filter.range.start === null) {
    return null;
  }
  const start = format(filter.range.start, 'MM/dd/yyyy');
  const end = format(filter.range.end, 'MM/dd/yyyy');
  return (
    <Chip
      variant="outlined"
      color="secondary"
      data-cy={`filterPill-${props.fieldName}`}
      onDelete={() => props.filter.clear()}
      label={
        <>
          {props.children}: {start} - {end}
        </>
      }
    />
  );
}

export function AddFilterChip(props: { onClick: () => void }): JSX.Element {
  return (
    <SecondaryButton data-cy="addFilterButton" onClick={props.onClick} size="small" startIcon={<AddIcon />}>
      <TypographySystem isEmphasis={true}>Add Filter</TypographySystem>
    </SecondaryButton>
  );
}

export const ChipSet = styled('div')(({ theme }) => ({
  '& > *': {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginBottom: theme.spacing(2),
}));
