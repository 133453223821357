/**
 * A report to-be-created.  won't have a unique identifier yet.
 */
export interface EditableReport<T> {
  // clientUuid is null for "canned" reports.
  clientUuid?: string | null;
  // divisionUuid is provided on POST, but not returned on GET.
  divisionUuid?: string;
  columnFields: string[];
  description: string;
  entityName: string;
  queryConstraints: ReportQueryConstraint<T>[]; // only comes back on direct GETs
  reportName: string;
  created?: string;
  updated?: string;
}
export interface Report<T> extends EditableReport<T> {
  uuid: string;
}

export enum ReportSaveAction {
  Create = 'Create',
  Update = 'Update',
  DontSave = 'DontSave',
}

export interface ReportQueryConstraint<T> {
  uuid?: string;
  clientCustomReportUuid?: string;
  comparison: string;
  field: keyof T;
  value: string; // allowing string here right now for lazy date query constraints.
}

export interface InvoiceReporting {
  // technically, a comma-joined array to a single string.
  explicitColumns: string;
}

/**
 * Format of representing a display field in a custom report for a report of type T.
 */
export interface ReportDisplayField<T> {
  /**
   * the api response key
   */
  name: keyof T;

  /**
   * the java type of the field
   */
  type: string;
  /**
   * user-displayable format of the column
   */
  displayName: string;
}

/**
 * Metadata response regarding a report of type T.
 * Inofrmation about available columns is currently the only metadata provided or needed.
 */
export interface ReportMetadata<T> {
  id: string;
  displayFields: ReportDisplayField<T>[];
}
