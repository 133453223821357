import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { ClearButton } from './ClearButton';
import { DateFilter, DatePreset } from './useDateFilter';
import { FormControlLabel, Radio, RadioGroup, TextField, styled } from '@c2fo/react-components';
import { format } from 'date-fns';

const SmallRadio = styled(Radio)(({ theme }) => ({
  padding: theme.spacing(1) / 2,
  paddingLeft: theme.spacing(1),
}));

function onFlatpickrReady(dates: Date[], currentDateString: string, api: any) {
  if (dates.length === 0) {
    api.input.focus();
  }
}

const FLATPICKR_OPTIONS = { mode: 'range' as const, showMonths: 2 };

function renderFlatpickrInput(inputProps: DateTimePickerProps, ref: React.Ref<HTMLInputElement>) {
  let { value } = inputProps;
  if (Array.isArray(value)) {
    value = value
      .map((elem) => {
        if (elem instanceof Date) {
          return format(elem, 'MM-dd-yyyy');
        }
        return elem.toString();
      })
      .join(' - ');
  }

  return (
    <TextField
      data-testid={'input'}
      data-cy={'datepicker-input'}
      {...(inputProps as any)}
      value={value}
      placeholder={'Click to Select...'}
      inputRef={ref}
      variant={'outlined'}
      size="small"
      color={'secondary'}
    />
  );
}

export function DatePicker(props: {
  filter: DateFilter;
  testFilterName?: string;
  isPastEvents?: boolean;
}): JSX.Element {
  const { filter, testFilterName, isPastEvents = true } = props;
  let flatpickrValue: string | [Date, Date] = '';
  if (filter.range.start && filter.range.end) {
    flatpickrValue = [filter.range.start, filter.range.end];
  }

  const handleChange = useCallback(
    (dates: Date[]) => {
      let [startDate, endDate] = dates;
      if (startDate && endDate) {
        filter.setDates(startDate, endDate);
      }
    },
    [filter],
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      data-testid={testFilterName && `dateFilter-${testFilterName}`}
      data-cy={testFilterName && `dateFilter-${testFilterName}`}
    >
      <RadioGroup value={filter.range.preset} onChange={(event) => filter.setPreset(event.target.value as DatePreset)}>
        <FormControlLabel
          value={DatePreset.ThisMonth}
          control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-thisMonth'} />}
          label={'This Month'}
        />
        {isPastEvents ? (
          <>
            <FormControlLabel
              value={DatePreset.LastMonth}
              control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-lastMonth'} />}
              label={'Last Month'}
            />
            <FormControlLabel
              value={DatePreset.Last30Days}
              control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-last30Days'} />}
              label={'Last 30 Days'}
            />
          </>
        ) : (
          <>
            <FormControlLabel
              value={DatePreset.NextMonth}
              control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-nextMonth'} />}
              label={'Next Month'}
            />
            <FormControlLabel
              value={DatePreset.Next30Days}
              control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-next30Days'} />}
              label={'Next 30 Days'}
            />
          </>
        )}
        <FormControlLabel
          value={DatePreset.Custom}
          control={<SmallRadio color={'secondary'} data-cy={'dateFilter-preset-custom'} />}
          label={'Custom'}
        />
        {filter.range.preset === DatePreset.Custom && (
          <Flatpickr
            value={flatpickrValue}
            onChange={handleChange}
            onReady={onFlatpickrReady}
            options={FLATPICKR_OPTIONS}
            render={renderFlatpickrInput}
          />
        )}
      </RadioGroup>
      {filter.range.start && (
        <div>
          <ClearButton onClick={filter.clear} />
        </div>
      )}
    </div>
  );
}
