import React, { ReactNode } from 'react';
import { BodyCell } from '../Table/TableCell';
import { Invoice } from '../../schemas/invoice.schema';
import { InvoiceStatusPillCell } from './InvoiceStatusPill';
import { LoadingState, UseAsyncQueryState } from '../Async/useAsyncQuery';
import { PageableResponse } from '../../schemas/http.schema';
import { Pagination, PaginationControls } from '../Table/Pagination';
import { SortableColumn, Sorting } from '../Table/Sorting';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  TypeBase,
  makeStyles,
} from '@c2fo/react-components';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    marginBottom: theme.spacing(6),
  },
}));

export function InvoiceGrid<T extends Invoice>(props: {
  invoices: LoadingState<PageableResponse<T>>;
  columns: ReactNode;
  tableSkeleton: ReactNode;
  sorting: Sorting;
  pagination: Pagination;
  row: (invoice: T) => ReactNode;
}) {
  let classes = useStyles();

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead data-cy="invoiceGridHeader">
            <TableRow>
              <SortableColumn sorting={props.sorting} sortField={'supplierDisplayStatus'} align={'center'}>
                Status
              </SortableColumn>
              <SortableColumn
                style={{ width: '180px' }}
                sorting={props.sorting}
                sortField={'debtorName'}
                align={'left'}
              >
                Customer Name
              </SortableColumn>
              <SortableColumn sorting={props.sorting} sortField={'invoiceId'}>
                Invoice
              </SortableColumn>
              {props.columns}
            </TableRow>
          </TableHead>
          <TableBody data-cy="invoiceGridBody">
            {props.invoices.isLoading ? (
              props.tableSkeleton
            ) : props.invoices.response.data.length === 0 ? (
              <TableRow data-cy="invoiceGridEmptyMessage">
                <TableCell colSpan={'100%' as any /* 100% is a valid value, but mui doesn't think so  */}>
                  <TypeBase align={'center'}>No invoices with these filter options.</TypeBase>
                </TableCell>
              </TableRow>
            ) : (
              props.invoices.response.data.map((invoice) => (
                <TableRow key={invoice.uuid} data-cy={`invoiceRow-${invoice.invoiceId}`}>
                  <InvoiceStatusPillCell
                    data-testid={'status'}
                    data-cy={`invoiceRow-${invoice.invoiceId}-supplierDisplayStatus`}
                    status={invoice.supplierDisplayStatus}
                  />
                  <BodyCell
                    data-testid={'debtor-name'}
                    align={'left'}
                    data-cy={`invoiceRow-${invoice.invoiceId}-debtorName`}
                  >
                    {invoice.debtorName}
                  </BodyCell>
                  <BodyCell data-testid={'invoice-id'} data-cy={`invoiceRow-${invoice.invoiceId}-invoiceId`}>
                    {invoice.invoiceId}
                  </BodyCell>
                  {props.row(invoice)}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {!props.invoices.isLoading && (
          <PaginationControls meta={props.invoices.response.meta} pagination={props.pagination} />
        )}
      </TableContainer>
    </>
  );
}
