import React from 'react';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  styled,
} from '@c2fo/react-components';

const SampleBackground = styled('div')(({ theme }) => ({
  display: 'block',
  content: 'sample',
  fontSize: 44,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  position: 'absolute',
  color: theme.palette.success.light,
  opacity: 0.15,
  zIndex: 1,
  transform: 'translate(150%, -180%) rotate(-15deg)',
  userSelect: 'none',
  marginTop: '1.5rem',
}));

const useSampleGridStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey['300']}`,
      fontSize: theme.typography.fontSize * 0.9,
    },
    '& th': {
      textAlign: 'left',
      fontWeight: '400',
    },
    '& td': {
      fontWeight: '500',
    },
  },
  headerRow: {
    backgroundColor: theme.palette.grey['100'],
  },
}));

export function UploadTemplateTable() {
  let classes = useSampleGridStyles();
  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell>
              Company Name &nbsp;
              <InfoTooltip name="uploadTemplateTable.CompanyName">The name of your customer</InfoTooltip>
            </TableCell>
            <TableCell>
              Company ID &nbsp;
              <InfoTooltip name="uploadTemplateTable.CompanyId">
                Number or name used in your accounting records for the customer (can be letters and/or numbers)
              </InfoTooltip>
            </TableCell>
            <TableCell>
              Invoice ID &nbsp;
              <InfoTooltip name="uploadTemplateTable.InvoiceId">
                Invoice number used in your accounting records (can be letters and/or numbers)
              </InfoTooltip>
            </TableCell>
            <TableCell>
              Order ID &nbsp;
              <InfoTooltip name="uploadTemplateTable.OrderId">
                Some customers use a different invoice ID when remitting payment. This is usually a PO number or
                variation on the invoice ID. This field is optional.
              </InfoTooltip>
            </TableCell>
            <TableCell>
              Amount &nbsp;
              <InfoTooltip name="uploadTemplateTable.Amount">Total invoice amount (numbers only)</InfoTooltip>
            </TableCell>
            <TableCell>
              Invoice Date &nbsp;
              <InfoTooltip name="uploadTemplateTable.InvoiceDate">
                Date invoice was submitted to customer (MM-DD-YYYY)
              </InfoTooltip>
            </TableCell>
            <TableCell>
              Due Date &nbsp;
              <InfoTooltip name="uploadTemplateTable.DueDate">Date invoice payment is due (MM-DD-YYYY)</InfoTooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Wayne Enterprises</TableCell>
            <TableCell>WYN582114</TableCell>
            <TableCell>8813</TableCell>
            <TableCell>10121966-2</TableCell>
            <TableCell>$1,000,000.00</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ACME Co.</TableCell>
            <TableCell>ACM1234567</TableCell>
            <TableCell>8901</TableCell>
            <TableCell>87LNTN00001</TableCell>
            <TableCell>$500,000.01</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Wonka Industries</TableCell>
            <TableCell>WON091288</TableCell>
            <TableCell>9001</TableCell>
            <TableCell>9001-INVPO</TableCell>
            <TableCell>$78,123.99</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
            <TableCell>MM-DD-YYYY</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <SampleBackground>Sample</SampleBackground>
    </TableContainer>
  );
}
