import React, { useContext, useEffect, useState } from 'react';
import {
  CTAButton,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ModalContext,
  PrimaryButton,
  SecondaryButton,
  Slide,
  Theme,
  TypeSectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { FactoringInvoice, InvoiceSupplierStatus, Report, ReportDisplayField, ReportSaveAction } from '../../schemas';
import { InvoiceReportColumnsStep } from './InvoiceReportColumnsStep';
import { InvoiceReportFiltersStep } from './InvoiceReportFiltersStep';
import { InvoiceReportSaveStep } from './InvoiceReportSaveStep';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useInvoiceReportBuilder } from './hooks';
import { useProductType } from '../../contexts/ProductType';

const useStyles = makeStyles((theme: Theme) => ({
  modalBlock: {
    [theme.breakpoints.up('md')]: {
      minWidth: 800,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'space-between',
    justifyContent: 'space-between',
  },
  content: {
    height: 600,
    padding: theme.spacing(3),
  },
  actionButton: {
    margin: theme.spacing(1),
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  backdrop: {
    // Must use !important to override background set via inline style.
    backgroundColor: `${theme.palette.secondary.main} !important`,
    opacity: '0.7 !important',
  },
}));

/**
 * Wizard steps for report builder.
 * IMPORTANT: declaration order here is important, as this takes
 * advantage of the natural ordering of typescript enums:
 * https://www.typescriptlang.org/docs/handbook/enums.html
 */
enum ReportBuilderSteps {
  /**
   * Select invoice filters.
   */
  SELECT_FILTERS,
  /**
   * Select which columns to include.
   */
  SELECT_COLUMNS,
  /**
   * optional save and download.
   */
  SAVE_AND_DOWNLOAD,
}

/**
 * i dont want to include lodash for something like this.
 * Maybe there's a helper already somewhere... too many languages.
 * @param str
 */
function isBlankOrEmpty(str?: string | null): boolean {
  if (typeof str !== 'string') {
    return false;
  }
  return str.length === 0 || /^\s*$/.test(str);
}

type Props = {
  onClose: () => void;
  onSubmit: (payload: Report<FactoringInvoice>, saveAction: ReportSaveAction) => void;
  reportColumns: ReportDisplayField<FactoringInvoice>[];
  statusOptions: InvoiceSupplierStatus[];
  reportToEdit: Report<FactoringInvoice> | null | undefined;
  reportToClone: Report<FactoringInvoice> | null | undefined;
};

export function BuildInvoiceReportDialog(props: Props): JSX.Element {
  const classes = useStyles();
  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const { setModalOpen: showModalBackdrop } = useContext(ModalContext);

  /**
   * We use c2fo-components' ModalContext to display (and hide) our
   * backdrop on mount and umount, respectively.
   */
  useEffect(() => {
    showModalBackdrop(true);
    return () => {
      showModalBackdrop(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.reportToEdit && props.reportToClone) {
    throw new Error('Illegal Arguments: shouldn`t have a report to edit and clone simultaneously');
  }
  const existingReport = props.reportToEdit ?? props.reportToClone;

  // do we have an existing report? and if so did it come from the clone prop? otherwise we aren't cloning.
  const isCloning = existingReport ? Boolean(props.reportToClone) : false;

  const reportBuilder = useInvoiceReportBuilder(props.reportColumns, isCloning, existingReport);

  const [reportBuilderStep, setReportBuilderStep] = useState<ReportBuilderSteps>(ReportBuilderSteps.SELECT_FILTERS);

  const handleSubmitOrDownload = () => {
    const payload = reportBuilder.toPayload(divisionUuid, productType);
    let saveAction = ReportSaveAction.DontSave;
    if (!isBlankOrEmpty(reportBuilder.reportName.name)) {
      if (props.reportToEdit) {
        saveAction = ReportSaveAction.Update;
      } else {
        saveAction = ReportSaveAction.Create;
      }
    }
    props.onSubmit(payload, saveAction);
  };

  const nextButtonHandler = () => {
    if (reportBuilderStep === ReportBuilderSteps.SAVE_AND_DOWNLOAD) {
      handleSubmitOrDownload();
    }
    setReportBuilderStep((prev) => {
      if (prev === ReportBuilderSteps.SAVE_AND_DOWNLOAD) {
        return prev;
      }
      return prev + 1;
    });
  };

  const prevButtonHandler = () => {
    if (reportBuilderStep === ReportBuilderSteps.SELECT_FILTERS) {
      return;
    }
    setReportBuilderStep((prev) => {
      if (prev === ReportBuilderSteps.SELECT_FILTERS) {
        return prev;
      }
      return prev - 1;
    });
  };

  const nextButton =
    reportBuilderStep !== ReportBuilderSteps.SAVE_AND_DOWNLOAD ? (
      <PrimaryButton data-cy="customReportNext" className={classes.actionButton} onClick={nextButtonHandler}>
        Next
      </PrimaryButton>
    ) : (
      <CTAButton
        data-testid={'download-button'}
        data-cy="customReportFinish"
        className={classes.actionButton}
        onClick={nextButtonHandler}
      >
        {isBlankOrEmpty(reportBuilder.reportName.name) ? 'Download' : 'Save & Download'}
      </CTAButton>
    );

  return (
    <Dialog open={true} onClose={props.onClose} maxWidth={'lg'} BackdropProps={{ className: classes.backdrop }}>
      <DialogTitle className={classes.modalBlock}>
        <div className={classes.title} data-testid={'build-custom-report-modal'}>
          <TypeSectionHeader data-cy="customReportHeader" component="div">
            Build a Custom Report
          </TypeSectionHeader>
          <IconButton aria-label="close" size="small" color={'secondary'} onClick={props.onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent data-cy="customReportModalBody" dividers className={classes.content}>
        {/* STEP 1: select filters */}
        <Slide direction="left" in={reportBuilderStep === ReportBuilderSteps.SELECT_FILTERS} mountOnEnter unmountOnExit>
          <div data-testid={'select-filters-step'}>
            <InvoiceReportFiltersStep
              statusOptions={props.statusOptions}
              statusFilters={reportBuilder.statusFilters}
              debtorFilters={reportBuilder.debtorFilters}
              dueDateFilter={reportBuilder.dueDateFilter}
              invoiceDateFilter={reportBuilder.invoiceDateFilter}
            />
          </div>
        </Slide>
        {/* STEP 2: select columns */}
        <Slide direction="left" in={reportBuilderStep === ReportBuilderSteps.SELECT_COLUMNS} mountOnEnter unmountOnExit>
          <div data-testid={'select-columns-step'}>
            <InvoiceReportColumnsStep
              columnFilters={reportBuilder.columnFilters}
              availableColumns={props.reportColumns}
            />
          </div>
        </Slide>
        {/* STEP 3: save & download  */}
        <Slide
          direction="left"
          in={reportBuilderStep === ReportBuilderSteps.SAVE_AND_DOWNLOAD}
          mountOnEnter
          unmountOnExit
        >
          <div data-testid={'save-step'}>
            <InvoiceReportSaveStep
              reportNameState={reportBuilder.reportName}
              // kind of gross, but we only want to suggest non blank, existing cloned names in
              // hints, if it applies.  Some existing reports have blank names.
              optionalCloneName={
                isCloning && !isBlankOrEmpty(props.reportToClone?.reportName)
                  ? props.reportToClone?.reportName ?? null
                  : null
              }
            />
          </div>
        </Slide>
      </DialogContent>
      <DialogActions className={classes.modalBlock}>
        {reportBuilderStep !== ReportBuilderSteps.SELECT_FILTERS ? (
          <>
            <SecondaryButton data-cy="customReportPrevious" onClick={prevButtonHandler}>
              Previous
            </SecondaryButton>
            {nextButton}
          </>
        ) : (
          nextButton
        )}
      </DialogActions>
    </Dialog>
  );
}
