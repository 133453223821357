/**
 * A service-layer abstraction around the clock.
 *
 * Useful for mocking out time in tests.
 */
export class ClockService {
  currentTime() {
    return new Date();
  }

  timestamp(): number {
    return Date.now();
  }
}
