import React from 'react';
import {
  AncillaryButton,
  AngleDownIcon,
  Container,
  Link,
  Logo,
  Menu,
  MenuItem,
  PrimaryButton,
  Theme,
  TypeBase,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';

const barHeight = 48;
const barHeightSm = 68;

const useStyles = makeStyles<Theme>((theme) => ({
  bar: {
    width: '100%',
    backgroundColor: 'white',
    color: 'white',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey['300'],
  },
  container: {
    backgroundColor: 'white',
    color: 'white',
    height: barHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto 0',
    [theme.breakpoints.up('sm')]: {
      height: barHeightSm,
    },
  },
  logo: {
    height: 16,
    paddingRight: theme.spacing(2),
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 32,
    },
    borderRight: '1px solid',
    borderRightColor: theme.palette.grey['300'],
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  link: {
    color: 'inherit',
  },
  profileButton: {
    color: 'inherit',
    textTransform: 'capitalize',
    fontSize: '1.2rem',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.primary.dark,
  },
}));

export const Navigation: React.FC = (props) => {
  const theme = useTheme();
  const { tokenService, redirectService } = useServices();
  const classes = useStyles({});

  const isMobile = useIsMobile();

  const [profileMenuAnchor, setProfileMenuAnchor] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setProfileMenuAnchor(null);
  };

  return (
    <nav className={classes.bar}>
      <Container disableGutters={isMobile} className={classes.container}>
        <div className={classes.logoContainer}>
          <a href={redirectService.getSignedEarlyPayLogoUrl()}>
            <Logo className={classes.logo} />
          </a>
          <AncillaryButton
            href={redirectService.getSignedEarlyPayMarketsUrl()}
            style={{ marginLeft: '8px' }}
            data-testid={'linkToMarkets'}
          >
            Markets
          </AncillaryButton>
        </div>
        <div className={classes.userContainer}>
          <AncillaryButton
            className={classes.profileButton}
            aria-label="profile-dropdown"
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple={true}
            variant="text"
            endIcon={<AngleDownIcon />}
          >
            Profile
          </AncillaryButton>
          <Menu
            id="profile-dropdown"
            getContentAnchorEl={null}
            anchorEl={profileMenuAnchor}
            keepMounted
            open={Boolean(profileMenuAnchor)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem>
              <Link
                data-testid={'linkToUserProfile'}
                underline={'none'}
                className={classes.link}
                href={redirectService.getSignedEarlyPayProfileUrl()}
              >
                Account Settings
              </Link>
            </MenuItem>
            <MenuItem data-testid={'linkToLogOut'} onClick={() => tokenService.logout()}>
              Log Out
            </MenuItem>
          </Menu>
        </div>
      </Container>
    </nav>
  );
};
