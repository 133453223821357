import React, { ReactNode, useContext, useState } from 'react';
import { AddFilterChip, ChipSet } from './FilterChips';
import {
  CloseIcon,
  Drawer,
  Grid,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TrashIcon,
  TypeSectionHeader,
  makeStyles,
  styled,
} from '@c2fo/react-components';
import { Filter } from './Filter';

const HEADER_SIZE = '10vh';
const BODY_SIZE = '80vh';
const FOOTER_SIZE = '10vh';

const WIDTH = 360;
const PADDING = 3;

const useSlideoutStyles = makeStyles((theme) => ({
  root: { minWidth: '360px' },
}));

const useAddFilterChipStyles = makeStyles((theme) => ({
  addFilterChip: { marginTop: '.5rem' },
}));

type CloseFn = () => void;

interface ContextValue {
  close: () => void;
  filters: Filter[];
}

const SlideoutContext = React.createContext<ContextValue | null>(null);

export function SlideoutFilterDialog(props: {
  open: boolean;
  onClose: CloseFn;
  children: ReactNode;
  filters: Filter[];
}): JSX.Element {
  let classes = useSlideoutStyles();
  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <SlideoutContext.Provider value={{ close: props.onClose, filters: props.filters }}>
        <div className={classes.root}>{props.children}</div>
      </SlideoutContext.Provider>
    </Drawer>
  );
}

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: HEADER_SIZE,
    position: 'fixed',
    width: WIDTH,
    padding: theme.spacing(PADDING),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export function SlideoutHeader(props: { children: ReactNode }): JSX.Element {
  let classes = useHeaderStyles();
  let context = useContext(SlideoutContext);
  return (
    <div className={classes.root}>
      <TypeSectionHeader data-cy={'filterSlideTitle'}>{props.children}</TypeSectionHeader>
      {context && (
        <IconButton
          data-cy={'clear-filters'}
          size={'small'}
          onClick={context.close}
          aria-label={'Close Filters'}
          color={'secondary'}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}
export const SlideoutBody = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: WIDTH,
  padding: theme.spacing(PADDING),

  height: BODY_SIZE,
  top: HEADER_SIZE,
  position: 'fixed',
  overflowY: 'scroll',
}));

export const SlideoutFilterGroup = styled('div')(({ theme }) => ({ marginBottom: theme.spacing(3) }));

const useFooterStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: WIDTH,
    padding: theme.spacing(PADDING),

    position: 'fixed',
    bottom: 0,
    height: FOOTER_SIZE,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export function SlideoutFooter(props: Record<string, unknown>): JSX.Element | null {
  let classes = useFooterStyles();
  let context = useContext(SlideoutContext);
  if (!context) {
    return null;
  }
  return (
    <div className={classes.root} data-testid={'slideout-footer'}>
      <SecondaryButton
        size="small"
        onClick={() => {
          // eslint-disable-next-line
          context!.filters.forEach((filter) => filter.clear());
        }}
      >
        Clear Filters
      </SecondaryButton>
      <PrimaryButton onClick={context.close} data-cy="slideAddFilterButton">
        OK
      </PrimaryButton>
    </div>
  );
}

export function SlideoutFilterAndChips(props: {
  filters: Filter[];
  chips: ReactNode;
  slideoutTitle: ReactNode;
  slideoutFilters: ReactNode;
}): JSX.Element {
  let [isDrawerOpen, setIsDrawerOpen] = useState(false);
  let classes = useAddFilterChipStyles();
  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <div className={classes.addFilterChip}>
            <ChipSet>
              <AddFilterChip onClick={() => setIsDrawerOpen(true)} />
              {props.chips}
            </ChipSet>
          </div>
        </Grid>
        <Grid item>
          {props.filters.some((filter) => filter.isEnabled) && (
            <SecondaryButton
              data-cy={'clearFiltersButton'}
              data-testid={'clear-filters'}
              variant={'text'}
              size="small"
              onClick={() => {
                props.filters.forEach((filter) => filter.clear());
              }}
              startIcon={<TrashIcon />}
            >
              Clear Filters
            </SecondaryButton>
          )}
        </Grid>
      </Grid>
      <SlideoutFilterDialog open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} filters={props.filters}>
        <SlideoutHeader>{props.slideoutTitle}</SlideoutHeader>
        <SlideoutBody>{props.slideoutFilters}</SlideoutBody>
        <SlideoutFooter />
      </SlideoutFilterDialog>
    </>
  );
}
