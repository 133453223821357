import React, { useContext } from 'react';

const DivisionContext = React.createContext<string | null>(null);

export function useCurrentDivision(): string {
  const division = useContext(DivisionContext);
  if (!division) {
    throw new Error('Tried to `useCurrentDivision` without wrapping it in a <DivisionProvider>');
  }
  return division;
}

export function useOptionalDivision(): string | null {
  return useContext(DivisionContext);
}

export function DivisionProvider(props: { divisionUuid: string; children: React.ReactNode }) {
  return <DivisionContext.Provider value={props.divisionUuid}>{props.children}</DivisionContext.Provider>;
}
