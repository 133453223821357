/**
 * A simple cache implementation, for use inside of a service
 *
 * Usually you should use this for a case when you're querying for
 * a single record, e.g. the client or the client's balance. Technically
 * caching of queries is possible with this abstraction, but a more purpose-built
 * one would work better.
 *
 * We primarily use this for some top-loading pieces of data that
 * we load, e.g. Client and OnboardingStatus. This primarily helps for development,
 * where we want to avoid seeing a full-page loading spinner and see immediate
 * feedback on what we're working on.
 *
 * Note: the following is development-only.
 * When webpack decides to do a hot-reload (as seen in src/index.ts), with
 * that whole `module.hot` business, we have react re-render the App. In doing so,
 * react will completely unmount the "old" instance of the app, and start up a "new"
 * instance. This also means that all of the state will be completely reset.
 *
 * By having a service cache, we can keep some state outside of react, so that when it remounts,
 * we have things like Clients already loaded, and can avoid the full-page loading spinner.
 */
export class ServiceCache<T> {
  private promiseCache: Map<string, Promise<T>>;

  constructor() {
    /**
     * Why do we cache promises? If two components in the page
     * try to load the client's balance at roughly the same time,
     * we'll send two network requests. Even worse, if the
     * value manages to change in between those two requests,
     * the 2nd one to come back will overwrite the first! That's
     * not a good time. The easiest way to avoid those issues
     * is to cache promises instead of values.
     */
    this.promiseCache = new Map();
  }

  /**
   * Get a value from the cache, or async load it if it
   * is not in the cache.
   */
  get(key: string, getValue: () => Promise<T>): Promise<T> {
    /**
     * DO NOT AWAIT INSIDE OF THIS FUNCTION. THIS FUNCTION
     * IS NOT ASYNCHRONOUS
     *
     * It's very important that we always synchronously
     * return, no matter what, because it avoids having
     * two "calls" suspended inside of here, where we
     * are doing gross cache management.
     */
    if (this.promiseCache.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.promiseCache.get(key)!;
    }
    let promise = getValue();
    this.promiseCache.set(key, promise);
    return promise;
  }
}
