import React, { ReactNode } from 'react';
import { TypeBase } from '@c2fo/react-components';

export function FilterLabel(props: { children: ReactNode }): JSX.Element {
  return (
    <label>
      <TypeBase isEmphasis component={'span'}>
        {props.children}
      </TypeBase>
    </label>
  );
}
