import React from 'react';
import { AlertBanner, Theme, TypeBase, makeStyles } from '@c2fo/react-components';
import { TREATMENTS, useFeature } from '../../providers/SplitProvider/SplitProvider';

const useStyles = makeStyles<Theme>((theme) => ({
  splitIOAlert: {
    '& div.MuiAlert-icon': {
      display: 'none',
    },
    width: '70%',
    margin: 'auto',
  },
}));

export function SplitTestBanner() {
  const classes = useStyles();
  const splitShowBanner = useFeature(TREATMENTS.SHOW_CAPFIN_TEST_BANNER);
  return (
    <>
      {splitShowBanner ? (
        <div className={classes.splitIOAlert}>
          <AlertBanner severity={'warning'} data-testid={'split-test-alert'} style={{ justifyContent: 'center' }}>
            <TypeBase>
              <em>Hey! This is a simple banner to test Split IO integration</em>
            </TypeBase>
          </AlertBanner>
        </div>
      ) : null}
    </>
  );
}
