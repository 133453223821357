import React, { useMemo, useState } from 'react';
import { Pagination as MuiPagination, useTheme } from '@c2fo/react-components';
import { PaginationMeta, QueryPagination } from '../../schemas';
import { useIsMobile } from '../../hooks/useIsMobile';

export interface Pagination {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  reset: () => void;
  params: QueryPagination;
}

const DEFAULT_PAGE_SIZE = 25;

export function usePagination(storageKey: string, initialPageSize: number = DEFAULT_PAGE_SIZE): Pagination {
  const LOCAL_STORAGE_KEY = `capfin-app:${storageKey}:page-size`;
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(() => {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (stored === null) {
      return initialPageSize;
    }
    return Number(stored);
  });
  const params = useMemo(
    () => ({
      size: pageSize,
      page,
    }),
    [pageSize, page],
  );
  return useMemo(
    () => ({
      page,
      pageSize,
      setPage,
      reset: () => {
        setPage(0);
      },
      setPageSize: (size) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, String(size));
        setPageSize(size);
        setPage(0);
      },
      params,
    }),
    [params, pageSize, page, LOCAL_STORAGE_KEY],
  );
}

export function PaginationControls(props: { meta: PaginationMeta; pagination: Pagination }): JSX.Element | null {
  let theme = useTheme();
  let siblingCount;
  let boundaryCount;
  let showFirstAndLast;
  if (useIsMobile()) {
    siblingCount = 0;
    boundaryCount = 1;
    showFirstAndLast = false;
  } else {
    siblingCount = 1;
    boundaryCount = 1;
    showFirstAndLast = true;
  }

  if (props.meta.pageCounts <= 1) {
    return null;
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}
      data-testid={'pagination-controls'}
    >
      <MuiPagination
        data-cy="paginationControl"
        size={'large'}
        color={'secondary'}
        count={props.meta.pageCounts}
        page={props.pagination.page + 1}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        showFirstButton={showFirstAndLast}
        showLastButton={showFirstAndLast}
        onChange={(event: any, page: number) => props.pagination.setPage(page - 1)}
      />
    </div>
  );
}
