import { ALERT_VARIANTS } from '@c2fo/react-components/dist/lib/design/AlertVariants';
import {
  Advance,
  AdvanceStatus,
  Client,
  ClientBalance,
  ClientInvoiceUploadSummary,
  RequestStatus,
} from '../../schemas';
import { RFHomeState } from './RFHomeState';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum RFHomeActionTypes {
  FetchRequest = 'FETCH_CLIENT_STATE_REQUEST',
  FetchSuccess = 'FETCH_CLIENT_STATE_SUCCESS',
  SubmitAdvanceRequest = 'SUBMIT_ADVANCE_REQUEST',
  SubmitAdvanceSuccess = 'SUBMIT_ADVANCE_SUCCESS',
  CancelAdvanceRequest = 'CANCEL_ADVANCE_REQUEST',
  CancelAdvanceSuccess = 'CANCEL_ADVANCE_SUCCESS',
  ShowNotification = 'SHOW_NOTIFICATION',
  CloseNotification = 'CLOSE_NOTIFICATION',
}

type RFHomeActionPayload = {
  [RFHomeActionTypes.FetchRequest]: null;
  [RFHomeActionTypes.FetchSuccess]: {
    client: Client;
    advance: Advance | null;
    upload: ClientInvoiceUploadSummary | null;
    balance: ClientBalance;
  };
  [RFHomeActionTypes.SubmitAdvanceRequest]: null;
  [RFHomeActionTypes.SubmitAdvanceSuccess]: {
    advance: Advance;
  };
  [RFHomeActionTypes.CancelAdvanceRequest]: null;
  [RFHomeActionTypes.CancelAdvanceSuccess]: null;
  [RFHomeActionTypes.ShowNotification]: {
    severity: keyof typeof ALERT_VARIANTS;
    message: string;
  };
  [RFHomeActionTypes.CloseNotification]: null;
};

export type RFHomeActions = ActionMap<RFHomeActionPayload>[keyof ActionMap<RFHomeActionPayload>];

const OVER_ADVANCE_THRESHOLD_PCT = 3.5; // 3.5% threshold used to calculate availability.

/**
 *  If a request is not already pending, we calculate the current status based
 *  on a clients outstanding balance and availability in calcStatusFromBalance.
 */
function calcOverAdvancePct(availability: number, outstanding: number): number {
  let pct = (((availability * -1) / outstanding) * 100).toFixed(2);
  return parseInt(pct, 10);
}

function calcStatusFromBalance(client: Client, balance: ClientBalance): RequestStatus | null {
  /**
   * We display overadvanced logic if the client has negative availability greater than 3.5% of
   * their outstanding balance.
   * This prevents showing overadvanced alerts for small blips in activity due to payment timings.
   */
  if (balance.availabilityBalance < 0 && !client.isClosed) {
    const overAdvPct = calcOverAdvancePct(balance.availabilityBalance, balance.outstandingBalance);

    /**
     * We display "client has reached credit limit" / "0 availability" logic if the client has 0 or negative
     * availability, but it doesn't meet the 3.5% threshold defined in OVER_ADVANCE_THRESHOLD_PCT.
     */
    if (overAdvPct > OVER_ADVANCE_THRESHOLD_PCT) {
      return RequestStatus.OverAdvanced;
    }
  }
  if (balance.availabilityBalance <= 0) {
    return RequestStatus.LimitReached;
  }

  return null;
}

function deriveStatus(client: Client, advance: Advance | null, balance: ClientBalance): RequestStatus | null {
  /** if the account is closed, no futher evaluation is required. */
  if (client.isClosed) {
    return RequestStatus.ClosedAccount;
  }
  if (!advance || !advance.status || advance.status === (RequestStatus.Canceled as string)) {
    /** if there isn't a pending advance, calculate the status from the client's balance. */
    return calcStatusFromBalance(client, balance);
  }
  /** otherwise, initialize status with what was passed from RFSummaryCard. */
  if (advance.status === AdvanceStatus.INITIATED || advance.status === AdvanceStatus.AUTHORIZED) {
    return RequestStatus.Pending;
  }
  return advance.status === AdvanceStatus.CANCELED ? RequestStatus.Canceled : RequestStatus.Pending;
}

export const rfHomeReducer = (state: RFHomeState, action: RFHomeActions): RFHomeState => {
  // This is extremely useful for debugging this code.  If you believe you are having state issues uncomment this.
  // console.log('ACTION: %s, PAYLOAD: %o', action.type, action.payload);
  switch (action.type) {
    case RFHomeActionTypes.FetchRequest:
      return {
        ...state,
        isLoading: true,
      };
    case RFHomeActionTypes.FetchSuccess:
      return {
        ...state,
        isLoading: false,
        client: action.payload.client,
        advance: action.payload.advance,
        upload: action.payload.upload,
        balance: action.payload.balance,
        requestStatus: deriveStatus(action.payload.client, action.payload.advance, action.payload.balance),
      };
    case RFHomeActionTypes.SubmitAdvanceRequest:
      return {
        ...state,
        isLoading: true,
      };
    case RFHomeActionTypes.SubmitAdvanceSuccess:
      return {
        ...state,
        isLoading: false,
        advance: {
          ...action.payload.advance,
        },
        requestStatus: deriveStatus(state.client, action.payload.advance, state.balance),
      };
    case RFHomeActionTypes.CancelAdvanceRequest:
      return {
        ...state,
        isLoading: true,
      };
    case RFHomeActionTypes.CancelAdvanceSuccess:
      return {
        ...state,
        isLoading: false,
        advance: null,
        requestStatus: deriveStatus(state.client, null, state.balance),
      };
    case RFHomeActionTypes.ShowNotification:
      return {
        ...state,
        message: {
          isOpen: true,
          severity: action.payload.severity,
          message: action.payload.message,
        },
      };
    case RFHomeActionTypes.CloseNotification:
      return {
        ...state,
        message: {
          ...state.message,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};
