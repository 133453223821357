import React, { ReactNode } from 'react';
import { Theme, TypeBase, makeStyles, styled, useMediaQuery, useTheme } from '@c2fo/react-components';

export function SummaryCardAccountOverviewGroup(props: { children: ReactNode }) {
  return <div>{props.children}</div>;
}

const textAlignSwitch = (theme: Theme) => theme.breakpoints.up('md');

export const SummaryCardAccountOverview = styled('div')(({ theme }) => ({
  [textAlignSwitch(theme)]: {
    textAlign: 'right',
  },
  marginBottom: theme.spacing(2),
}));

const useTitleStyles = makeStyles((theme) => ({
  subtitle: {
    margin: 0,
    color: theme.palette.secondary.main,
  },
}));

export function SummaryCardAccountOverviewTitle(props: { children: ReactNode; isInverse?: boolean }) {
  let classes = useTitleStyles();
  let shouldAlignRight = useMediaQuery(textAlignSwitch(useTheme()));
  return (
    <TypeBase isInverse={props.isInverse} align={shouldAlignRight ? 'trailing' : 'leading'}>
      <strong className={classes.subtitle}>{props.children}</strong>
    </TypeBase>
  );
}

const useValueStyles = makeStyles((theme) => ({
  detail: {
    fontSize: theme.typography.fontSize * 1.5,
    margin: 0,
  },
}));

export function SummaryCardAccountOverviewValue(props: { children: ReactNode; isInverse?: boolean }) {
  let classes = useValueStyles();
  let shouldAlignRight = useMediaQuery(textAlignSwitch(useTheme()));

  return (
    <TypeBase isInverse={props.isInverse} align={shouldAlignRight ? 'trailing' : 'leading'} component="div">
      <span className={classes.detail}>{props.children}</span>
    </TypeBase>
  );
}
