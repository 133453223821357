import { ArpBalance } from '../../schemas';
import { AxiosError } from 'axios';
import { BaseBalanceService } from '../BaseBalanceService/BaseBalanceService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

const arpBalanceBasePath = (divisionUuid: string) => `/v2/tpf-api/divisions/${divisionUuid}/arp-balances`;

/**
 * Service for interacting with arp balance API endpoints.
 */
export class ArpBalanceService extends BaseBalanceService<ArpBalance> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, arpBalanceBasePath);
  }

  async getBalance(divisionUuid: string): Promise<ArpBalance | null> {
    try {
      const response = await this.httpService.get<ArpBalance>(arpBalanceBasePath(divisionUuid));
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 404) {
        return null;
      }
      throw err;
    }
  }
}
