import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { BaseSelect, FormControl, FormHelperText, InputLabel, TextField, makeStyles } from '@c2fo/react-components';
import { FieldProps, FieldValidator, Field as FormField } from 'formik';

export const required = (input: any) => (input ? undefined : 'Required');

const useValidatedInputStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export function ValidatedInput<TFieldValue = string>(
  props: {
    name: string;
    label: string;
    validate: FieldValidator;
    redactFullStory?: boolean;
  } & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'inputMode'>,
) {
  let classes = useValidatedInputStyles();
  return (
    <FormField name={props.name} validate={props.validate}>
      {(field: FieldProps<TFieldValue>) => (
        <TextField
          id={props.name}
          className={clsx(classes.root, props.redactFullStory ? 'fs-exclude' : '')}
          variant={'outlined'}
          inputProps={{
            ...field.field,
            inputMode: props.inputMode,
            type: props.type,
          }}
          label={props.label}
          helperText={field.meta.touched && field.meta.error}
          error={Boolean(field.meta.touched && field.meta.error)}
        />
      )}
    </FormField>
  );
}

export function ValidatedSelect<TFieldValue = string>(props: {
  name: string;
  label: ReactNode;
  validate?: FieldValidator;
  children: React.ReactNode;
}) {
  return (
    <FormField name={props.name} validate={props.validate}>
      {(field: FieldProps<TFieldValue>) => (
        <FormControl
          variant={'outlined'}
          error={Boolean(field.meta.touched && field.meta.error)}
          style={{ width: '100%' }}
        >
          <InputLabel variant={'outlined'} htmlFor={props.name}>
            {props.label}
          </InputLabel>
          <BaseSelect native id={props.name} label={props.label} {...field.field}>
            {props.children}
          </BaseSelect>
          {field.meta.touched && field.meta.error && (
            <FormHelperText id={`${props.name}-helper-text`}>{field.meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FormField>
  );
}
