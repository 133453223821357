import React from 'react';
import { AccountBalance, DiscountFeesThisMonth, LastInvoiceUpload } from './FactoringAccountOverview';
import { ArpBalance } from '../../schemas';
import { ClientInvoiceUploadSummary } from '../../schemas/invoiceUpload.schema';
import { SummaryCardClosedAccount } from '../SummaryCard/SummaryCardClosedAccount';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useServices } from '../../services';

interface ARPSummaryClosedAccountCardProps {
  balance: ArpBalance;
  lastUpload: ClientInvoiceUploadSummary | null;
}

export const ARPSummaryClosedAccountCard: React.FC<ARPSummaryClosedAccountCardProps> = (props) => {
  let divisionUuid = useCurrentDivision();
  let { factoringTransactionHistoryService } = useServices();

  return (
    <div data-testid="ARPSummaryClosedAccountCard">
      <SummaryCardClosedAccount
        onDownloadHistory={() => {
          factoringTransactionHistoryService.downloadDetail(divisionUuid, {});
        }}
        accountOverviewStats={
          <>
            <AccountBalance balance={props.balance} />
            <DiscountFeesThisMonth balance={props.balance} />
            <LastInvoiceUpload lastUpload={props.lastUpload} />
          </>
        }
      />
    </div>
  );
};
