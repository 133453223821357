import React, { ReactNode } from 'react';
import { Container, makeStyles } from '@c2fo/react-components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
}));

export function PageWrapper(props: { children: ReactNode }) {
  let classes = useStyles();
  return (
    <Container className={classes.root} maxWidth={'lg'}>
      <>{props.children}</>
    </Container>
  );
}
