import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useMountedState } from 'react-use';

export enum UseAsyncQueryState {
  InitialLoad,
  Reload,
  NotLoading,
  ReloadingFromError,
  Errored,
}

type InternalLoadingStateInitial = {
  isLoading: true;
  response: null;
  error: null;
  state: UseAsyncQueryState.InitialLoad;
};

type InternalLoadingState<TData> =
  | {
      isLoading: true;
      response: TData;
      error: null;
      state: UseAsyncQueryState.Reload;
    }
  | InternalLoadingStateInitial
  | {
      isLoading: false;
      response: TData;
      error: null;
      state: UseAsyncQueryState.NotLoading;
    }
  | {
      isLoading: false;
      response: null;
      error: Error;
      state: UseAsyncQueryState.Errored;
    }
  | {
      isLoading: true;
      response: null;
      error: null;
      state: UseAsyncQueryState.ReloadingFromError;
    };

export type LoadingState<TData> =
  | {
      isLoading: true;
      response: TData;
      state: UseAsyncQueryState.Reload;
    }
  | {
      isLoading: true;
      response: null;
      state: UseAsyncQueryState.InitialLoad;
    }
  | {
      isLoading: false;
      response: TData;
      state: UseAsyncQueryState.NotLoading;
    };

type UseAsyncResult<TData> = LoadingState<TData> & {
  refetch: () => Promise<TData>;
};
type Action<TData> =
  | {
      type: 'LOADING';
    }
  | {
      type: 'SUCCESS';
      data: TData;
    }
  | {
      type: 'ERROR';
      error: Error;
    };

function reducer<TData>(prevState: InternalLoadingState<TData>, action: Action<TData>): InternalLoadingState<TData> {
  switch (action.type) {
    case 'ERROR':
      return {
        isLoading: false,
        response: null,
        error: action.error,
        state: UseAsyncQueryState.Errored,
      };
    case 'SUCCESS':
      return {
        isLoading: false,
        response: action.data,
        error: null,
        state: UseAsyncQueryState.NotLoading,
      };
    case 'LOADING':
      switch (prevState.state) {
        /**
         * These three states were already loading,
         * we simply return whatever they were doing.
         *
         * counter management is still handled in the hook
         * (maybe it shouldn't be?), so we don't have to
         * inspect anything
         *
         * Technically if we're on the initial load and
         * we issue another load, then we should be in the
         * reload state, but ehhhh that gets way overcomplicated.
         * ReloadingFromError is bad enough
         */
        /**
         * - If we're doing the initial load, and a refetch is requested, in practice
         *   that's the same thing as being in the initial load
         * - If we're doing a reload, and a refetch is requested, then we should still be refetching
         * - If we're reloading from an errored state, and we request a refetch, that's
         *   basically the same thing as reloading from an error
         */
        case UseAsyncQueryState.InitialLoad:
        case UseAsyncQueryState.Reload:
        case UseAsyncQueryState.ReloadingFromError: {
          return prevState;
        }
        case UseAsyncQueryState.Errored: {
          return {
            isLoading: true,
            response: null,
            error: null,
            state: UseAsyncQueryState.ReloadingFromError,
          };
        }
        case UseAsyncQueryState.NotLoading: {
          return {
            isLoading: true,
            response: prevState.response,
            error: null,
            state: UseAsyncQueryState.Reload,
          };
        }
      }
  }
}

const INITIAL_STATE: InternalLoadingStateInitial = {
  isLoading: true,
  response: null,
  error: null,
  state: UseAsyncQueryState.InitialLoad,
};

export function useAsyncQuery<TData>(callback: () => Promise<TData>, deps: any[]): UseAsyncResult<TData> {
  let counterRef = useRef(0);
  let isMounted = useMountedState();

  let [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  let unsafeRefetch = useCallback(
    (cacheCounter: number) => {
      dispatch({
        type: 'LOADING',
      });
      return Promise.resolve(callback()).then(
        (data) => {
          /**
           * counterRef.current holds the id of the last request
           * we have sent. cacheCounter is the id of _our_ request.
           *
           * If another request has been made, we don't want to
           * overwrite it's data with our own, so we bail out here.
           */
          if (counterRef.current > cacheCounter || !isMounted()) return data;
          dispatch({
            type: 'SUCCESS',
            data,
          });
          return data;
        },
        (error) => {
          if (counterRef.current > cacheCounter || !isMounted()) return;
          dispatch({
            type: 'ERROR',
            error,
          });
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  let safeRefetch = useCallback(() => {
    counterRef.current += 1;
    return unsafeRefetch(counterRef.current);
  }, [unsafeRefetch]);

  useEffect(() => {
    safeRefetch();
  }, [safeRefetch]);

  if (state.error) {
    throw state.error;
  }

  return {
    isLoading: state.isLoading,
    response: state.response,
    refetch: safeRefetch,
    /**
     * I don't _really_ want to expose the concept of "reloading from error" to the
     * consumers: from their perspective, it's the same thing as an initial load, so I'm
     * going to use that
     *
     * Plus since we throw errors, in theory the component will have unmounted, so... it's
     * hard to say if we'll ever get to that state?
     */
    state: state.state === UseAsyncQueryState.ReloadingFromError ? UseAsyncQueryState.InitialLoad : state.state,
  } as UseAsyncResult<TData>;
}
