import React from 'react';
import liquidityLoadingLogo from '../../../assets/liquidity/loading-logo.svg';
import { Theme, makeStyles } from '@c2fo/react-components';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    backgroundColor: theme.palette.grey.A100,
  },
  bouncyLogo: {
    width: '13rem',
    height: '7rem',
    position: 'relative',
    top: '-2rem',
  },
}));

export const LoadingScreen: React.FC = () => {
  const classes = useStyles();
  const imageIcon = (
    <object
      className={classes.bouncyLogo}
      data={liquidityLoadingLogo}
      type="image/svg+xml"
      data-testid="loading-screen:c2fo-logo"
    />
  );
  return <div className={classes.container}>{imageIcon}</div>;
};
