import { AdvanceService } from './AdvanceService/AdvanceService';
import { AnalyticsService } from './AnalyticsService/AnalyticsService';
import { ArpBalanceService } from './ArpBalanceService/ArpBalanceService';
import { BankAccountService } from './BankAccountService/BankAccountService';
import { CapfinOnboardingService } from './CapfinOnboardingService/CapfinOnboardingService';
import { ClientBalanceService } from './ClientBalanceService/ClientBalanceService';
import { ClientDebtorService } from './ClientDebtorService/ClientDebtorService';
import { ClientService } from './ClientService/ClientService';
import { ClockService, DataDogRumService, HttpService, TokenService, makeServices } from '../../lib';
import { CustomerPaymentAllocationService } from './CustomerPaymentAllocationService/CustomerPaymentAllocationService';
import { CustomerPaymentService } from './CustomerPaymentService/CustomerPaymentService';
import { DebtorService } from './DebtorService/DebtorService';
import { EarlyPayService } from './EarlyPayService/EarlyPayService';
import { FactoringTransactionHistoryService } from './FactoringTransactionHistoryService/FactoringTransactionHistoryService';
import { FileManagementService } from './FileManagementService/FileManagementService';
import { InvoiceService } from './InvoiceService/InvoiceService';
import { InvoiceUploadService } from './InvoiceUploadService/InvoiceUploadService';
import { MonthEndFactoringReportService } from './MonthEndFactoringReportService/MonthEndFactoringReportService';
import { MonthEndRFReportService } from './MonthEndRFReportService/MonthEndRFReportService';
import { OnboardingService } from './OnboardingService/OnboardingService';
import { RedirectService } from './RedirectService/RedirectService';
import { ReportService } from './ReportService/ReportService';
import { RfTransactionHistoryService } from './RfTransactionHistoryService/RfTransactionHistoryService';
import { TpfSupplierApiHttpService } from './TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { UnsettledBalanceReportService } from './UnsettledBalanceReportService/UnsettledBalanceReportService';
import { UserNewFeaturesService } from './UserNewFeaturesService/UserNewFeaturesService';

/**
 * Create service singletons.
 */
const clockService = new ClockService();
const tokenService = new TokenService('C2FOAuthToken');
const dataDogRUMService = new DataDogRumService(tokenService);
const redirectService = new RedirectService(tokenService);
const tpfSupplierApiHttpService = new TpfSupplierApiHttpService(tokenService, clockService);
const httpService = new HttpService(tokenService, clockService);

// intentionally not included in the list of services:
// the "view layer" shouldn't have knowledge of how encrypted data
// is stored
const clientService = new ClientService(tpfSupplierApiHttpService);
const invoiceUploadService = new InvoiceUploadService(tpfSupplierApiHttpService);
const invoiceService = new InvoiceService(tpfSupplierApiHttpService);
const reportService = new ReportService(tpfSupplierApiHttpService, invoiceService);
const clientDebtorService = new ClientDebtorService(tpfSupplierApiHttpService);
const debtorService = new DebtorService(tpfSupplierApiHttpService);
const advanceService = new AdvanceService(tpfSupplierApiHttpService);
const clientBalanceService = new ClientBalanceService(tpfSupplierApiHttpService);
const bankAccountService = new BankAccountService(tpfSupplierApiHttpService);
const onboardingService = new OnboardingService(tpfSupplierApiHttpService);
const rfTransactionHistoryService = new RfTransactionHistoryService(tpfSupplierApiHttpService);
const factoringTransactionHistoryService = new FactoringTransactionHistoryService(tpfSupplierApiHttpService);
const arpBalanceService = new ArpBalanceService(tpfSupplierApiHttpService);
const customerPaymentService = new CustomerPaymentService(tpfSupplierApiHttpService);
const customerPaymentAllocationService = new CustomerPaymentAllocationService(tpfSupplierApiHttpService);
const userNewFeaturesService = new UserNewFeaturesService(tpfSupplierApiHttpService);
const earlyPayService = new EarlyPayService(httpService);
const unsettledBalanceReportService = new UnsettledBalanceReportService(tpfSupplierApiHttpService);
const monthEndFactoringReportService = new MonthEndFactoringReportService(tpfSupplierApiHttpService);
const monthEndRFReportService = new MonthEndRFReportService(tpfSupplierApiHttpService);
const fileManagementService = new FileManagementService(tpfSupplierApiHttpService);
const capfinOnboardingService = new CapfinOnboardingService(tpfSupplierApiHttpService);
const analyticsService = new AnalyticsService(httpService);

const services = {
  tokenService,
  dataDogRUMService,
  redirectService,
  tpfSupplierApiHttpService,
  httpService,
  clientService,
  clientBalanceService,
  invoiceUploadService,
  invoiceService,
  reportService,
  debtorService,
  advanceService,
  clientDebtorService,
  bankAccountService,
  onboardingService,
  rfTransactionHistoryService,
  factoringTransactionHistoryService,
  arpBalanceService,
  customerPaymentService,
  customerPaymentAllocationService,
  userNewFeaturesService,
  clockService,
  earlyPayService,
  unsettledBalanceReportService,
  monthEndFactoringReportService,
  monthEndRFReportService,
  fileManagementService,
  capfinOnboardingService,
  analyticsService,
};

export type Services = typeof services;

export const { ServicesProvider, useServices, ServicesContext } = makeServices(services);
