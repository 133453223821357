import React, { useMemo } from 'react';
import { FinancialFile } from '../../schemas/fileManagement.schema';
import { Pagination, usePagination } from '../Table/Pagination';
import { Query } from '../../schemas';
import { Sorting, useSorting } from '../Table/Sorting';

export interface FileManagementFilters {
  sorting: Sorting;
  pagination: Pagination;
  params: Query<FinancialFile>;
}

export function useFileManagementFilters(storageKey: string): FileManagementFilters {
  let sorting = useSorting('created', storageKey, 'desc');

  let pagination = usePagination(storageKey);

  let params = useMemo(
    () => ({
      ...pagination.params,
      sort: [sorting.apiSort],
    }),
    [pagination.params, sorting.apiSort, sorting.field],
  );
  return {
    sorting,
    pagination,
    params,
  };
}
