import React from 'react';
import { ClientBalance } from '../../schemas';
import { ClientInvoiceUploadSummary } from '../../schemas';
import { ExclamationCircleIcon, TypeBase, makeStyles } from '@c2fo/react-components';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { InverseExclamationIcon } from '../CustomIcons/Icons';
import {
  SummaryCardAccountOverview,
  SummaryCardAccountOverviewTitle,
  SummaryCardAccountOverviewValue,
} from '../SummaryCard/SummaryCardAccountOverview';
import { SummaryCardCurrency } from '../SummaryCard/SummaryCardCurrency';
import {
  TooltipFormula,
  TooltipFormulaDivider,
  TooltipFormulaOperation,
  TooltipFormulaTerm,
} from '../Tooltips/TooltipFormula';
import { toDate } from '../../i18n/date';

const useStyles = makeStyles((theme) => ({
  limitReached: {
    fontSize: theme.typography.fontSize * 0.75,
    fontWeight: 300,
    color: theme.palette.secondary.main,
  },
  outstandingBalance: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    '& svg': {
      paddingRight: theme.spacing(1),
      fill: theme.palette.warning.main,
    },
  },
}));

export function OutstandingBalance(props: {
  balance: ClientBalance;
  isInverse?: boolean;
  isOverAdvanced?: boolean;
}): JSX.Element {
  const classes = useStyles();
  return (
    <SummaryCardAccountOverview>
      <SummaryCardAccountOverviewTitle isInverse={props.isInverse}>
        Outstanding Balance{' '}
        {props.balance.clientAvailableCredit <= 0 && (
          <TypeBase component={'span'} data-cy="credit-limit-reached-span" isInverse={props.isInverse}>
            <span className={classes.limitReached}>(LIMIT REACHED)</span>
          </TypeBase>
        )}
        &nbsp;
        <InfoTooltip isInverse={false} placement="bottom" name="rfAccountOverview.OutstandingBalance">
          <TooltipFormula>
            <TooltipFormulaTerm>Advances</TooltipFormulaTerm>
            <TooltipFormulaTerm operation={TooltipFormulaOperation.Add}>Fees</TooltipFormulaTerm>
            <TooltipFormulaTerm operation={TooltipFormulaOperation.Subtract}>Payments Made</TooltipFormulaTerm>
            <TooltipFormulaDivider />
            <TooltipFormulaTerm>Outstanding Balance</TooltipFormulaTerm>
          </TooltipFormula>
        </InfoTooltip>
      </SummaryCardAccountOverviewTitle>
      <SummaryCardAccountOverviewValue isInverse={props.isInverse}>
        <div data-cy={'outstanding-balance'} className={classes.outstandingBalance}>
          {props.isOverAdvanced ? <InverseExclamationIcon /> : null}
          <SummaryCardCurrency>{props.balance.outstandingBalance}</SummaryCardCurrency>
        </div>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

const THIS_MONTH = toDate(new Date(), 'LLLL');

export function InterestThisMonth(props: { balance: ClientBalance; isInverse?: boolean }): JSX.Element {
  return (
    <SummaryCardAccountOverview data-joyride-step="account-balance-stats">
      <SummaryCardAccountOverviewTitle isInverse={props.isInverse}>
        Interest in {THIS_MONTH}
        &nbsp;
        <InfoTooltip isInverse={false} placement="bottom" name="rfAccountOverview.InterestThisMonth">
          This is your total interest accrued from the first of the month to today’s date.
        </InfoTooltip>
      </SummaryCardAccountOverviewTitle>
      <SummaryCardAccountOverviewValue isInverse={props.isInverse}>
        <span data-cy={'interest-this-month'}>
          <SummaryCardCurrency>{props.balance.accruedInterestAmount}</SummaryCardCurrency>
        </span>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

export function LastInvoiceUpload(props: { lastUpload: ClientInvoiceUploadSummary | null }): JSX.Element {
  return (
    <SummaryCardAccountOverview>
      <SummaryCardAccountOverviewTitle>Last Invoice Uploaded</SummaryCardAccountOverviewTitle>

      <SummaryCardAccountOverviewValue>
        <span data-cy={'latest-upload'}>{toDate(props.lastUpload?.created ?? null)}</span>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

export function RFAvailabilityCalculation(props: { balance: ClientBalance }): JSX.Element {
  const borrowingBase = Math.min(props.balance.clientCreditLimit, props.balance.marginalCollateralBalance);
  const { outstandingBalance, accruedInterestAmount, availabilityBalance } = props.balance;

  return (
    <TooltipFormula>
      <TooltipFormulaTerm value={borrowingBase}>Borrowing Base</TooltipFormulaTerm>
      <TooltipFormulaTerm operation={TooltipFormulaOperation.Subtract} value={outstandingBalance}>
        Outstanding Balance
      </TooltipFormulaTerm>
      <TooltipFormulaTerm operation={TooltipFormulaOperation.Subtract} value={accruedInterestAmount}>
        Interest Accrued This Month
      </TooltipFormulaTerm>
      <TooltipFormulaDivider />
      <TooltipFormulaTerm value={availabilityBalance}>Funds Available for Advance</TooltipFormulaTerm>
    </TooltipFormula>
  );
}
