import React, { useState } from 'react';
import { BodyCell, HeaderCell } from './TableCell';
import { Checkbox } from '@c2fo/react-components';
import { QueryParam } from '../../schemas/http.schema';

const EMPTY_ARRAY: string[] = [];

export interface TableSelect<Item> {
  areAllSelected: boolean;
  toggleSelectAll: () => void;
  isSelected: (item: Item) => boolean;
  toggle: (item: Item) => void;
  param: QueryParam<string>;
  selectedKeys: string[];
}

/**
 * An implementation of a selectable table
 *
 * Assumes that every entry has a unique uuid field
 *
 * If that is not the case in the future, we'll need to refactor.
 * It makes things ez right now though.
 */
export function useTableSelect<Item extends { uuid: string }>(items: Item[]): TableSelect<Item> {
  let [selected, setSelected] = useState<string[]>(EMPTY_ARRAY);

  let selectedSet = new Set(selected);
  let areAllSelected = items.length > 0 && items.every((item) => selectedSet.has(item.uuid));
  let param: { in?: string[] } = {};
  if (selected.length > 0) {
    param.in = selected;
  }

  return {
    areAllSelected,
    selectedKeys: selected,
    param: param as QueryParam<string>,
    toggleSelectAll: () => {
      if (areAllSelected) {
        setSelected(EMPTY_ARRAY);
      } else {
        setSelected(items.map((item) => item.uuid));
      }
    },
    isSelected: (item: Item) => selectedSet.has(item.uuid),
    toggle: (item: Item) => {
      if (selectedSet.has(item.uuid)) {
        setSelected(selected.filter((uuid) => uuid !== item.uuid));
      } else {
        setSelected(selected.concat([item.uuid]));
      }
    },
  };
}

export function SelectAll<Item>(props: { select: TableSelect<Item> }) {
  return (
    <HeaderCell align={'center'}>
      <Checkbox
        checked={props.select.areAllSelected}
        onChange={props.select.toggleSelectAll}
        inputProps={
          {
            'data-testid': 'select-all',
          } as any
        }
        color={'primary'}
      />
    </HeaderCell>
  );
}

export function SelectBodyCell<Item extends { uuid: string }>(props: { select: TableSelect<Item>; item: Item }) {
  return (
    <BodyCell align={'center'}>
      <Checkbox
        data-cy={'select-item'}
        checked={props.select.isSelected(props.item)}
        onChange={() => props.select.toggle(props.item)}
        inputProps={
          {
            'data-testid': `${props.item.uuid}-toggle-item`,
          } as any
        }
        color={'primary'}
      />
    </BodyCell>
  );
}
