import React, { ReactNode } from 'react';
import {
  AlertBanner,
  Box,
  CloseIcon,
  Grid,
  IconButton,
  SecondaryButton,
  TypeBase,
  TypeSubsectionHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { ErrorRows } from './InvoiceUploadErrorRows';
import { FloatingCard, FloatingCardBody, FloatingCardHeader } from './FloatingCard';
import { InvoiceUploadError } from '../../schemas';
import { UploadTemplateTable } from './UploadTemplateTable';
import { useId } from '@reach/auto-id';

type AnchorStylesProps = { color: string };

const useAnchorStyles = makeStyles((theme) => ({
  anchor: {
    textDecoration: 'none',
    color: (props: AnchorStylesProps) => {
      if (props.color === 'green') {
        return theme.palette.primary.light;
      }
      return props.color;
    },
    borderBottom: (props: AnchorStylesProps) => {
      if (props.color === 'green') {
        return `1px solid ${theme.palette.primary.light}`;
      }
      return `1px solid ${props.color}`;
    },
  },
}));

function Anchor(props: { children: ReactNode; href: string; download?: string; color: string }) {
  let classes = useAnchorStyles(props);
  return (
    <a className={classes.anchor} href={props.href} download={props.download}>
      <TypeBase isEmphasis component={'span'} customColor={props.color}>
        {props.children}
      </TypeBase>
    </a>
  );
}

function NoErrorsMessage(props: Record<string, unknown>) {
  const theme = useTheme();
  return (
    <TypeBase data-testid={'no-errors-message'}>
      Match your Invoice file to the format shown to ensure a successful upload. Or download{' '}
      <Anchor download="invoice_template.csv" href="invoiceTemplate.csv" color={theme.palette.blue[500] || 'black'}>
        our template
      </Anchor>{' '}
      file and edit it with your invoice data.
    </TypeBase>
  );
}

const useErrorsListStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    margin: 0,
    marginTop: '1rem',
    marginLeft: '1rem',
  },
  listItem: {
    overflowWrap: 'break-word',
  },
}));

const ERROR_MESSAGES: { [x: string]: string } = {
  INELIGIBLE: 'This invoice is ineligible for upload',
  PARSE_ERROR: 'Unable to read parts of this line',
  FUTURE_DATED_INVOICE: 'Invoice date is too far into the future',
  FUTURE_DUE_DATE_INVOICE: 'Due date is too far into the future (>360 days)',
  PAST_DUE_INVOICE: "The invoice's due date is past due",
  DUE_DATE_BEFORE_TRANSACTION_DATE: 'The due date is before the invoice date',
  UNABLE_TO_PARSE_DATE: 'One of the date fields is missing or in an invalid format',
  INVALID_AMOUNT: 'Invoice amount is negative or empty',
  EMPTY_INVOICE_ID: 'Missing invoice ID',
  EMPTY_DEBTOR: 'Company name is empty',
  EMPTY_DEBTOR_ID: 'Company ID is empty',
  UNEXPECTED_ERROR: 'An unexpected error happened',
  DUPLICATE: 'Duplicate invoice ID in the file',
  UNKNOWN: 'An unknown error has occured',
  LOCKED: 'Invoice already approved',
  INVOICE_INSTRUCTIONS: 'Invoice upload instructions found in file',
  MISSING_REQUIRED_HEADERS: 'The file is missing required headers',
};

function ErrorsList(props: { errors: InvoiceUploadError[] }) {
  let classes = useErrorsListStyles();
  return (
    <ul className={classes.list} data-cy={'upload-errors'}>
      {props.errors.map((error, index) => (
        <li
          key={error.status}
          className={classes.listItem}
          data-testid={`error-row-${error.status}`}
          data-cy={`error-message-${index}`}
        >
          {ERROR_MESSAGES[error.status] ?? ERROR_MESSAGES.UNKNOWN} (
          {error.rows.length > 0 ? <ErrorRows rows={error.rows} /> : error.error})
        </li>
      ))}
    </ul>
  );
}
const ERROR_BACKGROUND_COLOR = `rgba(187, 49, 0, 0.05)`;

function ErrorsMessage(props: { errors: InvoiceUploadError[] }) {
  const theme = useTheme();

  let { errors } = props;
  let errorCount = errors.map((error) => error.rows.length).reduce((prev, curr) => prev + curr, 0);

  const content = (
    <>
      {errorCount <= 1 ? (
        <>
          <span data-cy={'error-count'}>One</span> error was found in your Invoice file.
        </>
      ) : (
        <>
          <span data-cy={'error-count'}>{errorCount}</span> errors were found in your Invoice file.
        </>
      )}{' '}
      Fix the errors below or download{' '}
      <Anchor download="invoice_template.csv" href="invoiceTemplate.csv" color={theme.palette.blue[500] || 'black'}>
        our template
      </Anchor>{' '}
      and try again.
      <ErrorsList errors={props.errors} />
    </>
  );

  return (
    <AlertBanner severity="error" variant="standard" icon={false}>
      {content}
    </AlertBanner>
  );
}

function UploadButton(props: { onFileSelect: (file: File) => void }) {
  let fileUploadId = useId();
  /**
   * Weird typing issues where the component prop doesn't think it exists,
   * even though it does.
   */
  let extraButtonProps = {
    component: 'span',
    style: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  } as any;

  return (
    <>
      <input
        id={fileUploadId}
        accept={'.csv'}
        style={{ display: 'none' }}
        type={'file'}
        onChange={(event) => {
          if (event.target.files) {
            props.onFileSelect(event.target.files[0]);
          }
        }}
      />
      <label htmlFor={fileUploadId}>
        <TypeBase>
          <SecondaryButton {...extraButtonProps}>Select File</SecondaryButton>
        </TypeBase>
      </label>
    </>
  );
}

export function UploadCard(props: {
  onFileSelect: (file: File) => void;
  errors?: InvoiceUploadError[];
  onClose: () => void;
}): JSX.Element {
  return (
    <FloatingCard data-testid={'upload-card'}>
      <section>
        <FloatingCardHeader>
          <TypeSubsectionHeader>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>Upload Invoices</Grid>
              <Grid item>
                <IconButton data-testid={'upload-card-close-button'} onClick={props.onClose} color={'secondary'}>
                  <CloseIcon color={'secondary'} />
                </IconButton>
              </Grid>
            </Grid>
          </TypeSubsectionHeader>
        </FloatingCardHeader>
        <FloatingCardBody>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {props.errors ? <ErrorsMessage errors={props.errors} /> : <NoErrorsMessage />}
                </Grid>
                <Grid item data-cy={'select-invoice-file-wrapper'}>
                  <UploadButton onFileSelect={props.onFileSelect} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <UploadTemplateTable />
            </Grid>
          </Grid>
        </FloatingCardBody>
      </section>
    </FloatingCard>
  );
}
