import { ProductType } from './enums.schema';

export enum TransferMethodPreference {
  ACH = 'ACH',
  WIRE = 'WIRE',
}

export interface Client {
  divisionUuid: string;
  isPreQualificationFormReviewed: boolean;
  isFinancialFilesPreQualificationComplete: boolean;
  isFinancialFilesComplete: boolean;
  uuid: string;
  productType: ProductType;
  isClosed: boolean;
  wireFee: number;
  transferMethodPreference: TransferMethodPreference;
}
