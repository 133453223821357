import React from 'react';
import { ClientBalance } from '../../schemas';
import { ClientInvoiceUploadSummary } from '../../schemas/invoiceUpload.schema';
import { InterestThisMonth, LastInvoiceUpload, OutstandingBalance } from './RFAccountOverview';
import { SummaryCardClosedAccount } from '../SummaryCard/SummaryCardClosedAccount';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useServices } from '../../services';

interface RFSummaryClosedAccountCardProps {
  balance: ClientBalance;
  lastUpload: ClientInvoiceUploadSummary | null;
}

export const RFSummaryClosedAccountCard: React.FC<RFSummaryClosedAccountCardProps> = (props) => {
  let divisionUuid = useCurrentDivision();
  let { rfTransactionHistoryService } = useServices();

  return (
    <div data-testid="RFSummaryClosedAccountCard">
      <SummaryCardClosedAccount
        onDownloadHistory={() => {
          rfTransactionHistoryService.download(divisionUuid, {});
        }}
        accountOverviewStats={
          <>
            <OutstandingBalance balance={props.balance} />
            <InterestThisMonth balance={props.balance} />
            <LastInvoiceUpload lastUpload={props.lastUpload} />
          </>
        }
      />
    </div>
  );
};
