import { BaseDataService } from '../BaseDataService/BaseDataService';
import {
  FactoringTransactionHistory,
  FactoringTransactionHistoryDetail,
} from '../../schemas/transaction-history.schema';
import { Query } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

import { tpfSupplierApiConfig } from '../../../config';

export class FactoringTransactionHistoryService extends BaseDataService<FactoringTransactionHistory> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(
      httpService,
      (divisionUuid) =>
        `${tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL}/v2/tpf-api/divisions/${divisionUuid}/factoring-transaction-history`,
    );
  }

  downloadSummary(divisionUuid: string, query: Query<FactoringTransactionHistory>) {
    this.httpService.downloadUrl(this.basePath(divisionUuid) + '/download', query);
  }

  getDownloadSummaryUrl(divisionUuid: string, query: Query<FactoringTransactionHistory>) {
    return this.httpService.formatDownloadUrl(this.basePath(divisionUuid) + '/download', query);
  }

  downloadDetail(divisionUuid: string, query: Query<FactoringTransactionHistoryDetail>) {
    this.httpService.downloadUrl(this.basePath(divisionUuid) + '-detail/download', query);
  }

  getDownloadDetailUrl(divisionUuid: string, query: Query<FactoringTransactionHistoryDetail>) {
    return this.httpService.formatDownloadUrl(this.basePath(divisionUuid) + '-detail/download', query);
  }
}
