import { BaseDataService } from '../BaseDataService/BaseDataService';
import { ClientDebtor } from '../../schemas/client-debtor.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const basePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/client-debtors`;

export class ClientDebtorService extends BaseDataService<ClientDebtor> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, basePath);
  }
}
