import React, { useContext, useEffect } from 'react';
import { DigitalData } from '../../lib';
import { ProductType } from '../schemas/enums.schema';

const ProductTypeContext = React.createContext<ProductType | null>(null);
const ddProductTypeConst = 'capFinProductType';

export function useProductType(): ProductType {
  const productType = useContext(ProductTypeContext);
  if (!productType) {
    throw new Error('Tried to `useProductType` without wrapping it in a <ProductTypeProvider>');
  }
  return productType;
}

export function ProductTypeProvider(props: { productType: ProductType; children: React.ReactNode }) {
  useEffect(() => {
    switch (props.productType) {
      case ProductType.Factoring:
        DigitalData.set(ddProductTypeConst, 'factoring');
        break;
      case ProductType.ReceivablesFinance:
        DigitalData.set(ddProductTypeConst, 'rf');
        break;
    }
  }, [props.productType]);

  return <ProductTypeContext.Provider value={props.productType}>{props.children}</ProductTypeContext.Provider>;
}
