import React from 'react';

import {
  Card,
  CardActions,
  CardContent,
  InboxIcon,
  PrimaryButton,
  SecondaryButton,
  Theme,
  TypeLabel,
  TypeSubsectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { ClientInvoiceUploadSummary } from '../../schemas';
import { CustomCloudUploadIcon } from '../CustomIcons/Icons';
import { ProductType } from '../../schemas';
import { routes } from '../../routes';
import { toCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  cardDraftStyles: {
    borderTop: `8px solid ${theme.palette.warning.main}`,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 190,
    paddingTop: theme.spacing(4),
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: theme.spacing(5),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  uploadHeader: {
    marginBottom: theme.spacing(3),
  },
  uploadIcon: {
    fontSize: 54,
  },
  uploadIconBlue: {
    color: theme.palette.secondary.main,
  },
  uploadIconWarn: {
    color: theme.palette.warning.main,
  },
  buttonStyles: {
    width: '80%',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
}));

type UploadCardProps = {
  lastUpload: ClientInvoiceUploadSummary | null;
  productType: ProductType;
  draftInvoiceTotal: number | null;
  isReadOnlyUser: boolean;
};

export const HomepageInvoiceUploadCard: React.FC<UploadCardProps> = ({
  lastUpload,
  productType,
  draftInvoiceTotal,
  isReadOnlyUser,
}) => {
  const classes = useStyles(productType);
  let divisionUuid = useCurrentDivision();

  let uploadString = toDate(lastUpload?.created ?? null, 'MMM d yyyy, h:mm a');

  let draftMarkup: JSX.Element | undefined;
  if (productType === ProductType.Factoring && draftInvoiceTotal && draftInvoiceTotal > 0) {
    draftMarkup = (
      <>
        <div className={[classes.uploadIcon, classes.uploadIconWarn].join(' ')}>
          <InboxIcon color="inherit" fontSize="inherit" />
        </div>
        <div className={classes.uploadHeader}>
          <TypeSubsectionHeader component="h1" align="center">
            Attention
          </TypeSubsectionHeader>
        </div>
        <div>
          <TypeLabel align="center" display="block" data-cy={'review-invoices-message'}>
            You have {toCurrency(draftInvoiceTotal)} in uploaded invoices waiting for your approval.
          </TypeLabel>
        </div>
      </>
    );
  }

  let history = useHistory();
  const goToUpload = () => {
    history.push({
      pathname: routes.division(divisionUuid).product(productType).invoices,
      search: '?showUpload=true',
    });
  };

  const buttonText = draftMarkup ? 'Review' : 'Upload Invoices';

  return (
    <Card
      className={(draftMarkup ? classes.cardDraftStyles : '', classes.card)}
      data-joyride-step={'invoice-card'}
      elevation={10}
    >
      <CardContent className={classes.cardContent}>
        {draftMarkup || (
          <>
            <div className={[classes.uploadIcon, classes.uploadIconBlue].join(' ')}>
              <CustomCloudUploadIcon size={'sm'} />
            </div>
            <div className={classes.uploadHeader}>
              <TypeSubsectionHeader component="h1" align="center">
                Have new invoices?
              </TypeSubsectionHeader>
            </div>
            <div>
              <TypeLabel align="center" display="block">
                Last Upload: <span data-cy={'last-upload-date'}>{uploadString}</span>
              </TypeLabel>
            </div>
          </>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <SecondaryButton
          className={'buttonStyles'}
          onClick={() => goToUpload()}
          data-cy={draftMarkup ? 'review-invoices-button' : 'upload-invoices'}
          disabled={isReadOnlyUser}
        >
          {buttonText}
        </SecondaryButton>
      </CardActions>
    </Card>
  );
};
