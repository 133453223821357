import { AxiosError } from 'axios';
import { BaseBalanceService } from '../BaseBalanceService/BaseBalanceService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { UnsettledBalanceReport } from '../../schemas';

const unsettledReportBasePath = (divisionUuid: string) => `/v2/tpf-api/divisions/${divisionUuid}/unsettled-report`;

export class UnsettledBalanceReportService extends BaseBalanceService<UnsettledBalanceReport> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, unsettledReportBasePath);
  }

  async getUnsettledReport(divisionUuid: string): Promise<UnsettledBalanceReport | null> {
    try {
      const response = await this.httpService.get<UnsettledBalanceReport>(unsettledReportBasePath(divisionUuid));
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 404) {
        return null;
      }
      throw err;
    }
  }
}
