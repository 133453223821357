import React, { useEffect } from 'react';
import { Client } from './schemas';
import { ConditionalClosedAccountBanner } from './components/ClosedAccount/ClosedAccountBanner';
import { DigitalData } from '../lib';
import {
  DivisionBasePage,
  FactoringCustomerPaymentsPage,
  FactoringHomePage,
  FactoringInvoicePage,
  FactoringTransactionHistoryPage,
  FileManagementPage,
  RfCustomerPaymentsPage,
  RfHomePage,
  RfInvoicePage,
  RfTransactionHistoryPage,
  RootPage,
  SetupPage,
} from './pages';
import { DivisionProvider } from './contexts/DivisionContext';
import { IntercomProvider } from 'react-use-intercom';
import { Navigation } from './components';
import { OnboardingPage } from './pages/OnboardingPage/OnboardingPage';
import { PageNotFoundSwitch } from './components/PageNotFound/PageNotFound';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { ProductType } from './schemas';
import { ProductTypeProvider } from './contexts/ProductType';
import { RFHomeStateProvider } from './pages/RfHomePage/RFHomeContext';
import { Route, useLocation } from 'react-router-dom';
import { SplitProvider } from './providers';
import { SubNav } from './components/SubNav/SubNav';
import { intercomConfig } from '../config';
import { useAsync } from 'react-use';
import { useServices } from './services';

declare global {
  interface Window {
    Appcues: any;
  }
}

window.Appcues = window.Appcues || {};

function initiateAppcuesIdentityEvent(client: Client) {
  let segmentName;
  const { divisionUuid, productType } = client;
  if (client.productType === ProductType.ReceivablesFinance) {
    segmentName = 'receivable-finance';
  } else if (client.productType === ProductType.Factoring) {
    segmentName = 'ar-purchase';
  }
  // eslint-disable-next-line no-unused-expressions
  window.Appcues &&
    window.Appcues.identify &&
    window.Appcues.identify(client.uuid, {
      createdAt: new Date(),
      siteName: 'capfin',
      tpfSegmentName: segmentName,
      tpfProductType: productType,
      tpfEnvironment: process.env.NODE_ENV === 'development' ? 'capfin_dev' : 'capfin_nondev',
      tpfDivisionUuid: divisionUuid,
    });
}

/**
 * All RF-related subroutes.
 * @param props
 */
function ReceivablesFinanceRoutes(props: { base: string; client: Client; isReadOnlyUser: boolean }) {
  initiateAppcuesIdentityEvent(props.client);

  return (
    <ProductTypeProvider productType={ProductType.ReceivablesFinance}>
      {/* Home page manages its own container */}
      <PageNotFoundSwitch>
        <Route path={`${props.base}/home`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <RFHomeStateProvider>
            <RfHomePage isReadOnlyUser={props.isReadOnlyUser} />
          </RFHomeStateProvider>
        </Route>
        <Route path={`${props.base}/invoice`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <RfInvoicePage client={props.client} isReadOnlyUser={props.isReadOnlyUser} />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/transaction-history`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <RfTransactionHistoryPage />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/customer-payments`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <RfCustomerPaymentsPage />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/file-management`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <FileManagementPage client={props.client} isReadOnlyUser={props.isReadOnlyUser} />
          </PageWrapper>
        </Route>
      </PageNotFoundSwitch>
    </ProductTypeProvider>
  );
}

/**
 * All factoring-related subroutes

 * @param props
 */
function FactoringRoutes(props: { base: string; client: Client; isReadOnlyUser: boolean }) {
  initiateAppcuesIdentityEvent(props.client);

  return (
    <ProductTypeProvider productType={ProductType.Factoring}>
      <PageNotFoundSwitch>
        <Route path={`${props.base}/home`}>
          <ConditionalClosedAccountBanner client={props.client} />

          <FactoringHomePage isReadOnlyUser={props.isReadOnlyUser} />
        </Route>
        <Route path={`${props.base}/invoice`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <FactoringInvoicePage client={props.client} isReadOnlyUser={props.isReadOnlyUser} />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/transaction-history`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <FactoringTransactionHistoryPage />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/customer-payments`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <FactoringCustomerPaymentsPage />
          </PageWrapper>
        </Route>
        <Route path={`${props.base}/file-management`}>
          <ConditionalClosedAccountBanner client={props.client} />
          <PageWrapper>
            <FileManagementPage client={props.client} isReadOnlyUser={props.isReadOnlyUser} />
          </PageWrapper>
        </Route>
      </PageNotFoundSwitch>
    </ProductTypeProvider>
  );
}

/**
 * Redirect to the onboarding page
 * @param props
 */
function OnboardingRedirect(props: { pathname: string }) {
  window.location.assign('/onboarding');
  return null;
}

// https://github.com/pillarjs/path-to-regexp/tree/v1.7.0
// tester: https://forbeslindesay.github.io/express-route-tester/
const uuidv4PathMatch = '[A-F0-9]{8}-[A-F0-9]{4}-4[A-F0-9]{3}-[89AB][A-F0-9]{3}-[A-F0-9]{12}';

export const Router: React.FC = () => {
  DigitalData.Hooks.useCurrentURL();

  const { tokenService, earlyPayService, dataDogRUMService } = useServices();
  const isNoAuthMode = process.env.NODE_ENV === 'development' && process.env.REACT_APP_NOAUTH_MODE === '1';
  dataDogRUMService.setUser();
  const asyncUser = useAsync(() => earlyPayService.getUser());

  function useAppcuesPage() {
    // call Appcues.page() after each URL change
    let location = useLocation();
    useEffect(() => {
      // eslint-disable-next-line no-unused-expressions
      window.Appcues && typeof window.Appcues.page === 'function' && window.Appcues.page();
    }, [location]);
  }
  const userData = tokenService.getTokenContent()?.payload.user;
  const intercomShouldInitialize = !!asyncUser.value;
  const appId = intercomConfig.INTERCOM_APP_ID;
  const intercomBootProps = asyncUser.value
    ? {
        // user_id and user_hash must be formatted this way for Intercom comp
        userId: userData?.uuid,
        userHash: asyncUser.value.ciphers.intercomUserId,
        name: userData?.emailAddress,
        createdAt: Date.now(),
        email: userData?.emailAddress,
        isReadOnlyUser: asyncUser.value.supplierReadOnly,
      }
    : undefined;

  useAppcuesPage();

  return (
    <IntercomProvider
      appId={appId}
      autoBoot
      shouldInitialize={intercomShouldInitialize}
      autoBootProps={intercomBootProps}
    >
      <PageNotFoundSwitch>
        <Route path="/" exact>
          <RootPage />
        </Route>
        <Route
          path="/onboarding"
          render={() => (
            <>
              <Navigation />
              <OnboardingPage />
            </>
          )}
        />
        <Route path="/landing-page/info" render={({ match }) => <OnboardingRedirect pathname={match.path} />} />
        <Route path="/receivable-finance/setup" render={({ match }) => <OnboardingRedirect pathname={match.path} />} />
        <Route
          path={`/:divisionUuid(${uuidv4PathMatch})`}
          render={({ match }) => (
            <DivisionProvider divisionUuid={match.params.divisionUuid}>
              <SplitProvider uniqueId={match.params.divisionUuid}>
                <DivisionBasePage divisionUuid={match.params.divisionUuid} isExact={match.isExact}>
                  {(client) => (
                    <>
                      <Navigation />
                      <SubNav />
                      <PageNotFoundSwitch>
                        <Route
                          path={`${match.path}/receivable-finance`}
                          render={({ match: rfMatch }) => (
                            <ReceivablesFinanceRoutes
                              base={rfMatch.path}
                              client={client}
                              isReadOnlyUser={asyncUser.value ? asyncUser.value.supplierReadOnly : !isNoAuthMode}
                            />
                          )}
                        />
                        <Route
                          path={`${match.path}/ar-purchase`}
                          render={({ match: factoringMatch }) => (
                            <FactoringRoutes
                              base={factoringMatch.path}
                              client={client}
                              // if we are running in noAuthMode, isReadOnlyUser will be set to false to allow for us to
                              // test features locally in no-auth environment.
                              isReadOnlyUser={asyncUser.value ? asyncUser.value.supplierReadOnly : !isNoAuthMode}
                            />
                          )}
                        />
                        <Route path={`${match.path}/setup/bank-account`}>
                          <ConditionalClosedAccountBanner client={client} />

                          <PageWrapper>
                            <SetupPage />
                          </PageWrapper>
                        </Route>
                      </PageNotFoundSwitch>
                    </>
                  )}
                </DivisionBasePage>
              </SplitProvider>
            </DivisionProvider>
          )}
        />
      </PageNotFoundSwitch>
    </IntercomProvider>
  );
};
