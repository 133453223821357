import update from 'immutability-helper';
import { FactoringInvoice, RfInvoice } from '../../schemas/invoice.schema';
import { InvoiceReporting, ProductType, Tokenized } from '../../schemas';
import { PageableResponse, Query } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const rfBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/invoices`;
const factoringBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/factoring-invoices`;
// download path doesn't include apiUrl because Httpservice handles that for us.
const invoiceDownloadBasePath = (divisionUuid: string, productType: ProductType) =>
  `/v2/tpf-api/divisions/${divisionUuid}/${
    productType === ProductType.ReceivablesFinance ? 'invoices' : 'factoring-invoices'
  }/download`;

/**
 * Service for interacting with client API endpoints.
 */
export class InvoiceService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  /**
   * get a client's data.
   * @param divisionUuid
   */
  async getRfInvoices(divisionUuid: string, params: Query<RfInvoice>): Promise<PageableResponse<RfInvoice>> {
    try {
      let response = await this.httpService.get<PageableResponse<RfInvoice>>(rfBasePath(divisionUuid), {
        params: this.httpService.serializeQueryParams(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(`Invoices get error debug: ${error}`);
    }
  }

  async getFactoringInvoices(
    divisionUuid: string,
    params: Query<FactoringInvoice>,
  ): Promise<PageableResponse<FactoringInvoice>> {
    try {
      let response = await this.httpService.get<PageableResponse<FactoringInvoice>>(factoringBasePath(divisionUuid), {
        params: this.httpService.serializeQueryParams(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(`Invoices get error debug: ${error}`);
    }
  }

  /**
   * Download invoices based on a Query.
   * ignore pagination and size in these downloads: we just care about legitimate filters/sorts.
   * @param divisionUuid
   * @param productType
   * @param params
   */
  downloadInvoicesFromQuery(
    divisionUuid: string,
    productType: ProductType,
    params: Query<RfInvoice | FactoringInvoice | InvoiceReporting | Tokenized>,
  ): string {
    // we never care about pagination in downloads.
    const paramsSansPagination = update(params, {
      $unset: ['page', 'size'],
    });
    return this.httpService.downloadUrl(invoiceDownloadBasePath(divisionUuid, productType), paramsSansPagination);
  }
}
