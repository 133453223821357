import React, { useEffect, useState } from 'react';
import { Box, C2foComponentsRootProvider, SnackbarProvider, Switch, makeStyles } from '@c2fo/react-components';
import { DigitalData, ErrorBoundary } from '../lib';
import { Footer } from './components/Footer/Footer';
import { GlobalErrorPage } from './pages';
import { Helmet } from 'react-helmet';
import { Router } from './Router';
import { ServicesProvider, useServices } from './services';
import { SplitProvider, TREATMENTS, useFeature } from './providers';

import FullStory from 'react-fullstory';
import faviconLg from '../assets/favicon-300x300.png';
import faviconSm from '../assets/favicon-150x150.png';
import rebrandFaviconLg from '../assets/liquidity/favicon-300x300.png';
import rebrandFaviconSm from '../assets/liquidity/favicon-150x150.png';
import { AuthTimeout } from './components/AuthTimeoutModal/AuthTimeoutModal';
import { BrowserRouter } from 'react-router-dom';
import { StoreToken } from './components/Navigation/StoreToken';
import { datadogRum } from '@datadog/browser-rum';
import { fullstoryConfig } from '../config';

import { dataDogRUMConfig } from '../config';

datadogRum.init({
  applicationId: dataDogRUMConfig.CAPFIN_RUM_APP_ID,
  clientToken: dataDogRUMConfig.CAPFIN_RUM_CLIENT_TOKEN,
  service: 'capfin-app',
  env: dataDogRUMConfig.CAPFIN_APP_ENV,
  version: dataDogRUMConfig.CAPFIN_APP_VERSION,
  sampleRate: 100,
  trackInteractions: true,
  trackSessionAcrossSubdomains: true,
  allowedTracingOrigins: [
    /https:\/\/.*\.c2fo\.com/,
    /https:\/\/.*\.c2fo\.dev/,
    /https:\/\/.*\.c2fo\.io/,
    /http:\/\/.*\.localhost/,
  ],
  trackFrustrations: true,
});

datadogRum.startSessionReplayRecording();

DigitalData.loadScriptForHostname(
  new Map<string, string>()
    .set('capfin-app.c2fo.com', 'https://assets.adobedtm.com/348995220a3c/2ec113471002/launch-b90639944002.min.js')
    .set(
      'fss-capfin-app.c2fo.com',
      'https://assets.adobedtm.com/348995220a3c/2ec113471002/launch-7f1d1449866a-staging.min.js',
    )
    .set('default', 'https://assets.adobedtm.com/348995220a3c/2ec113471002/launch-0c18ca9ad722-development.min.js'),
);

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    /* height: 100% inside min-height: 100% container only fills content space */
    height: '100vh',
  },
  pageContent: {
    flex: '1 0 auto',
  },
  footer: {
    flexShrink: 0,
  },
}));

const AppHead: React.FC = () => (
  <Helmet>
    <title>C2FO - Capital Finance</title>
    <base href="/" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <link href={rebrandFaviconLg} data-favicon-id="lg" rel="icon" type="image/x-icon" sizes="192x192" />
    <link href={rebrandFaviconSm} data-favicon-id="sm" rel="icon" type="image/x-icon" sizes="32x32" />
    <link href={rebrandFaviconLg} data-favicon-id="lg" rel="apple-touch-icon" />
  </Helmet>
);

const Main = () => {
  const classes = useStyles();
  const { analyticsService, tokenService } = useServices();

  // make sure we only call track event once when the page is loaded.
  useEffect(() => {
    const currentUserUUID: string = tokenService.getTokenContent()?.payload.user.uuid || '';
    if (currentUserUUID) {
      analyticsService.track('capfin:capfin_app:app_initialized', currentUserUUID);
    }
  }, []);

  DigitalData.Hooks.useSite('capfin');
  return (
    <C2foComponentsRootProvider theme="liquidity">
      <div className={classes.contentWrapper}>
        {/* GlobalErrorPage relies on styles and services, so it must be place below them */}
        <ErrorBoundary display={<GlobalErrorPage />}>
          <AppHead />
          {fullstoryConfig.FULLSTORY_ENABLED ? <FullStory org={fullstoryConfig.FULLSTORY_ORG_ID} /> : null}
          <div className={classes.pageContent}>
            <BrowserRouter>
              <StoreToken>
                <AuthTimeout />
                <Router />
              </StoreToken>
            </BrowserRouter>
          </div>
          <div className={classes.footer}>
            <Footer />
          </div>
        </ErrorBoundary>
      </div>
    </C2foComponentsRootProvider>
  );
};

const App: React.FC = () => (
  <SplitProvider>
    <ServicesProvider>
      <Main />
    </ServicesProvider>
  </SplitProvider>
);

export default App;
