import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ClockService } from '../clock/ClockService';
import { TokenService } from '../token';

export interface HandleStatusCode {
  [key: number]: (error: AxiosError) => void;
}

/**
 * Base HttpService abstraction.
 */
export class HttpService {
  constructor(protected tokenService: TokenService, protected clockService: ClockService) {
    this.tokenService = tokenService;
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'GET', url, ...config });
  }

  async post<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'POST', url, ...config });
  }

  async put<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PUT', url, ...config });
  }

  async patch<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'PATCH', url, ...config });
  }

  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.request<T>({ method: 'DELETE', url, ...config });
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const token = this.tokenService.getToken();

    const defaultRequestInfo: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    };

    if (token && !this.tokenService.isTokenExpired()) {
      // Guarding against headers being undefined. This is being thrown as a type error if we don't guard.
      if (defaultRequestInfo.headers === undefined) {
        defaultRequestInfo.headers = {};
      }
      defaultRequestInfo.headers.Authorization = `Bearer ${token}`;
    }

    return axios.request<T>({
      ...defaultRequestInfo,
      ...config,
      params: {
        ...config.params,
        // Internet explorer is caching API calls and not updating the
        // authorization headers correctly.
        _: this.clockService.timestamp(),
      },
      headers: {
        ...defaultRequestInfo.headers,
        ...config.headers,
      },
    });
  }

  getUri<T>(url: string, config?: AxiosRequestConfig): string {
    return axios.getUri({ url, ...config });
  }

  /**
   * Given an error and a mapping object, map specific error handling to a response status, if it exists.
   * @param error - AxiosError
   * @param handleStatusCode - map object of status codes to handle functions.
   */
  onHttpErrorStatus(error: AxiosError, handleStatusCode: HandleStatusCode): void {
    if (error.response && error.response.status) {
      const statusCode = error.response.status;
      const handleStatusCodeFn = handleStatusCode[statusCode];

      if (typeof handleStatusCodeFn === 'function') {
        handleStatusCodeFn(error);
      }
    }
  }
}
