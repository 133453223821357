import { AxiosError } from 'axios';
import {
  ClientInvoiceUploadSummary,
  CustomerMapping,
  FactoringUploadDraftTotals,
  FactoringUploadFinalizeDto,
  InvoiceUploadError,
  InvoiceUploadUnmapped,
  PageableResponse,
  StagedUploadResponse,
} from '../../schemas';
import { ProductType } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;

const divisionBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}`;
const clientInvoiceUploadsBasePath = (divisionUuid: string, productType: string) =>
  `${divisionBasePath(divisionUuid)}/${productType}/client-invoice-uploads`;

/**
 * Service for interacting with invoice upload API endpoints.
 */
export class InvoiceUploadService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  /**
   * get the latest invoice upload's data.
   * @param divisionUuid
   */
  async getLatestUpload(divisionUuid: string, productType: ProductType): Promise<ClientInvoiceUploadSummary | null> {
    try {
      const response = await this.httpService.get<PageableResponse<ClientInvoiceUploadSummary>>(
        `${clientInvoiceUploadsBasePath(
          divisionUuid,
          productType,
        )}/most-recent?id=most-recent&productType=${productType}`,
      );
      return response.data.data[0];
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 404) {
        return null;
      }
      throw err;
    }
  }

  /**
   * Get current draft invoice totals for a factoring client
   * @param divisionUuid - client/division identifier
   */
  async getFactoringUploadDraftTotals(divisionUuid: string): Promise<FactoringUploadDraftTotals> {
    let response = await this.httpService.get<FactoringUploadDraftTotals>(
      `${divisionBasePath(divisionUuid)}/factoring-upload-draft-totals`,
    );
    return response.data;
  }

  async uploadInvoiceFile(divisionUuid: string, file: File, productType: ProductType): Promise<StagedUploadResponse> {
    /**
     * Ok so how does uploading invoices work?
     *
     * First thing to keep in mind: there are soft & hard errors
     * for invoices. They're both instances of InvoiceUploadError, which
     * kinda makes it confusing.
     *
     * Just for testing purposes, know the following:
     * - An example of a hard error is having a non-numeric amount
     * - An example of a soft error is having a due date >60 days past due
     *
     * Ok so what is the significance of all this?
     *
     * Well if we encounter _hard_ errors, we stop the upload process completely!
     *
     * However, if there are _soft_ errors, we _still commit the file_!!! This is
     * super important to keep in mind.
     */
    interface UploadResponse {
      uuid: string;
      errors: InvoiceUploadError[];
    }
    let basePath = clientInvoiceUploadsBasePath(divisionUuid, productType);
    let upload = await this.httpService.post<UploadResponse>(`${basePath}/${file.name}`, {
      data: file,
    });
    let hardErrors = upload.data.errors;
    if (hardErrors.length > 0) {
      return {
        uuid: upload.data.uuid,
        hardErrors,
        softErrors: null,
        summary: null,
        unmapped: null,
      };
    }
    let uploadPath = `${basePath}/${upload.data.uuid}`;

    let [softErrors, unmapped, summary] = await Promise.all([
      this.getErrorSummary(divisionUuid, productType, upload.data.uuid),
      this.httpService.get<InvoiceUploadUnmapped[]>(`${uploadPath}/unmapped`),
      this.getSummary(divisionUuid, productType, upload.data.uuid),
    ]);
    /**
     * Basically the only reason we don't do an "early commit"
     * is if there are unmapped invoices
     *
     * This includes the case below where all rows are errors
     */
    if (summary.unmappedCount === 0) {
      summary = await this.commitUpload(divisionUuid, productType, upload.data.uuid);
      /**
       * Note that we need to re-grab the errors,
       * as they might have changed post commit
       */
      softErrors = await this.getErrorSummary(divisionUuid, productType, upload.data.uuid);
    }

    let totalNonErroredRows =
      summary.successCount + summary.newCount + summary.unmappedCount + summary.updatedCount + summary.identicalCount;

    if (totalNonErroredRows === 0) {
      return {
        uuid: upload.data.uuid,
        hardErrors: softErrors,
        softErrors: null,
        unmapped: null,
        summary: null,
      };
    }

    return {
      uuid: upload.data.uuid,
      hardErrors: null,
      softErrors,
      unmapped: unmapped.data,
      summary,
    };
  }

  async addMappings(
    divisionUuid: string,
    productType: ProductType,
    uploadUuid: string,
    mappings: CustomerMapping[],
  ): Promise<ClientInvoiceUploadSummary> {
    let basePath = clientInvoiceUploadsBasePath(divisionUuid, productType);

    await this.httpService.post(basePath + '/mappings', {
      data: mappings,
    });
    return this.commitUpload(divisionUuid, productType, uploadUuid);
  }

  private async getSummary(
    divisionUuid: string,
    productType: ProductType,
    uploadUuid: string,
  ): Promise<ClientInvoiceUploadSummary> {
    let basePath = clientInvoiceUploadsBasePath(divisionUuid, productType);
    let uploadPath = `${basePath}/${uploadUuid}`;
    let response = await this.httpService.get<ClientInvoiceUploadSummary>(uploadPath);
    return response.data;
  }

  private async getErrorSummary(
    divisionUuid: string,
    productType: ProductType,
    uploadUuid: string,
  ): Promise<InvoiceUploadError[]> {
    let basePath = clientInvoiceUploadsBasePath(divisionUuid, productType);
    let uploadPath = `${basePath}/${uploadUuid}`;
    let response = await this.httpService.get<InvoiceUploadError[]>(uploadPath + '/error-summary');
    return response.data;
  }

  private async commitUpload(
    divisionUuid: string,
    productType: ProductType,
    uploadUuid: string,
  ): Promise<ClientInvoiceUploadSummary> {
    let basePath = clientInvoiceUploadsBasePath(divisionUuid, productType);
    let uploadPath = `${basePath}/${uploadUuid}`;
    let response = await this.httpService.post<ClientInvoiceUploadSummary>(`${uploadPath}/commit`);
    return response.data;
  }

  async finalizeFactoringUpload(divisionUuid: string, data: FactoringUploadFinalizeDto): Promise<void> {
    await this.httpService.post(`${divisionBasePath(divisionUuid)}/factoring-invoices/finalize-upload`, { data });
  }

  shouldShowWhatsNext(): boolean {
    let storageItem = localStorage.getItem('capfin-app:invoice-upload:show-whats-next');
    return storageItem !== 'false';
  }

  dontShowWhatsNext() {
    localStorage.setItem('capfin-app:invoice-upload:show-whats-next', 'false');
  }
}
