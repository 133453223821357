import React, { useState } from 'react';
import clsx from 'clsx';
import {
  ButtonProps,
  CTAButton,
  CircularProgress,
  DestructiveButton,
  SecondaryButton,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { useMountedState } from 'react-use';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: 'calc(50% - 1rem)',
    left: 'calc(50% - 1rem)',
  },
  buttonStyle: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
}));

// We're building our own type here based on the ButtonProps. We Omit the original 'color' prop because we need to add
// a new 'destructive' color in order to create a DestructiveButton.
export type AsyncButtonProps = Omit<ButtonProps, 'color'> & {
  color?: ButtonProps['color'] | 'destructive';
  action: () => Promise<any>;
  wrapperClassName?: string;
};

export function AsyncButton(props: AsyncButtonProps): JSX.Element {
  const { action, wrapperClassName, color, ...rest } = props;
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const classes = useStyles();
  const isMounted = useMountedState();

  const onFinish = () => {
    if (isMounted()) {
      setIsRunning(false);
    }
  };
  const theme = useTheme();

  const progressColor = () => {
    switch (color) {
      case 'secondary':
        return theme.palette.secondary.main;
      case 'destructive':
        return theme.palette.error.light;
      default:
        return theme.palette.primary.light;
    }
  };

  const buttonStyle = { fontSize: '1.2rem', fontWeight: 600 };

  const renderButton = () => {
    switch (color) {
      case 'secondary':
        return (
          <SecondaryButton
            {...rest}
            color={color}
            onClick={() => {
              setIsRunning(true);
              Promise.resolve(action()).then(onFinish, onFinish);
            }}
            style={buttonStyle}
            disabled={isRunning}
          />
        );
      case 'destructive':
        return (
          <DestructiveButton
            {...rest}
            onClick={() => {
              setIsRunning(true);
              Promise.resolve(action()).then(onFinish, onFinish);
            }}
            style={buttonStyle}
            disabled={isRunning}
          />
        );
      default:
        return (
          <CTAButton
            {...rest}
            onClick={() => {
              setIsRunning(true);
              Promise.resolve(action()).then(onFinish, onFinish);
            }}
            style={buttonStyle}
            disabled={isRunning}
          />
        );
    }
  };
  return (
    <div className={clsx(classes.wrapper, wrapperClassName)}>
      {renderButton()}
      {isRunning && (
        <CircularProgress
          data-testid={'async-button-spinner'}
          style={{ color: `${progressColor()}` }}
          size={'2rem'}
          className={classes.progress}
        />
      )}
    </div>
  );
}
