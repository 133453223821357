import AsyncSelect from 'react-select/async';
import React, { useCallback } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@c2fo/react-components';
import { DropdownIndicator, Option } from '../Select/Select';
import { FactoringInvoice, RfInvoice } from '../../schemas';
import { LoadingIndicatorProps } from 'react-select/src/components/indicators';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import { MultiselectFilter } from './useMultiselectFilter';
import { ProductType } from '../../schemas';
import { components } from 'react-select';
import { useAsync } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

function LoadingIndicator<T>(props: LoadingIndicatorProps<T, boolean>) {
  let innerProps = {
    'data-cy': 'select-loading-indicator',
    'data-testid': 'select-loading-indicator',
    ...props.innerProps,
  };
  return <components.LoadingIndicator {...props} innerProps={innerProps} />;
}

function MultiValueRemove<T>(props: MultiValueRemoveProps<T>) {
  let innerProps = {
    ...props.innerProps,
    'data-testid': `remove`,
  };
  return <components.MultiValueRemove {...props} innerProps={innerProps} />;
}

export function TypeaheadSearch<T, TKey extends string>(props: {
  loadOptions: (value: string) => Promise<T[]>;
  getLabel: (item: T) => string;
  filter: MultiselectFilter<T, TKey>;
}) {
  let { loadOptions, getLabel, filter, ...rest } = props;
  /**
   * Apparently AsyncSelect already makes sure
   * that it doesn't try to load with an input
   * value of ''
   */
  return (
    <AsyncSelect<T, boolean>
      {...rest}
      isMulti
      aria-label={'Search for items'}
      value={filter.items}
      onChange={(options) => filter.onChange((options || []) as T[])}
      loadOptions={loadOptions}
      getOptionValue={(item) => filter.key(item)}
      getOptionLabel={getLabel}
      components={{ MultiValueRemove, DropdownIndicator, Option, LoadingIndicator }}
      noOptionsMessage={() => 'Start typing to search'}
    />
  );
}

export function DebtorSearch(props: { filter: MultiselectFilter<string> }) {
  const divisionUuid = useCurrentDivision();
  const { debtorService } = useServices();
  const { value: debtors, loading } = useAsync(async () => {
    const queryResult = await debtorService.query(divisionUuid, {
      sort: ['name,ASC'],
      size: 1000,
    });
    return queryResult.data.map((debtor) => debtor.name);
  }, [divisionUuid, debtorService]);

  return (
    <div data-cy={'typeAheadFilter-debtorName'}>
      <Autocomplete
        multiple
        aria-label={'Search for items'}
        options={debtors || []}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Search for items...'}
            size={'small'}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        loading={loading}
        onChange={(event, newValue) => {
          props.filter.onChange(newValue);
        }}
      />
    </div>
  );
}

export function InvoiceIdSearch(props: { filter: MultiselectFilter<string> }) {
  let divisionUuid = useCurrentDivision();
  let { invoiceService } = useServices();
  let productType = useProductType();
  let loadOptions = useCallback(
    async (inputValue) => {
      let invoicesPromise;
      switch (productType) {
        case ProductType.ReceivablesFinance:
          invoicesPromise = invoiceService.getRfInvoices(divisionUuid, {
            invoiceId: { like: inputValue },
            sort: ['invoiceId,DESC'],
            size: 10,
          });
          break;
        case ProductType.Factoring:
          invoicesPromise = invoiceService.getFactoringInvoices(divisionUuid, {
            invoiceId: { like: inputValue },
            sort: ['invoiceId,DESC'],
            size: 10,
          });
          break;
      }
      let invoices = await invoicesPromise;
      let invoiceData: (RfInvoice | FactoringInvoice)[] = invoices.data;
      return invoiceData.map((invoice: RfInvoice | FactoringInvoice) => invoice.invoiceId);
    },
    [divisionUuid, invoiceService, productType],
  );
  return (
    <div data-cy={'typeAheadFilter-invoiceId'}>
      <TypeaheadSearch filter={props.filter} loadOptions={loadOptions} getLabel={(invoice) => invoice} />
    </div>
  );
}
