import React, { ReactNode } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormGroupProps, styled } from '@c2fo/react-components';
import { ClearButton } from './ClearButton';
import { InvoiceSupplierStatus } from '../../schemas';
import { InvoiceSupplierStatusLabel } from '../InvoicePage/InvoiceSupplierStatusLabel';
import { MultiselectFilter } from './useMultiselectFilter';

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(1) / 2,
  paddingLeft: theme.spacing(1),
}));

export function ValueFilter<T, TKey extends string>(props: {
  filter: MultiselectFilter<T, TKey>;
  showSelectAll?: boolean;
  options: T[];
  formGroupProps?: FormGroupProps;
  render: (value: T) => ReactNode;
}): JSX.Element {
  const { key, items, onChange } = props.filter;
  return (
    <FormGroup {...props.formGroupProps}>
      {props.showSelectAll && (
        <FormControlLabel
          control={
            <SmallCheckbox
              color={'secondary'}
              checked={props.options.every((o) => items.includes(o))}
              onChange={(event) => {
                if (event.target.checked) {
                  onChange(props.options);
                } else {
                  onChange([]);
                }
              }}
            />
          }
          label={<strong>Select All</strong>}
        />
      )}
      {props.options.map((option) => {
        let optKey = key(option);
        return (
          <FormControlLabel
            data-cy={`valueFilter-${optKey}`}
            key={optKey}
            control={
              <SmallCheckbox
                color={'secondary'}
                checked={items.map(key).includes(optKey)}
                onChange={(event) => {
                  if (event.target.checked) {
                    onChange(items.concat([option]));
                  } else {
                    onChange(items.filter((s) => key(s) !== optKey));
                  }
                }}
              />
            }
            label={props.render(option)}
          />
        );
      })}
      {items.length > 0 && (
        <div>
          <ClearButton
            onClick={() => {
              onChange([]);
            }}
          />
        </div>
      )}
    </FormGroup>
  );
}

export function SupplierStatusFilter(props: {
  options: InvoiceSupplierStatus[];
  filter: MultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>;
  showSelectAll?: boolean;
  formGroupProps?: FormGroupProps;
}): JSX.Element {
  return (
    <div data-cy={'valueFilter-Status'}>
      <ValueFilter
        filter={props.filter}
        options={props.options}
        showSelectAll={props.showSelectAll}
        formGroupProps={props.formGroupProps}
        render={(status) => (
          <span data-cy={`valueFilter-Status-${status}`}>
            <InvoiceSupplierStatusLabel status={status} />
          </span>
        )}
      />
    </div>
  );
}
