import React from 'react';
import {
  Divider,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TypeBase,
  TypeSectionHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';

import clsx from 'clsx';
import { BodyCell, DateCell, HeaderCell, MoneyCell } from '../Table/TableCell';
import { CustomerPayment, CustomerPaymentAllocation } from '../../schemas/customerPayment.schema';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { ProductType } from '../../schemas/enums.schema';
import { TypographySystem } from '@c2fo/react-components';
import { toCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';
import { toTabularCurrency } from '../../i18n/currency';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  // specificity hacks: this conflicts with the
  // default paddingLeft rule
  // (same goes for padRight)
  padLeft: {
    '&$padLeft': {
      paddingLeft: theme.spacing(3),
    },
  },
  padRight: {
    '&$padRight': {
      paddingRight: theme.spacing(3),
    },
  },
  pendingTd: {
    '&$padRight': {
      paddingRight: theme.spacing(3),
    },
    display: 'flex',
  },
  pendingTextWrapper: {
    padding: theme.spacing(4),
  },
}));

function DownloadButton(props: { downloadHref: string }): JSX.Element {
  return (
    <PrimaryButton data-cy={'allocations-report'} href={props.downloadHref} data-testid={'download-button'}>
      Download
    </PrimaryButton>
  );
}

export function CustomerPaymentsAllocationModal(props: {
  allocations: CustomerPaymentAllocation[];
  payment: CustomerPayment;
  onClose: () => void;
}) {
  let classes = useStyles();
  let isMobile = useIsMobile();
  let { customerPaymentAllocationService } = useServices();
  let divisionUuid = useCurrentDivision();

  return (
    <Modal
      desktopWidth="wide"
      open={true}
      onClose={props.onClose}
      modalTitle={`Payment - ${props.payment.debtorName}`}
      variant="nopaddingonmobile"
    >
      <div className={classes.content}>
        <div className={classes.header} data-testid={'customer-payments-allocation-modal'}>
          <TypographySystem variant="h3" fontType="serif">
            {toCurrency(props.payment.receivedAmount)}
          </TypographySystem>
          <TypeBase spacingBottom={1}>
            on <span data-testid={'received-date'}>{toDate(props.payment.receivedDate)}</span> - Payment Reference{' '}
            <span data-testid={'reference-number'}>#{props.payment.referenceNumber}</span>
          </TypeBase>
          {isMobile === false && props.payment.isPending === false && (
            <DownloadButton
              downloadHref={customerPaymentAllocationService.downloadForReceiptUrl(divisionUuid, props.payment.uuid)}
            />
          )}
        </div>
        {props.payment.isPending ? (
          <div className={classes.pendingTextWrapper} data-testid={'payment-is-pending'}>
            <TypeBase>
              <em>
                We have not yet received remittance information for this payment. Once we do, we will update this
                display to show which invoices have been paid. Please contact your account manager for further
                clarification.
              </em>
            </TypeBase>
          </div>
        ) : (
          <>
            <Divider className={classes.divider} />
            <TableContainer>
              <TypeBase spacingBottom={1} isError data-testid={'denotes-unmatched'}>
                * Denotes item unmatched to an invoice
              </TypeBase>
              <TypeBase spacingBottom={1} isError>
                ** Denotes item allocated to a{' '}
                <span data-testid={'denotes-removed-or-charged-back'}>
                  {ProductType.Factoring ? 'charged back' : 'removed'}{' '}
                </span>
                invoice
              </TypeBase>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderCell align="left" className={clsx(!isMobile && classes.padLeft)}>
                      Item ID
                    </HeaderCell>
                    <HeaderCell>Original Due Date</HeaderCell>
                    <HeaderCell>Original Amount</HeaderCell>
                    <HeaderCell>Discount</HeaderCell>
                    <HeaderCell>Deductions</HeaderCell>
                    <HeaderCell className={classes.padRight}>Net Paid</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody data-cy={'payment-details-body'}>
                  {props.allocations.map((allocation) => {
                    let isIneligible = allocation.allocationType === 'INELIGIBLE';
                    let isRemovedOrChargedBack =
                      allocation.invoiceRemoveDate != null || allocation.invoiceChargedBackDate != null;
                    return (
                      <TableRow
                        data-cy={allocation.itemId}
                        key={allocation.itemId}
                        data-testid={`allocation-${allocation.itemId}`}
                      >
                        <BodyCell
                          data-cy={'item-id'}
                          data-testid={'item-id'}
                          className={clsx(!isMobile && classes.padLeft)}
                          align={'left'}
                        >
                          {allocation.itemId}
                          {isIneligible && !isRemovedOrChargedBack && (
                            <TypeBase data-cy={'is-unmatched'} component={'span'} isError>
                              *
                            </TypeBase>
                          )}
                          {isIneligible && isRemovedOrChargedBack && (
                            <TypeBase data-cy={'is-removed-or-charged-back'} component={'span'} isError>
                              **
                            </TypeBase>
                          )}
                        </BodyCell>
                        <DateCell data-cy={'due-date'} data-testid={'due-date'}>
                          {allocation.dueDate}
                        </DateCell>
                        <MoneyCell data-cy={'original-amount'} data-testid={'amount'} format={toTabularCurrency}>
                          {isIneligible ? allocation.grossPaid : allocation.invoiceGrossAmount}
                        </MoneyCell>
                        <MoneyCell data-cy={'discount-amount'} data-testid={'discount'} format={toTabularCurrency}>
                          {allocation.discountAmount}
                        </MoneyCell>
                        <MoneyCell data-cy={'deduction-amount'} data-testid={'deduction'} format={toTabularCurrency}>
                          {allocation.deductionAmount}
                        </MoneyCell>
                        <BodyCell
                          data-cy={'amount-paid'}
                          data-testid={'net-amount'}
                          className={classes.padRight}
                          align={'right'}
                        >
                          {toTabularCurrency(allocation.amountPaid)}
                          {allocation.isPartiallyPaid ? (
                            <span data-cy={'is-partially-paid'}>
                              &nbsp;
                              <InfoTooltip name="customerPaymentsModal.PartiallyPaid">Partially Paid</InfoTooltip>
                            </span>
                          ) : null}
                        </BodyCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
    </Modal>
  );
}
