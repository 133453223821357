import { BaseDataService } from '../BaseDataService/BaseDataService';
import { Query } from '../../schemas/http.schema';
import { RfTransactionHistory } from '../../schemas/transaction-history.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

export class RfTransactionHistoryService extends BaseDataService<RfTransactionHistory> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, (divisionUuid) => `/v2/tpf-api/divisions/${divisionUuid}/transaction-history`);
  }

  private downloadBasePath(divisionUuid: string) {
    return this.basePath(divisionUuid) + '/download';
  }

  download(divisionUuid: string, query: Query<RfTransactionHistory>) {
    this.httpService.downloadUrl(this.downloadBasePath(divisionUuid), query);
  }

  getDownloadUrl(divisionUuid: string, query: Query<RfTransactionHistory>) {
    return this.httpService.formatDownloadUrl(this.downloadBasePath(divisionUuid), query);
  }
}
