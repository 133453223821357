import React, { useEffect } from 'react';
import { Client, FactoringInvoice, ProductType, ReportDisplayField } from '../../schemas';
import { DateCell, MoneyCell } from '../../components/Table/TableCell';
import { DigitalData } from '../../../lib';
import { InvoiceFilters, useInvoicePageFilters } from '../../components/InvoicePage/InvoiceFilters';
import { InvoiceGrid } from '../../components/InvoicePage/InvoiceGrid';
import { InvoicePageHeader } from '../../components/InvoicePage/Header';
import { InvoiceSupplierStatus } from '../../schemas/invoice.schema';
import { RfInvoice } from '../../schemas/invoice.schema';
import { SortableColumn } from '../../components/Table/Sorting';
import { TableLoadingSkeletonCell } from '../../components/Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../../components/Table/TableLoadingSkeletonRow';
import { useAsync, useAsyncFn } from 'react-use';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

interface RfInvoicePageProps {
  client: Client;
  isReadOnlyUser: boolean;
}

export const RfInvoicePage: React.FC<RfInvoicePageProps> = (props) => {
  DigitalData.Hooks.usePageTitle('RF - Invoices');
  DigitalData.Hooks.useSection('product', 'invoices');

  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const { invoiceService, reportService } = useServices();
  let filters = useInvoicePageFilters('rf-invoice-page', ProductType.ReceivablesFinance);
  let invoicesAsync = useAsyncQuery(
    () => invoiceService.getRfInvoices(divisionUuid, filters.params),
    [divisionUuid, filters.params],
  );

  let [reportsAsync, refetchReports] = useAsyncFn(
    () =>
      reportService.getInvoiceReports(divisionUuid, {
        entityName: 'invoice',
      }),
    [reportService, divisionUuid],
  );

  let reportColumns = useAsync<ReportDisplayField<FactoringInvoice>[] | null>(
    () => reportService.getInvoiceMetadata(productType),
    [reportService, productType],
  );

  useEffect(() => {
    refetchReports();
  }, [refetchReports]);

  const rfStatusOptions = [
    InvoiceSupplierStatus.OPEN,
    InvoiceSupplierStatus.PAID,
    InvoiceSupplierStatus.PARTIALLY_PAID,
    InvoiceSupplierStatus.PENDING,
    InvoiceSupplierStatus.REJECTED,
    InvoiceSupplierStatus.REMOVED,
  ];

  return (
    <>
      <InvoicePageHeader
        client={props.client}
        reports={reportsAsync.value}
        onReportsRefetch={async () => refetchReports()}
        reportColumns={reportColumns.value}
        statusOptions={rfStatusOptions}
        currentQueryParams={filters.params}
        onInvoiceUploadComplete={invoicesAsync.refetch}
        isReadOnlyUser={props.isReadOnlyUser}
      />
      <InvoiceFilters statusOptions={rfStatusOptions} filters={filters} productType={productType} />

      <InvoiceGrid<RfInvoice>
        sorting={filters.sorting}
        pagination={filters.pagination}
        invoices={invoicesAsync}
        tableSkeleton={
          <TableLoadingSkeletonRow rows={25}>
            <TableLoadingSkeletonCell width={4} align={'center'} transparent={true} />
            <TableLoadingSkeletonCell width={10} />
            <TableLoadingSkeletonCell width={10} align={'right'} />
            <TableLoadingSkeletonCell width={8} align={'right'} />
            <TableLoadingSkeletonCell width={8} align={'right'} />
            <TableLoadingSkeletonCell width={8} align={'right'} />
            <TableLoadingSkeletonCell width={8} align={'right'} />
            {filters.statuses.items.includes(InvoiceSupplierStatus.REMOVED) && <TableLoadingSkeletonCell width={8} />}
          </TableLoadingSkeletonRow>
        }
        columns={
          <>
            <SortableColumn sorting={filters.sorting} sortField={'transactionDate'}>
              Invoice Date
            </SortableColumn>
            <SortableColumn sorting={filters.sorting} sortField={'projectedDueDate'}>
              Due Date
            </SortableColumn>
            <SortableColumn sorting={filters.sorting} sortField={'toBeRemovedOn'}>
              Eligible Invoice Until
            </SortableColumn>
            <SortableColumn sorting={filters.sorting} sortField={'amount'}>
              Gross Amount
            </SortableColumn>
            {filters.statuses.items.includes(InvoiceSupplierStatus.REMOVED) && (
              <SortableColumn sorting={filters.sorting} sortField={'removedDate'}>
                Removed Date
              </SortableColumn>
            )}
          </>
        }
        row={(invoice) => (
          <>
            <DateCell data-testid={'transaction-date'} data-cy={`invoiceRow-${invoice.invoiceId}-transactionDate`}>
              {invoice.transactionDate}
            </DateCell>
            <DateCell data-testid={'due-date'} data-cy={`invoiceRow-${invoice.invoiceId}-dueDate`}>
              {invoice.projectedDueDate}
            </DateCell>
            <DateCell data-testid={'to-be-removed-on'} data-cy={`invoiceRow-${invoice.invoiceId}-toBeRemovedOn`}>
              {invoice.toBeRemovedOn}
            </DateCell>
            <MoneyCell data-testid={'amount'} data-cy={`invoiceRow-${invoice.invoiceId}-amount`}>
              {invoice.amount}
            </MoneyCell>
            {filters.statuses.items.includes(InvoiceSupplierStatus.REMOVED) && (
              <DateCell data-testid={'removed-date'} data-cy={`invoiceRow-${invoice.invoiceId}-removedDate`}>
                {invoice.removedDate}
              </DateCell>
            )}
          </>
        )}
      />
    </>
  );
};
