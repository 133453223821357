import React, { ReactNode } from 'react';
import {
  BanIcon,
  DownloadIcon,
  Grid,
  Hidden,
  SecondaryButton,
  Theme,
  TypeBase,
  TypeSubsectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { SummaryCard } from './SummaryCard';
import { SummaryCardAccountOverviewGroup } from './SummaryCardAccountOverview';
import { SummaryCardHeader } from './SummaryCardHeader';

const useStyles = makeStyles<Theme>((_) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
}));

export const SummaryCardClosedAccount: React.FC<{
  accountOverviewStats: ReactNode;
  onDownloadHistory: () => void;
}> = (props) => {
  const classes = useStyles();
  return (
    <SummaryCard>
      <SummaryCardHeader isInverse={false} padding={4}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TypeSubsectionHeader data-cy={'closed-account-header'} spacingBottom={2}>
              <div className={classes.header}>
                <BanIcon fontSize={'inherit'} /> Your Account is Currently Closed
              </div>
            </TypeSubsectionHeader>
            <TypeBase spacingBottom={8}>
              All closed accounts are read-only. You may view and download your history, but new uploads and requests
              are disabled.
            </TypeBase>
            <Hidden smDown>
              <SecondaryButton
                onClick={props.onDownloadHistory}
                style={{ fontSize: '1.2rem', fontWeight: 600 }}
                data-cy={'closed-account-history-download'}
                startIcon={<DownloadIcon />}
              >
                Download History
              </SecondaryButton>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummaryCardAccountOverviewGroup>{props.accountOverviewStats}</SummaryCardAccountOverviewGroup>
          </Grid>
        </Grid>
      </SummaryCardHeader>
    </SummaryCard>
  );
};
