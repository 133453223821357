import React from 'react';
import { CTAButton, Theme, makeStyles } from '@c2fo/react-components';
import { Client } from '../../schemas';
import { FileUpload } from './FileUpload';
import { PageHeader, PageHeaderDivider } from '../PageWrapper/PageHeader';
import { PageTitle } from '../PageWrapper/PageTitle';
import { useBooleanQueryParam } from '../QueryParams/useQueryParam';
import { useIsMobile } from '../../hooks/useIsMobile';

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        marginTop: theme.spacing(1),
      },
    },
  },
  spacer: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  fileUpload: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
}));

type HeaderProps = {
  client: Client;
  isReadOnlyUser: boolean;
  onFileUploadComplete: () => void;
};

export function FileManagementPageHeader(props: HeaderProps): JSX.Element {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [showUpload, setShowUpload] = useBooleanQueryParam('showUpload');
  const shouldShowUploadCard = showUpload && !props.client.isClosed;
  const {
    client,
    client: { isClosed },
    isReadOnlyUser,
  } = props;

  return (
    <>
      <PageHeader>
        <div className={classes.pageHeader} data-testid={'file-management-page'}>
          <PageTitle>File Management</PageTitle>
          <CTAButton
            id={'uploadFileBtn'}
            className={classes.spacer}
            size={'medium'}
            disabled={showUpload === true || isClosed || isReadOnlyUser}
            onClick={() => {
              setShowUpload(true);
            }}
          >
            Upload File
          </CTAButton>
        </div>
        {shouldShowUploadCard && !isMobile && (
          <div className={classes.fileUpload}>
            <FileUpload
              client={client}
              onClose={async () => {
                setShowUpload(false);
              }}
              onComplete={async () => {
                setShowUpload(false);
                if (props.onFileUploadComplete) {
                  return props.onFileUploadComplete();
                }
              }}
            />
          </div>
        )}
      </PageHeader>
      <PageHeaderDivider spacing={4} />
    </>
  );
}
