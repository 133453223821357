import React, { ReactNode } from 'react';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { Paper, TypeBase, TypeCallout, createStyles, makeStyles, useTheme } from '@c2fo/react-components';
import { SummaryCardCurrency } from './SummaryCardCurrency';
import { useId } from '@reach/auto-id';
import { useIsMobile } from '../../hooks/useIsMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    overflow: 'hidden',
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: 15,
      margin: 0,
    },
    '& > *': {
      margin: theme.spacing(0),
      width: '100%',
    },
  },
}));

interface SummaryCardProps {
  children: ReactNode;
}

export const SummaryCard: React.FC<SummaryCardProps> = (props) => {
  const classes = useStyles({});
  const isMobile = useIsMobile();
  return (
    <Paper className={classes.root} elevation={isMobile ? 0 : 10} {...props}>
      {props.children}
    </Paper>
  );
};

const useLargeAmountStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      margin: 0,
      fontWeight: theme.typography.fontWeightBold as 'lighter',
    },
    amount: {
      fontSize: '2rem',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
        paddingBottom: '1rem',
      },
    },
    amountRebrand: {
      fontFamily:
        '"Libre Baskerville", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSize: '2rem',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '4rem',
        paddingBottom: '1rem',
      },
    },
  }),
);

const useSmallAmountStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      margin: 0,
      fontWeight: theme.typography.fontWeightLight as 'lighter',
    },
    amount: {
      fontSize: '1rem',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
    },
  }),
);

export function SummaryCardLargeAmount(props: {
  isInverse?: boolean;
  description: ReactNode;
  value: number;
  'data-cy'?: string;
}): JSX.Element {
  const id = useId();
  const classes = useLargeAmountStyles();
  const theme = useTheme();

  return (
    <>
      <TypeBase customColor={theme.palette.secondary.main} isEmphasis id={id}>
        {props.description}
      </TypeBase>
      <TypeBase component={'div'} isInverse={props.isInverse}>
        <p className={classes.amountRebrand} aria-labelledby={id} data-cy={props['data-cy']}>
          <SummaryCardCurrency>{props.value}</SummaryCardCurrency>
        </p>
      </TypeBase>
    </>
  );
}

export function SummaryCardSmallAmount(props: {
  transferMethod?: string;
  isInverse?: boolean;
  description: ReactNode;
  value: number;
  'data-cy'?: string;
}): JSX.Element {
  const id = useId();
  const classes = useSmallAmountStyles();
  const theme = useTheme();

  return (
    <>
      <TypeBase customColor={theme.palette.secondary.main} isEmphasis id={id}>
        {props.description}
        &nbsp;
        <InfoTooltip placement="bottom" name="summaryCard.SmallAmount">
          {`Funds will be sent via ${props.transferMethod} as soon as possible`}
        </InfoTooltip>
      </TypeBase>
      <TypeCallout isInverse={props.isInverse} align={'leading'} component={'div'}>
        <p className={classes.amount} aria-labelledby={id} data-testid={props['data-cy']} data-cy={props['data-cy']}>
          <SummaryCardCurrency>{props.value}</SummaryCardCurrency>
        </p>
      </TypeCallout>
    </>
  );
}
