import React from 'react';
import { AlertBanner, Container, TypeBase } from '@c2fo/react-components';
import { Client } from '../../schemas';
import { useIsMobile } from '../../hooks/useIsMobile';

export function ClosedAccountBanner(_: Record<string, unknown>): JSX.Element {
  const isMobile = useIsMobile();
  return (
    <Container disableGutters={isMobile} data-testid={'closed-account-banner'}>
      <AlertBanner severity="warning" variant="standard">
        <TypeBase>
          Your account is currently inactive and in read-only mode. You may view and download your history, but new
          uploads and requests are disabled.
        </TypeBase>
      </AlertBanner>
    </Container>
  );
}

export function ConditionalClosedAccountBanner(props: { client: Client }): JSX.Element | null {
  if (props.client.isClosed) {
    return <ClosedAccountBanner />;
  }
  return null;
}
