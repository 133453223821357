import { Paper, styled } from '@c2fo/react-components';

export const FloatingCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const FloatingCardHeader = styled('header')(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottomStyle: 'inset',
  borderBottomWidth: 'thin',
}));

export const FloatingCardBody = styled('article')(({ theme }) => ({
  padding: theme.spacing(2),
}));
