import { MonthEndReport, Query } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const monthEndReportBasePath = (divisionUuid: string) =>
  `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/rf-client-month-end-reports-download`;
const monthEndStatementDownloadPath = (divisionUuid: string) => `${monthEndReportBasePath(divisionUuid)}/statement`;

export class MonthEndRFReportService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  downloadStatement(divisionUuid: string, query: Query<MonthEndReport>): string {
    return this.httpService.downloadUrl(monthEndStatementDownloadPath(divisionUuid), query);
  }
}
