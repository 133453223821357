import { HttpService } from '../../../lib';
import { analyticsApiConfig } from '../../../config/analyticsApiConfig/analyticsApiConfig';

const apiUrl = analyticsApiConfig.ANALYTICS_API_BASE_URL;

export class AnalyticsService {
  constructor(private httpService: HttpService) {}

  async track(eventName: string, trackedUserUUID: string, properties?: Record<string, any>): Promise<void> {
    try {
      const response = await this.httpService.post(`${apiUrl}/track`, {
        data: {
          eventName,
          authServiceUuid: trackedUserUUID,
          appId: 'capfin-app',
          properties: properties || {},
        },
      });

      if (response.status !== 201) {
        // eslint-disable-next-line no-console
        console.error('Failed to send tracking event: %s', eventName);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to send tracking event: %s', eventName);
    }
  }
}
