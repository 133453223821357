import React, { useContext, useEffect } from 'react';
import joyrideSteps from '../../joyrides/HomepageV2Rf';
import { Alert, Grid, Hidden } from '@c2fo/react-components';
import { Client, ProductType } from '../../schemas';
import { DigitalData } from '../../../lib';
import { HomepageInvoiceUploadCard } from '../../components/HomepageInvoiceUploadCard/HomepageInvoiceUploadCard';
import { HomepageInvoiceUploadCardSkeleton } from '../../components/HomepageInvoiceUploadCard/HomepageInvoiceUploadCardSkeleton';
import { PageTitle } from '../../components/PageWrapper/PageTitle';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { QueryParamEnabledJoyride } from '../../joyrides/ShepherdJoyride';
import { RFHomeActionTypes } from './reducers';
import { RFHomeContext } from './RFHomeContext';
import { RFHomeDispatcherService } from './service';
import { RFSummaryCard } from '../../components/RFSummaryCard/RFSummaryCard';
import { SplitTestBanner } from '../../components/SplitTestBanner/SplitTestBanner';
import { SummaryCardSkeleton } from '../../components/SummaryCard/SummaryCardSkeleton';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';

interface RfHomePageProps {
  isReadOnlyUser: boolean;
}

export const RfHomePage: React.FC<RfHomePageProps> = (props) => {
  const { state, dispatch } = useContext(RFHomeContext);
  DigitalData.Hooks.usePageTitle('RF - Home');
  DigitalData.Hooks.useSection('product', 'home');

  const divisionUuid = useCurrentDivision();
  const { clientService, clientBalanceService, advanceService, invoiceUploadService } = useServices();

  const rfHomeService = new RFHomeDispatcherService(
    clientService,
    clientBalanceService,
    advanceService,
    invoiceUploadService,
    dispatch,
  );

  useEffect(() => {
    (async () => {
      await rfHomeService.fetchState(divisionUuid);
    })();
  }, []);

  let summaryCard = <SummaryCardSkeleton />;
  let uploadCard = <HomepageInvoiceUploadCardSkeleton />;
  const isClosedAccount = state.client?.isClosed ?? false;
  const summaryCardWidth: 9 | 12 = isClosedAccount ? 12 : 9;
  const isMobile = useIsMobile();

  if (!state.isLoading) {
    summaryCard = <RFSummaryCard isReadOnlyUser={props.isReadOnlyUser} />;
    uploadCard = (
      <HomepageInvoiceUploadCard
        productType={ProductType.ReceivablesFinance}
        lastUpload={state.upload || null}
        draftInvoiceTotal={null}
        isReadOnlyUser={props.isReadOnlyUser}
      />
    );
  }

  let gridStyle = isMobile ? undefined : { paddingTop: '2rem' };
  if (isMobile) {
    return (
      <Grid container style={{ minHeight: 300 }}>
        <Grid item xs={12}>
          {summaryCard}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <SplitTestBanner />
      <Alert
        data-testid={'rf-home-error-banner'}
        open={state.message.isOpen}
        variant={state.message.severity}
        onClose={() =>
          dispatch({
            type: RFHomeActionTypes.CloseNotification,
            payload: null,
          })
        }
      >
        {state.message.message}
      </Alert>
      {state.client && state.balance && <QueryParamEnabledJoyride steps={joyrideSteps} />}
      <PageWrapper>
        <PageTitle data-testid={'rf-home-page-title'}>
          {state.client.isClosed ? 'Account Details' : 'Request Funds'}
        </PageTitle>
        <Grid container spacing={3} style={gridStyle}>
          <Grid item sm={summaryCardWidth}>
            {summaryCard}
          </Grid>
          {!isClosedAccount && (
            <Hidden smDown>
              <Grid item sm={3}>
                {uploadCard}
              </Grid>
            </Hidden>
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};
