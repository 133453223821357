import 'spin.js/spin.css';
import React, { useLayoutEffect, useRef } from 'react';
import { Spinner, SpinnerOptions } from 'spin.js';

export function LoadingSpinner(props: { options?: SpinnerOptions }) {
  let { options } = props;
  let spinnerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    let opts = {
      lines: 13, // The number of lines to draw
      length: 38, // The length of each line
      width: 17, // The line thickness
      radius: 45, // The radius of the inner circle
      scale: 0.5, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      color: '#ffffff', // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      speed: 0.5, // Rounds per second
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-default', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      className: 'spinner', // The CSS class to assign to the spinner
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      shadow: '0 0 1px transparent', // Box-shadow for the lines
      position: 'absolute', // Element positioning
      ...options,
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let spinner = new Spinner(opts).spin(spinnerRef.current!);
    return () => {
      spinner.stop();
    };
  }, [options]);

  return <div data-testid={'loading-spinner'} ref={spinnerRef} />;
}
