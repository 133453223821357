import React, { ReactNode } from 'react';
import qs from 'qs';
import { DigitalData } from '../../../lib';
import { Redirect, useLocation } from 'react-router-dom';
import { useServices } from '../../services';

/**
 * StoreToken
 * simple path-parsing component to extract `token` from queryParams if it exists, store it as the auth
 * token and redirect to a path without the token.
 */
export function StoreToken(props: { children: ReactNode }) {
  const { tokenService } = useServices();
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true, strictNullHandling: true });
  const token = tokenService.getTokenContent();

  React.useEffect(() => {
    let email = (token && token.payload.user.emailAddress) || '';
    let userType = ['@c2fo.com', '@wfcfund.com'].some((suffix) => email.includes(suffix)) ? 'internal' : 'client';
    DigitalData.setUserType(userType);
  }, [token]);

  if (params && params.token && typeof params.token === 'string') {
    tokenService.setToken(params.token);
    delete params.token;
    const searchSansToken = qs.stringify(params, { strictNullHandling: true });
    return <Redirect to={{ pathname: location.pathname, search: searchSansToken }} />;
  }
  /**
   * Typescript is making me wrap this in a fragment - it doesn't like directly returning
   * children (even though that's a valid pattern)
   */
  return <>{props.children}</>;
}
