export enum ProductType {
  ReceivablesFinance = 'RECEIVABLES_FINANCE',
  Factoring = 'FACTORING',
}

export enum RequestStatus {
  Pending = 'PENDING',
  LimitReached = 'LIMIT_REACHED',
  OverAdvanced = 'OVER_ADVANCED',
  ClosedAccount = 'IS_CLOSED',
  Canceled = 'CANCELED',
}

export enum MonthEndReportType {
  Invoices = 'INVOICES',
  Fees = 'FEES',
  Summary = 'SUMMARY',
  Statement = 'STATEMENT',
}
