import decode from 'jwt-decode';
import qs from 'qs';
import { legacyC2foUiConfig } from '../../config/legacyC2foUiConfig/legacyC2foUiConfig';

import * as Token from './Token.schema';

const EARLY_PAY_TOKEN_PARAM = 'authToken';
const CAPFIN_APPS_TOKEN_PARAM = 'token';
const EMPTY_TOKEN = '';

type TokenListener = (token: string) => void;

export class TokenService {
  private listeners: TokenListener[];

  constructor(private tokenKey: string) {
    this.tokenKey = tokenKey;
    this.listeners = [];
  }

  getToken(): string {
    try {
      const jwt = localStorage.getItem(this.tokenKey) || EMPTY_TOKEN;
      return jwt;
    } catch (error) {
      return EMPTY_TOKEN;
    }
  }

  isTokenSet(): boolean {
    return this.getToken() !== EMPTY_TOKEN;
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    /**
     * Let our subscribers know that we've updated
     * the token.
     *
     * Note that in practice, the only subscriber of this
     * is AuthTimeoutModal.
     *
     * Something super-duper important here: we must
     * notify after we've actually persisted the change
     * to localStorage. SERIOUSLY THIS IS SO IMPORTANT.
     * If a listener is notified of a change, and
     * it calls `getToken` and gets the old value, we're
     * gonna have a Bad Time.
     */
    this.listeners.forEach((listener) => listener(token));
  }

  private clearToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  getTokenContent(): Token.Content | null {
    try {
      return decode(this.getToken());
    } catch (error) {
      return null;
    }
  }

  isNoAuthMode(): boolean {
    return process.env.NODE_ENV === 'development' && process.env.REACT_APP_NOAUTH_MODE === '1';
  }

  timeUntilTokenExpiresMs(): number {
    let token = this.getTokenContent();
    if (!token) {
      return -1;
    }
    return Math.max(token.exp * 1000 - Date.now(), 0);
  }

  isTokenExpired(): boolean {
    if (this.isNoAuthMode()) {
      return false;
    }
    const token = this.getTokenContent();

    if (token && token.exp) {
      return token.exp < Date.now() / 1000;
    }

    return true;
  }

  /**
   * Explicit logout action.
   * 1. Clear auth token from local storage
   * 2. Redirect to app.c2fo to clear any tokens for that app as well.
   */
  logout(): void {
    this.clearToken();
    window.location.replace(`${legacyC2foUiConfig.LEGACY_C2FO_UI_BASE_URL}/logout`);
  }

  /**
   * Attempt a re-login.
   * 1. Delete the invalid token
   * 2. redirect to app.c2fo login with a redirect url specifying previous url and a token identifier.
   */
  relogin(): void {
    this.clearToken();
    const encodedRedirect = encodeURIComponent(window.location.href);
    window.location.replace(
      `${legacyC2foUiConfig.LEGACY_C2FO_UI_BASE_URL}/login?redirect-to=${encodedRedirect}&tokenQueryParam=token`,
    );
  }

  /**
   * For an expected url back to the early-pay app, format and sign it with a fresh auth token, in case
   * the user's existing token on that domain has expired.
   * @param url
   */
  signUrlForEarlyPay(url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    const params = qs.parse(anchor.search, { ignoreQueryPrefix: true, strictNullHandling: true });
    if (params[EARLY_PAY_TOKEN_PARAM]) delete params[EARLY_PAY_TOKEN_PARAM];
    if (this.isTokenSet()) {
      params.authToken = this.getToken();
    }
    anchor.search = qs.stringify(params, { strictNullHandling: true });
    return anchor.href;
  }

  signUrlForEmberApp(url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    const params = qs.parse(anchor.search, { ignoreQueryPrefix: true, strictNullHandling: true });
    if (params[CAPFIN_APPS_TOKEN_PARAM]) delete params[CAPFIN_APPS_TOKEN_PARAM];
    const token = this.getToken();
    if (this.isTokenSet()) {
      params.token = token;
    }
    anchor.search = qs.stringify(params, { strictNullHandling: true });
    return anchor.href;
  }

  /**
   * Be notified when the token changes.
   *
   * returns a cancellation callback
   */
  subscribeToTokenChanges(callback: TokenListener): () => void {
    this.listeners.push(callback);
    return () => {
      this.listeners = this.listeners.filter((listener) => listener !== callback);
    };
  }
}
