import React from 'react';
import clsx from 'clsx';
import {
  ColorManipulator,
  TableCell,
  TableCellProps,
  Theme,
  TypeBase,
  createStyles,
  makeStyles,
} from '@c2fo/react-components';
import { InvoiceSupplierStatus } from '../../schemas';

type Props = { status: InvoiceSupplierStatus };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: theme.typography.fontWeightMedium as 'lighter',
      fontSize: '.9rem',
      width: '100%',
      display: 'inline-block',
      borderRadius: '1rem',
      textTransform: 'capitalize',
      padding: '0 0.4em',
      color: (props: Props) => {
        switch (props.status) {
          case 'PAID':
            return ColorManipulator.darken(theme.palette.green[500] ?? theme.palette.common.black, 0.6);
          case 'PARTIALLY_PAID':
            return ColorManipulator.darken(theme.palette.lightGreen[900] ?? theme.palette.common.black, 0.6);
          case 'PENDING':
          case 'DRAFT':
          case 'STAGED':
            return theme.palette.grey[800];
          case 'OPEN':
          case 'PURCHASED':
            return ColorManipulator.darken(theme.palette.lightBlue[500] ?? theme.palette.common.black, 0.6);
          case 'APPROVED':
            return ColorManipulator.darken(theme.palette.purple[500] ?? theme.palette.common.black, 0.6);
          case 'REJECTED':
            return ColorManipulator.darken(theme.palette.red[500] ?? theme.palette.common.black, 0.6);
          case 'CHARGED_BACK':
          case 'WRITTEN_DOWN':
            return ColorManipulator.darken(theme.palette.warning.dark ?? theme.palette.common.black, 0.6);
          case 'REMOVED':
            return theme.palette.deepGrey[500];
          default:
            return '#FFFFFF';
        }
      },
      border: (props: Props) => {
        switch (props.status) {
          case 'PAID':
            return `solid 1px ${theme.palette.green[500]}`;
          case 'PARTIALLY_PAID':
            return `solid 1px ${theme.palette.lightGreen[500]}`;
          case 'PENDING':
          case 'DRAFT':
          case 'STAGED':
            return `solid 1px ${theme.palette.grey[500]}`;
          case 'OPEN':
          case 'PURCHASED':
            return `solid 1px ${theme.palette.lightBlue[500]}`;
          case 'APPROVED':
            return `solid 1px ${theme.palette.purple[500]}`;
          case 'REJECTED':
            return `solid 1px ${theme.palette.red[500]}`;
          case 'CHARGED_BACK':
          case 'WRITTEN_DOWN':
            return `solid 1px ${theme.palette.yellow[800]}`;
          case 'REMOVED':
            return `solid 1px ${theme.palette.deepGrey[500]}`;
          default:
            return 'solid 1px #FFFFFF';
        }
      },
      backgroundColor: (props: Props) => {
        switch (props.status) {
          case 'PAID':
            return theme.palette.green[50];
          case 'PARTIALLY_PAID':
            return theme.palette.lightGreen[50];
          case 'PENDING':
          case 'DRAFT':
          case 'STAGED':
            return '#2c324f0a';
          case 'OPEN':
          case 'PURCHASED':
            return theme.palette.lightBlue[50];
          case 'APPROVED':
            return theme.palette.purple[50];
          case 'REJECTED':
            return theme.palette.red[50];
          case 'CHARGED_BACK':
          case 'WRITTEN_DOWN':
            return theme.palette.yellow[50];
          case 'REMOVED':
            return theme.palette.deepGrey[50];
          default:
            return '#777777';
        }
      },
    },
  }),
);

export function InvoiceStatusPill(props: Props): JSX.Element {
  const classes = useStyles(props);
  return <span className={classes.root}>{props.status.toLowerCase().replace('_', ' ')}</span>;
}

const useCellStyles = makeStyles({
  root: {
    /**
     * Set a constant width so that when the user goes
     * between different pages of the grid, we
     * show a constant width, making the transition less
     * "noisy"
     *
     * If you change this width, please verify in the storybook
     * that everything looks good for each status
     *
     * We use both minWidth and maxWidth here because the browser
     * seems to respect that more than just width. For some reason,
     * the browser feels that it can increase the width if need be. This
     * ensures that doesn't happen
     */
    minWidth: 160,
    maxWidth: 160,
  },
});

export function InvoiceStatusPillCell(props: Props & TableCellProps): JSX.Element {
  const { status, ...bodyCellProps } = props;
  const classes = useCellStyles();
  return (
    <TableCell {...bodyCellProps} align={'center'} className={clsx(classes.root, bodyCellProps.className)}>
      <TypeBase component={'span'} align={'center'}>
        <InvoiceStatusPill status={status} />
      </TypeBase>
    </TableCell>
  );
}
