import { BaseDataService } from '../BaseDataService/BaseDataService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { UserNewFeature } from '../../schemas/user-new-features.schema';

const basePath = () => `/v2/tpf-api/users-new-ui-features`;

export class UserNewFeaturesService extends BaseDataService<UserNewFeature> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, basePath);
  }

  async markFeatureAsSeen(featureUuid: string) {
    await this.httpService.put(basePath() + '/' + featureUuid, {
      data: {
        newUiFeaturesUuid: featureUuid,
        isSeen: true,
      },
    });
  }
}
