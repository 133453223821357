import React, { ReactNode, useReducer } from 'react';
import { CloseIcon, IconButton, TableSortLabel, styled } from '@c2fo/react-components';
import { HeaderCell, HeaderCellProps } from './TableCell';

type Direction = 'asc' | 'desc';

function otherDirection(dir: Direction): Direction {
  if (dir === 'asc') {
    return 'desc';
  }
  return 'asc';
}

export interface Sorting {
  defaultColumn: string;
  direction: Direction;
  field: string;
  apiSort: string;
  toggleSort: (field: string) => void;
  resetSort: () => void;
}

type State = {
  field: string;
  direction: Direction;
};

type Action =
  | {
      type: 'TOGGLE_FIELD';
      field: string;
    }
  | {
      type: 'RESET';
    };

const DEFAULT_SORT_DIR: Direction = 'asc';

function loadFromLocalStorage(key: string): { field: string | null; direction: Direction | null } {
  let field = localStorage.getItem(`capfin-app:${key}:field`);
  let direction = localStorage.getItem(`capfin-app:${key}:direction`);
  return { field, direction: direction as Direction };
}

function getInitialState({
  initialField,
  localStorageKey,
  initialDir,
}: {
  initialField: string;
  localStorageKey: string;
  initialDir: Direction;
}): State {
  let stored = loadFromLocalStorage(localStorageKey);
  return {
    field: stored.field || initialField,
    direction: stored.direction ?? initialDir,
  };
}

export function useSorting(
  initialField: string,
  localStorageKey: string,
  initialDir: Direction = DEFAULT_SORT_DIR,
): Sorting {
  let [state, dispatch] = useReducer(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (state: State, action: Action): State => {
      let nextState = state;
      switch (action.type) {
        case 'TOGGLE_FIELD': {
          if (state.field !== action.field) {
            nextState = {
              field: action.field,
              direction: initialDir,
            };
          } else {
            nextState = {
              field: state.field,
              direction: otherDirection(state.direction),
            };
          }
          break;
        }
        case 'RESET': {
          nextState = {
            field: initialField,
            direction: initialDir,
          };
          break;
        }
      }
      localStorage.setItem(`capfin-app:${localStorageKey}:field`, nextState.field);
      localStorage.setItem(`capfin-app:${localStorageKey}:direction`, nextState.direction);
      return nextState;
    },
    { initialField, localStorageKey, initialDir },
    getInitialState,
  );
  return {
    defaultColumn: initialField,
    direction: state.direction,
    field: state.field,
    apiSort: `${state.field},${state.direction}`,
    toggleSort: (field) => dispatch({ type: 'TOGGLE_FIELD', field }),
    resetSort: () => dispatch({ type: 'RESET' }),
  };
}

const SortClear = styled(CloseIcon)({
  height: '0.5em',
  width: '0.5em',
});

export function SortableColumn(
  props: { sorting: Sorting; sortField: string; endIcon?: ReactNode; children: ReactNode } & HeaderCellProps,
): JSX.Element {
  let { sorting, sortField, endIcon, children, ...rest } = props;
  let isActive = sorting.field === sortField;

  return (
    <HeaderCell {...rest} sortDirection={isActive ? sorting.direction : false}>
      <TableSortLabel
        active={isActive}
        direction={sorting.direction}
        data-cy={`gridSort-${sortField}-Toggle`}
        onClick={() => props.sorting.toggleSort(props.sortField)}
        hideSortIcon
        data-testid={'toggle-sort'}
      >
        {isActive && sortField !== sorting.defaultColumn && (
          <IconButton
            data-cy={`gridSort-${sortField}-Cancel`}
            data-testid={'clear-sort'}
            color={'inherit'}
            size={'small'}
            onClick={(event) => {
              event.stopPropagation();
              sorting.resetSort();
            }}
          >
            <SortClear />
          </IconButton>
        )}
        {isActive && <span data-cy={`gridSort-${sortField}-${sorting.direction.toUpperCase()}`} />}
        {props.children}
      </TableSortLabel>
      {props.endIcon}
      {/* So... the element we would try to find to assert sort order visually is the arrow, which is abstracted inside
      c2fo-components' TableSortLabel.  Instead of adding data-cy attributes to it, we're adding a
      hidden span here for test purposes. Sorry. */}
      {isActive && <span hidden data-cy={`gridSort-${sortField}-${sorting.direction.toUpperCase()}`}></span>}
    </HeaderCell>
  );
}
