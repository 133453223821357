import React, { useState } from 'react';
import clsx from 'clsx';
import { AccountBalance, DiscountFeesThisMonth, TotalObligation } from './FactoringAccountOverview';
import { ArpBalance, UnsettledBalanceReport } from '../../schemas';
import { AsyncButton } from '../Async/AsyncButton';
import { CustomerPayment } from '../../schemas/customerPayment.schema';
import { CustomerPaymentsAllocationModal } from '../CustomerPayments/CustomerPaymentsAllocationModal';
import {
  DownloadIcon,
  Grid,
  Theme,
  TypeHelper,
  TypographySystem,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@c2fo/react-components';
import { IconButton } from '@c2fo/react-components';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { SummaryCard, SummaryCardLargeAmount } from '../SummaryCard/SummaryCard';
import { SummaryCardAccountOverviewGroup } from '../SummaryCard/SummaryCardAccountOverview';
import { SummaryCardChevron } from '../SummaryCard/SummaryCardChevron';
import { SummaryCardHeader, SummaryCardHeaderSection } from '../SummaryCard/SummaryCardHeader';
import { toCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';
import { useAsyncFn } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useServices } from '../../services';

interface StylesProps {
  hasOpenInvoices: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    footer: {
      '&.open': {
        top: '-1rem',
      },
      transition: '1s ease all',
      background: '#FFF',
      maxWidth: '95%',
      margin: '0 auto',
      padding: '1rem',
      borderRadius: '5px',
      zIndex: 1,
      top: '-10.8rem',
      position: 'relative',
      boxShadow: theme.shadows[1],
      [theme.breakpoints.up('md')]: {
        boxShadow: 'none',
        maxWidth: '100%',
        top: 0,
        padding: '1rem 2rem',
        borderRadius: 0,
      },
    },

    footerViewButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
    },
    footerViewButtonWrapper: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
    footerViewButton: {
      width: '100%',
      fontSize: theme.typography.fontSize * 1.3,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    latestCustomerPaymentText: {
      fontSize: '1.5rem',
      fontWeight: theme.typography.fontWeightLight as 'lighter',
    },
    downloadUnsettledReportButton: {
      padding: '0.75rem 0.45rem',
    },
    downloadInverse: {
      color: 'inherit',
    },
    downloadAnchor: {
      color: 'inherit',
      textDecoration: 'none',
    },
  }),
);

interface ARPSummaryRequestCardProps {
  balance: ArpBalance;
  lastCustomerPayment: CustomerPayment | null;
  unsettledReport: UnsettledBalanceReport | null;
  testReports?: string;
}

export const ARPSummaryRequestCard: React.FC<ARPSummaryRequestCardProps> = (props) => {
  const hasOpenInvoices = props.balance.factoringPurchasedAmount > 0;
  const classes = useStyles({
    hasOpenInvoices,
  });
  const [openCard, setOpenCard] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  function toggleCard() {
    setOpenCard(!openCard);
  }
  const { customerPaymentAllocationService } = useServices();
  const divisionUuid = useCurrentDivision();

  const [paymentsDetails, fetchPaymentsDetails] = useAsyncFn(
    async () =>
      customerPaymentAllocationService.query(divisionUuid, {
        // can't get in here without the view button showing up, which has this
        // as a guard
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        remittanceUuid: props.lastCustomerPayment!.uuid,
        allocationType: {
          in: ['ELIGIBLE', 'INELIGIBLE'],
        },
      }),
    [customerPaymentAllocationService, divisionUuid, props.lastCustomerPayment?.uuid],
  );
  const [isShowingPaymentsDetails, setIsShowingPaymentsDetails] = useState<boolean>(false);

  return (
    <>
      <SummaryCard data-testid="ARPSummaryRequestCard">
        <SummaryCardHeader isInverse={hasOpenInvoices}>
          <Grid>
            <SummaryCardHeaderSection>
              <SummaryCardLargeAmount
                isInverse={hasOpenInvoices}
                description={
                  <>
                    All Open Invoices &nbsp;
                    <InfoTooltip isInverse={false} placement="bottom" name="ARPSummaryRequestCard.AllOpenInvoices">
                      The total of all of your open invoices purchased to date
                    </InfoTooltip>
                  </>
                }
                data-cy={'all-open-invoices'}
                value={props.balance.factoringPurchasedAmount}
              />
              <SummaryCardChevron onClick={toggleCard} isOpen={openCard} data-testid="summary-card-chevron" />
            </SummaryCardHeaderSection>
            {props.unsettledReport && props.unsettledReport.s3Path ? (
              <SummaryCardHeaderSection>
                <Grid>
                  <a
                    href={props.unsettledReport.s3Path}
                    data-cy={'download-unsettled-report-url'}
                    data-testid={'download-unsettled-report-grid'}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={classes.downloadAnchor}
                  >
                    <TypographySystem
                      isEmphasis={true}
                      component="span"
                      customColor={hasOpenInvoices ? 'white' : undefined}
                    >
                      Download Account Activity Report&nbsp;
                    </TypographySystem>
                    <IconButton
                      className={clsx(
                        classes.downloadUnsettledReportButton,
                        hasOpenInvoices ? classes.downloadInverse : '',
                      )}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </a>
                </Grid>
                <Grid>
                  <TypographySystem
                    data-cy={'report-last-updated'}
                    data-testid={'report-last-updated-grid'}
                    isEmphasis={true}
                    component="span"
                    customColor={hasOpenInvoices ? 'white' : undefined}
                  >
                    Report last updated: {toDate(props.unsettledReport?.updated, 'MMM dd yyyy')} at &nbsp;
                    {toDate(props.unsettledReport?.updated, 'hh:mm aa')}
                  </TypographySystem>
                </Grid>
                <SummaryCardChevron onClick={toggleCard} isOpen={openCard} data-testid="summary-card-chevron" />
              </SummaryCardHeaderSection>
            ) : props.unsettledReport?.s3Path === '' && props.testReports ? (
              <SummaryCardHeaderSection>
                <Grid>
                  <a
                    href={props.testReports}
                    data-cy={'download-unsettled-report-url'}
                    data-testid={'download-unsettled-report-grid'}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    className={classes.downloadAnchor}
                  >
                    <TypographySystem
                      isEmphasis={true}
                      component="span"
                      customColor={hasOpenInvoices ? 'white' : undefined}
                    >
                      Download Account Activity Report&nbsp;
                    </TypographySystem>
                    <IconButton
                      className={clsx(
                        classes.downloadUnsettledReportButton,
                        hasOpenInvoices ? classes.downloadInverse : '',
                      )}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </a>
                </Grid>
                <Grid>
                  <TypographySystem
                    data-cy={'report-last-updated'}
                    data-testid={'report-last-updated-grid'}
                    isEmphasis={true}
                    component="span"
                    customColor={hasOpenInvoices ? 'white' : undefined}
                  >
                    Report last updated: {toDate(props.unsettledReport?.updated, 'MMM dd yyyy')} at &nbsp;
                    {toDate(props.unsettledReport?.updated, 'hh:mm aa')}
                  </TypographySystem>
                </Grid>
                <SummaryCardChevron onClick={toggleCard} isOpen={openCard} data-testid="summary-card-chevron" />
              </SummaryCardHeaderSection>
            ) : (
              ''
            )}
          </Grid>
          <SummaryCardAccountOverviewGroup>
            <AccountBalance isInverse={hasOpenInvoices} balance={props.balance} />
            <TotalObligation isInverse={hasOpenInvoices} balance={props.balance} />
            <DiscountFeesThisMonth isInverse={hasOpenInvoices} balance={props.balance} />
          </SummaryCardAccountOverviewGroup>
        </SummaryCardHeader>

        <Grid
          className={`${classes.footer} ${openCard === false ? '' : 'open'}`}
          container
          wrap={'nowrap'}
          justifyContent={'space-between'}
          direction={isSmallScreen ? 'column' : 'row'}
          spacing-xs={0}
          spacing-md={3}
          spacing-lg={3}
          data-joyride-step={'latest-customer-payment'}
          data-testid="summary-card-overview"
        >
          <Grid item container direction={'column'}>
            <Grid item>
              <TypeHelper>Latest Customer Payment</TypeHelper>
            </Grid>
            {props.lastCustomerPayment ? (
              <>
                <Grid item>
                  <span
                    className={classes.latestCustomerPaymentText}
                    data-testid={'customer-payment-header'}
                    data-cy={'latest-customer-payment'}
                  >
                    {props.lastCustomerPayment.debtorName} - {toCurrency(props.lastCustomerPayment.receivedAmount)}
                  </span>
                </Grid>
                <Grid item>
                  <TypeHelper data-testid={'customer-payment-date'}>
                    Paid: {toDate(props.lastCustomerPayment.receivedDate, 'LLLL dd yyyy')}
                  </TypeHelper>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <span
                    className={classes.latestCustomerPaymentText}
                    data-testid={'customer-payment-header'}
                    data-cy={'latest-customer-payment'}
                  >
                    N/A
                  </span>
                </Grid>
                <Grid item>
                  <TypeHelper data-testid={'customer-payment-date'}>
                    We have not received remittance from your customers yet.
                  </TypeHelper>
                </Grid>
              </>
            )}
          </Grid>
          {props.lastCustomerPayment && (
            <Grid item className={classes.footerViewButtonContainer}>
              <AsyncButton
                variant={'outlined'}
                color={'secondary'}
                className={classes.footerViewButton}
                wrapperClassName={classes.footerViewButtonWrapper}
                action={async () => {
                  await fetchPaymentsDetails();
                  setIsShowingPaymentsDetails(true);
                }}
              >
                View
              </AsyncButton>
            </Grid>
          )}
        </Grid>
      </SummaryCard>
      {props.lastCustomerPayment && paymentsDetails.value && isShowingPaymentsDetails && (
        <CustomerPaymentsAllocationModal
          allocations={paymentsDetails.value.data}
          payment={props.lastCustomerPayment}
          onClose={() => setIsShowingPaymentsDetails(false)}
        />
      )}
    </>
  );
};
