import React, { useState } from 'react';
import { Client } from '../../schemas';
import { FileUploadInterstitial } from './FileUploadInterstitial';
import { UploadCard } from './UploadCard';
import { useAsyncQuery } from '../Async/useAsyncQuery';
import { useServices } from '../../services';

type State =
  /**
   * The initial state. We are displaying the card, but the user
   * has not selected a file yet.
   */
  | {
      state: 'START';
    }
  /**
   * The user has selected a file, and we are processing it in the background.
   *
   * This is the state where we display the loading spinner and the intersitial messages
   */
  | {
      state: 'VERIFY';
    }
  /**
   * This is the state where we display an error which has occurred while uploading a file.
   */
  | {
      state: 'SHOW_ERRORS';
      errors: string;
    }
  /**
   * This is the state which denotes file has been uploaded successfully.
   */
  | {
      state: 'UPLOAD_COMPLETE';
    };

export function FileUpload(props: { onComplete: () => void; onClose: () => void; client: Client }) {
  let [state, setState] = useState<State>({
    state: 'START',
  });

  const { fileManagementService } = useServices();
  const getAllLabels = useAsyncQuery(() => fileManagementService.getAllLabels(), []);
  const [labelName, setLabelName] = useState('Select Label');
  const selectLabel = function (label: string) {
    setLabelName(label);
  } as any;
  const handleFileSelect = async (file: File) => {
    setState({
      state: 'VERIFY',
    });
    let categoryEnum = '';
    let labelUuid = '';
    getAllLabels.response?.forEach((labelObj) => {
      if (labelObj.labelName === labelName) {
        categoryEnum = labelObj.categoryEnum;
        labelUuid = labelObj.uuid;
      }
    });
    if (labelName && labelName !== 'Select Label') {
      let response = await fileManagementService.uploadFinancialFile(
        props.client.uuid,
        file,
        file.name,
        categoryEnum,
        labelUuid,
      );
      if (!response.status.toString().startsWith('2')) {
        setState({
          state: 'SHOW_ERRORS',
          errors: response.data.message,
        });
      } else {
        setState({
          state: 'UPLOAD_COMPLETE',
        });
        props.onComplete();
      }
    } else {
      setState({
        state: 'SHOW_ERRORS',
        errors: 'Please select label',
      });
    }
  };

  return (
    <>
      {state.state === 'START' && (
        <UploadCard
          onFileSelect={handleFileSelect}
          onClose={props.onClose}
          allLabels={getAllLabels}
          onLabelSelect={selectLabel}
          labelName={labelName}
        />
      )}
      {state.state === 'VERIFY' && <FileUploadInterstitial />}
      {state.state === 'SHOW_ERRORS' && (
        <UploadCard
          onFileSelect={handleFileSelect}
          errors={state.errors}
          onClose={props.onClose}
          allLabels={getAllLabels}
          onLabelSelect={selectLabel}
          labelName={labelName}
        />
      )}
    </>
  );
}
