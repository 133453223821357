import React, { useState } from 'react';
import {
  AngleDownIcon,
  DownloadIcon,
  IconButton,
  Menu,
  MenuItem,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TypeBase,
  makeStyles,
} from '@c2fo/react-components';
import { BodyCell, DateCell, HeaderCell, MoneyCell } from '../../components/Table/TableCell';
import { DateChips } from '../../components/Filters/FilterChips';
import { DatePicker } from '../../components/Filters/DatePicker';
import { DatePreset, LazyDateRange, lazyDateRangeAdapter, useDateFilter } from '../../components/Filters/useDateFilter';
import { DigitalData } from '../../../lib';
import { FilterLabel } from '../../components/Filters/FilterLabel';
import { PageHeader, PageHeaderDivider } from '../../components/PageWrapper/PageHeader';
import { PageTitle } from '../../components/PageWrapper/PageTitle';
import { PaginationControls, usePagination } from '../../components/Table/Pagination';
import { SelectAll, SelectBodyCell, useTableSelect } from '../../components/Table/Selectable';
import { SlideoutFilterAndChips, SlideoutFilterGroup } from '../../components/Filters/Slideout';
import { SortableColumn, useSorting } from '../../components/Table/Sorting';
import { TableLoadingSkeletonCell } from '../../components/Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../../components/Table/TableLoadingSkeletonRow';
import { composeAdapters } from '../../components/Filters/Filter';
import { toTabularCurrency } from '../../i18n/currency';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';
import { useTablePageDateAdapter } from '../../components/Filters/useDateFilter';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    marginBottom: theme.spacing(6),
  },
  buttonAligner: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    textTransform: 'capitalize',
    maxWidth: 150,
  },
  buttonWithSelected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

const INITIAL_FACTORING_TX_DATE_RANGE: LazyDateRange = {
  preset: DatePreset.Last30Days,
  start: null,
  end: null,
};

export const FactoringTransactionHistoryPage: React.FC = () => {
  DigitalData.Hooks.usePageTitle('Factoring - Transaction History');
  DigitalData.Hooks.useSection('product', 'transaction history');

  const classes = useStyles();
  const isMobile = useIsMobile();
  const pagination = usePagination('factoring-txn-history-pagination');
  const transactionDate = useDateFilter(
    composeAdapters(
      useTablePageDateAdapter(pagination, 'factoring-txn-history-txn-date'),
      lazyDateRangeAdapter(INITIAL_FACTORING_TX_DATE_RANGE),
    ),
  );

  const divisionUuid = useCurrentDivision();
  const { factoringTransactionHistoryService } = useServices();
  const sorting = useSorting('transactionDate', 'factoring-txn-history-sorting');

  const historyEntriesAsync = useAsyncQuery(
    async () =>
      factoringTransactionHistoryService.query(divisionUuid, {
        ...pagination.params,
        transactionDate: transactionDate.param,
        sort: [sorting.apiSort],
      }),
    [pagination.params, transactionDate.param, sorting.apiSort],
  );
  const select = useTableSelect(historyEntriesAsync.response?.data ?? []);

  const downloadLabel =
    select.selectedKeys.length > 0 ? `Download Selected (${select.selectedKeys.length})` : 'Download';
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PageHeader>
        <div className={classes.pageHeader}>
          <div>
            <PageTitle>Transaction History</PageTitle>
          </div>
          <div className={classes.buttonAligner}>
            {isMobile ? null : historyEntriesAsync.isLoading ? (
              <Skeleton variant={'rect'} width={100} height={'2rem'} />
            ) : (
              <>
                <SecondaryButton
                  onClick={handleClick}
                  size={'medium'}
                  endIcon={<AngleDownIcon data-testid={'buttondropdown-down-chevron'} />}
                  startIcon={<DownloadIcon />}
                  data-testid={'download-history'}
                  disabled={historyEntriesAsync.response?.data.length === 0}
                >
                  {downloadLabel}
                </SecondaryButton>
                <Menu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  data-cy="downloadTransactionsButton"
                >
                  <MenuItem
                    onClick={() => {
                      factoringTransactionHistoryService.downloadSummary(divisionUuid, {
                        transactionDate: transactionDate.param,
                        uuid: select.param,
                      });
                    }}
                  >
                    <TypeBase>
                      Summary Report
                      <a
                        hidden
                        data-cy="downloadUrlSummary"
                        href={factoringTransactionHistoryService.getDownloadSummaryUrl(divisionUuid, {
                          transactionDate: transactionDate.param,
                          uuid: select.param,
                        })}
                      />
                    </TypeBase>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      factoringTransactionHistoryService.downloadDetail(divisionUuid, {
                        transactionDate: transactionDate.param,
                        clientSettlementUuid: select.param,
                      });
                    }}
                  >
                    <TypeBase>
                      Detail Report
                      <a
                        hidden
                        data-cy="downloadUrlDetail"
                        href={factoringTransactionHistoryService.getDownloadDetailUrl(divisionUuid, {
                          transactionDate: transactionDate.param,
                          uuid: select.param,
                        })}
                      />
                    </TypeBase>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
      </PageHeader>
      <PageHeaderDivider />
      <SlideoutFilterAndChips
        filters={[transactionDate]}
        chips={
          <DateChips fieldName={'transactionDate'} filter={transactionDate}>
            Transaction Date
          </DateChips>
        }
        slideoutTitle={'Adjust Filters'}
        slideoutFilters={
          <SlideoutFilterGroup>
            <FilterLabel>Date</FilterLabel>
            <DatePicker filter={transactionDate} testFilterName="date" />
          </SlideoutFilterGroup>
        }
      />
      <TableContainer className={classes.tableContainer}>
        <Table data-cy="transactionHistoryTable">
          <TableHead data-cy="transactionGridHeader">
            <TableRow>
              {!isMobile && <SelectAll select={select} />}

              <SortableColumn sorting={sorting} sortField={'transactionDate'}>
                Date
              </SortableColumn>
              <SortableColumn sorting={sorting} sortField={'totalPayment'}>
                Total Payment
              </SortableColumn>
              <SortableColumn sorting={sorting} sortField={'totalNewPurchase'}>
                Purchase Price
              </SortableColumn>
              <SortableColumn sorting={sorting} sortField={'totalRebatesAndUnderpayments'}>
                Rebates
              </SortableColumn>
              <SortableColumn sorting={sorting} sortField={'totalPassthrough'}>
                Passthrough
              </SortableColumn>
              <SortableColumn sorting={sorting} sortField={'totalFee'}>
                Fees/Credits
              </SortableColumn>
              {!isMobile && <HeaderCell align={'center'}>Details</HeaderCell>}
            </TableRow>
          </TableHead>
          <TableBody data-cy="transactionGridBody">
            {historyEntriesAsync.isLoading ? (
              <TableLoadingSkeletonRow rows={25}>
                {!isMobile && <TableLoadingSkeletonCell width={2} align={'center'} transparent={true} />}
                <TableLoadingSkeletonCell width={8} align={'center'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                {!isMobile && <TableLoadingSkeletonCell width={4} align={'center'} transparent={true} />}
              </TableLoadingSkeletonRow>
            ) : historyEntriesAsync.response.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={'100%' as any /* 100% is a valid value, but mui doesn't think so  */}>
                  <TypeBase align={'center'} data-testid={'no-entries-message'}>
                    No Activity in This Period
                  </TypeBase>
                </TableCell>
              </TableRow>
            ) : (
              historyEntriesAsync.response.data.map((entry) => (
                <TableRow
                  data-cy={`row-TransactionId-${entry.uuid}`}
                  key={entry.uuid}
                  data-testid={`row-${entry.uuid}`}
                >
                  {!isMobile && <SelectBodyCell select={select} item={entry} />}

                  <DateCell data-testid={'transaction-date'}>{entry.transactionDate}</DateCell>
                  <MoneyCell data-testid={'total-payment'} format={toTabularCurrency}>
                    {entry.totalPayment ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'total-new-purchase'} format={toTabularCurrency}>
                    {entry.totalNewPurchase ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'total-rebates-and-underpayments'} format={toTabularCurrency}>
                    {entry.totalRebatesAndUnderpayments ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'total-passthrough'} format={toTabularCurrency}>
                    {entry.totalPassthrough ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'total-fee'} format={toTabularCurrency}>
                    {entry.totalFee ?? 0}
                  </MoneyCell>
                  {!isMobile && (
                    <BodyCell align={'center'}>
                      <IconButton
                        aria-label={'Download Detail'}
                        color={'secondary'}
                        onClick={() => {
                          factoringTransactionHistoryService.downloadDetail(divisionUuid, {
                            clientSettlementUuid: entry.uuid,
                          });
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </BodyCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {historyEntriesAsync.response && (
          <PaginationControls meta={historyEntriesAsync.response.meta} pagination={pagination} />
        )}
      </TableContainer>
    </>
  );
};
