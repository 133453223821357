import { Step } from 'react-joyride';

const steps: Step[] = [
  {
    title: 'Latest customer remittance',
    /**
     * This disableBeacon means that we will not
     * show the beacon when the tour starts,
     * instead we will immediately start it.
     *
     * Not needed on subsequent tours because we have
     * continuous in the Joyride component
     */
    disableBeacon: true,
    target: '[data-joyride-step="latest-customer-payment"]',
    placement: 'bottom',
    content: 'View the latest payments received from your customers. Click view for a detailed breakdown',
  },
  {
    title: 'Total discount fee',
    target: '[data-joyride-step="total-discount-fee"]',
    placement: 'bottom',
    content: 'Track total discount fees incurred for invoices purchased this month',
  },
  {
    title: 'Invoice Box',
    target: '[data-joyride-step="invoice-card"]',
    placement: 'left',
    content: 'View the date invoices were last uploaded to quickly identify new invoices to upload',
  },
];

export default steps;
