import { MonthEndReport, Query } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const monthEndReportBasePath = (divisionUuid: string) =>
  `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/client-month-end-reports-download`;
const monthEndInvoicesReportDownloadPath = (divisionUuid: string) => `${monthEndReportBasePath(divisionUuid)}/invoices`;
const monthEndFeesReportDownloadPath = (divisionUuid: string) => `${monthEndReportBasePath(divisionUuid)}/fees`;
const monthEndSummaryReportDownloadPath = (divisionUuid: string) => `${monthEndReportBasePath(divisionUuid)}/summary`;
const monthEndStatementDownloadPath = (divisionUuid: string) => `${monthEndReportBasePath(divisionUuid)}/statement`;

export class MonthEndFactoringReportService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  downloadFeesReport(divisionUuid: string, query: Query<MonthEndReport>): string {
    return this.httpService.downloadUrl(monthEndFeesReportDownloadPath(divisionUuid), query);
  }

  downloadInvoicesReport(divisionUuid: string, query: Query<MonthEndReport>): string {
    return this.httpService.downloadUrl(monthEndInvoicesReportDownloadPath(divisionUuid), query);
  }

  downloadSummaryReport(divisionUuid: string, query: Query<MonthEndReport>): string {
    return this.httpService.downloadUrl(monthEndSummaryReportDownloadPath(divisionUuid), query);
  }

  downloadStatement(divisionUuid: string, query: Query<MonthEndReport>): string {
    return this.httpService.downloadUrl(monthEndStatementDownloadPath(divisionUuid), query);
  }
}
