import React from 'react';
import { CloseIcon, SecondaryButton, TypeBase, useTheme } from '@c2fo/react-components';

export function ClearButton(props: { onClick: () => void }) {
  let theme = useTheme();

  return (
    <SecondaryButton
      onClick={props.onClick}
      variant={'text'}
      data-cy={'clear'}
      startIcon={<CloseIcon color={'secondary'} />}
    >
      <TypeBase
        customColor={theme.palette.secondary.main}
        style={{ fontSize: '1.2rem', fontWeight: 600 }}
        component={'span'}
      >
        {' '}
        Clear
      </TypeBase>
    </SecondaryButton>
  );
}
