import React, { useMemo } from 'react';
import { DateChips, MultiselectChips } from '../Filters/FilterChips';
import { DateFilter, useDateFilter } from '../Filters/useDateFilter';
import { DatePicker } from '../Filters/DatePicker';
import { DebtorSearch, InvoiceIdSearch } from '../Filters/TypeaheadSearch';
import { FilterLabel } from '../Filters/FilterLabel';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { Invoice, InvoiceSupplierStatus } from '../../schemas/invoice.schema';
import { InvoiceSupplierStatusLabel } from './InvoiceSupplierStatusLabel';
import { MultiselectFilter, useMultiselectFilter } from '../Filters/useMultiselectFilter';
import { Pagination, usePagination } from '../Table/Pagination';
import { ProductType } from '../../schemas';
import { Query } from '../../schemas/http.schema';
import { SlideoutFilterAndChips, SlideoutFilterGroup } from '../Filters/Slideout';
import { Sorting, useSorting } from '../Table/Sorting';
import { SupplierStatusFilter } from '../Filters/ValueFilter';
import { useTablePageDateAdapter } from '../Filters/useDateFilter';
import { useTablePageMultiselectAdapter } from '../Filters/useMultiselectFilter';

export interface InvoicePageFilters {
  sorting: Sorting;
  pagination: Pagination;
  statuses: MultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>;
  invoiceIds: MultiselectFilter<string>;
  debtors: MultiselectFilter<string>;
  transactionDate: DateFilter;
  dueDate: DateFilter;

  params: Query<Invoice>;
}

export function useInvoicePageFilters(storageKey: string, productType: ProductType): InvoicePageFilters {
  const sorting = useSorting('updated', storageKey, 'desc');

  const pagination = usePagination(storageKey);

  const statuses = useMultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>(
    (status) => status,
    useTablePageMultiselectAdapter(pagination, `${storageKey}:statuses`),
  );
  const invoiceIds = useMultiselectFilter<string>(
    (invoice) => invoice,
    useTablePageMultiselectAdapter(pagination, `${storageKey}:invoice-ids`),
  );
  const debtors = useMultiselectFilter<string>(
    (debtor) => debtor,
    useTablePageMultiselectAdapter(pagination, `${storageKey}:debtors`),
  );

  const transactionDate = useDateFilter(useTablePageDateAdapter(pagination, `${storageKey}:transaction-date`));
  const dueDateKey = productType === ProductType.ReceivablesFinance ? 'due-date' : 'expected-due-date';
  const dueDate = useDateFilter(useTablePageDateAdapter(pagination, `${storageKey}:${dueDateKey}`));

  const params = useMemo(
    () => ({
      ...pagination.params,
      sort: sorting.field === 'updated' ? [sorting.apiSort, 'dueDate,desc'] : [sorting.apiSort],
      supplierDisplayStatus: statuses.param,
      invoiceId: invoiceIds.param,
      debtorName: debtors.param,
      ...(productType === ProductType.ReceivablesFinance && { projectedDueDate: dueDate.param }),
      ...(productType === ProductType.Factoring && { expectedDueDate: dueDate.param }),
      transactionDate: transactionDate.param,
    }),
    [
      statuses.param,
      invoiceIds.param,
      debtors.param,
      dueDate.param,
      transactionDate.param,
      pagination.params,
      sorting.apiSort,
      productType,
      sorting.field,
    ],
  );
  return {
    sorting,
    pagination,
    statuses,
    invoiceIds,
    debtors,
    transactionDate,
    dueDate,
    params,
  };
}

export function InvoiceFilters(props: {
  statusOptions: InvoiceSupplierStatus[];
  filters: InvoicePageFilters;
  productType: ProductType;
}): JSX.Element {
  const { filters, statusOptions, productType } = props;

  return (
    <SlideoutFilterAndChips
      filters={[filters.statuses, filters.invoiceIds, filters.debtors, filters.dueDate, filters.transactionDate]}
      chips={
        <>
          <MultiselectChips filter={filters.debtors}>Customer Name</MultiselectChips>
          <MultiselectChips filter={filters.invoiceIds}>Invoice ID</MultiselectChips>
          <MultiselectChips
            filter={filters.statuses}
            render={(status) => <InvoiceSupplierStatusLabel status={status} />}
          >
            Status
          </MultiselectChips>
          <DateChips
            filter={filters.dueDate}
            fieldName={productType === ProductType.ReceivablesFinance ? 'projectedDueDate' : 'dueDate'}
          >
            Due Date
          </DateChips>
          <DateChips filter={filters.transactionDate} fieldName={'transactionDate'}>
            Invoice Date
          </DateChips>
        </>
      }
      slideoutTitle={'Invoice Filters'}
      slideoutFilters={
        <>
          <SlideoutFilterGroup>
            <FilterLabel>Invoice ID</FilterLabel>
            <InvoiceIdSearch filter={filters.invoiceIds} />
          </SlideoutFilterGroup>
          <SlideoutFilterGroup>
            <FilterLabel>Status</FilterLabel>
            <SupplierStatusFilter filter={filters.statuses} options={statusOptions} />
          </SlideoutFilterGroup>
          <SlideoutFilterGroup>
            <FilterLabel>Customer Name</FilterLabel>
            <DebtorSearch filter={filters.debtors} />
          </SlideoutFilterGroup>
          <SlideoutFilterGroup>
            <FilterLabel>
              Due Date &nbsp;
              {productType !== ProductType.ReceivablesFinance && (
                <InfoTooltip placement="bottom" name="invoiceFilter.EstimatedRemittance">
                  This will filter result based on Estimated Remittance
                </InfoTooltip>
              )}
            </FilterLabel>
            <DatePicker
              isPastEvents={false}
              filter={filters.dueDate}
              testFilterName={productType === ProductType.ReceivablesFinance ? 'projectedDueDate' : 'dueDate'}
            />
          </SlideoutFilterGroup>
          <SlideoutFilterGroup>
            <FilterLabel>Invoice Date</FilterLabel>
            <DatePicker filter={filters.transactionDate} testFilterName={'transactionDate'} />
          </SlideoutFilterGroup>
        </>
      }
    />
  );
}
