import React, { ReactNode, useState } from 'react';
import { DigitalData } from '../../../lib';
import { Popover, PopoverOrigin, TypeBase, makeStyles } from '@c2fo/react-components';

const useInfoTooltipStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(1),
    backgroundColor: 'white',
    maxWidth: 300,
    overflowWrap: 'break-word',
  },
}));

export type MouseoverTooltipAsProps = {
  onMouseEnter: (event: React.MouseEvent) => void;
  onMouseLeave: (event: React.MouseEvent) => void;
  onTouchStart: (event: React.TouchEvent) => void;
};

const DEFAULT_MOUSEOVER_TOOLTIP_POPOVER_CY = 'default-mouseover-tooltip-popover-cy';

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export interface TooltipPosition {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const tooltipPositionBottomCenter: TooltipPosition = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

const POPOVER_STYLES: React.CSSProperties = { pointerEvents: 'none' };

export function MouseoverTooltipPopover(props: {
  children: ReactNode;
  anchorEl: Element | null;
  position?: TooltipPosition;
  name?: string;
  onClose?: () => void;
}) {
  let classes = useInfoTooltipStyles();

  // TODO : TPF-5336 : add jest test case for "name" value in props
  return (
    <Popover
      style={POPOVER_STYLES}
      open={props.anchorEl !== null}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={props.position?.anchorOrigin ?? DEFAULT_ANCHOR_ORIGIN}
      transformOrigin={props.position?.transformOrigin ?? DEFAULT_TRANSFORM_ORIGIN}
      disableRestoreFocus
    >
      <div
        className={classes.content}
        data-cy={props.name ?? DEFAULT_MOUSEOVER_TOOLTIP_POPOVER_CY}
        data-testid="mouseover-tooltip-popover"
      >
        <TypeBase customColor='"rgba(0, 0, 0, 0.7)"'>{props.children}</TypeBase>
      </div>
    </Popover>
  );
}

export function MouseoverTooltip(props: {
  children: ReactNode;
  content: ReactNode;
  as?: React.ElementType<MouseoverTooltipAsProps>;
  position?: TooltipPosition;
  name?: string;
}) {
  let [anchorEl, setAnchor] = useState<Element | null>(null);
  const handleClose = () => setAnchor(null);

  /**
   * Cannot fully type this, because typescript says that it produces a union type that
   * is too complex
   */
  let Component = (props.as as React.ElementType<any>) ?? 'span';
  return (
    <>
      <Component
        onMouseEnter={(event: React.MouseEvent) => {
          event.stopPropagation();
          setAnchor(event.currentTarget);
          if (props.name) {
            DigitalData.set(`capFinToolTip`, props.name);
          }
        }}
        onMouseLeave={handleClose}
        onTouchStart={(event: React.TouchEvent) => {
          event.stopPropagation();
          setAnchor(event.currentTarget);
        }}
      >
        {props.children}
      </Component>
      <MouseoverTooltipPopover anchorEl={anchorEl} onClose={handleClose} position={props.position} name={props.name}>
        {props.content}
      </MouseoverTooltipPopover>
    </>
  );
}
