import Lottie from 'react-lottie';
import React, { useEffect, useState } from 'react';
import heroImage from '../../../assets/comp_lady_shutterstock.jpg';
import runningClock from '../../../assets/liquidity/lotties/runningClock.json';
import stopwatch from '../../../assets/stopwatch.svg';
import {
  Box,
  CTAButton,
  Card,
  PrimaryButton,
  Skeleton,
  Theme,
  TypeBase,
  TypeSectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { DigitalData } from '../../../lib';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';
import { capfinOnboardingApiConfig } from '../../../config';
import { useAsync } from 'react-use';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';
import { v4 as uuidv4 } from 'uuid';

declare global {
  interface Window {
    lendflow: any;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#ffffff',
    padding: '0px',
    border: 'inherit',
    borderRadius: '15px',
    flexDirection: 'column',
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.25rem',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardFooter: {
    padding: '0.75rem 1.25rem',
    borderTopStyle: 'none',
    borderRadius: '0 0 0px 0px',
    borderTop: '3px solid rgba(0, 0, 0, 0.125)',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const HeroImage = () => (
  <div style={{ display: 'flex', flexGrow: 1 }}>
    <img src={heroImage} width="100%" alt="" />
  </div>
);

const ApplicationUnderReviewCard = (props: { earlyPayUrl: string }) => {
  const { earlyPayUrl } = props;
  const classes = useStyles();

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: runningClock,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <PageWrapper>
      <Card className={classes.card} elevation={10}>
        <div className={classes.cardContent}>
          <div>
            <Lottie options={animationOptions} height={150} />
          </div>
          <div style={{ paddingBottom: '0.75rem' }}>
            <TypeSectionHeader component="h2" data-testid="onboarding-header" data-cy="onboarding-header">
              <strong>Thanks for Applying</strong>
            </TypeSectionHeader>
          </div>
          <div>
            <TypeBase component="p" data-cy="onboarding-text">
              We'll be in touch within 48 hours. Once approved, you'll receive an email from us outlining next steps.
            </TypeBase>
          </div>
        </div>
        <div className={classes.cardFooter}>
          <div>
            <div>
              <CTAButton
                data-testid="onboarding-back-button"
                data-cy="onboarding-back-button"
                onClick={() => {
                  window.location.href = earlyPayUrl;
                }}
              >
                Back to Early Pay
              </CTAButton>
            </div>
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
};

const ScheduleAppointmentCard = (props: { isMobile: boolean }) => {
  const { isMobile } = props;
  const classes = useStyles();

  const calendlyUrl = 'https://calendly.com/c2fo-capital-finance/working-capital-discussion';

  const onSchedule = () => {
    window.open(calendlyUrl);
  };

  return (
    <PageWrapper>
      <Card className={classes.card} style={{ flexDirection: isMobile ? 'column' : 'row' }} elevation={10}>
        {isMobile && <HeroImage />}
        <div className={classes.cardContent} style={{ justifyContent: 'space-between', padding: '3rem' }}>
          <section>
            <TypeSectionHeader component="h1" data-testid="onboarding-header" data-cy="onboarding-header">
              <strong>Chat with an Advisor</strong>
            </TypeSectionHeader>
            <TypeBase component="div" data-cy="onboarding-text">
              <p>
                Our dedicated Working Capital Advisors partner with you to identify a solution that meets your company’s
                unique needs. Book an appointment using our online scheduler.
              </p>
            </TypeBase>
          </section>
          <section style={{ minWidth: '100%' }}>
            <CTAButton fullWidth={false} onClick={onSchedule}>
              Schedule Appointment
            </CTAButton>
          </section>
        </div>
        {!isMobile && <HeroImage />}
      </Card>
    </PageWrapper>
  );
};

export const OnboardingPage: React.FC = () => {
  DigitalData.Hooks.usePageTitle('Client Onboarding');
  DigitalData.Hooks.useSection('product', 'set up');

  const { redirectService, capfinOnboardingService } = useServices();
  const onboardingAsync = useAsync(async () => capfinOnboardingService.getApplication());
  const isMobile = useIsMobile();

  if (onboardingAsync.loading) {
    return (
      <PageWrapper>
        <Box>
          <Skeleton variant={'rect'} height={450} />
        </Box>
      </PageWrapper>
    );
  }

  const onboardingResponse = onboardingAsync.value;

  // eslint-disable-next-line camelcase
  if (onboardingResponse?.data.capfin_client_approved && onboardingResponse.data.capfin_app_link) {
    window.location.assign(onboardingResponse.data.capfin_app_link);
  }

  if (
    onboardingResponse?.data.los_application_id &&
    onboardingResponse.data.los_application_status === 'UNDER_REVIEW'
  ) {
    return <ApplicationUnderReviewCard earlyPayUrl={redirectService.getSignedEarlyPayLogoUrl()} />;
  }
  return <ScheduleAppointmentCard isMobile={isMobile} />;
};
