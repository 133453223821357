import React, { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PrimaryButton,
  SecondaryButton,
  TypeBase,
  TypeSectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { Subscription, useSubscription } from 'use-subscription';
import { useInterval } from 'react-use';
import { useServices } from '../../services';

/**
 * How long before the token expires we want to display the modal
 */
const TIMEOUT_MODAL_DISPLAY_SECONDS = 30;
const ONE_SECOND = 1000;
/**
 * How often we should check for the time until the token expires changing.
 */
const CHECK_TOKEN_INTERVAL_LENGTH = ONE_SECOND;
const TIMEOUT_MODAL_DISPLAY_MS = TIMEOUT_MODAL_DISPLAY_SECONDS * ONE_SECOND;

let useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.warning.main,
    display: 'flex',
    justifyContent: 'center',
  },
  timeLeft: {
    fontSize: theme.typography.fontSize * 3,
  },
  continueButton: {
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
}));

function useToken(): string {
  let { tokenService } = useServices();
  /**
   * useSubscription is an official react package, so it's
   * pretty bulletproof.
   *
   * It lets you synchronize react state with the
   * state of something that can be "subscribed to"
   *
   * So we have the tokenService let us subscribe
   * to token changes!
   *
   * In the future react will have something like
   * useMutableSource, which will be more compatible
   * with concurrent mode.
   */
  let subscription: Subscription<string> = useMemo(
    () => ({
      getCurrentValue: () => tokenService.getToken(),
      subscribe: (callback) => tokenService.subscribeToTokenChanges(callback),
    }),
    // should never change, but hey it's the right thing to do.
    [tokenService],
  );

  return useSubscription(subscription);
}

function useTimeUntilTokenExpiresMs() {
  let { tokenService } = useServices();
  let token = useToken();
  let [timeUntilTokenExpiresMs, setTimeUntilTokenExpiresMs] = useState(() => tokenService.timeUntilTokenExpiresMs());
  // both of these hooks are necessary: tests will fail if they don't exist.

  /**
   * Always check on every second.
   */
  useInterval(() => {
    setTimeUntilTokenExpiresMs(tokenService.timeUntilTokenExpiresMs());
  }, CHECK_TOKEN_INTERVAL_LENGTH);

  /**
   * But also always check when the token is reset
   */
  useEffect(() => {
    setTimeUntilTokenExpiresMs(tokenService.timeUntilTokenExpiresMs());
  }, [token, tokenService]);

  return timeUntilTokenExpiresMs;
}

/**
 * Manages the users token timeout.
 * Due to issues with the counter going down to zero
 * and then nothing happening, the actual
 * display logic and the auto-logout
 * happen in the same component now. They
 * have the same view of the world at all times.
 */
export function AuthTimeout() {
  let classes = useStyles();

  let { tokenService, tpfSupplierApiHttpService } = useServices();
  let timeUntilTokenExpiresMs = useTimeUntilTokenExpiresMs();

  /**
   * Honestly this logic belongs in the TokenService
   * but that would require the TokenService to know
   * how to refresh the users token, which requires firing
   * off an http request, which means the TokenService would need
   * the http service, but the http service already needs the token service,
   * leading to a nice, wonderful, circular dependency.
   *
   * That being said, useEffect _is_ kinda nice for this.
   */
  useEffect(() => {
    /**
     * If the token isn't expired, go ahead and return quickly.
     * No cleanup is needed here: we haven't
     * fired off any effects.
     */
    if (!tokenService.isTokenExpired()) {
      return;
    }
    /**
     * Let's us know whether
     * react has attempted to clean up this
     * effect, useful for when we fire off an asnyc
     * request (like token refresh)
     */
    let isCurrent = true;

    /**
     * token looks expired. Let's try refreshing it, just in case there's a weird
     * timezone issue or something
     */
    tpfSupplierApiHttpService.refreshToken().then(
      /**
       * For the successful case, we're probably
       * doing something weird with timezones, or the
       * users system clock is messed up for some reason.
       * If that's the case, and we successfully get a new
       * token, then the effect will be re-fired and a
       * new timeout will be set.
       *
       * We can provide null here, indicating that
       * nothing should happen as a response to the promise
       * successfully resolving (because a new token
       * will be created anyway).
       */
      null,

      () => {
        /**
         * The more interesting case: the token is actually, properly
         * expired, meaning we need to logout.
         *
         * Guard against the token being refreshed
         * while we did async stuff.
         */
        if (isCurrent) {
          tokenService.logout();
        }
      },
    );

    /**
     * The above fires off an async effect, so
     * we need to cancel it if we re-run this effect
     *
     * WOW what I wouldn't give for something like
     * `defer` in js
     *
     * Read here for info on how this works: basically,
     * it's all about race conditons
     * https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/#what-about-refactoring-class-based-code-to-hooks
     */
    return () => {
      isCurrent = false;
    };
  }, [tokenService, tpfSupplierApiHttpService, timeUntilTokenExpiresMs]);

  /**
   * Don't bother displaying the modal if there's no token set at all.  This isn't for that scenario.
   */
  let isDisplayingModal = tokenService.isTokenSet() && timeUntilTokenExpiresMs < TIMEOUT_MODAL_DISPLAY_MS;

  if (!isDisplayingModal || tokenService.isNoAuthMode()) {
    return null;
  }
  /**
   * Never display less than 0 seconds
   */
  let secondsLeft = Math.max(Math.floor(timeUntilTokenExpiresMs / ONE_SECOND), 0);

  return (
    <Dialog open={true} maxWidth={'xs'}>
      <DialogTitle className={classes.header}>
        <TypeSectionHeader component={'span'} align={'center'} isInverse>
          Session Timeout
        </TypeSectionHeader>
      </DialogTitle>
      <DialogContent dividers data-testid={'auth-timeout-modal'}>
        <TypeBase align={'center'}>Your online session will expire in</TypeBase>
        <TypeBase align={'center'}>
          <strong className={classes.timeLeft}>{secondsLeft} seconds</strong>
        </TypeBase>
        <TypeBase align={'center'}>
          Please click "Continue" to keep working; or click "Log Out" to end your session now
        </TypeBase>
      </DialogContent>
      <DialogActions>
        <SecondaryButton
          variant={'text'}
          onClick={() => {
            tokenService.logout();
          }}
        >
          Logout
        </SecondaryButton>
        <PrimaryButton
          className={classes.continueButton}
          onClick={() => {
            tpfSupplierApiHttpService.refreshToken();
          }}
        >
          Continue
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
