import React from 'react';
import { CustomerPaymentsGrid } from '../../components/CustomerPayments/CustomerPaymentsGrid';
import { DigitalData } from '../../../lib';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useCustomerPaymentsFilters } from '../../components/CustomerPayments/CustomerPaymentsFilters';
import { useServices } from '../../services';

export const FactoringCustomerPaymentsPage: React.FC = () => {
  DigitalData.Hooks.usePageTitle('Factoring - Customer Payments');
  DigitalData.Hooks.useSection('product', 'customer payments');

  let filters = useCustomerPaymentsFilters('arp-customer-payments-page');

  let { customerPaymentService } = useServices();
  let divisionUuid = useCurrentDivision();

  let customerPaymentsAsync = useAsyncQuery(
    () => customerPaymentService.query(divisionUuid, filters.params),
    [divisionUuid, filters.params],
  );

  return (
    <>
      <CustomerPaymentsGrid customerPayments={customerPaymentsAsync} filters={filters} />
    </>
  );
};
