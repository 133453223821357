import React, { useState } from 'react';
import {
  AngleDownIcon,
  DownloadIcon,
  IconButton,
  ListAltIcon,
  Menu,
  MenuItem,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TypeBase,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { BodyCell, DateCell, HeaderCell, MoneyCell } from '../Table/TableCell';
import { CustomerPayment, ProductType } from '../../schemas';
import { CustomerPaymentsAllocationModal } from './CustomerPaymentsAllocationModal';
import { CustomerPaymentsFilters } from './CustomerPaymentsFilters';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { LoadingState } from '../Async/useAsyncQuery';
import { PageHeader, PageHeaderDivider } from '../PageWrapper/PageHeader';
import { PageTitle } from '../PageWrapper/PageTitle';
import { PageableResponse } from '../../schemas';
import { PaginationControls } from '../Table/Pagination';
import { SelectAll, SelectBodyCell } from '../Table/Selectable';
import { SortableColumn } from '../Table/Sorting';
import { TableLoadingSkeletonCell } from '../Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../Table/TableLoadingSkeletonRow';
import { toTabularCurrency } from '../../i18n/currency';
import { useAsync } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';
import { useTableSelect } from '../Table/Selectable';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    marginBottom: theme.spacing(6),
  },
  buttonAligner: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    textTransform: 'capitalize',
    maxWidth: 150,
  },
}));

function Spacer(): JSX.Element {
  const theme = useTheme();
  return <div style={{ height: theme.spacing(2) }} />;
}

export function CustomerPaymentsGrid(props: {
  customerPayments: LoadingState<PageableResponse<CustomerPayment>>;
  filters: CustomerPaymentsFilters;
}): JSX.Element {
  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const isMobile = useIsMobile();

  const { customerPaymentService, customerPaymentAllocationService } = useServices();

  const [payment, setPayment] = useState<CustomerPayment | null>(null);

  const paymentsDetails = useAsync(async () => {
    if (payment) {
      return customerPaymentAllocationService.query(divisionUuid, {
        remittanceUuid: payment.uuid,
        allocationType: {
          in: ['ELIGIBLE', 'INELIGIBLE'],
        },
      });
    } else {
      return null;
    }
  }, [customerPaymentAllocationService, divisionUuid, payment]);

  const [isShowingPaymentsDetails, setIsShowingPaymentsDetails] = useState<boolean>(false);

  const selectedPayments = useTableSelect(props.customerPayments.response?.data ?? []);
  const downloadLabel =
    selectedPayments.selectedKeys.length > 0
      ? `Download Selected (${selectedPayments.selectedKeys.length})`
      : 'Download';
  const pageTitle = productType === ProductType.ReceivablesFinance ? 'Customer Payments' : 'Remittance';
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PageHeader>
        <div className={classes.pageHeader}>
          <PageTitle>{pageTitle}</PageTitle>
          <div className={classes.buttonAligner}>
            {isMobile ? null : props.customerPayments.isLoading ? (
              <Skeleton variant={'rect'} width={150} height={'2rem'} />
            ) : (
              <>
                <SecondaryButton
                  className={'download-menu-button'}
                  size={'medium'}
                  endIcon={<AngleDownIcon />}
                  startIcon={<DownloadIcon />}
                  disabled={props.customerPayments.response?.data.length === 0}
                  onClick={handleClick}
                >
                  {downloadLabel}
                </SecondaryButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <MenuItem
                    data-cy={'summary-report-button'}
                    onClick={() => {
                      customerPaymentService.downloadSummaryCustomerPaymentFromQuery(
                        divisionUuid,
                        props.filters.params,
                        selectedPayments.selectedKeys,
                      );
                    }}
                  >
                    <a
                      hidden
                      data-cy={'summary-download-url'}
                      href={customerPaymentService.getDownloadSummaryCustomerPaymentFromQueryUrl(
                        divisionUuid,
                        props.filters.params,
                        selectedPayments.selectedKeys,
                      )}
                    />
                    Summary Report
                  </MenuItem>
                  <MenuItem
                    data-cy={'detail-report-button'}
                    onClick={() => {
                      customerPaymentAllocationService.downloadDetailsCustomerPaymentFromQuery(
                        divisionUuid,
                        selectedPayments.selectedKeys,
                      );
                    }}
                  >
                    <a
                      hidden
                      data-cy={'detail-download-url'}
                      href={customerPaymentAllocationService.getDownloadDetailsCustomerPaymentFromQueryUrl(
                        divisionUuid,
                        selectedPayments.selectedKeys,
                      )}
                    />
                    Detail Report
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
      </PageHeader>
      <PageHeaderDivider />
      <CustomerPaymentsFilters filters={props.filters} />
      <Spacer />
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {!isMobile && <SelectAll select={selectedPayments}></SelectAll>}
              <SortableColumn sorting={props.filters.sorting} sortField={'debtorName'} align={'left'}>
                Customer
              </SortableColumn>
              <SortableColumn sorting={props.filters.sorting} sortField={'receivedDate'} align={'center'}>
                Paid On
              </SortableColumn>
              <SortableColumn sorting={props.filters.sorting} sortField={'receivedAmount'} align={'right'}>
                Total Amount
              </SortableColumn>
              <HeaderCell align={'right'}>Reference Number</HeaderCell>
              <HeaderCell align={'center'}>Details</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy={'customer-payments-body'}>
            {props.customerPayments.isLoading ? (
              <TableLoadingSkeletonRow rows={25}>
                <TableLoadingSkeletonCell width={2} align={'center'} transparent={true} />
                <TableLoadingSkeletonCell width={10} />
                <TableLoadingSkeletonCell width={8} align={'center'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={10} align={'right'} />
                <TableLoadingSkeletonCell width={4} align={'center'} transparent={true} />
              </TableLoadingSkeletonRow>
            ) : props.customerPayments.response.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={'100%' as any /* 100% is a valid value, but mui doesn't think so  */}>
                  <TypeBase data-testid={'noCustomerPaymentsMessage'} align={'center'}>
                    No customer payments with these filter options.
                  </TypeBase>
                </TableCell>
              </TableRow>
            ) : (
              props.customerPayments.response.data.map((customerPayment) => (
                <TableRow key={customerPayment.uuid} data-cy={`customer-payment-${customerPayment.uuid}`}>
                  {!isMobile && <SelectBodyCell select={selectedPayments} item={customerPayment}></SelectBodyCell>}

                  <BodyCell data-testid={'debtorName'} align={'left'} data-cy={'column-debtorName'}>
                    {customerPayment.debtorName}
                    {customerPayment.isPending && (
                      <span data-cy={'is-unallocated'}>
                        &nbsp;
                        <InfoTooltip name="customerPaymentGrid.Unallocated">
                          This payment has not been allocated to specific invoices as we have not yet received
                          remittance information. Please contact your account manager.
                        </InfoTooltip>
                      </span>
                    )}
                  </BodyCell>
                  <DateCell data-cy={'column-receivedDate'} data-testid={'receivedDate'} align={'center'}>
                    {customerPayment.receivedDate}
                  </DateCell>
                  <MoneyCell
                    data-cy={'column-receivedAmount'}
                    data-testid={'receivedAmount'}
                    format={toTabularCurrency}
                  >
                    {customerPayment.receivedAmount}
                  </MoneyCell>
                  <BodyCell
                    data-testid={'referenceNumber'}
                    data-cy={`customerPaymentRow-${customerPayment.uuid}-referenceNumber`}
                  >
                    {customerPayment.referenceNumber}
                  </BodyCell>
                  <BodyCell align={'center'}>
                    <IconButton
                      color="secondary"
                      data-testid={'detailsButton'}
                      data-cy={'show-payment-details'}
                      aria-label={'Details Button'}
                      onClick={async () => {
                        setPayment(customerPayment);
                        setIsShowingPaymentsDetails(true);
                      }}
                    >
                      <ListAltIcon color="secondary" fontSize="inherit" />
                    </IconButton>
                  </BodyCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {props.customerPayments.response && (
          <PaginationControls meta={props.customerPayments.response.meta} pagination={props.filters.pagination} />
        )}
      </TableContainer>
      {payment && paymentsDetails.value && isShowingPaymentsDetails && (
        <CustomerPaymentsAllocationModal
          allocations={paymentsDetails.value.data}
          payment={payment}
          onClose={() => setIsShowingPaymentsDetails(false)}
        />
      )}
    </>
  );
}
