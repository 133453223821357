import InputMask from 'inputmask';
import React, { useLayoutEffect, useRef } from 'react';
import { OutlinedInput, OutlinedInputProps } from '@c2fo/react-components';

/**
 * I knew going into this project that masked inputs were going to be annoying, and boy oh boy
 * was I correct.
 *
 * Lemme give you the lowdown: there are TONS of input masking libraries out there. react-number-mask. imask. inputmask.
 * The list goes on. NONE of these input masks work the same way as each other. Some of them support very
 * specific kinds of input masking, so we couldn't use those. Some of them don't have typedefs. Other's don't have
 * react integrations. The following are all the options I looked at:
 *
 * - imask: no types, react integration isn't very good, had weird behavior when you moved the cursor
 * - react-number-mask: doesn't allow for freeform input, only a limited # of characters.
 * - inputmask: no react integration
 *
 * In the end, I decided to do what I did for flatpickr: replicate the ember app as much as possible. This
 * meant choosing inputmask, and building our own masked input integration. It also means we need
 * to stick with v4 of inputmask, because v5 also has weirdness when you move the cursor before the
 * prefix and start typing.
 */

const DEFAULT_OPTIONS = {
  rightAlign: false,
};

/**
 * A simple masked input
 */
export function MaskedInput(
  /**
   * Right now, this component
   * doesn't support passing the value. Yes this means
   * that we could possibly get out of state with the browser,
   * but UGH supporting that is really really annoying and
   * frankly I don't want to do that if I don't need to
   *
   * (if you're from the future and are rightfully angry at me, look at
   * https://github.com/brandynbennett/ember-inputmask/blob/7d9c0d35b8919175d53eb5e44b8339e8be0ff6c9/addon/components/one-way-input-mask.js
   * as a starting point.)
   */
  props: Omit<OutlinedInputProps, 'value' | 'onChange'> & {
    mask: string;
    allowMinus?: boolean;
    onUpdate: (value: string) => void;
  },
) {
  let { mask, allowMinus, onUpdate, ...rest } = props;
  let inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    let inputmask: InputMask.Instance;
    if (mask === 'currency') {
      inputmask = new InputMask('currency', {
        ...DEFAULT_OPTIONS,
        allowMinus,
        prefix: '$ ',
      });
    } else {
      inputmask = new InputMask(mask, {
        ...DEFAULT_OPTIONS,
        allowMinus,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    inputmask.mask(inputRef.current!);
    return () => {
      inputmask.remove();
    };
  }, [mask, allowMinus]);
  return (
    <OutlinedInput
      {...rest}
      onChange={(event) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onUpdate(event.target.inputmask!.unmaskedvalue());
      }}
      inputRef={inputRef}
    />
  );
}
