import React, { useState } from 'react';
import { Advance, ClientBalance } from '../../schemas';
import { ClientInvoiceUploadSummary } from '../../schemas';
import {
  ClockIcon,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Theme,
  TypeBase,
  TypeSubsectionHeader,
  TypographySystem,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { InterestThisMonth, OutstandingBalance } from './RFAccountOverview';
import { SummaryCard, SummaryCardLargeAmount, SummaryCardSmallAmount } from '../SummaryCard/SummaryCard';
import { SummaryCardAccountOverviewGroup } from '../SummaryCard/SummaryCardAccountOverview';
import {
  SummaryCardHeaderLayout,
  SummaryCardHeaderPresentation,
  SummaryCardHeaderSection,
} from '../SummaryCard/SummaryCardHeader';
import { toCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';
import { useIsMobile } from '../../hooks/useIsMobile';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    background: theme.palette.secondary.main,
    color: '#FFF',
    padding: '2rem 2.5rem',
    marginLeft: '-15px',
    marginRight: '-15px',
    width: '110%',
    position: 'relative',
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      marginLeft: 0,
      marginRight: 0,
      padding: '2rem',
      paddingBottom: 0,
    },
  },
  footer: {
    background: '#FFF',
    maxWidth: '95%',
    margin: '0 auto',
    padding: '1rem',
    borderRadius: '5px',
    zIndex: 1,
    top: '-1rem',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      maxWidth: '100%',
      top: 0,
      padding: '1rem 2rem',
      borderRadius: 0,
      background: theme.palette.secondary.main,
      color: '#FFF',
      paddingTop: 0,
    },
  },
  desktop: {
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
  },
  detail: {
    fontSize: '1.5rem',
    margin: 0,
    fontWeight: 600,
  },
  alignRight: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    margin: '1rem auto',
    [theme.breakpoints.up('md')]: {
      height: '50px',
      paddingRight: '.5rem',
    },
  },
  button: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      height: '50px',
      marginTop: '3rem',
    },
    borderColor: 'white',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  fee: {
    marginTop: 0,
    color: '#757575',
  },
  link: {
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'block',
    margin: '1rem auto',
    color: '#005d83',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  clockIcon: {
    verticalAlign: 'middle',
    marginRight: '0.2rem',
  },
  title: {
    padding: theme.spacing(5, 0),
    width: '100%',
  },
  buttonGroup: {
    '& > button': {
      margin: `auto ${theme.spacing(1)}px`,
    },
  },
  cancelModalContent: {
    marginBottom: theme.spacing(3),
  },
}));

interface RFSummaryPendingCardProps {
  balance: ClientBalance;
  lastUpload: ClientInvoiceUploadSummary | null;
  advance: Advance;
  onCancelRequest: any;
  confirmedTransferMethod?: string;
  isReadOnlyUser: boolean;
}

export const RFSummaryPendingCard: React.FC<RFSummaryPendingCardProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles({});
  let pendingAvailability = props.balance.availabilityBalance - props.advance.advanceAmount;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const handleCancel = () => {
    setOpenModal(true);
  };

  const exitDialog = () => {
    setOpenModal(false);
  };

  const confirmCancelRequest = () => {
    props.onCancelRequest(exitDialog);
  };

  const transferButton = (
    <SecondaryButton
      onClick={handleCancel}
      color="inherit"
      variant="outlined"
      className={classes.button}
      data-cy={'cancel-advance'}
      data-testid={'cancel-advance'}
      disabled={props.isReadOnlyUser}
    >
      Cancel Request
    </SecondaryButton>
  );

  return (
    <>
      {/* Start Confirmation Modal */}
      <Modal
        open={openModal}
        variant="informational"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        modalTitle={
          <div className={classes.title}>
            <TypeSubsectionHeader customColor={theme.palette.secondary.main} align={'center'}>
              Are You Sure You Want to Cancel?
            </TypeSubsectionHeader>
          </div>
        }
        actionsContent={
          <div className={classes.buttonGroup}>
            <SecondaryButton onClick={exitDialog} style={{ fontSize: '1.2rem', fontWeight: 600 }}>
              No
            </SecondaryButton>
            <PrimaryButton
              onClick={confirmCancelRequest}
              style={{ fontSize: '1.2rem', fontWeight: 600 }}
              data-cy={'confirm-cancel-advance'}
              data-testid={'confirm-cancel-advance'}
            >
              Yes
            </PrimaryButton>
          </div>
        }
      >
        <div className={classes.cancelModalContent}>
          <TypeBase>
            Are you sure you want to cancel your request for{' '}
            <span data-cy={'confirm-cancel-advance-amount'}>{toCurrency(props.advance.advanceAmount)}</span>? (A new
            advance can be requested immediately upon this action based on availability)
          </TypeBase>
        </div>
      </Modal>
      {/* End Confirmation Modal */}
      <SummaryCard data-testid="RFSummaryPendingCard">
        <SummaryCardHeaderPresentation padding={4}>
          <SummaryCardHeaderLayout>
            <SummaryCardHeaderSection>
              <SummaryCardLargeAmount isInverse description={'Pending Availability'} value={pendingAvailability} />
              <SummaryCardSmallAmount
                isInverse
                description={'Funds Requested'}
                transferMethod={props.advance.transferMethod}
                value={props.advance.advanceAmount}
                data-cy={'funds-requested'}
              />
              <p>
                <ClockIcon fontSize={'inherit'} className={classes.clockIcon} />
                <TypographySystem isEmphasis component={'span'} color={'inverse'}>
                  {`Requested on ${toDate(props.advance.created, 'LLL d, yyyy, h:mm a')}`}
                </TypographySystem>
              </p>
            </SummaryCardHeaderSection>
            <SummaryCardAccountOverviewGroup>
              <OutstandingBalance isInverse balance={props.balance} />
              <InterestThisMonth isInverse balance={props.balance} />
              {!isMobile && transferButton}
            </SummaryCardAccountOverviewGroup>
          </SummaryCardHeaderLayout>
          {isMobile && (
            <SummaryCardHeaderLayout>
              <SummaryCardHeaderSection>{transferButton}</SummaryCardHeaderSection>
            </SummaryCardHeaderLayout>
          )}
        </SummaryCardHeaderPresentation>
      </SummaryCard>
    </>
  );
};
