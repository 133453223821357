import React from 'react';
import { InvoiceSupplierStatus } from '../../schemas/invoice.schema';
import { TypeBase, styled } from '@c2fo/react-components';

const StatusLabel = styled(TypeBase)({
  textTransform: 'capitalize',
});

export function InvoiceSupplierStatusLabel(props: { status: InvoiceSupplierStatus }) {
  return <StatusLabel component={'span'}>{props.status.replace('_', ' ').toLowerCase()}</StatusLabel>;
}
