import { TransferMethodPreference } from './client.schema';

export enum InvoiceUploadStatusEnum {
  NEW = 'NEW',
  INSPECTED = 'INSPECTED',
  ANALYZED = 'ANALYZED',
  COMMITTED = 'COMMITTED',
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  HARD_ERRORS = 'HARD-ERRORS',
  ERROR_IMPORTING = 'ERROR-IMPORTING',
  ERROR_INSPECTING = 'ERROR-INSPECTING',
  ERROR_COMMITTING = 'ERROR-COMMITTING',
}

export interface ClientInvoiceUploadSummary {
  uuid: string;
  clientUuid: string;
  status: InvoiceUploadStatusEnum;
  errorCount: number;
  newCount: number;
  newAmount: number;
  updatedCount: number;
  updatedAmount: number;
  unmappedCount: number;
  unmappedAmount: number;
  successCount: number;
  successAmount: number;
  identicalCount: number;
  identicalAmount: number;
  created: string;
}

export interface InvoiceUploadUnmapped {
  companyId: string;
  companyName: string[];
}

export enum InvoiceUploadErrorStatus {
  UNPROCESSED = 'UNPROCESSED',
  VALID = 'VALID',
  LOCKED = 'LOCKED',
  UPDATED = 'UPDATED',
  INELIGIBLE = 'INELIGIBLE',
  PARSE_ERROR = 'PARSE_ERROR',
  DUE_DATE_BEFORE_TRANSACTION_DATE = 'DUE_DATE_BEFORE_TRANSACTION_DATE',
  UNABLE_TO_PARSE_DATE = 'UNABLE_TO_PARSE_DATE',
  FUTURE_DATED_INVOICE = 'FUTURE_DATED_INVOICE',
  FUTURE_DUE_DATE_INVOICE = 'FUTURE_DUE_DATE_INVOICE',
  PAST_DUE_INVOICE = 'PAST_DUE_INVOICE',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
  EMPTY_INVOICE_ID = 'EMPTY_INVOICE_ID',
  EMPTY_DEBTOR = 'EMPTY_DEBTOR',
  EMPTY_DEBTOR_ID = 'EMPTY_DEBTOR_ID',
  SAVE_ERROR = 'SAVE_ERROR',
  DUPLICATE = 'DUPLICATE',
  INVOICE_INSTRUCTIONS = 'INVOICE_INSTRUCTIONS',
  MISSING_REQUIRED_HEADERS = 'MISSING_REQUIRED_HEADERS',
}

export interface InvoiceUploadError {
  status: InvoiceUploadErrorStatus;
  rows: number[];
  error?: string;
}

export interface FactorInvoiceDraftTotals {
  clientUuid: string;
  totalPurchasePrice: number;
  totalCount: number;
}
/**
 * An encoding of the process of uploading
 * an invoice CSV file
 */
export type StagedUploadResponse =
  /**
   * If there were hard errors, then we do _not_
   * try to commit the upload,
   * nor do we try to find any soft errors / unmapped rows
   *
   * A special case: if all the rows were soft
   * errors, then we treat it as if there were
   * hard errors.
   */
  | {
      uuid: string;
      hardErrors: InvoiceUploadError[];
      softErrors: null;
      unmapped: null;
      summary: null;
    }

  /**
   * If no hard errors were found,
   * then we go ahead and
   * - do an initial "commit" of the file
   * - find the soft errors,
   * - find the unmapped rows
   */
  | {
      uuid: string;
      hardErrors: null;
      softErrors: InvoiceUploadError[];
      unmapped: InvoiceUploadUnmapped[];
      summary: ClientInvoiceUploadSummary;
    };

export interface CustomerMapping {
  companyId: string;
  displayName: string;
  clientDebtorUuid: string;
}

export interface FactoringUploadDraftTotals {
  clientUuid: string;
  totalPurchasePrice: number;
  totalCount: number;
}

export enum UploadFinalizeStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export interface FactoringUploadFinalizeDto {
  status: UploadFinalizeStatus;
  transferMethodPreference?: TransferMethodPreference;
}
