import React from 'react';
import { Container, Tab, Tabs, Theme, makeStyles } from '@c2fo/react-components';
import { NewFeaturesDrawer } from '../NewFeatures/NewFeaturesDrawer';
import { ProductType } from '../../schemas/enums.schema';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useHistory } from 'react-router-dom';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useProductType } from '../../contexts/ProductType';

function productToSlugValue(productType: ProductType): string {
  return ProductType.ReceivablesFinance === productType ? 'receivable-finance' : 'ar-purchase';
}

const useStyles = makeStyles<Theme>((theme) => ({
  bar: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    color: 'white',
    zIndex: 2,
    boxShadow: '0px 2px 3px 0px rgb(0 0 0 / 25%)',
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
}));

interface LinkTabProps {
  label: string;
  value: string;
}

const useLinkTabStyles = makeStyles((theme) => ({
  root: {
    /**
     * Material-ui imposes a 160px width on the
     * tabs. There isn't a prop (that I can see) to
     * disable this, so we need to apply it
     * with ✨css 💃
     */
    minWidth: 'auto',
  },
}));

function LinkTab(props: LinkTabProps): JSX.Element {
  const classes = useLinkTabStyles();
  return (
    <Tab
      className={classes.root}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      aria-label={props.label}
      // TODO: we need to unify on font family.  Seeing roboto, system-ui, and Inter in places.
      style={{ textTransform: 'none' }}
      href={props.value}
      {...props}
    />
  );
}

export const SubNav: React.FC = () => {
  const classes = useStyles({});
  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const productSlug = productToSlugValue(productType);

  const history = useHistory();

  const handleChange = (_event: React.ChangeEvent<Record<string, unknown>>, value: string) => {
    history.push(value);
  };

  const customerPaymentHeader = productType === ProductType.Factoring ? 'Remittance' : 'Customer Payments';
  const dataCy = productType === ProductType.Factoring ? 'remittance' : 'customer-payments';
  const isMobile = useIsMobile();
  /**
   * Material-ui really doesn't like us providing invalid routes to
   * the <Tabs/> component,
   * so we have to determine if the location is valid before passing it down
   */
  const tabs = [
    {
      label: 'Home',
      value: `/${divisionUuid}/${productSlug}/home`,
    },
    {
      label: 'Invoices',
      value: `/${divisionUuid}/${productSlug}/invoice`,
    },
    {
      label: 'Transaction History',
      value: `/${divisionUuid}/${productSlug}/transaction-history`,
    },
    {
      label: customerPaymentHeader,
      value: `/${divisionUuid}/${productSlug}/customer-payments`,
      dataCy,
    },
    {
      label: 'File Management',
      value: `/${divisionUuid}/${productSlug}/file-management`,
    },
    {
      label: 'Bank Details',
      value: `/${divisionUuid}/setup/bank-account`,
    },
  ];

  const tabValues = tabs.map((tab) => tab.value);
  if (!tabValues.includes(history.location.pathname)) {
    /**
     * We're in a 404 state, just don't show the tabs.
     */
    return null;
  }

  return (
    <div className={classes.bar}>
      <Container disableGutters={isMobile} className={classes.tabsContainer}>
        <Tabs
          value={history.location.pathname}
          onChange={handleChange}
          indicatorColor="secondary"
          aria-label="sub navigation"
          variant={'scrollable'}
          data-cy={'subnav-tabs'}
        >
          {tabs.map((tab) => (
            <LinkTab key={tab.value} label={tab.label} value={tab.value} data-cy={tab.dataCy ? tab.dataCy : ''} />
          ))}
        </Tabs>
        {!isMobile && <NewFeaturesDrawer />}
      </Container>
    </div>
  );
};
