import {
  Filter,
  FilterAdapter,
  composeAdapters,
  localStorageAdapter,
  noopAdapter,
  paginationAdapter,
  useQueryParamsAdapter,
} from './Filter';
import { Pagination } from '../Table/Pagination';
import { QueryParam } from '../../schemas/http.schema';
import { useMemo, useReducer } from 'react';

/**
 * A multiselect filter is anything that... selects multiple
 * items.
 *
 * For this, you must have two things: An item (T) and
 * a key that is unique for each item (TKey).
 *
 * An example of this would be Invoice and Invoice.invoiceId.
 */
export interface MultiselectFilter<T, TKey = string> extends Filter {
  /**
   * Change the value of the filter
   */
  onChange: (values: T[]) => void;
  /**
   * Remove an item by it's key
   */
  remove: (key: TKey) => void;
  /**
   * Add an item to the set
   */
  add: (item: T) => void;

  /**
   * Consumer-Provided function that maps from the data
   * to the key of the data.
   */
  key: (item: T) => TKey;

  /**
   * The list of selected items
   */
  items: T[];

  /**
   * Pre-serialized query params
   */
  param: QueryParam<TKey>;
}

// shhh typescript it's ok
const EMPTY_ARRAY = [] as any;

type MultiselectAdapter<T> = FilterAdapter<T[]>;

export function useTablePageMultiselectAdapter<T>(pagination: Pagination, storageKey: string): FilterAdapter<T[]> {
  return composeAdapters(
    useQueryParamsAdapter<T[]>(storageKey),
    localStorageAdapter<T[]>(storageKey),
    paginationAdapter(pagination),
  );
}

function getInitialState<T>(source: MultiselectAdapter<T>): T[] {
  let initial = source.getInitialValue();
  return initial ?? EMPTY_ARRAY;
}

/**
 * Any filter that needs to accept multiple items can use the multiselect filter.
 *
 * @param key A way to get a unique id for each item in the list
 * @param valueAdapter A way to get/set initial values
 */
export function useMultiselectFilter<T, TKey = string>(
  key: (item: T) => TKey,
  valueAdapter: MultiselectAdapter<T> = noopAdapter(),
): MultiselectFilter<T, TKey> {
  let [state, setState] = useReducer((_: T[], update: T[]) => update, valueAdapter, getInitialState);

  let filter: MultiselectFilter<T, TKey> = useMemo(
    () => {
      const updateState = (values: T[]) => {
        setState(values);
        valueAdapter.syncValue(values);
      };

      return {
        onChange: (values: T[]) => {
          updateState(values);
        },
        remove: (keyToRemove) => {
          updateState(state.filter((item) => key(item) !== keyToRemove));
        },
        add: (item) => {
          updateState(state.concat([item]));
        },
        clear: () => {
          updateState(EMPTY_ARRAY);
        },
        isEnabled: state.length > 0,
        key,
        items: state,
        param: {
          in: state.map(key),
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return filter;
}
