const usCurrencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const currencyMap = new Map<string, Intl.NumberFormat>();
currencyMap.set('USD', usCurrencyFormat);

/**
 * Format to currency a given
 * @param value number | string : value to convert to currency display
 * @param currency string : currency to use.
 * @throws Error if coercing a string to number fails.
 */
export function toCurrency(value: number | string | null, currency = 'USD') {
  const formatter = currencyMap.get(currency);
  if (!formatter) {
    throw new Error(`Formatter specified not found: "${currency}"`);
  }
  if (value === null) {
    return '-';
  }
  let floatValue;
  if (typeof value === 'string') {
    floatValue = parseFloat(value);
    if (Number.isNaN(floatValue)) {
      throw new Error(`Non-coercible string passed to toCurrency: "${value}"`);
    }
  } else {
    floatValue = value;
  }
  return formatter.format(floatValue);
}

/**
 * Formats a currency amount so that it can be put into a table
 *
 * Basically the only difference is that
 * -$10.00
 * becomes
 * $(10.00)
 *
 * We only accept USD currencies, because it is unknown how other
 * locales do tabular currency formatting
 */
export function toTabularCurrency(value: number | null) {
  if (value === null) {
    return toCurrency(value);
  }
  if (value < 0) {
    let formatted = toCurrency(-value).replace('$', '');
    return `$(${formatted})`;
  }
  return toCurrency(value);
}
