import qs from 'qs';
import { Pagination } from '../Table/Pagination';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Common abstractions around types of filters.
 *
 * The idea here is the following: pages like
 * RfInvoiceGrid shouldn't need to care about _how_ a
 * date filter works, only that they need to pass it off
 * to some filter components, and pull the data off of it.
 * The exact specifics of how everything works is 100000%
 * abstracted away, so that we can make changes to filters without
 * having to update every page.
 */

/**
 * Base type for all filters
 */
export interface Filter {
  /**
   * Clear the filter and return it to it's
   * default state
   *
   * The meaning of "cleared" is up to the filter.
   */
  clear: () => void;

  /**
   * Whether the filter is "enabled"
   *
   * For a multiselect filter, this means the filter
   * has some items selected
   *
   * For a date range, this means the filter has dates
   * selected
   */
  isEnabled: boolean;
}

/**
 * The "official" way to provide some initial values to a filter.
 *
 * Why have it this way, you might ask?
 * So there are several ways that filters like DateFilter might be used:
 * - As part of the invoice page, meaning our initial value comes from localStorage
 * - As part of the reports editor, meaning the value comes from the api
 *
 * We also need to deal with changes being persisted to those two values.
 * We also need to deal with pagination: if we paginate, we should reset it on every change.
 *
 * This abstraction allows us to fit all of those use-cases,
 * in a nicely decoupled way. It also opens the door to allowing for more complex changes
 * in the future.
 */
export interface FilterAdapter<T> {
  /**
   * Get the initial value from whatever source.
   *
   * Is allowed to return null, in which case the filter
   * must provide some default.
   */
  // TODO: figure out how to resolve this type without using 'any'. Original 'T | null' broke down in upgrade.
  getInitialValue(): any;

  /**
   * React to updates to the filter.
   * This can include stuff like pagination reset,
   * or persisting to local storage.
   */
  syncValue(value: T): void;
}
export function initialValueAdapter<T>(value: T | null | (() => T | null)): FilterAdapter<T> {
  return {
    getInitialValue: () => {
      if (typeof value === 'function') {
        return (value as () => T)();
      }
      return value;
    },
    syncValue: () => {},
  };
}

export function localStorageAdapter<T>(storageKey: string): FilterAdapter<T> {
  return {
    getInitialValue: () => {
      let stored = localStorage.getItem(storageKey);
      if (stored) {
        return JSON.parse(stored);
      }
      return null;
    },
    syncValue: (value: T) => {
      localStorage.setItem(storageKey, JSON.stringify(value));
    },
  };
}

/**
 * "Composes" any number of adapters together.
 *
 * Basically this allows you to combine the features of any number of filter
 * adapters into one.
 * thus allowing you to do things like:
 * have a localStorage-backed filter that can react to changes in pagination
 *
 * For the initial value, this will attempt to get each one in turn.
 */
export function composeAdapters<T>(...filterAdapters: FilterAdapter<T>[]): FilterAdapter<T> {
  return {
    getInitialValue: () => {
      for (let adapter of filterAdapters) {
        let value = adapter.getInitialValue();
        if (value) {
          return value;
        }
      }
      return null;
    },
    syncValue: (value) => {
      filterAdapters.forEach((fa) => fa.syncValue(value));
    },
  };
}

export function paginationAdapter(pagination: Pagination): FilterAdapter<never> {
  return {
    getInitialValue: () => null,
    syncValue: () => {
      pagination.reset();
    },
  };
}

export function noopAdapter(): FilterAdapter<never> {
  return {
    getInitialValue: () => null,
    syncValue: () => {},
  };
}

export function useQueryParamsAdapter<T>(key: string): FilterAdapter<T> {
  let location = useLocation();
  let history = useHistory();

  // Use localstorage key but strip out prefix
  let cleanedKey = key.includes(':') ? key.split(':')[1] : key;

  let flattenedParams = qs.parse(location.search, { ignoreQueryPrefix: true, strictNullHandling: true });

  return {
    getInitialValue: () => flattenedParams[cleanedKey],
    syncValue: (value: T) => {
      let updatedParams = qs.stringify(
        {
          ...flattenedParams,
          [cleanedKey]: value,
        },
        { strictNullHandling: true },
      );
      history.push({ pathname: location.pathname, search: updatedParams });
    },
  };
}
