import React from 'react';
import clsx from 'clsx';
import { Skeleton, TableCell, makeStyles } from '@c2fo/react-components';

// The TableLoadingSkeletonCell should only show for columns that are loading data.
// The prop 'transparent' is needed in order to make the 'Skeleton' component
// transparent/not show for columns with icons.
// Examples of these columns include 'Select' and 'Details'.

type TableLoadingSkeletonCellProps = {
  width?: number;
  align?: 'left' | 'right' | 'center';
  transparent?: boolean;
};

const useStyles = makeStyles((theme) => ({
  skeletonAlignRight: { display: 'block', marginLeft: 'auto' },
  skeletonAlignCenter: { display: 'block', marginLeft: 'auto', marginRight: 'auto' },
  skeletonLoadingForIcons: { backgroundColor: 'transparent' },
}));

export function TableLoadingSkeletonCell(props: TableLoadingSkeletonCellProps) {
  let classes = useStyles();
  return (
    <TableCell style={{ padding: '.3rem', backgroundColor: '#fafafa' }} data-testid={'table-loading-skeleton-cell'}>
      <Skeleton
        variant={'rect'}
        height={'2rem'}
        width={props.width ? props.width + 'rem' : '10rem'}
        className={clsx(
          props.align === 'center' && classes.skeletonAlignCenter,
          props.align === 'right' && classes.skeletonAlignRight,
          props.transparent === true && classes.skeletonLoadingForIcons,
        )}
      />
    </TableCell>
  );
}
