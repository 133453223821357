import React, { ReactNode } from 'react';
import {
  Box,
  CloseIcon,
  DialogTitle,
  IconButton,
  Modal,
  TypeSectionHeader,
  makeStyles,
  styled,
} from '@c2fo/react-components';

const useCloseIconStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(3),
  },
}));

export function InvoiceUploadDialogCloseButton(props: { onClose: () => void }) {
  let classes = useCloseIconStyles();
  return (
    <IconButton
      onClick={props.onClose}
      size={'small'}
      className={classes.closeIcon}
      data-testid={'invoice-upload-dialog-close-button'}
    >
      <CloseIcon />
    </IconButton>
  );
}

export function InvoiceUploadDialogTitle(props: { id?: string; children?: ReactNode; onClose?: () => void }) {
  return (
    <DialogTitle>
      <TypeSectionHeader id={props.id} component={'p'} align={'center'}>
        <strong>{props.children}</strong>
        {props.onClose && <InvoiceUploadDialogCloseButton onClose={props.onClose} />}
      </TypeSectionHeader>
    </DialogTitle>
  );
}

export const InvoiceUploadDialogActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

export function InvoiceUploadDialogContent(props: { children: ReactNode; padding?: number }) {
  return <Box padding={props.padding == null ? 4 : props.padding}>{props.children}</Box>;
}

export const InvoiceUploadDialog = Modal;
