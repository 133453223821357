import React from 'react';
import { BodyCell, DateCell, HeaderCell, MoneyCell } from '../../components/Table/TableCell';
import { DateChips } from '../../components/Filters/FilterChips';
import { DatePicker } from '../../components/Filters/DatePicker';
import { DatePreset, LazyDateRange, lazyDateRangeAdapter, useDateFilter } from '../../components/Filters/useDateFilter';
import { DigitalData } from '../../../lib';
import {
  DownloadIcon,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TypeBase,
  makeStyles,
} from '@c2fo/react-components';
import { FilterLabel } from '../../components/Filters/FilterLabel';
import { InfoTooltip } from '../../components/Tooltips/InfoTooltip';
import { PageHeader, PageHeaderDivider } from '../../components/PageWrapper/PageHeader';
import { PageTitle } from '../../components/PageWrapper/PageTitle';
import { PaginationControls, usePagination } from '../../components/Table/Pagination';
import { SlideoutFilterAndChips, SlideoutFilterGroup } from '../../components/Filters/Slideout';
import { TableLoadingSkeletonCell } from '../../components/Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../../components/Table/TableLoadingSkeletonRow';
import { composeAdapters } from '../../components/Filters/Filter';
import { toCurrency, toTabularCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useServices } from '../../services';
import { useTablePageDateAdapter } from '../../components/Filters/useDateFilter';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonAligner: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    textTransform: 'capitalize',
    maxWidth: 150,
  },
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    marginBottom: theme.spacing(6),
  },
}));

const INITIAL_RF_TX_DATE_RANGE: LazyDateRange = {
  preset: DatePreset.Last30Days,
  start: null,
  end: null,
};

export const RfTransactionHistoryPage: React.FC = () => {
  DigitalData.Hooks.usePageTitle('RF - Transaction History');
  DigitalData.Hooks.useSection('product', 'transaction history');

  const classes = useStyles();
  const isMobile = useIsMobile();
  const pagination = usePagination('rf-txn-history-pagination');
  const transactionDate = useDateFilter(
    composeAdapters(
      useTablePageDateAdapter(pagination, 'rf-txn-history-txn-date'),
      lazyDateRangeAdapter(INITIAL_RF_TX_DATE_RANGE),
    ),
  );
  const divisionUuid = useCurrentDivision();
  const { rfTransactionHistoryService } = useServices();

  const historyEntriesAsync = useAsyncQuery(
    async () =>
      rfTransactionHistoryService.query(divisionUuid, {
        ...pagination.params,
        transactionDate: transactionDate.param,
      }),
    [pagination.params, transactionDate.param],
  );

  return (
    <>
      <PageHeader>
        <div className={classes.pageHeader}>
          <PageTitle>Transaction History</PageTitle>
          <div className={classes.buttonAligner}>
            {isMobile ? null : historyEntriesAsync.isLoading ? (
              <Skeleton variant={'rect'} width={100} height={'2rem'} />
            ) : (
              <SecondaryButton
                onClick={() => {
                  rfTransactionHistoryService.download(divisionUuid, {
                    transactionDate: transactionDate.param,
                  });
                }}
                size={'medium'}
                disabled={historyEntriesAsync.response?.data.length === 0}
                data-cy="downloadTransactionsButton"
                startIcon={<DownloadIcon />}
              >
                <a
                  hidden
                  data-cy="downloadUrl"
                  href={rfTransactionHistoryService.getDownloadUrl(divisionUuid, {
                    transactionDate: transactionDate.param,
                  })}
                />
                Download
              </SecondaryButton>
            )}
          </div>
        </div>
      </PageHeader>
      <PageHeaderDivider />
      <SlideoutFilterAndChips
        filters={[transactionDate]}
        chips={
          <DateChips fieldName={'transactionDate'} filter={transactionDate}>
            Transaction Date
          </DateChips>
        }
        slideoutTitle={'Adjust Filters'}
        slideoutFilters={
          <SlideoutFilterGroup>
            <FilterLabel>Date</FilterLabel>
            <DatePicker filter={transactionDate} testFilterName="date" />
          </SlideoutFilterGroup>
        }
      />
      <TableContainer className={classes.tableContainer}>
        <Table data-cy="transactionHistoryTable">
          <TableHead>
            <TableRow>
              <HeaderCell>Date</HeaderCell>
              <HeaderCell align={'left'}>Description</HeaderCell>
              <HeaderCell>AR Credit / Debit</HeaderCell>
              <HeaderCell>Balance Debit / Credit</HeaderCell>
              <HeaderCell>AR Balance</HeaderCell>
              <HeaderCell>Outstanding Balance</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy="transactionGridBody">
            {historyEntriesAsync.isLoading ? (
              <TableLoadingSkeletonRow rows={25}>
                <TableLoadingSkeletonCell width={8} align={'center'} />
                <TableLoadingSkeletonCell width={10} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
                <TableLoadingSkeletonCell width={8} align={'right'} />
              </TableLoadingSkeletonRow>
            ) : historyEntriesAsync.response.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={'100%' as any /* 100% is a valid value, but mui doesn't think so  */}>
                  <TypeBase align={'center'} data-testid={'no-entries-message'}>
                    No Activity in This Period
                  </TypeBase>
                </TableCell>
              </TableRow>
            ) : (
              historyEntriesAsync.response.data.map((entry) => (
                <TableRow key={entry.createdTime + entry.transactionType + entry.outstandingBalance}>
                  <DateCell data-testid={'transaction-date'}>{entry.transactionDate}</DateCell>
                  <BodyCell data-testid={'description'} className={classes.description} align={'left'}>
                    {entry.transactionType.toLowerCase().replace(/_/g, ' ')}
                    {entry.transactionType.startsWith('Payment allocated') && (
                      <>
                        &nbsp;
                        <InfoTooltip placement="bottom" name="rfTransactionHistory.Description">
                          Original payment date: {toDate(entry.originalPaymentDate)}
                          <br />
                          Payment amount: {toCurrency(entry.originalPaymentAmount)}
                        </InfoTooltip>
                      </>
                    )}
                  </BodyCell>
                  <MoneyCell data-testid={'ar-debit-credit'} format={toTabularCurrency}>
                    {entry.arDebitCredit ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'outstanding-balance-debit-credit'} format={toTabularCurrency}>
                    {entry.outstandingBalanceDebitCredit ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'ar-balance'} format={toTabularCurrency}>
                    {entry.arBalance ?? 0}
                  </MoneyCell>
                  <MoneyCell data-testid={'outstanding-balance'} format={toTabularCurrency}>
                    {entry.outstandingBalance ?? 0}
                  </MoneyCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {historyEntriesAsync.response && (
          <PaginationControls meta={historyEntriesAsync.response.meta} pagination={pagination} />
        )}
      </TableContainer>
    </>
  );
};
