import React from 'react';
import { DigitalData } from '../../lib';

type AccountStatus = 'closed' | 'open' | 'overdrawn';

/**
 * React Hook for setting the account status digital data property. Specifcally not in the
 * library for DigitalData as this is specific to CapFin. I still am hopeful we can move the
 * DigitalData library into the mono for re-use across teams.
 */
export function useAccountStatus({ isClosed, isOverdrawn }: { isClosed: boolean; isOverdrawn: boolean }) {
  return React.useEffect(() => {
    let status: AccountStatus = 'open';
    if (isClosed) {
      status = 'closed';
    } else if (isOverdrawn) {
      status = 'overdrawn';
    }
    DigitalData.set('capFinAccountStatus', status);
  }, [isClosed, isOverdrawn]);
}
