import React, { useEffect } from 'react';
import { Client, FactoringInvoice, ProductType, ReportDisplayField } from '../../schemas';
import { DigitalData } from '../../../lib';
import { FactoringInvoiceGrid } from './FactoringInvoiceGrid';
import { InvoiceFilters, useInvoicePageFilters } from '../../components/InvoicePage/InvoiceFilters';
import { InvoicePageHeader } from '../../components/InvoicePage/Header';
import { InvoiceSupplierStatus } from '../../schemas';
import { useAsync, useAsyncFn } from 'react-use';
import { useAsyncQuery } from '../../components/Async/useAsyncQuery';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

interface FactoringInvoicePageProps {
  client: Client;
  isReadOnlyUser: boolean;
}

export const FactoringInvoicePage: React.FC<FactoringInvoicePageProps> = (props) => {
  DigitalData.Hooks.usePageTitle('Factoring - Invoices');
  DigitalData.Hooks.useSection('product', 'invoices');

  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const { invoiceService, reportService } = useServices();
  let filters = useInvoicePageFilters('arp-invoice-page', ProductType.Factoring);

  let invoicesAsync = useAsyncQuery(
    () => invoiceService.getFactoringInvoices(divisionUuid, filters.params),
    [divisionUuid, filters.params],
  );

  let [reportsAsync, refetchReports] = useAsyncFn(
    () =>
      reportService.getInvoiceReports(divisionUuid, {
        entityName: 'factoring-invoice',
      }),
    [reportService, divisionUuid],
  );

  useEffect(() => {
    refetchReports();
  }, [refetchReports]);

  let reportColumns = useAsync<ReportDisplayField<FactoringInvoice>[] | null>(
    () => reportService.getInvoiceMetadata(productType),
    [reportService, productType],
  );

  const factoringInvoiceStatuses = [
    InvoiceSupplierStatus.APPROVED,
    InvoiceSupplierStatus.CHARGED_BACK,
    InvoiceSupplierStatus.PAID,
    InvoiceSupplierStatus.PARTIALLY_PAID,
    InvoiceSupplierStatus.PENDING,
    InvoiceSupplierStatus.PURCHASED,
    InvoiceSupplierStatus.REJECTED,
  ];

  return (
    <>
      <InvoicePageHeader
        client={props.client}
        reports={reportsAsync.value}
        onReportsRefetch={async () => refetchReports()}
        reportColumns={reportColumns.value}
        statusOptions={factoringInvoiceStatuses}
        currentQueryParams={filters.params}
        onInvoiceUploadComplete={invoicesAsync.refetch}
        isReadOnlyUser={props.isReadOnlyUser}
      />
      <InvoiceFilters productType={productType} statusOptions={factoringInvoiceStatuses} filters={filters} />

      <FactoringInvoiceGrid invoices={invoicesAsync} sorting={filters.sorting} pagination={filters.pagination} />
    </>
  );
};
