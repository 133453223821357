import React, { useState } from 'react';
import {
  AngleDownIcon,
  CopyIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  DownloadIcon,
  IconButton,
  Menu,
  MenuItem,
  PencilIcon,
  PrimaryButton,
  SecondaryButton,
  Theme,
  TrashIcon,
  TypeLabel,
  TypeSectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { FactoringInvoice, MonthEndReportType, PageableResponse, ProductType, Report } from '../../schemas';
import { TREATMENTS, useFeature } from '../../providers';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

import { MouseoverTooltip } from '../Tooltips/MouseoverTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  multiActionDropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  multiActionEmphasis: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  // clickable span in multi action menu items. wider for more onClick area.
  greedySpan: {
    minWidth: 100,
  },
  savedItemActionWrapper: {
    paddingLeft: theme.spacing(5),
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 130,
  },
  downloadButton: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
}));

type InvoiceDownloadBtnProps = {
  /**
   * An array of existing custom reports.
   */
  reports?: PageableResponse<Report<FactoringInvoice>>;
  onBuildReportClick: () => void;
  onReportsRefetch: () => Promise<PageableResponse<Report<FactoringInvoice>>>;
  onEditReportClick: (uuid: string) => void;
  onCloneReportClick: (uuid: string) => void;
  onDownloadInvoices: (divisionUuid: string, productType: ProductType) => void;
  onDownloadMonthEndReports: (divisionUuid: string, reportType: MonthEndReportType) => void;
  onFetchAndDownloadInvoices: (divisionUuid: string, reportUuid: string) => void;
};

export function InvoiceDownloadButton(props: InvoiceDownloadBtnProps): JSX.Element {
  const classes = useStyles();
  const { reportService } = useServices();
  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const showRFMonthEndReports = useFeature(TREATMENTS.SHOW_RF_MONTH_END_REPORTS);

  const [reportToDelete, setReportToDelete] = useState<Report<FactoringInvoice> | null>(null);

  let cannedReports: Report<FactoringInvoice>[] = [];
  let customReports: Report<FactoringInvoice>[] = [];
  if (props.reports) {
    cannedReports = props.reports.data.filter((report) => report.clientUuid === null);
    customReports = props.reports.data.filter((report) => report.clientUuid !== null);
  }

  const handleDeleteReport = async (reportUuid?: string | null) => {
    if (!reportUuid) {
      throw new Error('missing required reportUuid for a report delete!');
    }
    await reportService.deleteReport(divisionUuid, reportUuid);
    await props.onReportsRefetch();
    // TODO: need some progress spinners on button(s).
    return setReportToDelete(null);
  };

  const [menuEl, setMenuEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuEl(null);
  };

  const cannedReportJsx = cannedReports.map((report) => (
    <MenuItem
      className={classes.multiActionDropdownItem}
      key={`${report.uuid}`}
      data-testid={`canned-report-${report.uuid}`}
      onClick={() => props.onFetchAndDownloadInvoices(divisionUuid, report.uuid)}
    >
      <span
        role="button"
        tabIndex={0}
        data-testid={'download-button'}
        className={[classes.multiActionEmphasis, classes.greedySpan].join(' ')}
      >
        <MouseoverTooltip content={`${report.reportName}${report.description ? ` - ${report.description}` : ''}`}>
          {report.reportName}
        </MouseoverTooltip>
      </span>
      <span className={classes.savedItemActionWrapper}>
        <MouseoverTooltip content={'Clone'}>
          <IconButton
            aria-label="clone"
            size="small"
            color={'default'}
            className={classes.multiActionEmphasis}
            data-testid={'clone-button'}
            onClick={(event) => {
              /**
               * If we don't do this, clicking this button
               * will also cause the report to download
               */
              event.stopPropagation();
              props.onCloneReportClick(report.uuid);
            }}
          >
            <CopyIcon fontSize="inherit" />
          </IconButton>
        </MouseoverTooltip>
      </span>
    </MenuItem>
  ));

  const customReportJsx = customReports.map((report) => (
    <MenuItem
      className={classes.multiActionDropdownItem}
      key={`custom-${report.uuid}`}
      data-testid={`custom-report-${report.uuid}`}
      onClick={() => props.onFetchAndDownloadInvoices(divisionUuid, report.uuid)}
    >
      <span
        role="button"
        data-testid={'download-button'}
        tabIndex={0}
        className={[classes.multiActionEmphasis, classes.greedySpan].join(' ')}
      >
        <MouseoverTooltip content={`${report.reportName}${report.description ? ` - ${report.description}` : ''}`}>
          <div className={classes.overflow}>{report.reportName}</div>
        </MouseoverTooltip>
      </span>
      <span className={classes.savedItemActionWrapper}>
        <MouseoverTooltip content={'Edit'}>
          <IconButton
            aria-label="edit"
            data-cy={`customReport-${report.reportName}-edit`}
            size="small"
            color={'default'}
            data-testid={'edit-report'}
            onClick={(event) => {
              /**
               * If we don't do this, clicking this button
               * will also cause the report to download
               */
              event.stopPropagation();
              props.onEditReportClick(report.uuid);
            }}
            className={classes.multiActionEmphasis}
          >
            <PencilIcon fontSize="inherit" />
          </IconButton>
        </MouseoverTooltip>
        <MouseoverTooltip content={'Clone'}>
          <IconButton
            aria-label="clone"
            data-cy={`customReport-${report.reportName}-clone`}
            data-testid={'clone-button'}
            size="small"
            color={'default'}
            onClick={(event) => {
              /**
               * If we don't do this, clicking this button
               * will also cause the report to download
               */
              event.stopPropagation();
              props.onCloneReportClick(report.uuid);
            }}
            className={classes.multiActionEmphasis}
          >
            <CopyIcon fontSize="inherit" />
          </IconButton>
        </MouseoverTooltip>
        <MouseoverTooltip content={'Delete'}>
          <IconButton
            aria-label="delete"
            data-cy={`customReport-${report.reportName}-delete`}
            data-testid={'delete-button'}
            size="small"
            color={'default'}
            onClick={(event) => {
              /**
               * If we don't do this, clicking this button
               * will also cause the report to download
               */
              event.stopPropagation();
              setReportToDelete(report);
            }}
            className={classes.multiActionEmphasis}
          >
            <TrashIcon fontSize="inherit" />
          </IconButton>
        </MouseoverTooltip>
      </span>
    </MenuItem>
  ));

  const monthEndFactoringReportsJsx = () => (
    <div>
      <MenuItem disabled style={{ opacity: 1 }}>
        <TypeLabel align="leading">Month End Reports</TypeLabel>
      </MenuItem>
      <Divider />
      <MenuItem
        data-cy="downloadFeesReport"
        className={classes.multiActionEmphasis}
        onClick={() => props.onDownloadMonthEndReports(divisionUuid, MonthEndReportType.Fees)}
      >
        Fees Report
      </MenuItem>
      <MenuItem
        data-cy="downloadSummaryReport"
        className={classes.multiActionEmphasis}
        onClick={() => props.onDownloadMonthEndReports(divisionUuid, MonthEndReportType.Summary)}
      >
        Summary Report
      </MenuItem>
      <MenuItem
        data-cy="downloadInvoicesReport"
        className={classes.multiActionEmphasis}
        onClick={() => props.onDownloadMonthEndReports(divisionUuid, MonthEndReportType.Invoices)}
      >
        Invoices Report
      </MenuItem>
      <MenuItem
        data-cy="downloadStatement"
        className={classes.multiActionEmphasis}
        onClick={() => props.onDownloadMonthEndReports(divisionUuid, MonthEndReportType.Statement)}
      >
        Statement
      </MenuItem>
    </div>
  );

  const monthEndRFReportsJsx = () => (
    <div>
      <MenuItem disabled style={{ opacity: 1 }}>
        <TypeLabel align="leading">Month End Reports</TypeLabel>
      </MenuItem>
      <Divider />
      <MenuItem
        data-cy="downloadStatement"
        className={classes.multiActionEmphasis}
        onClick={() => props.onDownloadMonthEndReports(divisionUuid, MonthEndReportType.Statement)}
      >
        Statement
      </MenuItem>
    </div>
  );

  return (
    <>
      <SecondaryButton
        size={'medium'}
        onClick={handleMenuClick}
        endIcon={<AngleDownIcon />}
        startIcon={<DownloadIcon />}
      >
        Download
      </SecondaryButton>
      <Menu
        anchorEl={menuEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        data-testid="download-invoices-button"
        data-cy="downloadInvoicesButton"
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <TypeLabel align="leading">Reports</TypeLabel>
        </MenuItem>
        <MenuItem
          data-cy="downloadCurrentView"
          className={classes.multiActionEmphasis}
          onClick={() => props.onDownloadInvoices(divisionUuid, productType)}
        >
          Download Current View
        </MenuItem>
        {cannedReportJsx}
        {productType === ProductType.Factoring && monthEndFactoringReportsJsx()}
        {showRFMonthEndReports && productType === ProductType.ReceivablesFinance && monthEndRFReportsJsx()}
        <MenuItem disabled style={{ opacity: 1 }}>
          <TypeLabel align="leading">Custom Reports</TypeLabel>
        </MenuItem>
        {customReportJsx}
        <Divider />
        <MenuItem
          data-cy="buildCustomReport"
          onClick={() => props.onBuildReportClick()}
          className={classes.multiActionEmphasis}
        >
          Build a Custom Report
        </MenuItem>
      </Menu>
      <Dialog open={reportToDelete !== null} maxWidth={'sm'}>
        <DialogTitle>
          <TypeSectionHeader component="div">Confirm Delete</TypeSectionHeader>
        </DialogTitle>
        <DialogContent>Are you sure you wish to delete report "{reportToDelete?.reportName}"?</DialogContent>
        <DialogActions>
          <SecondaryButton
            data-testid={'delete-report-cancel'}
            data-cy="deleteReportCancel"
            style={{ fontSize: '1.2rem', fontWeight: 600 }}
            onClick={() => setReportToDelete(null)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            data-testid={'delete-report-confirm'}
            data-cy="deleteReportConfirm"
            style={{ fontSize: '1.2rem', fontWeight: 600 }}
            onClick={() => handleDeleteReport(reportToDelete?.uuid)}
          >
            DELETE
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
