import { OnboardingStatus } from '../../schemas/onboarding.schema';
import { ServiceCache } from '../../../lib/cache/ServiceCache';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const basePath = (divisionUuid: string) =>
  `${tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL}/v2/tpf-api/divisions/${divisionUuid}/onboarding-statuses`;

export class OnboardingService {
  private cache: ServiceCache<OnboardingStatus>;

  constructor(private httpService: TpfSupplierApiHttpService) {
    this.cache = new ServiceCache();
  }

  async getOnboardingStatus(divisionUuid: string): Promise<OnboardingStatus> {
    return this.cache.get(divisionUuid, async () => {
      let response = await this.httpService.get<OnboardingStatus>(basePath(divisionUuid), {
        params: {
          uuid: divisionUuid,
        },
      });
      return response.data;
    });
  }
}
