import React, { ReactNode, useState } from 'react';
import { ActionsAllowed, FinancialFile } from '../../schemas/fileManagement.schema';
import { BodyCell, HeaderCell } from '../Table/TableCell';
import {
  CommentsAltIcon,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TertiaryButton,
  TypeBase,
  TypeTableHeader,
  makeStyles,
} from '@c2fo/react-components';
import {
  FileCsvIcon,
  FilePdfIcon,
  FileSpreadsheetIcon,
  FileWordIcon,
  MoreVertIcon,
} from '@c2fo/react-components/dist/lib/material/Icon/Icon';
import { FileStatusPillCell } from './FileStatusPill';
import { LoadingState } from '../Async/useAsyncQuery';
import { MoreHorizontalIcon } from '../CustomIcons/Icons';
import { PageableResponse } from '../../schemas';
import { Pagination, PaginationControls } from '../Table/Pagination';
import { SortableColumn, Sorting } from '../Table/Sorting';
import { toDate } from '../../i18n/date';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '15px',
    boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 30%)',
    marginBottom: theme.spacing(6),
  },
  actionsAllowedButton: {
    width: '50px',
    height: '50px',
    backgroundColor: 'rgba(225,229,234,0.8)',
    borderRadius: '50%',
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'rgba(225,229,234,1)',
    },
    '& span.MuiButton-endIcon': {
      margin: '0',
    },
  },
  fileIconWrapper: {
    width: '24px',
    height: '24px',
    padding: '5px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileNameWrapper: {
    padding: '5px',
  },
  fileInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  notesWrapper: {
    minWidth: '240px',
  },
  actionsListItem: {
    textTransform: 'capitalize',
    '&:nth-child(2)': {
      paddingBottom: '14px',
    },
  },
  actionsDeleteItem: {
    padding: '18px 16px 10px 16px',
    borderTop: '1px solid rgba(0,0,0,0.2)',
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold as 'lighter',
  },
  moreIcon: {
    color: theme.palette.secondary.main,
  },
  notesIcon: {
    color: theme.palette.secondary.main,
  },
  iconButton: {
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: '',
    },
  },
}));

const fileTypeIcon = function (file: FinancialFile) {
  const fileNameArr = file.fileName.split('.');
  const fileType = fileNameArr[fileNameArr.length - 1];
  switch (fileType) {
    case 'pdf':
      return <FilePdfIcon />;

    case 'csv':
      return <FileCsvIcon />;

    case 'xls':
      return <FileSpreadsheetIcon />;

    case 'doc':
      return <FileWordIcon />;
  }
};

export function FileManagementGrid(props: {
  files: LoadingState<PageableResponse<FinancialFile>>;
  tableSkeleton: ReactNode;
  sorting: Sorting;
  pagination: Pagination;
  actionHandler: (file: FinancialFile, action: string) => void;
  isReadOnlyUser: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { files, actionHandler, sorting, tableSkeleton, isReadOnlyUser } = props;

  const [anchorEl, setAnchorEl] = useState<(HTMLButtonElement | null)[]>([]);
  const [menus, setMenus] = useState<boolean[]>([]);
  const setAnchor = (index: number, el: HTMLButtonElement | null) => {
    const newAnchors = anchorEl.slice(0);
    newAnchors[index] = el;
    setAnchorEl(newAnchors);
  };

  const refreshMenuStates = (index: number) => {
    const newMenuStates = menus.slice(0);
    newMenuStates[index] = !menus[index];
    setMenus(newMenuStates);
  };

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead data-cy="fileGridHeader" data-testid={'fileGridHead'}>
            <TableRow>
              <TableCell>
                <TypeTableHeader>Status</TypeTableHeader>
              </TableCell>
              <TableCell>
                <TypeTableHeader>File</TypeTableHeader>
              </TableCell>
              <SortableColumn sorting={sorting} sortField={'created'} align={'right'}>
                Uploaded Date
              </SortableColumn>
              <HeaderCell align={'center'} className={classes.notesWrapper}>
                Notes
              </HeaderCell>
              <HeaderCell align={'center'}>Actions</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy="fileGridBody">
            {files.isLoading ? (
              tableSkeleton
            ) : files.response.data.length === 0 ? (
              <TableRow data-cy="fileGridEmptyMessage">
                <TableCell colSpan={'100%' as any /* 100% is a valid value, but mui doesn't think so  */}>
                  <TypeBase align={'center'}>No files uploaded yet.</TypeBase>
                </TableCell>
              </TableRow>
            ) : (
              files.response.data.map((file, index) => (
                <TableRow key={file.uuid} data-cy={`fileRow-${file.uuid}`}>
                  <FileStatusPillCell
                    data-testid={'file-status'}
                    data-cy={`fileRow-${file.uuid}-approvalStatus`}
                    status={file.approvalStatus}
                  />
                  <BodyCell data-testid={'file-name'} data-cy={`fileRow-${file.fileName}-fileName`} align={'left'}>
                    <span className={classes.fileInfoWrapper}>
                      <span className={classes.fileIconWrapper}>{fileTypeIcon(file)}</span>
                      <span className={classes.fileNameWrapper}>{file.fileName}</span>
                    </span>
                  </BodyCell>
                  <BodyCell data-testid={'upload-date'} align={'right'} data-cy={`fileRow-${file.created}-uploadTime`}>
                    {toDate(file.created ?? null, 'MMM d yyyy, h:mm a')}
                  </BodyCell>
                  <BodyCell
                    data-testid={'review-notes'}
                    data-cy={`fileRow-${file.reviewComments}-reviewNotes`}
                    align={'center'}
                  >
                    {!file.reviewComments ? (
                      '--'
                    ) : (
                      <IconButton
                        data-testid={'review-notes-button'}
                        data-cy={'review-notes'}
                        onClick={() => actionHandler(file, 'REVIEW_NOTES')}
                        color={'secondary'}
                      >
                        <CommentsAltIcon className={classes.notesIcon} />
                      </IconButton>
                    )}
                  </BodyCell>
                  <BodyCell
                    data-testid={'actions-allowed'}
                    data-cy={`fileRow-${file.actionsAllowed}-actionsAllowed`}
                    align="center"
                  >
                    <>
                      <IconButton
                        className={classes.iconButton}
                        data-cy="actions-allowed-drop-down"
                        disabled={!file.actionsAllowed.length}
                        color={'secondary'}
                        onClick={(event) => {
                          setAnchor(index, event.currentTarget);
                          refreshMenuStates(index);
                        }}
                      >
                        <MoreVertIcon className={classes.moreIcon} />
                      </IconButton>
                      <Menu
                        key={'menu-' + index}
                        anchorEl={anchorEl[index]}
                        keepMounted
                        open={menus[index]}
                        onClose={() => {
                          setAnchor(index, null);
                          refreshMenuStates(index);
                        }}
                      >
                        {Object.values(ActionsAllowed).map((action) => {
                          if (!file.actionsAllowed.includes(action)) {
                            return null;
                          }
                          return (
                            <MenuItem
                              className={`${classes.actionsListItem} ${
                                action === 'DELETE' ? classes.actionsDeleteItem : null
                              }`}
                              key={action}
                              data-cy={'action-allowed-button-' + action}
                              onClick={() => {
                                actionHandler(file, action);
                                refreshMenuStates(index);
                              }}
                              disabled={isReadOnlyUser}
                            >
                              {action.toLowerCase()}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </>
                  </BodyCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {!files.isLoading && <PaginationControls meta={files.response.meta} pagination={props.pagination} />}
      </TableContainer>
    </>
  );
}
