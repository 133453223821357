import * as Yup from 'yup';
import Dropzone, { DropEvent } from 'react-dropzone';
import React, { ReactNode, useEffect, useState } from 'react';
import states from '../../fixtures/states';
import {
  Alert,
  Box,
  CTAButton,
  Checkbox,
  CloudUploadIcon,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  PrimaryButton,
  SecondaryButton,
  Skeleton,
  TimesCircleIcon,
  TypeBase,
  TypeHelper,
  TypeLabel,
  TypeSubsectionHeader,
  makeStyles,
  useTheme,
} from '@c2fo/react-components';
import { BankAccount, BankAccountFormData, BankAccountStatus } from '../../schemas';
import { CustomCloudUploadIcon } from '../../components/CustomIcons/Icons';
import { DigitalData } from '../../../lib';
import { Form, Formik } from 'formik';
import { OnboardingStatus } from '../../schemas';
import { PageHeader, PageHeaderDivider } from '../../components/PageWrapper/PageHeader';
import { PageTitle } from '../../components/PageWrapper/PageTitle';
import { ValidatedInput, ValidatedSelect, required } from '../../components/Form/ValidatedField';
import { useAsync, useAsyncFn } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useServices } from '../../services';

// This is 24MB.  The actual api limit is 25MB, but we don't really know how the calculation is done for the
//  setting in tpf-supplier-api, we set it to 24MB just to be safe.  AWS is pretty vague as to the definition
//  of the proxy-body-size.
const MaxFileSize = 25165842;

const SupportedMIMETypes = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const useStyles = makeStyles((theme) => ({
  rebrandUploadIcon: {
    fontSize: 100,
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(-9),
  },

  uploadIcon: {
    fontSize: 100,
    color: theme.palette.secondary.main,
  },

  cancelIcon: {
    color: theme.palette.error.main,
  },

  formStyles: {
    '& .MuiSnackbarContent-root': {
      flexWrap: 'noWrap',
    },
  },

  rebrandUploadBox: {
    border: '1px dashed #9E9E9E',
    borderRadius: '15px',
  },

  uploadBox: {
    border: '1px solid black',
  },
}));

function StateOptions() {
  return (
    <>
      <option value={''}></option>
      {states.map((state) => (
        <option key={state.value} value={state.value}>
          {state.label}
        </option>
      ))}
    </>
  );
}

function Title(props: { onboarding: OnboardingStatus; bankAccount: BankAccount | null }) {
  const { onboarding, bankAccount } = props;
  let header: ReactNode = 'Set Up Your Bank Account';
  let subheader: ReactNode = 'Provide details of your primary business bank account.';
  if (onboarding.isBankAccountRequired === false) {
    header = 'Bank Account Information Is Not Required';
    subheader = "We currently don't need your bank account information.";
  } else if (bankAccount?.status === BankAccountStatus.PENDING_VERIFICATION) {
    header = 'Bank Account Is Being Verified';
    subheader =
      'Thank you for submitting your bank account details. It usually takes 48 hours to verify your bank account.';
  } else if (bankAccount?.status === BankAccountStatus.VERIFIED) {
    header = 'Bank Account Complete';
    subheader = 'Contact customer support to change your bank account information.';
  } else if (bankAccount?.status === BankAccountStatus.FAILED_VALIDATION) {
    header = 'Bank Account Failed Validation';
    subheader =
      'We tried to transfer $0.01 to your bank account. However, the payment failed because the supplied routing and account numbers are invalid. Please re-enter your bank account information.';
  } else if (bankAccount?.status === BankAccountStatus.INVALID_VERIFICATION_DOCUMENT) {
    header = 'Bank Account Failed Validation';
    subheader =
      "The information you gave us didn't match the verification document you uploaded. Please re-enter your bank account information.";
  }

  return (
    <>
      <TypeSubsectionHeader data-testid={'setup-header'} align={'center'}>
        {header}
      </TypeSubsectionHeader>
      <TypeHelper data-testid={'setup-subheader'} align={'center'}>
        {subheader}
      </TypeHelper>
    </>
  );
}

const validationSchema = Yup.object().shape({
  postalCode: Yup.string().min(5, 'Postal code is too short (minimum is 5 characters)'),
  routingNumberWire: Yup.string().min(9, 'Routing number wire is the wrong length (should be 9 characters)'),
  routingNumberAch: Yup.string().min(9, 'Routing number ACH is the wrong length (should be 9 characters)'),
});

function BankAccountForm(props: { onSubmit: () => void }) {
  const [verificationDocument, setVerificationDocument] = useState<File | null>(null);
  const theme = useTheme();
  const { bankAccountService } = useServices();
  const divisionUuid = useCurrentDivision();
  const [isAgreementChecked, setAgreementChecked] = useState<boolean>(false);
  const [showAgreementCheckedRequired, setShowAgreementCheckedRequired] = useState<boolean>(false);
  const [showVerificationFileRequired, setShowVerificationFileRequired] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFormSubmit = async (values: BankAccountFormData) => {
    if (!isAgreementChecked) {
      setShowAgreementCheckedRequired(true);
    }
    if (!verificationDocument) {
      setShowVerificationFileRequired(true);
    }
    if (!isAgreementChecked || !verificationDocument) {
      return;
    }

    try {
      await bankAccountService.saveBankAccountForm(divisionUuid, values, verificationDocument);
      props.onSubmit();
    } catch (err) {
      setErrorMessage('An error occurred while trying to save your information, please try again later.');
    }
  };

  const handleFileRejected = (files: File[], event: DropEvent) => {
    if (files.length && files[0].size > MaxFileSize) {
      setErrorMessage(
        'Your document is too large.  The maximum size is 24MB.  If the document is an image, try resizing and try again.',
      );
      return;
    }

    setErrorMessage(
      'The file you uploaded was not a supported document type.  Supported types are Microsoft Word, PDF, PNG and JPEG.',
    );
  };

  const classes = useStyles();

  return (
    <Formik<BankAccountFormData>
      initialValues={{
        accountNumber: '',
        address: '',
        city: '',
        name: '',
        nameOnAccount: '',
        postalCode: '',
        routingNumberAch: '',
        routingNumberWire: '',
        state: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {() => (
        <Form className={classes.formStyles}>
          {errorMessage && (
            <Alert variant={'error'} open onClose={() => setErrorMessage('')}>
              {errorMessage}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ValidatedInput name={'name'} label={'Bank Name'} validate={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput name={'address'} label={'Bank Address'} validate={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput name={'city'} label={'City'} validate={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedSelect name={'state'} label={'State'} validate={required}>
                <StateOptions />
              </ValidatedSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput inputMode={'numeric'} name={'postalCode'} label={'Postal Code'} validate={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput
                inputMode={'numeric'}
                name={'routingNumberWire'}
                label={'Routing Number - Wire'}
                validate={required}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput
                inputMode={'numeric'}
                name={'routingNumberAch'}
                label={'Routing Number - ACH'}
                validate={required}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput name={'nameOnAccount'} label={'Name on Account'} validate={required} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ValidatedInput
                inputMode={'numeric'}
                name={'accountNumber'}
                label={'Account Number'}
                validate={required}
                redactFullStory={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TypeBase>Upload an image of a voided check, or verification letter from your bank.</TypeBase>
              {showVerificationFileRequired && (
                <TypeBase isError data-testid={'show-verification-file-required-message'}>
                  A voided check or verification letter is required.
                </TypeBase>
              )}
              <Dropzone
                maxSize={MaxFileSize}
                accept={SupportedMIMETypes}
                onDropAccepted={(acceptedFiles) => {
                  setShowVerificationFileRequired(false);
                  setVerificationDocument(acceptedFiles[0]);
                }}
                onDropRejected={handleFileRejected}
              >
                {({ getRootProps, getInputProps }) => (
                  <div data-testid={'file-upload'} {...getRootProps()}>
                    <Box className={classes.rebrandUploadBox} padding={6}>
                      <Grid container direction={'column'} alignItems={'center'} spacing={2}>
                        <Grid item>
                          <div className={classes.rebrandUploadIcon}>
                            <CustomCloudUploadIcon />
                          </div>
                        </Grid>
                        <Grid item data-cy="financial-file-upload">
                          <input {...getInputProps()} />
                          {verificationDocument === null ? (
                            <>
                              <TypeBase align={'center'}>Drop image here or</TypeBase>
                              <SecondaryButton component={'span'}>Select a file</SecondaryButton>
                            </>
                          ) : (
                            <>
                              <TypeBase align={'center'} spacingBottom={2}>
                                Attached Image
                              </TypeBase>
                              <IconButton
                                data-testid="remove-uploaded-file"
                                data-cy="remove-uploaded-file"
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                  setShowVerificationFileRequired(true);
                                  setVerificationDocument(null);
                                }}
                                size="small"
                              >
                                <TimesCircleIcon className={classes.cancelIcon} />
                                <TypeBase data-cy="uploaded-file-name" customColor={theme.palette.secondary.main}>
                                  {verificationDocument.name}
                                </TypeBase>
                              </IconButton>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                )}
              </Dropzone>
            </Grid>
            <Grid item xs={12}>
              {showAgreementCheckedRequired && (
                <TypeBase isError data-testid={'agreement-checked-required-message'}>
                  Must agree to the terms below.
                </TypeBase>
              )}
              <FormControlLabel
                data-cy="terms-conditions"
                control={
                  <Checkbox
                    checked={isAgreementChecked}
                    onChange={(event) => {
                      setShowAgreementCheckedRequired(false);
                      setAgreementChecked(event.target.checked);
                    }}
                    color={'primary'}
                  />
                }
                label={
                  <>
                    By clicking <strong>Agree {'&'} Submit</strong>, I authorize the credit provider or its affiliate
                    to: (i) debit/credit test the provided banking information (“Bank Account”); (ii) validate the Bank
                    Account with the associated banking institution; and (iii) use the Bank Account in conjunction with
                    application for credit and subsequent provision of credit, if approved.
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} container justifyContent={'center'}>
              <CTAButton type={'submit'}>Agree {'&'} Submit</CTAButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

function BankAccountDisplay(props: { bankAccount: BankAccount }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypeLabel>Bank Name</TypeLabel>
        <TypeBase>{props.bankAccount.name}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Bank Address</TypeLabel>
        <TypeBase>{props.bankAccount.address}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>City</TypeLabel>
        <TypeBase>{props.bankAccount.city}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>State</TypeLabel>
        <TypeBase>{props.bankAccount.state}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Postal Code</TypeLabel>
        <TypeBase>{props.bankAccount.postalCode}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Routing Number - Wire</TypeLabel>
        <TypeBase>{props.bankAccount.routingNumberWire}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Routing Number - ACH</TypeLabel>
        <TypeBase>{props.bankAccount.routingNumberAch}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Name on Account</TypeLabel>
        <TypeBase>{props.bankAccount.nameOnAccount}</TypeBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <TypeLabel>Account Number</TypeLabel>
        <TypeBase>{props.bankAccount.lastFourBankAccountNumber.padStart(17, '*')}</TypeBase>
      </Grid>
    </Grid>
  );
}

const INVALID_BANKACCOUNT_STATUSES = [
  BankAccountStatus.CHANGE_REQUEST,
  BankAccountStatus.INVALID_VERIFICATION_DOCUMENT,
  BankAccountStatus.FAILED_VALIDATION,
];

export const SetupPage: React.FC = () => {
  DigitalData.Hooks.usePageTitle('Setup - Bank Details');
  DigitalData.Hooks.useSection('product', 'set up');

  const divisionUuid = useCurrentDivision();
  const { bankAccountService, onboardingService } = useServices();
  const onboardingAsync = useAsync(async () => onboardingService.getOnboardingStatus(divisionUuid), [divisionUuid]);
  const [bankAccountAsync, refetchBankAccount] = useAsyncFn(
    () => bankAccountService.getBankAccount(divisionUuid),
    [divisionUuid],
  );

  useEffect(() => {
    refetchBankAccount();
  }, [refetchBankAccount]);

  const pageHeader = (
    <PageHeader>
      <PageTitle>Bank Details</PageTitle>
      <PageHeaderDivider />
    </PageHeader>
  );

  if (onboardingAsync.loading || bankAccountAsync.loading) {
    return (
      <Box>
        {pageHeader}
        <Skeleton variant={'rect'} height={450} />
      </Box>
    );
  }

  let bankAccountForm: ReactNode = <BankAccountForm onSubmit={refetchBankAccount} />;

  if (onboardingAsync.value?.isBankAccountRequired === false) {
    bankAccountForm = null;
  } else if (bankAccountAsync.value && !INVALID_BANKACCOUNT_STATUSES.includes(bankAccountAsync.value.status)) {
    bankAccountForm = <BankAccountDisplay bankAccount={bankAccountAsync.value} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onboardingValue = onboardingAsync.value!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const bankAccountValue = bankAccountAsync.value!;

  return (
    <Box>
      {pageHeader}
      <Paper>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title onboarding={onboardingValue} bankAccount={bankAccountValue} />
            </Grid>
            <Grid item xs={12}>
              {bankAccountForm}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
