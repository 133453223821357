import React, { ReactFragment, ReactNode } from 'react';
import { IconButton, Tooltip, TooltipProps } from '@c2fo/react-components';
import { makeStyles } from '@c2fo/react-components';

const useInfoTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'relative',
    top: '-2px',
    height: '.6em',
    width: '.6em',
    padding: '0',
    '& svg': {
      fontSize: '.55em',
    },
  },
}));

export function IconTooltip(props: {
  children: ReactFragment;
  icon: ReactNode;
  name?: string;
  arrow?: boolean;
  placement?: TooltipProps['placement'];
}): JSX.Element {
  const classes = useInfoTooltipStyles();

  return (
    <Tooltip
      data-testid={props.name}
      title={props.children}
      arrow={props.arrow}
      placement={props.placement}
      className={classes.tooltip}
      color={'dark'}
    >
      <IconButton name={props.name}>{props.icon}</IconButton>
    </Tooltip>
  );
}
