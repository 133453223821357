import { PageableResponse, Query } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

/**
 * Service for interacting with API endpoints.
 */
export class BaseDataService<T extends Record<string, unknown>> {
  constructor(protected httpService: TpfSupplierApiHttpService, protected basePath: (divisionUuid: string) => string) {}

  async query(divisionUuid: string, params: Query<T>): Promise<PageableResponse<T>> {
    try {
      let response = await this.httpService.get<PageableResponse<T>>(this.basePath(divisionUuid), {
        params: this.httpService.serializeQueryParams(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(`${this.constructor.name}#query error debug: ${error}`);
    }
  }
  // possibly include getByUuid? We use that less than you'd expect, but it
  // might be worthwhile
}
