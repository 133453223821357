import { BaseDataService } from '../BaseDataService/BaseDataService';
import { Debtor } from '../../schemas/debtor.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const basePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/debtors`;

/**
 * Service for interacting with client API endpoints.
 */

export class DebtorService extends BaseDataService<Debtor> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, basePath);
  }
}
