import React, { ReactNode } from 'react';
import { Divider, makeStyles } from '@c2fo/react-components';

export function PageHeader(props: { children: ReactNode }) {
  /**
   * Empty so that we can make changes in the future
   */
  return <>{props.children}</>;
}

const useDividerStyles = (props: any): any =>
  makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(props.spacing || 3),
      marginBottom: theme.spacing(props.spacing || 3),
      height: 2,
    },
  }));

export function PageHeaderDivider(props: Record<string, unknown>) {
  let classes = useDividerStyles(props)();
  return <Divider className={classes.root} />;
}
