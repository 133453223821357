/**
 * Wrappers around the c2fo table component to make it align better with what we want
 *
 * Key differences:
 * - Headings are bold by default
 * - Columns are right-aligned by default
 *
 * When we add sorting logic, we will want to do it in this folder.
 */

import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  TableCell as BaseTableCell,
  TableCellProps,
  Theme,
  TypeBase,
  TypeTableHeader,
  makeStyles,
} from '@c2fo/react-components';
import { toCurrency } from '../../i18n/currency';
import { toDate } from '../../i18n/date';

function alignToTypographyAlign(align: 'left' | 'right' | 'center'): 'leading' | 'trailing' | 'center' {
  if (align === 'left') {
    return 'leading';
  }
  if (align === 'right') {
    return 'trailing';
  }
  return align;
}

const useHeaderCellStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
  },
}));

export type HeaderCellProps = { children: ReactNode; align?: 'left' | 'right' | 'center' } & TableCellProps;

export function HeaderCell(props: HeaderCellProps): JSX.Element {
  let classes = useHeaderCellStyles();
  let { align, className, ...rest } = props;
  if (!align) {
    align = 'right';
  }
  let typographyAlign = alignToTypographyAlign(align);
  return (
    <BaseTableCell {...rest} align={align} className={clsx(classes.root, className)}>
      <TypeTableHeader align={typographyAlign}>{props.children}</TypeTableHeader>
    </BaseTableCell>
  );
}

type BodyCellProps = { children: ReactNode; align?: 'left' | 'right' | 'center'; variant?: 'numeric' } & Omit<
  TableCellProps,
  'variant'
>;

export function BodyCell(props: BodyCellProps): JSX.Element {
  let { align, variant, ...rest } = props;
  if (!align) {
    align = 'right';
  }
  let typographyAlign = alignToTypographyAlign(align);
  return (
    <BaseTableCell {...rest} align={align}>
      <TypeBase isTabular={props.variant === 'numeric'} align={typographyAlign}>
        {props.children}
      </TypeBase>
    </BaseTableCell>
  );
}

export function DateCell(props: { children: string | Date | null } & BodyCellProps): JSX.Element {
  let { children: value, ...rest } = props;

  return (
    <BodyCell {...rest} variant={'numeric'}>
      {toDate(value)}
    </BodyCell>
  );
}

export function MoneyCell(
  props: { children: number | null; format?: (value: number) => string } & BodyCellProps,
): JSX.Element {
  let display: string;
  let { children: value, format, ...rest } = props;
  format = format ?? toCurrency;
  if (value === null) {
    display = '-';
  } else {
    display = format(value);
  }
  return (
    <BodyCell {...rest} variant={'numeric'}>
      {display}
    </BodyCell>
  );
}
