import React, { useEffect, useMemo, useState } from 'react';
import { FloatingCard, FloatingCardBody } from './FloatingCard';
import { LoadingSpinner } from '../LoadingScreen/LoadingSpinner';
import { SpinnerOptions } from 'spin.js';
import { Typography, makeStyles, useTheme } from '@c2fo/react-components';

const REMARKS = [
  'Verifying format...',
  'Checking for errors...',
  'Importing invoices...',
  'Mapping invoices to customers...',
  'Working our magic...',
];

const MAX_REMARK_IDX = REMARKS.length - 1;

const REMARK_INTERVAL_MS = 400;

const LOADING_SPINNER_HEIGHT = 250;

const useStyles = makeStyles((theme) => ({
  loadingSpinnerWrapper: {
    position: 'relative',
    height: LOADING_SPINNER_HEIGHT,
  },
}));

export function InvoiceUploadInterstitial(props: Record<string, unknown>) {
  let [remarkIdx, setRemarkIdx] = useState<number>(0);
  let theme = useTheme();
  let classes = useStyles();

  useEffect(() => {
    let interval = setInterval(() => {
      // never go past the last index
      setRemarkIdx((idx) => Math.min(idx + 1, MAX_REMARK_IDX));
    }, REMARK_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let spinnerOpts: SpinnerOptions = useMemo(
    () => ({
      color: theme.palette.secondary.main,
    }),
    [theme],
  );

  return (
    <FloatingCard>
      <FloatingCardBody>
        <div aria-label={'Processing Upload'} data-cy={'processing-upload'}>
          <div className={classes.loadingSpinnerWrapper}>
            <LoadingSpinner options={spinnerOpts} />
          </div>
          <Typography align={'center'} variant={'body2'} aria-hidden={'true'} data-testid="interstitial-remark">
            {REMARKS[remarkIdx]}
          </Typography>
        </div>
      </FloatingCardBody>
    </FloatingCard>
  );
}
