import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpService } from '../../../lib/http/HttpService';
import { Query } from '../../schemas/http.schema';
import { tpfSupplierApiConfig } from '../../../config';

export class TpfSupplierApiHttpService extends HttpService {
  /**
   * tpf-suplier-api specific query parameter serialization.
   * @param query
   */
  serializeQueryParams<T>(query: Query<T>): any {
    let serialized: any = {};
    Object.keys(query).forEach((key) => {
      /**
       * You know that scene in parks & rec
       * where Ron Swanson tells the hardware
       * store employee "I know more than you"
       *
       * That's me with Typescript right now
       *
       * Anyways with a comment like that I should probably explain
       * in detail what's about to happen here. If you open
       * up the type definition for Query<T>, you'll see
       * that it allows us to safely create queries like:
       * { invoiceId: { like: 'foo' }, status: { in: ['PENDING'] } }
       * Which means that we can confidently say that key
       * is either a key of the type we're querying, or it's
       * a special qp like sort or size.
       *
       * So basically we just need to ask "is this query param
       * an object with some attributes? Is it a special one?
       * Or is it just a regular value?", and serialize based on that.
       */
      let value = query[key as keyof T] as any;

      if (key === 'sort') {
        serialized.sort = value;
        return;
      }

      /**
       * Don't need to special case size:
       * it won't hit any of the following conditions,
       * so it'll fall through to the else case, which
       * is what we want to do with it
       */
      if (typeof value === 'object' && value !== null) {
        if ('eq' in value) {
          serialized[`${key}[EQ]`] = value.eq;
        }
        if ('in' in value) {
          serialized[`${key}[$IN]`] = value.in;
        }
        if ('like' in value) {
          serialized[`${key}[LIKE]`] = value.like;
        }
        if ('gte' in value && value.gte != null) {
          serialized[`${key}[GTE]`] = value.gte;
        }
        if ('lte' in value && value.lte != null) {
          serialized[`${key}[LTE]`] = value.lte;
        }
      } else {
        serialized[key] = value;
      }
    });
    return serialized;
  }

  async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await super.request<T>({
        ...config,
        baseURL: tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL,
      });
      if (response.status < 400 && response?.headers?.authorization) {
        const tokenSansBearer = response.headers.authorization;
        this.tokenService.setToken(tokenSansBearer);
      }

      return response;
    } catch (error: any) {
      // handle base authN/Z errors.  Right now opt in to this handling for these
      // response codes for all requests from tpf-supplier-api.
      this.onHttpErrorStatus(error, {
        401: () => this.handleUnauthenticated(),
        403: () => this.handleUnauthorized(),
      });
      throw error;
    }
  }

  /**
   * A 401 error should be handled by deleting the token used to make the request, and
   * redirecting to app.c2fo.com/login with a redirect parameter to attempt re-authN.
   * potential future: call reauth endpoint before redirect? we don't do that in supplier-app right now.
   */
  private handleUnauthenticated() {
    this.tokenService.relogin();
  }

  /**
   * A 403 error should be handled by explicitly logging the user out and sending them to the c2fo app
   * login page.
   * future: might want to handle this with 404-like messaging and a conditional "go back to early pay"
   * the only time our API should spit this out to a c2fo user is if they get pointed to a client they
   * aren't tied to.
   */
  private handleUnauthorized() {
    this.tokenService.logout();
  }

  /**
   * Explicitly refresh the user's token, resetting the timeout
   */
  async refreshToken(): Promise<void> {
    /**
     * Spooky action-at-a-distance
     *
     * We refresh the token on every api request,
     * so by virtue of making this api call, we
     * will receive the new token in the request
     * method above
     *
     * We intentionally don't return the token here.
     * It should be updated by the time this method
     * returns.
     *
     * Additionally, We don't want to send this request if there's
     * no existing token at all.  The purpose of this is only to refresh
     * when we have one, or indirectly invalidate an expired token.
     */

    if (!this.tokenService.isTokenSet()) return;
    await this.get('/v2/tpf-api/refresh-tokens/1');
  }

  /**
   * Downloads a url via opening a new page
   *
   * The url should not be prefixed with the supplier-api server.
   * returns the url string for ease of testing.
   */
  downloadUrl<T>(url: string, params: Query<T> = {}): string {
    let formatted = this.formatDownloadUrl(url, params);
    if (!(window as any).Cypress) {
      // in cypress test runs, we want to avoid real downloads.
      window.open(formatted, '_blank');
    }
    return formatted;
  }

  formatDownloadUrl<T>(url: string, params: Query<T> = {}): string {
    let token = this.tokenService.getToken();
    let serializedParams = this.serializeQueryParams(params);
    let urlWithToken = axios.getUri({
      url,
      params: {
        ...serializedParams,
        token,
      },
    });
    if (!urlWithToken.startsWith(tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL)) {
      urlWithToken = `${tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL}${urlWithToken}`;
    }
    return encodeURI(urlWithToken);
  }
}
