import React, { useState } from 'react';
import { CTAButton, Checkbox, FormControlLabel, Grid, Theme, TypeBase, makeStyles } from '@c2fo/react-components';
import { InvoiceUploadDialog, InvoiceUploadDialogContent } from './InvoiceUploadDialog';
import { ProductType } from '../../schemas/enums.schema';
import { useId } from '@reach/auto-id';
import { useProductType } from '../../contexts/ProductType';

const useStyles = makeStyles((theme: Theme) => ({
  listStyles: {
    '& li': {
      paddingBottom: theme.spacing(1),
    },
  },
}));

export function InvoiceUploadWhatsNext(props: {
  onOk: (values: { dontShowAgain: boolean }) => void;
  onClose: () => void;
}) {
  let productType = useProductType();
  let headerId = useId();
  let [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
  let classes = useStyles();

  return (
    <InvoiceUploadDialog
      open={true}
      onClose={props.onClose}
      aria-describedby={headerId}
      variant="informational"
      modalTitle="Here's what happens next"
      actionsContent={
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dontShowAgain}
                  onChange={(event) => {
                    setDontShowAgain(event.target.checked);
                  }}
                />
              }
              label={"Don't show this again"}
            />
          </Grid>
          <Grid item>
            <CTAButton
              data-cy={'whats-next-ok'}
              style={{ fontSize: '1.2rem', fontWeight: 600 }}
              onClick={() =>
                props.onOk({
                  dontShowAgain,
                })
              }
            >
              OK
            </CTAButton>
          </Grid>
        </Grid>
      }
    >
      <InvoiceUploadDialogContent padding={0}>
        <TypeBase component={'div'}>
          {productType === ProductType.Factoring && (
            <ul className={classes.listStyles}>
              <li>Your new invoices are marked as pending for review</li>
              <li>We'll reach out if we need any additional documents for verification</li>
              <li>We'll approve all eligible invoices within 2 business days</li>
              <li>Once invoices are approved we will release funds via ACH or wire transfer</li>
            </ul>
          )}
          {productType === ProductType.ReceivablesFinance && (
            <ul className={classes.listStyles}>
              <li>Your new invoices are marked as pending for review</li>
              <li>We'll reach out if we need any additional documents for verification</li>
              <li>We'll approve all eligible invoices within 2 business days</li>
              <li>
                Once invoices are approved, applicable terms and fees are applied. We'll update your account to reflect
                the amount you can request
              </li>
            </ul>
          )}
        </TypeBase>
      </InvoiceUploadDialogContent>
    </InvoiceUploadDialog>
  );
}
