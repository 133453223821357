import React, { ReactNode } from 'react';
import { TableRow } from '@c2fo/react-components';

type TableLoadingSkeletonRowProps = {
  children: ReactNode;
  rows?: number;
};

export function TableLoadingSkeletonRow(props: TableLoadingSkeletonRowProps) {
  return (
    <>
      {Array.from({ length: props.rows ?? 25 }).map((_, i) => (
        <TableRow key={i} data-testid={i === 0 ? 'table-loading-skeleton-row' : undefined}>
          {props.children}
        </TableRow>
      ))}
    </>
  );
}
