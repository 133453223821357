import React from 'react';
import { BodyCell, DateCell, MoneyCell } from '../../components/Table/TableCell';
import { FactoringInvoice, InvoiceStatus } from '../../schemas';
import { IconTooltip } from '../../components/Tooltips/IconTooltip';
import { InvoiceGrid } from '../../components/InvoicePage/InvoiceGrid';
import { LoadingState } from '../../components/Async/useAsyncQuery';
import { PageableResponse } from '../../schemas';
import { Pagination } from '../../components/Table/Pagination';
import { QuestionCircleIcon, TypeBase, makeStyles } from '@c2fo/react-components';
import { SortableColumn, Sorting } from '../../components/Table/Sorting';
import { TableLoadingSkeletonCell } from '../../components/Table/TableLoadingSkeletonCell';
import { TableLoadingSkeletonRow } from '../../components/Table/TableLoadingSkeletonRow';
import {
  TooltipFooter,
  TooltipFormula,
  TooltipFormulaDivider,
  TooltipFormulaOperation,
  TooltipFormulaTerm,
} from '../../components/Tooltips/TooltipFormula';

function EstimatedCell(props: { invoice: FactoringInvoice; value: number | null }): JSX.Element {
  let { invoice, value, ...rest } = props;
  if (invoice.status === InvoiceStatus.UNMAPPED) {
    return <BodyCell {...rest}>Unknown</BodyCell>;
  }
  value = value || 0;
  let asterisk = null;
  if (!invoice.isPurchased) {
    asterisk = (
      <TypeBase component={'span'} isError>
        *
      </TypeBase>
    );
  }

  return (
    <BodyCell {...rest} variant={'numeric'}>
      {asterisk}
      {value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}
    </BodyCell>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.primary.contrastTextSmall,
    },
  },
}));

export function FactoringInvoiceGrid(props: {
  invoices: LoadingState<PageableResponse<FactoringInvoice>>;
  sorting: Sorting;
  pagination: Pagination;
}): JSX.Element {
  const classes = useStyles();
  const tooltipIcon = <QuestionCircleIcon className={classes.icon} />;
  return (
    <InvoiceGrid
      pagination={props.pagination}
      sorting={props.sorting}
      invoices={props.invoices}
      tableSkeleton={
        <TableLoadingSkeletonRow rows={25}>
          <TableLoadingSkeletonCell width={4} transparent={true} />
          <TableLoadingSkeletonCell width={10} />
          <TableLoadingSkeletonCell width={10} align={'right'} />
          <TableLoadingSkeletonCell width={8} align={'right'} />
          <TableLoadingSkeletonCell width={8} align={'right'} />
          <TableLoadingSkeletonCell width={10} align={'right'} />
          <TableLoadingSkeletonCell width={8} align={'right'} />
          <TableLoadingSkeletonCell width={8} align={'center'} />
        </TableLoadingSkeletonRow>
      }
      columns={
        <>
          <SortableColumn sorting={props.sorting} sortField={'transactionDate'}>
            Invoice Date
          </SortableColumn>
          <SortableColumn sorting={props.sorting} sortField={'netAmount'}>
            Net Amount
          </SortableColumn>
          <SortableColumn
            sorting={props.sorting}
            sortField={'expectedDueDate'}
            endIcon={
              <IconTooltip placement="bottom" name="factoringInvoiceGrid.EstimatedRemittance" icon={tooltipIcon}>
                This is the date we expect to get paid for the invoice, taking into account invoice terms and historical
                remittance trends.
              </IconTooltip>
            }
          >
            Estimated Remittance &nbsp;
          </SortableColumn>
          <SortableColumn
            sorting={props.sorting}
            sortField={'discountFee'}
            endIcon={
              <IconTooltip placement="bottom" name="factoringInvoiceGrid.Discount" icon={tooltipIcon}>
                <TooltipFormula>
                  <TooltipFormulaTerm>Amount</TooltipFormulaTerm>
                  <TooltipFormulaTerm>(Invoice Tenor / 360)</TooltipFormulaTerm>
                  <TooltipFormulaTerm operation={TooltipFormulaOperation.Multiply}>Rate %</TooltipFormulaTerm>
                  <TooltipFormulaDivider />
                  <TooltipFormulaTerm operation={TooltipFormulaOperation.Equal}>Discount</TooltipFormulaTerm>
                </TooltipFormula>
                <TooltipFooter>*Indicates estimated discount.</TooltipFooter>
              </IconTooltip>
            }
          >
            Discount &nbsp;
          </SortableColumn>
          <SortableColumn
            sorting={props.sorting}
            sortField={'purchasePrice'}
            endIcon={
              <IconTooltip placement="bottom" name="factoringInvoiceGrid.PurchasePrice" icon={tooltipIcon}>
                <TooltipFormula>
                  <TooltipFormulaTerm>Rate %</TooltipFormulaTerm>
                  <TooltipFormulaTerm operation={TooltipFormulaOperation.Multiply}>Amount</TooltipFormulaTerm>
                  <TooltipFormulaTerm operation={TooltipFormulaOperation.Subtract}>Total Cost</TooltipFormulaTerm>
                  <TooltipFormulaDivider />
                  <TooltipFormulaTerm operation={TooltipFormulaOperation.Equal}>Purchase Price</TooltipFormulaTerm>
                </TooltipFormula>
                <TooltipFooter>*Indicates estimated purchase price.</TooltipFooter>
              </IconTooltip>
            }
          >
            Purchase Price &nbsp;
          </SortableColumn>
        </>
      }
      row={(invoice) => (
        <>
          <DateCell data-testid={'transaction-date'} data-cy={`invoiceRow-${invoice.invoiceId}-transactionDate`}>
            {invoice.transactionDate}
          </DateCell>
          <MoneyCell data-testid={'netAmount'} data-cy={`invoiceRow-${invoice.invoiceId}-netAmount`}>
            {invoice.netAmount}
          </MoneyCell>
          <DateCell data-testid={'expected-due-date'} data-cy={`invoiceRow-${invoice.invoiceId}-expectedDueDate`}>
            {invoice.expectedDueDate}
          </DateCell>
          <EstimatedCell
            data-testid={'discount-fee'}
            data-cy={`invoiceRow-${invoice.invoiceId}-discountFee`}
            invoice={invoice}
            value={invoice.discountFee}
          />
          <EstimatedCell
            data-testid={'purchase-price'}
            data-cy={`invoiceRow-${invoice.invoiceId}-purchasePrice`}
            invoice={invoice}
            value={invoice.purchasePrice}
          />
        </>
      )}
    />
  );
}
