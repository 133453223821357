import React from 'react';
import { ARPSummaryClosedAccountCard } from './ARPSummaryClosedAccountCard';
import { ARPSummaryRequestCard } from './ARPSummaryRequestCard';
import { ArpBalance } from '../../schemas';
import { ClientInvoiceUploadSummary } from '../../schemas/invoiceUpload.schema';
import { CustomerPayment } from '../../schemas/customerPayment.schema';
import { UnsettledBalanceReport } from '../../schemas/unsettledBalanceReport.schema';

interface ARPSummaryCardProps {
  balance: ArpBalance;
  lastCustomerPayment: CustomerPayment | null;
  lastUpload: ClientInvoiceUploadSummary | null;
  unsettledReport: UnsettledBalanceReport | null;
}

export const ARPSummaryCard: React.FC<ARPSummaryCardProps> = (props) => {
  if (props.balance.isClosed) {
    return <ARPSummaryClosedAccountCard balance={props.balance} lastUpload={props.lastUpload} />;
  }
  return (
    <ARPSummaryRequestCard
      balance={props.balance}
      lastCustomerPayment={props.lastCustomerPayment}
      unsettledReport={props.unsettledReport}
    />
  );
};
