import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Box, Theme, makeStyles } from '@c2fo/react-components';
import { useIsMobile } from '../../hooks/useIsMobile';

const usePresentationStyles = makeStyles<Theme, { padding: number }>((theme) => ({
  root: {
    padding: '2rem 2.5rem',
    [theme.breakpoints.up('md')]: {
      padding: (props) => theme.spacing(props.padding),
    },
  },
  inverse: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
  },
  notInverse: /* unverse? verse? */ {
    backgroundColor: theme.palette.background.paper,
  },
  mobile: {
    boxShadow: 'none',
    /**
     * Philosophically speaking, it's debatable where these go
     * Practically speaking, they need to be here or else there's bugs.
     */
    width: '110%',
    marginLeft: '-15px',
    marginRight: '-15px',
  },
  desktop: {
    boxShadow: '0 8px 17px 2px rgba(0,0,0,0.14)',
    marginLeft: 0,
    marginRight: 0,
  },
}));

/**
 * There are a few components (notably RF pending request) that have complex layout requirements,
 * and so they require a separation between the presentation layer of the header and the
 * layout layer.
 */
export function SummaryCardHeaderLayout(props: { children: ReactNode }) {
  let isMobile = useIsMobile();
  return (
    <Box
      position={'relative'}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {props.children}
    </Box>
  );
}

export function SummaryCardHeaderPresentation(props: { children: ReactNode; isInverse?: boolean; padding?: number }) {
  let isInverse = props.isInverse ?? true;
  let classes = usePresentationStyles({
    padding: props.padding ?? 2,
  });
  let isMobile = useIsMobile();
  return (
    <div
      className={clsx(
        classes.root,
        isInverse ? classes.inverse : classes.notInverse,
        isMobile ? classes.mobile : classes.desktop,
      )}
    >
      {props.children}
    </div>
  );
}

export function SummaryCardHeader(props: { children: ReactNode; padding?: number; isInverse?: boolean }) {
  return (
    <SummaryCardHeaderPresentation isInverse={props.isInverse} padding={props.padding}>
      <SummaryCardHeaderLayout>{props.children}</SummaryCardHeaderLayout>
    </SummaryCardHeaderPresentation>
  );
}

export function SummaryCardHeaderSection(props: { children: ReactNode }) {
  /**
   * An empty div, for flexbox reasons
   */
  return <div>{props.children}</div>;
}
