import { Client, PageableResponse } from '../../schemas';
import { ServiceCache } from '../../../lib/cache/ServiceCache';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const clientBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/clients`;
const clientsForUserPath = `${apiUrl}/v2/tpf-api/user/clients`;

/**
 * Service for interacting with client API endpoints.
 */
export class ClientService {
  private cache: ServiceCache<Client>;

  private allClientsCache: ServiceCache<Client[]>;

  constructor(private httpService: TpfSupplierApiHttpService) {
    this.httpService = httpService;
    this.cache = new ServiceCache();
    this.allClientsCache = new ServiceCache();
  }

  /**
   * get a client's data.
   * @param divisionUuid
   */
  async getClient(divisionUuid: string): Promise<Client> {
    return this.cache.get(divisionUuid, async () => {
      let response = await this.httpService.get<Client>(clientBasePath(divisionUuid));
      if (!response.data) {
        throw new Error(`Couldn't find client with division ${divisionUuid}`);
      }
      return response.data;
    });
  }

  /**
   * If any valid client exists for the user, return it.
   * Note: as in API doc, this does some imperfect sorting to return only one client, and this
   * method is only to be used to determine clients in an expected end-user scenario where there
   * is only one client for a user, when they hit our base domain.
   *
   * Until we have a true "all clients" page and don't use c2fo markets for that purpose, we need
   * to guess which client they use when they hit our root domain path and try to redirect.
   */
  async getClientForUser(): Promise<Client[]> {
    return this.allClientsCache.get('', async () => {
      let response = await this.httpService.get<PageableResponse<Client>>(clientsForUserPath);
      return response.data.data;
    });
  }
}
