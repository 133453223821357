import React, { useEffect, useState } from 'react';
import { BuildInvoiceReportDialog } from '../InvoiceReports/BuildInvoiceReportDialog';
import { CTAButton, PrimaryButton, Theme, TypeHelper, makeStyles } from '@c2fo/react-components';
import {
  Client,
  FactoringInvoice,
  Invoice,
  InvoiceSupplierStatus,
  MonthEndReportType,
  PageableResponse,
  ProductType,
  Query,
  Report,
  ReportDisplayField,
  ReportSaveAction,
} from '../../schemas';
import { InvoiceDownloadButton } from './InvoiceDownloadButton';
import { InvoiceUpload } from '../InvoiceUpload/InvoiceUpload';
import { PageHeader, PageHeaderDivider } from '../PageWrapper/PageHeader';
import { PageTitle } from '../PageWrapper/PageTitle';
import { toCurrency } from '../../i18n/currency';
import { useAsyncFn } from 'react-use';
import { useBooleanQueryParam } from '../QueryParams/useQueryParam';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useProductType } from '../../contexts/ProductType';
import { useServices } from '../../services';

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& button': {
        marginTop: theme.spacing(1),
      },
    },
  },
  spacer: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  invoiceUpload: {
    marginTop: '3rem',
    marginBottom: '4.5rem',
  },
}));

type HeaderProps = {
  reports?: PageableResponse<Report<FactoringInvoice>>;
  client: Client;
  onReportsRefetch: () => Promise<PageableResponse<Report<FactoringInvoice>>>;
  reportColumns?: ReportDisplayField<FactoringInvoice>[] | null;
  statusOptions: InvoiceSupplierStatus[];
  currentQueryParams: Query<Invoice | FactoringInvoice>;
  onInvoiceUploadComplete?: () => void;
  isReadOnlyUser: boolean;
};

export function InvoicePageHeader(props: HeaderProps): JSX.Element {
  const classes = useStyles();
  const {
    reportService,
    invoiceUploadService,
    invoiceService,
    monthEndFactoringReportService,
    monthEndRFReportService,
  } = useServices();
  const divisionUuid = useCurrentDivision();
  const productType = useProductType();
  const isMobile = useIsMobile();
  const [showUpload, setShowUpload] = useBooleanQueryParam('showUpload');
  const [showReportBuilder, setShowReportBuilder] = useState<boolean>(false);
  const [reportToEdit, setReportToEdit] = useState<Report<FactoringInvoice> | null>(null);
  const [reportToClone, setReportToClone] = useState<Report<FactoringInvoice> | null>(null);

  const [cypressDownloadUrl, setCypressDownloadUrl] = useState<string | null>(null);

  const handleEditReport = async (uuid: string) => {
    let report = await reportService.getReport(divisionUuid, uuid);
    setReportToEdit(report);
    setShowReportBuilder(true);
  };

  const handleCloneReport = async (uuid: string) => {
    let report = await reportService.getReport(divisionUuid, uuid);
    setReportToClone(report);
    setShowReportBuilder(true);
  };

  const handleCloseReportDialog = () => {
    setReportToEdit(null);
    setReportToClone(null);
    setShowReportBuilder(false);
  };

  const handleSubmitCustomReport = async (payload: Report<FactoringInvoice>, saveAction: ReportSaveAction) => {
    const downloadUrl = await reportService.upsertAndDownloadReport(divisionUuid, payload, saveAction);
    setCypressDownloadUrl(downloadUrl);
    props.onReportsRefetch();
    handleCloseReportDialog();
  };

  // download: query parameters coming from current query.
  const handleDownloadReport = (divUuid: string, product: ProductType) => {
    const downloadUrl = invoiceService.downloadInvoicesFromQuery(divUuid, product, props.currentQueryParams);
    setCypressDownloadUrl(downloadUrl);
  };

  const handleDownloadMonthEndReport = (divUuid: string, product: ProductType, reportType: MonthEndReportType) => {
    let downloadUrl = '';
    switch (reportType) {
      case MonthEndReportType.Fees:
        downloadUrl = monthEndFactoringReportService.downloadFeesReport(divUuid, {
          clientUuid: props.client.uuid,
          reportType: MonthEndReportType.Fees,
        });
        break;
      case MonthEndReportType.Invoices:
        downloadUrl = monthEndFactoringReportService.downloadInvoicesReport(divUuid, {
          clientUuid: props.client.uuid,
          reportType: MonthEndReportType.Invoices,
        });
        break;
      case MonthEndReportType.Summary:
        downloadUrl = monthEndFactoringReportService.downloadSummaryReport(divUuid, {
          clientUuid: props.client.uuid,
          reportType: MonthEndReportType.Summary,
        });
        break;
      default:
        if (productType === ProductType.Factoring) {
          downloadUrl = monthEndFactoringReportService.downloadStatement(divUuid, {
            clientUuid: props.client.uuid,
            reportType: MonthEndReportType.Statement,
          });
        } else {
          downloadUrl = monthEndRFReportService.downloadStatement(divUuid, {
            clientUuid: props.client.uuid,
            reportType: MonthEndReportType.Statement,
          });
        }
    }
    setCypressDownloadUrl(downloadUrl);
  };

  // download a report by uuid
  const handleFetchAndDownloadReport = async (divUuid: string, reportUuid: string) => {
    const downloadUrl = await reportService.fetchAndDownloadReport(divUuid, reportUuid);
    setCypressDownloadUrl(downloadUrl);
  };

  const [draftInvoiceTotalsAsync, refetchDraftTotals] = useAsyncFn(
    async () => invoiceUploadService.getFactoringUploadDraftTotals(divisionUuid),
    [divisionUuid],
  );

  useEffect(() => {
    if (productType === ProductType.Factoring) {
      refetchDraftTotals();
    }
  }, [refetchDraftTotals, productType]);

  const draftInvoicesCount = draftInvoiceTotalsAsync.value?.totalCount ?? 0;
  const shouldWaitForFactoringTotals = productType === ProductType.Factoring;

  /**
   * If the client is closed, then we should never allow them to upload invoices
   */
  let shouldShowUploadCard = showUpload && !props.client.isClosed;
  /**
   * The only section of upload we want to always show on mobile is draft review button and
   * modal.  This tracks when we're in that unique state and want to show upload flows on
   * mobile.
   */
  let isFactoringDraftReview = false;
  if (shouldWaitForFactoringTotals) {
    shouldShowUploadCard = shouldShowUploadCard && draftInvoiceTotalsAsync.loading === false;
  }
  if (shouldWaitForFactoringTotals && draftInvoicesCount) {
    isFactoringDraftReview = draftInvoicesCount > 0;
  }

  return (
    <PageHeader>
      <div className={classes.pageHeader} data-testid={'invoices-page'}>
        <PageTitle>Invoices</PageTitle>
        {(!isMobile || isFactoringDraftReview) && (
          <div className={classes.buttonGroup}>
            {isFactoringDraftReview && (
              <TypeHelper>
                <strong className={classes.spacer}>
                  {toCurrency(draftInvoiceTotalsAsync.value?.totalPurchasePrice ?? null)} in uploaded invoices need
                  review
                </strong>
              </TypeHelper>
            )}
            <div>
              {!isMobile && (
                <InvoiceDownloadButton
                  reports={props.reports}
                  onBuildReportClick={() => setShowReportBuilder(true)}
                  onEditReportClick={(uuid: string) => handleEditReport(uuid)}
                  onCloneReportClick={(uuid: string) => handleCloneReport(uuid)}
                  onDownloadInvoices={(divUuid, product) => handleDownloadReport(divUuid, product)}
                  onDownloadMonthEndReports={(divUuid: string, reportType: MonthEndReportType) =>
                    handleDownloadMonthEndReport(divUuid, productType, reportType)
                  }
                  onFetchAndDownloadInvoices={(divUuid, repUuid) => handleFetchAndDownloadReport(divUuid, repUuid)}
                  onReportsRefetch={props.onReportsRefetch}
                />
              )}
              {isFactoringDraftReview ? (
                <PrimaryButton
                  id={'uploadInvoicesBtn'}
                  className={classes.spacer}
                  size="small"
                  disabled={showUpload === true || props.client.isClosed || props.isReadOnlyUser}
                  onClick={() => {
                    setShowUpload(true);
                  }}
                >
                  Review Invoices
                </PrimaryButton>
              ) : (
                <CTAButton
                  id={'uploadInvoicesBtn'}
                  className={classes.spacer}
                  size="medium"
                  disabled={showUpload === true || props.client.isClosed || props.isReadOnlyUser}
                  onClick={() => {
                    setShowUpload(true);
                  }}
                >
                  Upload Invoices
                </CTAButton>
              )}
            </div>
          </div>
        )}
      </div>
      {shouldShowUploadCard && (!isMobile || isFactoringDraftReview) && (
        <div className={classes.invoiceUpload}>
          <InvoiceUpload
            client={props.client}
            factoringDraftInvoiceTotals={draftInvoiceTotalsAsync.value ?? null}
            onClose={async () => {
              setShowUpload(false);
              if (productType === ProductType.Factoring) {
                await refetchDraftTotals();
              }
            }}
            onComplete={async () => {
              setShowUpload(false);
              if (productType === ProductType.Factoring) {
                await refetchDraftTotals();
              }
              if (props.onInvoiceUploadComplete) {
                return props.onInvoiceUploadComplete();
              }
            }}
          />
        </div>
      )}
      {showReportBuilder && props.reportColumns && (
        <BuildInvoiceReportDialog
          onClose={() => handleCloseReportDialog()}
          onSubmit={async (payload, saveAction) => handleSubmitCustomReport(payload, saveAction)}
          reportColumns={props.reportColumns}
          reportToEdit={reportToEdit}
          reportToClone={reportToClone}
          statusOptions={props.statusOptions}
        />
      )}
      {cypressDownloadUrl && <a hidden href={cypressDownloadUrl} data-cy="downloadUrl"></a>}
      <PageHeaderDivider />
    </PageHeader>
  );
}
