import { datadogRum } from '@datadog/browser-rum';

import { TokenService } from '../token';

export class DataDogRumService {
  constructor(private tokenService: TokenService) {}

  setUser(): unknown {
    let user;
    const tokenPayload = this.tokenService.getTokenContent()?.payload;

    if (!tokenPayload || tokenPayload === undefined) {
      return;
    }

    user = tokenPayload.user;
    if (!user) {
      return;
    }

    datadogRum.addRumGlobalContext('isC2FO', user.emailAddress.includes('@c2fo.com'));
    datadogRum.setUser({
      id: user.uuid,
    });
  }
}
