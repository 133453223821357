import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';

import { Client, OnboardingStatus, ProductType } from '../../schemas';
import { useServices } from '../../services';

import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { ProductTypeProvider } from '../../contexts/ProductType';

type Props = {
  divisionUuid: string;
  isExact: boolean;
  children: (client: Client) => ReactNode;
};

function isConfiguringBankAccount(status: OnboardingStatus): boolean {
  return status.isApprovedAndConfigured && !status.isBankAccountComplete && status.isBankAccountRequired;
}

/**
 * Parent page/route to display loading state and perform routing logic to the appropriate
 * base page/route for a given division, if exists.
 * Additionally, this component handles redirects to the ember app, if necessary depending on desired
 * route (or more likely, they are in onboarding phase).
 * @param props
 */
export function DivisionBasePage({ divisionUuid, isExact, children }: Props): JSX.Element {
  const { clientService, onboardingService } = useServices();

  const client = useAsync(async () => clientService.getClient(divisionUuid), [divisionUuid]);
  const onboardingStatus = useAsync(async () => onboardingService.getOnboardingStatus(divisionUuid), [divisionUuid]);

  if (client.error) {
    throw client.error;
  }
  if (onboardingStatus.error) {
    throw onboardingStatus.error;
  }

  let redirect;
  if (client.value && onboardingStatus.value && isExact) {
    // special case : the only time we hit here and `home` is not appropriate is if they're
    // in the process of configuring bank account, which lives in this app.
    if (isConfiguringBankAccount(onboardingStatus.value)) {
      redirect = <Redirect to={`/${divisionUuid}/setup/bank-account`} />;
    } else {
      // no, eslint, this else is meaningful.
      // eslint-disable-next-line no-lonely-if
      if (client.value.productType === ProductType.ReceivablesFinance) {
        redirect = <Redirect to={`/${divisionUuid}/receivable-finance/home`} />;
      } else if (client.value.productType === ProductType.Factoring) {
        redirect = <Redirect to={`/${divisionUuid}/ar-purchase/home`} />;
      }
    }
  }

  if (onboardingStatus.value && !onboardingStatus.value.isApprovedAndConfigured) {
    redirect = <Redirect to={`/onboarding`} />;
  }

  return client.loading || onboardingStatus.loading ? (
    <LoadingScreen />
  ) : (
    redirect || (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <ProductTypeProvider productType={client.value!.productType}>{children(client.value!)}</ProductTypeProvider>
    )
  );
}
