import React, { Dispatch } from 'react';
import {
  Advance,
  AdvanceRequestExceedsAvailabilityError,
  ProductType,
  RequestStatus,
  TransferMethodPreference,
} from '../../schemas';
import { AdvanceService } from '../../services/AdvanceService/AdvanceService';
import { AxiosError } from 'axios';
import { ClientBalanceService } from '../../services/ClientBalanceService/ClientBalanceService';
import { ClientService } from '../../services/ClientService/ClientService';
import { InvoiceUploadService } from '../../services/InvoiceUploadService/InvoiceUploadService';
import { RFHomeActionTypes, RFHomeActions } from './reducers';
import { toCurrency } from '../../i18n/currency';

function isAxiosError(error: Error): error is AxiosError {
  return (error as any).isAxiosError;
}

function isExceedsAvailabilityError(error: unknown): error is AdvanceRequestExceedsAvailabilityError {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return typeof error === 'object' && Boolean(error) && 'code' in error! && (error as any).code === 'TPF:10001';
}

function AdvanceRequestErrorMessage(error: unknown): string {
  if (!(error instanceof Error)) {
    return 'Unknown error occurred when requesting advance. Contact your account manager.';
  }
  if (isAxiosError(error) && isExceedsAvailabilityError(error.response?.data)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const errorResponse = error.response!.data.params;
    return `Request exceeds availability. You have ${toCurrency(
      errorResponse.advanceAvailability,
    )} available, but requested ${toCurrency(errorResponse.requestedAmount)}`;
  }
  return `Error while requesting advance: ${error.message}`;
}

export class RFHomeDispatcherService {
  constructor(
    private clientService: ClientService,
    private clientBalanceService: ClientBalanceService,
    private advanceService: AdvanceService,
    private invoiceUploadService: InvoiceUploadService,
    private dispatch: Dispatch<RFHomeActions>,
  ) {}

  async fetchState(divisionUuid: string): Promise<void> {
    this.dispatch({
      type: RFHomeActionTypes.FetchRequest,
      payload: null,
    });

    try {
      const client = await this.clientService.getClient(divisionUuid);
      const balance = await this.clientBalanceService.getBalance(divisionUuid);

      if (!client || !balance) {
        this.dispatch({
          type: RFHomeActionTypes.ShowNotification,
          payload: {
            severity: 'error',
            message: 'We were unable to load client data.  If this problem persists please contact support.',
          },
        });
        return;
      }

      const advance = await this.advanceService.getAdvance(divisionUuid);
      const upload = await this.invoiceUploadService.getLatestUpload(divisionUuid, ProductType.ReceivablesFinance);

      this.dispatch({
        type: RFHomeActionTypes.FetchSuccess,
        payload: {
          client,
          advance,
          upload,
          balance,
        },
      });
    } catch (e) {
      this.dispatch({
        type: RFHomeActionTypes.ShowNotification,
        payload: {
          severity: 'error',
          message: 'We were unable to load client data.  If this problem persists please contact support.',
        },
      });
    }
  }

  async submitAdvanceRequest(
    divisionUuid: string,
    advanceAmount: number,
    transactionType: TransferMethodPreference,
  ): Promise<void> {
    this.dispatch({
      type: RFHomeActionTypes.SubmitAdvanceRequest,
      payload: null,
    });
    try {
      const updatedAdvance = await this.advanceService.submitRequest({
        uuid: null,
        divisionUuid,
        advanceAmount,
        transferMethod: transactionType,
        status: RequestStatus.Pending,
      });

      this.dispatch({
        type: RFHomeActionTypes.SubmitAdvanceSuccess,
        payload: {
          advance: updatedAdvance,
        },
      });
    } catch (err) {
      this.dispatch({
        type: RFHomeActionTypes.ShowNotification,
        payload: {
          severity: 'error',
          message: AdvanceRequestErrorMessage(err),
        },
      });
    }
  }

  async cancelAdvanceRequest(advance: Advance, divisionUuid: string): Promise<void> {
    this.dispatch({
      type: RFHomeActionTypes.CancelAdvanceRequest,
      payload: null,
    });

    try {
      await this.advanceService.cancelRequest({
        uuid: advance.uuid,
        divisionUuid,
        advanceAmount: advance.advanceAmount,
        transferMethod: advance.transferMethod,
        status: RequestStatus.Canceled,
      });
      this.dispatch({
        type: RFHomeActionTypes.CancelAdvanceSuccess,
        payload: null,
      });
    } catch (e) {
      // AxiosError.response is now a generic and throws an object of type unknown TS error if you reference anything
      // within the response without declaring the type. The only thing we care about is the code, so defining a simple
      // type with code: string here.
      type tpfErrorCode = {
        code: string;
      };

      const axiosErr = e as AxiosError<tpfErrorCode>;
      if (axiosErr.response?.data?.code === 'TPF:10002') {
        this.dispatch({
          type: RFHomeActionTypes.ShowNotification,
          payload: {
            severity: 'info',
            message: 'The advance cannot be cancelled because it is no longer in the pending status.',
          },
        });
        await this.fetchState(divisionUuid);
      } else {
        this.dispatch({
          type: RFHomeActionTypes.ShowNotification,
          payload: {
            severity: 'error',
            message: 'The request could not be cancelled.  If this problem persists please contact support.',
          },
        });
      }
    }
  }
}
