import { BaseDataService } from '../BaseDataService/BaseDataService';
import { CustomerPaymentAllocation } from '../../schemas/customerPayment.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const customerPaymentBasePath = (divisionUuid: string) =>
  `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/customer-payment-allocations`;

/**
 * Service for interacting with customer payment API endpoints.
 */
export class CustomerPaymentAllocationService extends BaseDataService<CustomerPaymentAllocation> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, customerPaymentBasePath);
  }

  downloadForReceiptUrl(divisionUuid: string, remittanceUuid: string): string {
    return this.httpService.formatDownloadUrl(customerPaymentBasePath(divisionUuid) + '/download', {
      remittanceUuid,
      sort: ['dueDate,ASC'],
      allocationType: {
        in: ['ELIGIBLE', 'INELIGIBLE'],
      },
    });
  }

  /**
   * downloads details customer payment info based on
   * given divisionUuid and selected payments
   * @param divisionUuid
   * @param selectedPayments
   */

  downloadDetailsCustomerPaymentFromQuery(divisionUuid: string, selectedPayments: string[]) {
    return this.httpService.downloadUrl<CustomerPaymentAllocation>(
      customerPaymentBasePath(divisionUuid) + '/download',
      {
        remittanceUuid: { in: selectedPayments },
      },
    );
  }

  getDownloadDetailsCustomerPaymentFromQueryUrl(divisionUuid: string, selectedPayments: string[]) {
    return this.httpService.formatDownloadUrl<CustomerPaymentAllocation>(
      customerPaymentBasePath(divisionUuid) + '/download',
      {
        remittanceUuid: { in: selectedPayments },
      },
    );
  }
}
