import React from 'react';

interface CustomCloudUploadIconProps {
  size?: string;
}

interface ImageSize {
  height: string;
  width: string;
  viewBox: string;
}

function imageSize(size?: string): ImageSize {
  if (size && size === 'sm') {
    return {
      height: '60',
      width: `74`,
      viewBox: '0 0 74 60',
    };
  }

  return {
    height: '144',
    width: `144`,
    viewBox: '0 12 74 60',
  };
}

export function CustomCloudUploadIcon(props: CustomCloudUploadIconProps): JSX.Element {
  const size = imageSize(props.size);
  return (
    <svg width={size.width} height={size.height} viewBox={size.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3074_57231)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4678 38.1781C51.4678 37.7643 51.8033 37.4288 52.2171 37.4288H52.7167C53.1306 37.4288 53.4661 37.7643 53.4661 38.1781C53.4661 38.592 53.1306 38.9275 52.7167 38.9275H52.2171C51.8033 38.9275 51.4678 38.592 51.4678 38.1781Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.1202 21.7501C55.1267 21.3363 55.4674 21.0061 55.8812 21.0125C60.7609 21.0889 64.6971 25.0686 64.6971 29.97C64.6971 34.435 61.4421 38.131 57.1726 38.8181C56.764 38.8838 56.3794 38.6059 56.3136 38.1973C56.2479 37.7887 56.5258 37.4041 56.9344 37.3384C60.4884 36.7665 63.1984 33.6882 63.1984 29.97C63.1984 25.8889 59.9206 22.5747 55.8578 22.5111C55.444 22.5046 55.1137 22.1639 55.1202 21.7501Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.9813 21.3106C53.8704 21.0983 53.6599 20.9428 53.4042 20.9127C52.9932 20.8643 52.6207 21.1583 52.5724 21.5694C52.5567 21.7026 52.5336 21.8296 52.5048 21.9876C52.5031 21.9974 52.5012 22.0074 52.4994 22.0175C52.4518 22.2792 52.5466 22.5465 52.7484 22.7199C52.9501 22.8933 53.2287 22.9467 53.4803 22.8602C53.614 22.8142 53.7521 22.7751 53.898 22.7386C54.2996 22.6382 54.5437 22.2314 54.4433 21.8299C54.3814 21.5823 54.203 21.3946 53.9813 21.3106Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.692 12.3398C42.7679 11.881 43.9469 11.6303 45.1832 11.6303C48.9737 11.6303 52.2165 13.9868 53.5244 17.3164C53.6757 17.7016 53.486 18.1365 53.1008 18.2878C52.7156 18.4391 52.2807 18.2495 52.1294 17.8643C51.0392 15.089 48.337 13.1291 45.1832 13.1291C44.1515 13.1291 43.1723 13.3379 42.2798 13.7185C41.8991 13.8808 41.4589 13.7038 41.2966 13.3231C41.1342 12.9424 41.3113 12.5021 41.692 12.3398Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1079 13.3204C39.9066 13.1616 39.629 13.1103 39.3726 13.2095C38.9867 13.359 38.7949 13.7929 38.9443 14.1789C39.005 14.3358 39.0598 14.4775 39.1093 14.6216C39.1897 14.8556 39.3805 15.0348 39.619 15.1005C39.8576 15.1662 40.1132 15.1099 40.302 14.9501C40.4234 14.8474 40.5442 14.7498 40.6646 14.6572C40.9926 14.4049 41.054 13.9344 40.8016 13.6064C40.6323 13.3862 40.3648 13.2862 40.1079 13.3204Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2725 18.2398C16.2725 11.3463 21.8586 5.76019 28.7521 5.76019C32.3938 5.76019 35.6637 7.32433 37.9423 9.81002C38.2219 10.1151 38.2013 10.5891 37.8962 10.8688C37.5911 11.1485 37.1171 11.1278 36.8374 10.8228C34.8296 8.63236 31.9546 7.25894 28.7521 7.25894C22.6863 7.25894 17.7712 12.1741 17.7712 18.2398C17.7712 18.5469 17.7852 18.8519 17.8089 19.1556C17.8411 19.5682 17.5328 19.9288 17.1202 19.9611C16.7076 19.9933 16.3469 19.685 16.3147 19.2723C16.2885 18.9366 16.2725 18.5921 16.2725 18.2398Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2284 20.5909C17.6269 20.4788 18.0407 20.7109 18.1527 21.1094C18.1982 21.2709 18.2403 21.4204 18.2865 21.5683C18.354 21.7845 18.3202 22.0196 18.1945 22.2079C18.0687 22.3963 17.8646 22.5176 17.6391 22.5381L17.631 22.5389C17.4629 22.5542 17.3215 22.567 17.1923 22.5872C16.7834 22.6511 16.4001 22.3714 16.3362 21.9625C16.2878 21.6527 16.4367 21.3576 16.6895 21.2049C16.7307 20.9209 16.9343 20.6736 17.2284 20.5909Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4133 23.2616C13.6832 23.5753 13.6476 24.0485 13.3338 24.3183C11.7443 25.6853 10.7419 27.7089 10.7419 29.97C10.7419 34.0926 14.0836 37.4338 18.2007 37.4338H18.8751C19.289 37.4338 19.6245 37.7693 19.6245 38.1831C19.6245 38.597 19.289 38.9325 18.8751 38.9325H18.2007C13.2553 38.9325 9.24316 34.9198 9.24316 29.97C9.24316 27.2553 10.449 24.8225 12.3565 23.182C12.6703 22.9122 13.1435 22.9478 13.4133 23.2616Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4736 38.1781C20.4736 37.7643 20.8091 37.4288 21.223 37.4288H21.7226C22.1365 37.4288 22.472 37.7643 22.472 38.1781C22.472 38.592 22.1365 38.9275 21.7226 38.9275H21.223C20.8091 38.9275 20.4736 38.592 20.4736 38.1781Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.5684 33.487C38.5684 33.0732 38.9039 32.7377 39.3177 32.7377H48.6999C49.7632 32.7377 50.6233 33.5977 50.6233 34.6611V49.9084C50.6233 50.3222 50.2878 50.6577 49.8739 50.6577C49.4601 50.6577 49.1246 50.3222 49.1246 49.9084V34.6611C49.1246 34.4255 48.9355 34.2364 48.6999 34.2364H39.3177C38.9039 34.2364 38.5684 33.9009 38.5684 33.487Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2398 34.2364C25.0042 34.2364 24.8152 34.4255 24.8152 34.6611V49.9084C24.8152 50.3222 24.4796 50.6577 24.0658 50.6577C23.6519 50.6577 23.3164 50.3222 23.3164 49.9084V34.6611C23.3164 33.5977 24.1765 32.7377 25.2398 32.7377H34.622C35.0358 32.7377 35.3714 33.0732 35.3714 33.487C35.3714 33.9009 35.0358 34.2364 34.622 34.2364H25.2398Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7998 49.9084C19.7998 49.4945 20.1353 49.159 20.5492 49.159H53.3918C53.8057 49.159 54.1412 49.4945 54.1412 49.9084V51.0824C54.1412 51.1987 54.1141 51.3135 54.0621 51.4175L52.888 53.7656C52.7611 54.0194 52.5016 54.1798 52.2178 54.1798H21.7232C21.4394 54.1798 21.1799 54.0194 21.0529 53.7656L19.8789 51.4175C19.8269 51.3135 19.7998 51.1987 19.7998 51.0824V49.9084ZM21.2986 50.6577V50.9055L22.1863 52.6811H51.7546L52.6424 50.9055V50.6577H21.2986Z"
          fill="#2C324F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9701 23.3555C37.3839 23.3555 37.7195 23.691 37.7195 24.1049V25.4083C37.7195 25.8222 37.3839 26.1577 36.9701 26.1577C36.5562 26.1577 36.2207 25.8222 36.2207 25.4083V24.1049C36.2207 23.691 36.5562 23.3555 36.9701 23.3555ZM36.9701 28.3086C37.3839 28.3086 37.7195 28.6441 37.7195 29.0579V31.6648C37.7195 32.0786 37.3839 32.4142 36.9701 32.4142C36.5562 32.4142 36.2207 32.0786 36.2207 31.6648V29.0579C36.2207 28.6441 36.5562 28.3086 36.9701 28.3086ZM36.9701 34.565C37.3839 34.565 37.7195 34.9005 37.7195 35.3144V37.9212C37.7195 38.3351 37.3839 38.6706 36.9701 38.6706C36.5562 38.6706 36.2207 38.3351 36.2207 37.9212V35.3144C36.2207 34.9005 36.5562 34.565 36.9701 34.565ZM36.9701 40.8215C37.3839 40.8215 37.7195 41.157 37.7195 41.5708V42.8743C37.7195 43.2881 37.3839 43.6236 36.9701 43.6236C36.5562 43.6236 36.2207 43.2881 36.2207 42.8743V41.5708C36.2207 41.157 36.5562 40.8215 36.9701 40.8215Z"
          fill="#06A76C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9696 23.3555C37.1683 23.3555 37.3589 23.4345 37.4995 23.575L41.0165 27.0921C41.3092 27.3847 41.3092 27.8592 41.0165 28.1519C40.7239 28.4445 40.2494 28.4445 39.9567 28.1519L36.9696 25.1647L33.9824 28.1519C33.6897 28.4445 33.2153 28.4445 32.9226 28.1519C32.63 27.8592 32.63 27.3847 32.9226 27.0921L36.4397 23.575C36.5802 23.4345 36.7708 23.3555 36.9696 23.3555Z"
          fill="#06A76C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3074_57231">
          <rect width="74" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MoreHorizontalIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="#103f76"
      className="bi bi-three-dots"
      viewBox="0 0 16 16"
    >
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  );
}

export function InverseQuestionIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 20 20">
      <path
        d="M10 19.5C15.2473 19.5 19.5 15.2473 19.5 10C19.5 4.75273 15.2473 0.5 10 0.5C4.75273 0.5 0.5 4.75273 0.5 10C0.5 15.2473 4.75273 19.5 10 19.5ZM6.80117 6.63418C7.09434 5.80664 7.88105 5.25 8.76055 5.25H10.924C12.2191 5.25 13.2656 6.3002 13.2656 7.5916C13.2656 8.43027 12.8166 9.20586 12.0893 9.6252L10.8906 10.3117C10.8832 10.7941 10.4861 11.1875 10 11.1875C9.50645 11.1875 9.10938 10.7904 9.10938 10.2969V9.7959C9.10938 9.47676 9.28008 9.18359 9.5584 9.02402L11.2023 8.08145C11.3768 7.98125 11.4844 7.7957 11.4844 7.59531C11.4844 7.28359 11.232 7.03496 10.924 7.03496H8.76055C8.63438 7.03496 8.52305 7.11289 8.48223 7.23164L8.46738 7.27617C8.3041 7.74004 7.79199 7.98125 7.33184 7.81797C6.87168 7.65469 6.62676 7.14258 6.79004 6.68242L6.80488 6.63789L6.80117 6.63418ZM11.1875 13.5625C11.1875 14.2193 10.6568 14.75 10 14.75C9.34316 14.75 8.8125 14.2193 8.8125 13.5625C8.8125 12.9057 9.34316 12.375 10 12.375C10.6568 12.375 11.1875 12.9057 11.1875 13.5625Z"
        fill="#2C324F"
      />
    </svg>
  );
}

export function InverseExclamationIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 19.5C15.2473 19.5 19.5 15.2473 19.5 10C19.5 4.75273 15.2473 0.5 10 0.5C4.75273 0.5 0.5 4.75273 0.5 10C0.5 15.2473 4.75273 19.5 10 19.5ZM10 5.25C10.4936 5.25 10.8906 5.64707 10.8906 6.14062V10.2969C10.8906 10.7904 10.4936 11.1875 10 11.1875C9.50644 11.1875 9.10938 10.7904 9.10938 10.2969V6.14062C9.10938 5.64707 9.50644 5.25 10 5.25ZM11.1875 13.5625C11.1875 14.2193 10.6568 14.75 10 14.75C9.34316 14.75 8.8125 14.2193 8.8125 13.5625C8.8125 12.9057 9.34316 12.375 10 12.375C10.6568 12.375 11.1875 12.9057 11.1875 13.5625Z"
        fill="#2C324F"
      />
    </svg>
  );
}
