import {
  Filter,
  FilterAdapter,
  composeAdapters,
  initialValueAdapter,
  localStorageAdapter,
  noopAdapter,
  paginationAdapter,
  useQueryParamsAdapter,
} from './Filter';
import { Pagination } from '../Table/Pagination';
import { QueryParam } from '../../schemas/http.schema';
import { endOfMonth, format, lastDayOfMonth, parseJSON, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import { useMemo, useReducer } from 'react';

/**
 * Note: string values of these enums are slightly different for
 * for backwards compatibility to the way they are
 * persisted in the database (for reports).
 */
export enum DatePreset {
  ThisMonth = 'currentMonth',
  LastMonth = 'lastMonth',
  Last30Days = 'last30',
  NextMonth = 'nextMonth',
  Next30Days = 'next30',
  Custom = 'Custom',
}

export type RelativeDatePreset =
  | DatePreset.Next30Days
  | DatePreset.NextMonth
  | DatePreset.Last30Days
  | DatePreset.LastMonth
  | DatePreset.ThisMonth;

type EmptyDateRange = {
  preset: null;
  start: null;
  end: null;
};

/**
 * Need to be able to set the preset to Custom without
 * setting the dates
 */
type EmptyCustomDateRange = {
  preset: DatePreset.Custom;
  start: null;
  end: null;
};

/**
 * We make the actual type customizable so that things
 * like the localStorage adapter can talk about
 * parsing / serializing dates in a type-safe way,
 * while still retaining the same structures.
 */
type FullDateRange<DateType = Date> = {
  preset: DatePreset;
  start: DateType;
  end: DateType;
};
/**
 * A date range that
 */
export type MaterializableDateRange<DateType = Date> = FullDateRange<DateType> | EmptyDateRange;
export type DateRange<DateType = Date> = FullDateRange<DateType> | EmptyCustomDateRange | EmptyDateRange;

/**
 * A date range that might not have had initialized values for start and end yet.
 */
export type LazyDateRange<DateType = Date> =
  | EmptyDateRange
  | {
      preset: RelativeDatePreset;
      start: null;
      end: null;
    }
  | {
      preset: DatePreset.Custom;
      start: DateType;
      end: DateType;
    };

export interface DateFilter extends Filter {
  range: DateRange;
  setPreset: (preset: DatePreset) => void;
  setDates: (start: Date, end: Date) => void;
  param: QueryParam<string>;
}

const INITIAL_DATE_RANGE: DateRange = {
  preset: null,
  start: null,
  end: null,
};

const DEFAULT_PARAMS: QueryParam<string> = {
  gte: null,
  lte: null,
};

type DateAction =
  | {
      type: 'SET_PRESET';
      preset: DatePreset;
    }
  | {
      type: 'CLEAR';
    }
  | {
      type: 'SET_DATES';
      start: Date;
      end: Date;
    };

/**
 * Return a valid DateRange based on the presets available in our date selections.
 * @param preset DatePreset to base DateRange values off of.
 */
export function deriveDateRangeFromPreset(preset: RelativeDatePreset): FullDateRange {
  let start: Date;
  let end: Date;
  let today = startOfDay(new Date());
  switch (preset) {
    case DatePreset.Last30Days:
      start = subDays(today, 30);
      end = today;
      break;
    case DatePreset.Next30Days:
      start = today;
      end = subDays(today, -30);
      break;
    case DatePreset.LastMonth: {
      let prevMonth = subMonths(today, 1);
      start = startOfMonth(prevMonth);
      end = endOfMonth(prevMonth);
      break;
    }
    case DatePreset.NextMonth: {
      let nextMonth = subMonths(today, -1);
      start = startOfMonth(nextMonth);
      end = endOfMonth(nextMonth);
      break;
    }
    case DatePreset.ThisMonth:
      start = startOfMonth(today);
      end = lastDayOfMonth(today);
      break;
  }
  return {
    preset,
    start,
    end,
  };
}

function isEmptyDateRange(range: LazyDateRange): range is EmptyDateRange {
  return range.preset === null && range.start === null && range.end === null;
}

function dateRangeFromLazyDateRange(range: LazyDateRange): MaterializableDateRange | null {
  if (isEmptyDateRange(range)) {
    return range;
  }
  if (range.preset !== DatePreset.Custom) {
    return deriveDateRangeFromPreset(range.preset);
  }
  // using instanceof because null checks weren't convincing typescript this would be not null.
  if (range.start instanceof Date && range.end instanceof Date) {
    return {
      preset: DatePreset.Custom,
      start: range.start,
      end: range.end,
    };
  }
  // this would only happen if start and end somehow didn't evaluate to instanceof Date.
  // that ONLY would happen if someone passed an invalid datestring to Date() and hand't performed
  // any other operation on it yet.
  throw new Error(`IllegalArgument: start and end must be set to set a valid date for custom date type.`);
}

/**
 * Wraps an existing storage adapter (like localStorage),
 * so that we can only store the "lazy" date values, instead
 * of storing the actual date values.
 *
 * Why does this matter? Well, first off, if we naively store Date values,
 * they will be string values when we bring them back up. The type info
 * is lost.
 * The other reason is that it won't be reactive to the user actually coming
 * back to the app. If the user sets the filter on March 1st to "This Month", then
 * the filter should adapt to the date change on March 2nd. Naively storing /
 * parsing a DateRange won't give that to us: we need to work with LazyDateRange's
 */
export function datePresetStorageAdapter(
  storageAdapter: FilterAdapter<LazyDateRange<string>>,
): FilterAdapter<MaterializableDateRange> {
  return {
    getInitialValue: () => {
      let stored = storageAdapter.getInitialValue();
      if (!stored) {
        return null;
      }
      if (stored.preset === DatePreset.Custom) {
        return dateRangeFromLazyDateRange({
          preset: stored.preset,
          start: parseJSON(stored.start),
          end: parseJSON(stored.end),
        });
      }
      return dateRangeFromLazyDateRange({
        preset: stored.preset,
        start: null,
        end: null,
      });
    },
    syncValue(value) {
      if (value.preset === DatePreset.Custom) {
        storageAdapter.syncValue({
          preset: DatePreset.Custom,
          start: value.start.toJSON(),
          end: value.end.toJSON(),
        });
      } else {
        storageAdapter.syncValue({
          preset: value.preset,
          start: null,
          end: null,
        });
      }
    },
  };
}

export function useTablePageDateAdapter(pagination: Pagination, storageKey: string): FilterAdapter<DateRange> {
  return composeAdapters(
    datePresetStorageAdapter(useQueryParamsAdapter(storageKey)),
    datePresetStorageAdapter(localStorageAdapter(storageKey)),
    paginationAdapter(pagination),
  );
}

function dateReducer(state: DateRange, action: DateAction): DateRange {
  switch (action.type) {
    case 'CLEAR':
      return INITIAL_DATE_RANGE;
    case 'SET_DATES':
      return {
        preset: DatePreset.Custom,
        start: action.start,
        end: action.end,
      };
    case 'SET_PRESET': {
      if (action.preset === DatePreset.Custom) {
        return {
          preset: DatePreset.Custom,
          start: null,
          end: null,
        };
      }
      return deriveDateRangeFromPreset(action.preset);
    }
  }
}

function initDateFilter(adapter: FilterAdapter<DateRange>): DateRange {
  return adapter.getInitialValue() ?? INITIAL_DATE_RANGE;
}

export function lazyDateRangeAdapter(initialRange: LazyDateRange | null): FilterAdapter<DateRange> {
  return initialValueAdapter(() => {
    if (initialRange) {
      return dateRangeFromLazyDateRange(initialRange);
    }
    return null;
  });
}

export function useDateFilter(adapter: FilterAdapter<DateRange> = noopAdapter()): DateFilter {
  let [state, setState] = useReducer((_: DateRange, action: DateRange) => action, adapter, initDateFilter);
  let filter: DateFilter = useMemo(() => {
    let param = DEFAULT_PARAMS;
    if (state.start !== null) {
      // can infer from typedef that end is not null
      param = {
        gte: format(state.start, 'yyyy-MM-dd'),
        lte: format(state.end, 'yyyy-MM-dd'),
      };
    }
    const updateState = (action: DateAction) => {
      let nextState = dateReducer(state, action);
      setState(nextState);
      adapter.syncValue(nextState);
    };
    return {
      range: state,
      param,
      clear: () => {
        updateState({ type: 'CLEAR' });
      },
      isEnabled: state.start !== null,
      setPreset: (preset: DatePreset) => {
        if (preset !== DatePreset.Custom) {
          updateState({ type: 'SET_PRESET', preset });
        } else {
          // Custom does not set a range, don't need
          // to sync with our subscribers.
          setState(
            dateReducer(state, {
              type: 'SET_PRESET',
              preset,
            }),
          );
        }
      },
      setDates: (start: Date, end: Date) => {
        updateState({ type: 'SET_DATES', start, end });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return filter;
}
