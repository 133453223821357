import React from 'react';
import { ArpBalance } from '../../schemas/arpBalance.schema';
import { ClientInvoiceUploadSummary } from '../../schemas/invoiceUpload.schema';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import {
  SummaryCardAccountOverview,
  SummaryCardAccountOverviewTitle,
  SummaryCardAccountOverviewValue,
} from '../SummaryCard/SummaryCardAccountOverview';
import { SummaryCardCurrency } from '../SummaryCard/SummaryCardCurrency';
import { TooltipFormula, TooltipFormulaDivider, TooltipFormulaTerm } from '../Tooltips/TooltipFormula';
import { makeStyles } from '@c2fo/react-components';
import { toDate } from '../../i18n/date';

const useStyles = makeStyles((theme) => ({
  accountBalance: {
    '& svg': {
      fill: theme.palette.warning.main,
    },
  },
}));

/**
 * Encapsulates the copy / formatting for several account stats that
 * we show on the Factoring homepage card
 */

export function AccountBalance(props: { balance: ArpBalance; isInverse?: boolean }): JSX.Element {
  const classes = useStyles();
  return (
    <SummaryCardAccountOverview>
      <SummaryCardAccountOverviewTitle isInverse={props.isInverse}>
        Account Balance &nbsp;
        <InfoTooltip isInverse={false} name="factoringAccountOverview.AccountBalance">
          <TooltipFormula>
            <TooltipFormulaTerm value={props.balance.factoringUnsettledInvoices}>
              Total invoice purchase price
            </TooltipFormulaTerm>
            <TooltipFormulaTerm
              value={props.balance.factoringUnsettledInvoiceFees + props.balance.factoringUnsettledFees}
            >
              Fees
            </TooltipFormulaTerm>
            <TooltipFormulaTerm value={props.balance.factoringUnsettledInvoiceRebateAmount}>
              Invoice rebates
            </TooltipFormulaTerm>
            <TooltipFormulaTerm value={props.balance.factoringUnsettledUnderpaymentAmount}>
              Invoice underpayments
            </TooltipFormulaTerm>
            <TooltipFormulaTerm value={props.balance.factoringUnsettledChargebackAmount}>
              Invoice chargebacks
            </TooltipFormulaTerm>
            <TooltipFormulaTerm value={props.balance.factoringUnsettledPassthroughAmount}>
              Pass through funds
            </TooltipFormulaTerm>
            <TooltipFormulaDivider />
            <TooltipFormulaTerm value={props.balance.factoringTotalUnsettledAmount}>Account Balance</TooltipFormulaTerm>
          </TooltipFormula>
        </InfoTooltip>
      </SummaryCardAccountOverviewTitle>
      <SummaryCardAccountOverviewValue isInverse={props.isInverse}>
        {props.balance.factoringTotalUnsettledAmount < 0 ? (
          <span data-cy={'account-balance'}>
            <InfoTooltip isInverse={true} name="factoringAccountOverview.NegativeBalance">
              Please contact your C2FO Account Manager to discuss options to correct the negative balance.
            </InfoTooltip>
            &nbsp;
            <SummaryCardCurrency>{props.balance.factoringTotalUnsettledAmount}</SummaryCardCurrency>
          </span>
        ) : (
          <span data-cy={'account-balance'} className={classes.accountBalance}>
            <SummaryCardCurrency>{props.balance.factoringTotalUnsettledAmount}</SummaryCardCurrency>
          </span>
        )}
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

export function TotalObligation(props: { balance: ArpBalance; isInverse?: boolean }): JSX.Element {
  return (
    <SummaryCardAccountOverview>
      <SummaryCardAccountOverviewTitle isInverse={props.isInverse}>
        Total Obligation &nbsp;
        <InfoTooltip isInverse={false} name="factoringAccountOverview.TotalObligation">
          <TooltipFormula>
            <TooltipFormulaTerm value={props.balance.factoringPurchasedAmount}>
              Open purchased invoices
            </TooltipFormulaTerm>
            <TooltipFormulaTerm
              value={
                props.balance.factoringNegativeTotalUnsettledAmount < 0
                  ? props.balance.factoringNegativeTotalUnsettledAmount * -1
                  : 0
              }
            >
              Total unsettled amount
            </TooltipFormulaTerm>
            <TooltipFormulaDivider />
            <TooltipFormulaTerm value={props.balance.factoringTotalObligation}>Total Obligation</TooltipFormulaTerm>
          </TooltipFormula>
        </InfoTooltip>
      </SummaryCardAccountOverviewTitle>
      <SummaryCardAccountOverviewValue isInverse={props.isInverse}>
        <span data-cy={'total-obligation'}>
          <SummaryCardCurrency>{props.balance.factoringTotalObligation}</SummaryCardCurrency>
        </span>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

const CURRENT_MONTH = toDate(new Date(), 'LLLL');

export function DiscountFeesThisMonth(props: { balance: ArpBalance; isInverse?: boolean }): JSX.Element {
  return (
    <SummaryCardAccountOverview data-joyride-step={'total-discount-fee'}>
      <SummaryCardAccountOverviewTitle isInverse={props.isInverse}>
        Discount fees in {CURRENT_MONTH}
        &nbsp;
        <InfoTooltip isInverse={false} name="factoringAccountOverview.DiscountFeesThisMonth">
          Total fees incurred this month for invoice purchase
        </InfoTooltip>
      </SummaryCardAccountOverviewTitle>
      <SummaryCardAccountOverviewValue isInverse={props.isInverse}>
        <span data-cy={'monthly-discount-fees'}>
          <SummaryCardCurrency>{props.balance.monthlyDiscountFees}</SummaryCardCurrency>
        </span>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}

export function LastInvoiceUpload(props: { lastUpload: ClientInvoiceUploadSummary | null }): JSX.Element {
  return (
    <SummaryCardAccountOverview>
      <SummaryCardAccountOverviewTitle>Last invoice uploaded</SummaryCardAccountOverviewTitle>

      <SummaryCardAccountOverviewValue>
        <span data-cy={'latest-upload'}>{toDate(props.lastUpload?.created ?? null)}</span>
      </SummaryCardAccountOverviewValue>
    </SummaryCardAccountOverview>
  );
}
