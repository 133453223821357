import React from 'react';
import { ClientBalance } from '../../schemas';
import { InterestThisMonth, OutstandingBalance } from './RFAccountOverview';
import { RFSummaryCardCreditLimit } from './RFSummaryCardCreditLimit';
import { makeStyles } from '@c2fo/react-components';

interface RFSummaryLimitCardProps {
  balance: ClientBalance;
}

let useStyles = makeStyles(() => ({
  card: {
    height: '100%',
  },
}));

export const RFSummaryLimitCard: React.FC<RFSummaryLimitCardProps> = (props) => {
  let classes = useStyles();
  return (
    <div data-testid="RFSummaryLimitCard" className={classes.card}>
      <RFSummaryCardCreditLimit
        balance={props.balance}
        accountOverviewStats={
          <>
            <OutstandingBalance balance={props.balance} />
            <InterestThisMonth balance={props.balance} />
          </>
        }
      />
    </div>
  );
};
