import { BaseDataService } from '../BaseDataService/BaseDataService';
import { CustomerPayment } from '../../schemas';
import { Query } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
const customerPaymentBasePath = (divisionUuid: string) =>
  `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/customer-payments`;

/**
 * Service for interacting with customer payment API endpoints.
 */
export class CustomerPaymentService extends BaseDataService<CustomerPayment> {
  constructor(httpService: TpfSupplierApiHttpService) {
    super(httpService, customerPaymentBasePath);
  }

  /**
   * get a customer payment data.
   * @param divisionUuid
   */
  async getLatestCustomerPayment(divisionUuid: string): Promise<CustomerPayment | null> {
    let response = await this.query(divisionUuid, {
      size: 1,
      sort: ['created,DESC'],
    });
    return response.data[0] ?? null;
  }

  /**
   * downloads summary customer payment info based on
   * given divisionUuid and sorting parameters
   * @param divisionUuid
   * @param params
   * @param selectedPayments
   */
  downloadSummaryCustomerPaymentFromQuery(
    divisionUuid: string,
    params: Query<CustomerPayment>,
    selectedPayments: string[],
  ) {
    return this.httpService.downloadUrl(customerPaymentBasePath(divisionUuid) + '/download', {
      ...params,
      uuid: {
        in: selectedPayments,
      },
    });
  }

  getDownloadSummaryCustomerPaymentFromQueryUrl(
    divisionUuid: string,
    params: Query<CustomerPayment>,
    selectedPayments: string[],
  ) {
    return this.httpService.formatDownloadUrl(customerPaymentBasePath(divisionUuid) + '/download', {
      ...params,
      uuid: {
        in: selectedPayments,
      },
    });
  }
}
