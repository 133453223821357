function getString(name: string): string | null {
  const value = process.env[name] || process.env[`REACT_APP_${name}`];
  return value || null;
}

function getInteger(name: string): number | null {
  const stringValue = getString(name);
  let integerValue = null;

  if (stringValue) {
    integerValue = parseInt(stringValue, 10);

    if (Number.isNaN(integerValue)) {
      integerValue = null;
    }
  }

  return integerValue;
}

function getBoolean(name: string): boolean | null {
  const stringValue = getString(name);

  if (stringValue === null) {
    return null;
  }

  const lowerCaseStringValue = stringValue.toLowerCase();

  if (['true', 'false'].includes(lowerCaseStringValue)) {
    return stringValue.toLowerCase() === 'true';
  }
  return null;
}

export const env = {
  getString,
  getInteger,
  getBoolean,
};
