import React, { ReactNode } from 'react';
import { makeStyles } from '@c2fo/react-components';
import { toCurrency } from '../../i18n/currency';

export enum TooltipFormulaOperation {
  Subtract,
  Add,
  Multiply,
  Equal,
}

export function TooltipFormula(props: { children: ReactNode }) {
  return <div>{props.children}</div>;
}

const useTooltipFormulaTermStyles = makeStyles((theme) => ({
  number: {
    margin: 0,
    fontVariantNumeric: 'tabular-nums',
    textAlign: 'right',
  },
  description: {
    margin: 0,
    color: theme.palette.grey['500'],
    textAlign: 'right',
  },
}));

export function TooltipFormulaTerm(props: {
  value?: number;
  children: ReactNode;
  operation?: TooltipFormulaOperation;
}) {
  let classes = useTooltipFormulaTermStyles();
  let operation: ReactNode =
    props.operation === TooltipFormulaOperation.Subtract
      ? '-'
      : props.operation === TooltipFormulaOperation.Add
      ? '+'
      : props.operation === TooltipFormulaOperation.Multiply
      ? 'x'
      : props.operation === TooltipFormulaOperation.Equal
      ? '='
      : null;
  if (props.value === undefined) {
    return (
      <p className={classes.number} data-testid={'description'}>
        {operation} {props.children}
      </p>
    );
  }
  return (
    <>
      <h3 className={classes.number} data-testid={'value'}>
        {operation} {toCurrency(props.value)}
      </h3>
      <p className={classes.description} data-testid={'description'}>
        {props.children}
      </p>
    </>
  );
}

const useFormulaDividerStyles = makeStyles({
  root: {
    width: 200,
    /**
     * Forces the <hr> to be right-aligned
     * in situations where the width of the tooltip
     * is wider than 200px
     */
    marginRight: 0,
  },
});

export function TooltipFormulaDivider(props: Record<string, unknown>) {
  let classes = useFormulaDividerStyles();
  return <hr className={classes.root} />;
}

export function TooltipFooter(props: { children: ReactNode }) {
  return (
    <div>
      <hr style={{ height: '2px', backgroundColor: 'white' }} />
      <div>{props.children}</div>
    </div>
  );
}
