import React, { useMemo } from 'react';
import { CustomerPayment } from '../../schemas/customerPayment.schema';
import { DateChips, MultiselectChips } from '../Filters/FilterChips';
import {
  DateFilter,
  DatePreset,
  LazyDateRange,
  lazyDateRangeAdapter,
  useDateFilter,
  useTablePageDateAdapter,
} from '../Filters/useDateFilter';
import { DatePicker } from '../Filters/DatePicker';
import { DebtorSearch } from '../Filters/TypeaheadSearch';
import { FilterLabel } from '../Filters/FilterLabel';
import {
  MultiselectFilter,
  useMultiselectFilter,
  useTablePageMultiselectAdapter,
} from '../Filters/useMultiselectFilter';
import { Pagination, usePagination } from '../Table/Pagination';
import { Query } from '../../schemas/http.schema';
import { SlideoutFilterAndChips, SlideoutFilterGroup } from '../Filters/Slideout';
import { Sorting, useSorting } from '../Table/Sorting';
import { composeAdapters } from '../Filters/Filter';

export interface CustomerPaymentsFilters {
  sorting: Sorting;
  pagination: Pagination;
  debtors: MultiselectFilter<string>;
  receivedDate: DateFilter;

  params: Query<CustomerPayment>;
}

const INITIAL_RF_PAYMENTS_DATE_RANGE: LazyDateRange = {
  preset: DatePreset.Last30Days,
  start: null,
  end: null,
};

export function useCustomerPaymentsFilters(storageKey: string): CustomerPaymentsFilters {
  let sorting = useSorting('receivedDate', storageKey, 'desc');

  let pagination = usePagination(storageKey);
  let debtors = useMultiselectFilter<string>(
    (debtor) => debtor,
    useTablePageMultiselectAdapter(pagination, `${storageKey}:debtors`),
  );
  let receivedDate = useDateFilter(
    composeAdapters(
      useTablePageDateAdapter(pagination, `${storageKey}:received-date`),
      lazyDateRangeAdapter(INITIAL_RF_PAYMENTS_DATE_RANGE),
    ),
  );
  let params = useMemo(
    () => ({
      ...pagination.params,
      sort: [sorting.apiSort],
      debtorName: debtors.param,
      receivedDate: receivedDate.param,
    }),
    [pagination.params, sorting.apiSort, debtors.param, receivedDate.param],
  );
  return {
    sorting,
    pagination,
    debtors,
    receivedDate,
    params,
  };
}

export function CustomerPaymentsFilters(props: { filters: CustomerPaymentsFilters }) {
  let { filters } = props;

  return (
    <SlideoutFilterAndChips
      filters={[filters.debtors, filters.receivedDate]}
      chips={
        <>
          <MultiselectChips filter={filters.debtors}>Customer Name</MultiselectChips>
          <DateChips filter={filters.receivedDate} fieldName={'receivedDate'}>
            Date
          </DateChips>
        </>
      }
      slideoutTitle={'Payment Filters'}
      slideoutFilters={
        <>
          <SlideoutFilterGroup>
            <FilterLabel>Customer Name</FilterLabel>
            <DebtorSearch filter={filters.debtors} />
          </SlideoutFilterGroup>
          <SlideoutFilterGroup>
            <FilterLabel>Date</FilterLabel>
            <DatePicker filter={filters.receivedDate} testFilterName={'receivedDate'} />
          </SlideoutFilterGroup>
        </>
      }
    />
  );
}
