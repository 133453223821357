import { Step } from 'react-joyride';

const steps: Step[] = [
  {
    title: 'Transaction type',
    /**
     * This disableBeacon means that we will not
     * show the beacon when the tour starts,
     * instead we will immediately start it.
     *
     * Not needed on subsequent tours because we have
     * continuous in the Joyride component
     */
    disableBeacon: true,
    target: '[data-joyride-step="transaction-type"]',
    placement: 'bottom',
    content:
      'Toggle between ACH or wire to select the transfer method for each advance. Your preferred method is selected by default',
  },
  {
    title: 'Monthly interest',
    target: '[data-joyride-step="account-balance-stats"]',
    placement: 'bottom',
    content: 'Track interest accruing during the month based on your outstanding balance',
  },
  {
    title: 'Invoice Box',
    target: '[data-joyride-step="invoice-card"]',
    placement: 'left',
    content: 'View the date invoices were last uploaded to quickly identify new invoices to upload',
  },
];

export default steps;
