import React, { ReactFragment } from 'react';
import { IconTooltip } from './IconTooltip';
import { InverseQuestionIcon } from '../CustomIcons/Icons';
import { QuestionCircleIcon, TooltipProps } from '@c2fo/react-components';

export function InfoTooltip(props: {
  children: ReactFragment;
  isInverse?: boolean;
  name?: string;
  placement?: TooltipProps['placement'];
}): JSX.Element {
  if (props.isInverse) {
    return (
      <IconTooltip
        name={props.name}
        placement={props.placement}
        arrow={false}
        icon={
          <div>
            <InverseQuestionIcon />
          </div>
        }
      >
        {props.children}
      </IconTooltip>
    );
  } else {
    return (
      <IconTooltip
        name={props.name}
        placement={props.placement}
        arrow={false}
        icon={<QuestionCircleIcon color={'secondary'} />}
      >
        {props.children}
      </IconTooltip>
    );
  }
}
