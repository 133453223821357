import { TokenService } from '../../../lib';
import { legacyC2foUiConfig } from '../../../config';

/**
 * RedirectService
 * Handle the forming & execution of external links and redirects for the app.
 */
export class RedirectService {
  constructor(private tokenService: TokenService) {
    this.tokenService = tokenService;
  }

  getSignedEarlyPayProfileUrl(): string {
    return this.tokenService.signUrlForEarlyPay(`${legacyC2foUiConfig.LEGACY_C2FO_UI_BASE_URL}/profile/account`);
  }

  getSignedEarlyPayMarketsUrl(): string {
    return this.tokenService.signUrlForEarlyPay(`${legacyC2foUiConfig.LEGACY_C2FO_UI_BASE_URL}/supplier/markets`);
  }

  getSignedEarlyPayLogoUrl(): string {
    return this.tokenService.signUrlForEarlyPay(`${legacyC2foUiConfig.LEGACY_C2FO_UI_BASE_URL}/supplier`);
  }
}
