import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parseISO';

export function toDate(date: string | Date | null, format = 'MM-dd-yyyy') {
  if (date === null) {
    return '-';
  }

  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = parseDate(date);
  }

  return formatDate(date, format);
}
