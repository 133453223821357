import { AxiosResponse } from 'axios';
import { DatePreset, RelativeDatePreset, deriveDateRangeFromPreset } from '../../components/Filters/useDateFilter';
import {
  FactoringInvoice,
  InvoiceReporting,
  PageableResponse,
  ProductType,
  Query,
  Report,
  ReportDisplayField,
  ReportMetadata,
  ReportQueryConstraint,
  ReportSaveAction,
  Tokenized,
} from '../../schemas';
import { InvoiceService } from '../InvoiceService/InvoiceService';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { format } from 'date-fns';
import { tpfSupplierApiConfig } from '../../../config';

const apiUrl = tpfSupplierApiConfig.TPF_SUPPLIER_API_BASE_URL;
export const reportsBasePath = (divisionUuid: string) => `${apiUrl}/v2/tpf-api/divisions/${divisionUuid}/reports`;
export const invoiceMetadataPath = (productType: ProductType) =>
  `${apiUrl}/v2/tpf-api/metadata/${productType === ProductType.ReceivablesFinance ? 'invoice' : 'factoring-invoice'}`;

/**
 * ReportService:
 * the only reporting we do so far is for invoice downloads, but the schema is there for more on
 * the backend, so making this slightly more generic-sounding...
 *
 * A note on types: using FactoringInvoice as the response type for many of these endpoints, because
 * right now FactoringInvoice extends all our Invoice fields.  If this changes in the future, these
 * signatures will need to reflect that.
 */
export class ReportService {
  constructor(private httpService: TpfSupplierApiHttpService, private invoiceService: InvoiceService) {}

  /**
   * Get existing invoice reports for the client.
   * @param divisionUuid
   * @param params
   */
  async getInvoiceReports(
    divisionUuid: string,
    params: Query<Report<FactoringInvoice>>,
  ): Promise<PageableResponse<Report<FactoringInvoice>>> {
    let response = await this.httpService.get<PageableResponse<Report<FactoringInvoice>>>(
      reportsBasePath(divisionUuid),
      {
        params: this.httpService.serializeQueryParams(params),
      },
    );
    return response.data;
  }

  /**
   * Get metadata for the invoice object (list of available columns and their display names)
   */
  async getInvoiceMetadata(productType: ProductType): Promise<ReportDisplayField<FactoringInvoice>[]> {
    let response = await this.httpService.get<ReportMetadata<FactoringInvoice>>(invoiceMetadataPath(productType));
    return response.data.displayFields;
  }

  /**
   * Download an invoice report after retrieving the necessary query building information from the db.
   * @param divisionUuid
   * @param reportUuid
   */
  async fetchAndDownloadReport(divisionUuid: string, reportUuid: string): Promise<string> {
    const report = await this.getReport(divisionUuid, reportUuid);
    return this.downloadInvoiceReport(divisionUuid, report);
  }

  /**
   * Download an invoice report based on the provided report object.  Will form URL from the
   * report itself, and then open a download url with query param filters.
   * @param report
   */
  downloadInvoiceReport(divisionUuid: string, report: Report<FactoringInvoice>): string {
    const query = this.invoiceReportConstraintsToQuery(report.queryConstraints);
    query.explicitColumns = report.columnFields.join(',');
    const productType = report.entityName === 'invoice' ? ProductType.ReceivablesFinance : ProductType.Factoring;

    return this.invoiceService.downloadInvoicesFromQuery(divisionUuid, productType, query);
  }

  /**
   * In order to bridge an API response on a GET of a report to a valid download URL, we need to transform
   * the queryConstraints that come back in the response to a valid query as our httpService sees it.
   * In the DB/API response, we have 3 types of constraint filters:
   * 1. "IN": handles all multi-option filters, like debtor name, invoice status, etc.
   * 2. "LAZY_DATE": any relative-to-now date filter e.g. last 30 days, current month, last month.
   * 3. "GTE"+"LTE": a static date range filter (could technically be any range filter, but we only implement
   *     date right now)
   * In order to transform these to a query, we'll reduce to:
   * 1.  Transform "IN" rows into their proper format: { "field" { "in" ["value1", "value2"]}}
   * 2.  Evaluate + transform "LAZY_DATE" rows into their format:
   *     {"field" : { start: "yyyy-MM-dd", end: "yyyy-MM-dd", preset: "[<preset from db>]"}}
   * 3.  Transform "GTE"+"LTE" queries to proper format:
   *     {"field" : { start: "yyyy-MM-dd", end: "yyyy-MM-dd", preset: "Custom"}}
   * @param constraints - queryConstraints returned for a particular report.
   */
  invoiceReportConstraintsToQuery(
    constraints: ReportQueryConstraint<FactoringInvoice>[],
  ): Query<FactoringInvoice & InvoiceReporting & Tokenized> {
    let asQuery = constraints.reduce(
      (memo: Query<FactoringInvoice & InvoiceReporting & Tokenized>, x: ReportQueryConstraint<FactoringInvoice>) => {
        if (x.comparison === 'IN') {
          // i can't convince typescript that this is valid. I must have a definition wrong somewhere.
          // eslint-disable-next-line
          // @ts-ignore
          if (memo && memo[x.field] && memo[x.field] && memo[x.field]?.in) {
            // eslint-disable-next-line
            // @ts-ignore
            const prevInValues = memo[x.field]?.in;
            Object.assign(memo, {
              [x.field]: {
                in: prevInValues.concat(x.value),
              },
            });
          } else {
            Object.assign(memo, {
              [x.field]: {
                in: [x.value],
              },
            });
          }
        } else if (x.comparison === 'LAZY_DATE') {
          // DatePreset enum should be exhaustive of what exists in the DB, but we store
          // presets simply as 'text', so bail a warning here in case things are off.
          if (
            !Object.values(DatePreset)
              .map((s) => s as string)
              .includes(x.value)
          ) {
            throw new Error(`IllegalState : ${x.value} is not a valid DatePreset`);
          }
          const translatedDateFilters = this.dateFiltersFromLazyValues(x.value as RelativeDatePreset);
          Object.assign(memo, {
            [x.field]: translatedDateFilters,
          });
        } else {
          // this block handles explicit date ranges and equality operators
          const properCaseComparator = this.toLowerCaseComparator(x.comparison);
          let existing: any = memo[x.field] || {};
          existing[properCaseComparator] = x.value;
          Object.assign(memo, {
            [x.field]: existing,
          });
        }
        return memo;
      },
      {},
    );

    return asQuery;
  }

  /**
   * Alternately we could adjust httpService.serializeQueryParams to handle both casing options.
   * This function is simply for interop with how that function currently works.
   * @param comparison
   */
  toLowerCaseComparator(comparison: string) {
    if (['EQ', 'IN', 'GTE', 'LTE', 'LIKE'].includes(comparison)) {
      return comparison.toLowerCase();
    }
    return comparison;
  }

  /**
   * Transform "lazy" (aka current-time-relative) date filters on invoice queries into
   * their appropriate format for querying via httpService params.
   * @param lazyQuery - the value of a 'LAZY_DATE' report_query_constraint as it exists from
   * the API response.
   * Could tighten up types here.
   */
  dateFiltersFromLazyValues(lazyQuery: RelativeDatePreset) {
    const dateRange = deriveDateRangeFromPreset(lazyQuery);
    return {
      gte: format(dateRange.start, 'yyyy-MM-dd'),
      lte: format(dateRange.end, 'yyyy-MM-dd'),
    };
  }

  /**
   * Get a single report, and all of its constraints.
   * @param divisionUuid client identifier
   * @param reportUuid report identifier
   */
  async getReport(divisionUuid: string, reportUuid: string): Promise<Report<FactoringInvoice>> {
    let response = await this.httpService.get<Report<FactoringInvoice>>(
      `${reportsBasePath(divisionUuid)}/${reportUuid}`,
    );
    return response.data;
  }

  /**
   * Create report for client.
   * @param data post payload.
   */
  async createInvoiceReport(data: Report<FactoringInvoice>): Promise<Report<FactoringInvoice> | null> {
    if (!data.divisionUuid) {
      throw new Error('IllegalArgument: attempted to create a report without divisionUuid');
    }
    let response = await this.httpService.post<Report<FactoringInvoice>>(`${reportsBasePath(data.divisionUuid)}`, {
      data,
    });
    return response.data;
  }

  /**
   * Update/Edit an existing report.
   * @param data - payload
   */
  async updateInvoiceReport(data: Report<FactoringInvoice>): Promise<Report<FactoringInvoice> | null> {
    if (!data.uuid || !data.divisionUuid) {
      throw new Error('IllegalArgument: report must have UUID & divisionUuid for update PUT');
    }
    let response = await this.httpService.put<Report<FactoringInvoice>>(
      `${reportsBasePath(data.divisionUuid)}/${data.uuid}`,
      {
        data,
      },
    );
    return response.data;
  }

  async deleteReport(divisionUuid: string, reportUuid: string): Promise<AxiosResponse<any>> {
    return this.httpService.delete(`${reportsBasePath(divisionUuid)}/${reportUuid}`);
  }

  /**
   * conditionally create/update a report, and download the report based on provided action.
   * @param divisionUuid - div identifier
   * @param data - report
   * @param saveAction - whether to create, update, or neither
   */
  async upsertAndDownloadReport(
    divisionUuid: string,
    data: Report<FactoringInvoice>,
    saveAction: ReportSaveAction,
  ): Promise<string> {
    // download can come before create/update, since we build URI from report directly.
    let downloadUrl = this.downloadInvoiceReport(divisionUuid, data);
    switch (saveAction) {
      case ReportSaveAction.Create:
        await this.createInvoiceReport(data);
        break;
      case ReportSaveAction.Update:
        await this.updateInvoiceReport(data);
        break;
      case ReportSaveAction.DontSave:
        // no-op
        break;
    }
    return downloadUrl;
  }
}
