import React from 'react';
import { Client } from '../../schemas';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { PageNotFound } from '../../components/PageNotFound/PageNotFound';
import { Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useServices } from '../../services';

export function RootPage() {
  const { clientService } = useServices();

  const clients = useAsync(async () => clientService.getClientForUser());

  // fall back to a 404 page if an error happens that somehow isn't handled
  // by our error boundary.  It's safe and provides a link to markets.
  if (clients.error) {
    return <PageNotFound />;
  }

  if (clients.value) {
    // the user had no client, so this page doesn't make sense for them.
    if (clients.value.length < 1) {
      return <PageNotFound />;
    }
    // we found a client.  redirect to the base division route where product
    // or onboarding process discovery takes place.
    return <Redirect to={`/${clients.value[0].divisionUuid}`} />;
  }

  return <LoadingScreen />;
}
