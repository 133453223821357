/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-polyfill';
import ReactDOM from 'react-dom';
import App from './app/App';

function render() {
  const root = document.getElementById('root')!;
  ReactDOM.render(<App />, root);
}
render();
