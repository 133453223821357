import React, { useState } from 'react';
import {
  AngleDownIcon,
  Box,
  CloseIcon,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  PrimaryButton,
  SecondaryButton,
  TypeBase,
  TypeSubsectionHeader,
} from '@c2fo/react-components';
import { FloatingCard, FloatingCardBody, FloatingCardHeader } from '../InvoiceUpload/FloatingCard';
import { LabelCategoriesObj } from '../../schemas/fileManagement.schema';
import { LoadingState } from '../Async/useAsyncQuery';
import { useId } from '@reach/auto-id';

const ERROR_BACKGROUND_COLOR = `rgba(187, 49, 0, 0.05)`;

function ErrorsMessage(props: Record<string, string>) {
  let { errors } = props;
  return (
    <Box padding={1} bgcolor={ERROR_BACKGROUND_COLOR} sx={{ padding: '8px 16px' }}>
      <TypeBase isError component={'div'}>
        <span data-cy={'error-message'} data-testid={'error-message'}>
          {errors}
        </span>
      </TypeBase>
    </Box>
  );
}

function NoErrorsMessage(props: Record<string, unknown>) {
  return (
    <TypeBase data-testid={'no-errors-message'}>
      <ul style={{ margin: '0', listStyle: 'none', padding: '0' }}>
        <li>• &nbsp; Please select label</li>
        <li>• &nbsp; Please upload file of max size 25MB</li>
        <li>• &nbsp; Upload files of following formats only: .pdf, .xls, .doc, .csv</li>
      </ul>
    </TypeBase>
  );
}

function UploadButton(props: { onFileSelect: (file: File) => void }) {
  const fileUploadId = useId();
  /**
   * Weird typing issues where the component prop doesn't think it exists,
   * even though it does.
   */
  const extraButtonProps = {
    component: 'span',
    style: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  } as any;

  return (
    <>
      <input
        id={fileUploadId}
        accept={'.csv, .pdf, .xls, .doc'}
        style={{ display: 'none' }}
        type={'file'}
        onChange={(event) => {
          if (event.target.files) {
            props.onFileSelect(event.target.files[0]);
          }
        }}
      />
      <label htmlFor={fileUploadId}>
        <TypeBase>
          <SecondaryButton {...extraButtonProps}>Select File</SecondaryButton>
        </TypeBase>
      </label>
    </>
  );
}

export function UploadCard(props: {
  onFileSelect: (file: File) => void;
  errors?: string;
  onClose: () => void;
  allLabels: LoadingState<Array<LabelCategoriesObj>>;
  onLabelSelect: (label: string) => void;
  labelName: string;
}): JSX.Element {
  const { onFileSelect, errors, onClose, allLabels, onLabelSelect, labelName } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => setAnchorElement(null);
  return (
    <FloatingCard data-testid={'upload-card'}>
      <section>
        <FloatingCardHeader>
          <TypeSubsectionHeader>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>Upload Files</Grid>
              <Grid item>
                <IconButton data-testid={'upload-card-close-button'} onClick={onClose} color={'secondary'}>
                  <CloseIcon color={'secondary'} />
                </IconButton>
              </Grid>
            </Grid>
          </TypeSubsectionHeader>
        </FloatingCardHeader>
        <FloatingCardBody style={{ padding: '32px 16px' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Grid container xs={6} spacing={2} direction={'column'} alignItems={'baseline'}>
              <Grid item xs={12}>
                <NoErrorsMessage />
              </Grid>
              {errors && (
                <Grid item>
                  <ErrorsMessage errors={errors} />
                </Grid>
              )}
            </Grid>
            <Grid container xs={6} justifyContent="flex-end" alignItems="center" spacing={2} direction="row">
              <Grid item data-cy={'select-file-label-wrapper'}>
                <PrimaryButton
                  onClick={handleClick}
                  endIcon={<AngleDownIcon />}
                  data-testid={'select-label'}
                  data-cy={'select-label-file-upload'}
                >
                  {labelName}
                </PrimaryButton>
                <Menu
                  keepMounted
                  open={Boolean(anchorElement)}
                  onClose={handleClose}
                  anchorEl={anchorElement}
                  variant="menu"
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {allLabels.response?.map((label) => (
                    <MenuItem
                      key={label.labelName}
                      data-cy={'detail-label-button'}
                      onClick={() => {
                        onLabelSelect(label.labelName);
                      }}
                    >
                      {label.labelName}
                    </MenuItem>
                  ))}
                  <MenuItem
                    key={'other-documents'}
                    data-cy={'detail-label-button'}
                    onClick={() => {
                      onLabelSelect('Other documents');
                    }}
                  >
                    Other Documents
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item data-cy={'select-file-button-wrapper'}>
                <UploadButton onFileSelect={onFileSelect} />
              </Grid>
            </Grid>
          </Grid>
        </FloatingCardBody>
      </section>
    </FloatingCard>
  );
}
