import React from 'react';
import { AlertBanner, Theme, TypeBase, UploadIcon, makeStyles } from '@c2fo/react-components';
import { LoadingState } from '../Async/useAsyncQuery';
import { PageableResponse } from '../../schemas';

const useStyles = makeStyles<Theme>((theme) => ({
  fileUploadAlert: {
    '& div.MuiAlert-icon': {
      display: '',
    },
    paddingBottom: '32px',
  },
  uploadIconWarning: {
    marginRight: theme.spacing(1),
    color: '#000',
    fontWeight: 'bold',
  },
}));

export function FileUploadAlert(props: Record<string, LoadingState<PageableResponse<any>>>): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.fileUploadAlert}>
      <AlertBanner severity="warning" data-testid={'file-upload-alert'} variant="standard">
        Attention! Upload your {props.document.response?.data.map((item) => item.name).join(', ')}. Your account manager
        will reach out within 1-2 business days if supporting documents are needed.
      </AlertBanner>
    </div>
  );
}
