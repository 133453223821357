import React from 'react';
import ReactSelect, { IndicatorProps, OptionProps, Props as SelectProps, components as select } from 'react-select';

export function DropdownIndicator<T, b extends boolean>(props: IndicatorProps<T, b>) {
  let innerProps = {
    'aria-label': 'Open dropdown',
    'data-cy': 'dropdown-indicator',
    ...props.innerProps,
  };
  return <select.DropdownIndicator {...props} innerProps={innerProps} />;
}

export function Option<T, b extends boolean>(props: OptionProps<T, b>) {
  let innerProps = {
    'data-cy': 'select-option',
    'data-testid': 'select-option',
    ...props.innerProps,
  };

  return <select.Option {...props} innerProps={innerProps} />;
}

export function Select<OptionType, IsMulti extends boolean = false>(props: SelectProps<OptionType, IsMulti>) {
  let { components, ...rest } = props;
  return <ReactSelect<OptionType, IsMulti> {...rest} components={{ DropdownIndicator, Option, ...components }} />;
}
