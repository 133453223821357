import React from 'react';
import { MouseoverTooltip } from '../Tooltips/MouseoverTooltip';
import { TypeBase } from '@c2fo/react-components';

function FewErrors(props: { rows: number[] }) {
  let rows = props.rows.length === 1 ? 'Row' : 'Rows';
  return (
    <>
      {rows} {props.rows.join(', ')}
    </>
  );
}

function ManyErrors(props: { rows: number[] }) {
  let truncated = props.rows.slice(0, 3).join(', ');
  return (
    <>
      Rows {truncated}
      <MouseoverTooltip content={<>Rows with this error: {props.rows.join(', ')}</>}>
        <TypeBase isEmphasis component={'span'} customColor={'inherit'}>
          ...
        </TypeBase>
      </MouseoverTooltip>
    </>
  );
}

export function ErrorRows(props: { rows: number[] }) {
  if (props.rows.length <= 3) {
    return <FewErrors rows={props.rows} />;
  }
  return <ManyErrors rows={props.rows} />;
}
