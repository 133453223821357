import React, { ReactNode, useState } from 'react';
import { AsyncButton } from '../Async/AsyncButton';
import { AsyncState } from 'react-use/lib/useAsync';
import { ClientDebtor } from '../../schemas/client-debtor.schema';
import { CustomerMapping, InvoiceUploadUnmapped } from '../../schemas/invoiceUpload.schema';
import { Grid, TypeBase, styled, useTheme } from '@c2fo/react-components';
import { InvoiceUploadDialog, InvoiceUploadDialogContent } from './InvoiceUploadDialog';
import { MenuListComponentProps, components } from 'react-select';
import { PageableResponse } from '../../schemas/http.schema';
import { Select } from '../Select/Select';
import { useAsync } from 'react-use';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useId } from '@reach/auto-id';
import { useServices } from '../../services';

function CustomerSelectTitle(props: { children: ReactNode; id?: string }) {
  let theme = useTheme();
  return (
    <TypeBase id={props.id} isEmphasis component={'label'} customColor={theme.palette.grey['700']}>
      {props.children}
    </TypeBase>
  );
}

const CustomerSelectNone = styled('div')(
  ({ theme }) =>
    ({
      borderTop: `1px solid ${theme.palette.grey['800']}`,
      color: theme.palette.grey['800'],
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: `32px`,
      paddingLeft: `calc(1.1 * ${theme.spacing(2)}px)`,
      cursor: 'pointer',
    } as any),
);

function MenuList<T>(props: MenuListComponentProps<T, false>) {
  return (
    <components.MenuList {...props}>
      {props.children}
      <CustomerSelectNone
        data-cy={'customer-select-none'}
        role={'button'}
        onClick={() => {
          props.setValue(null, 'deselect-option');
        }}
      >
        Customer not in this list
      </CustomerSelectNone>
    </components.MenuList>
  );
}

function useClientDebtors() {
  let { clientDebtorService } = useServices();
  let divisionUuid = useCurrentDivision();
  return useAsync(
    () =>
      clientDebtorService.query(divisionUuid, {
        size: 1000,
        sort: ['debtorName,ASC'],
      }),
    [clientDebtorService, divisionUuid],
  );
}

type MappingForm = [
  { [x: string]: ClientDebtor | null },
  (companyId: string, clientDebtor: ClientDebtor | null) => void,
];

function useMappingForm(): MappingForm {
  let [mappings, setMappings] = useState<{ [x: string]: ClientDebtor | null }>({});
  const updateMapping = (companyId: string, clientDebtor: ClientDebtor | null) => {
    setMappings({
      ...mappings,
      [companyId]: clientDebtor,
    });
  };
  return [mappings, updateMapping];
}

function MappingRow(props: {
  unmapped: InvoiceUploadUnmapped;
  onUpdateMapping: (companyId: string, clientDebtor: ClientDebtor | null) => void;
  mapping: ClientDebtor | null | undefined;
  clientDebtors: AsyncState<PageableResponse<ClientDebtor>>;
  menuPortalTarget: HTMLElement | null;
}) {
  let { unmapped, onUpdateMapping, clientDebtors, menuPortalTarget, mapping } = props;
  let theme = useTheme();
  let placeholder = 'Select';
  let companyIdId = useId();
  let companyNamesId = useId();
  let mapToId = useId();
  if (mapping === null) {
    /**
     * If the value exists, but is null,
     * then that means the user chose the "customer
     * not in this list" option, which sets
     * the value to null.
     */
    placeholder = 'Customer not in this list';
  }
  return (
    <Grid container spacing={2} data-testid={`mapping-${unmapped.companyId}`}>
      <Grid item xs={12} md={3}>
        <CustomerSelectTitle id={companyIdId}>Company ID</CustomerSelectTitle>
        <TypeBase aria-labelledby={companyIdId}>{unmapped.companyId}</TypeBase>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomerSelectTitle id={companyNamesId}>Uploaded Company Name(s)</CustomerSelectTitle>
        <TypeBase aria-labelledby={companyNamesId}>{unmapped.companyName.join(', ')}</TypeBase>
      </Grid>
      <Grid item xs={12} md={5}>
        <CustomerSelectTitle id={mapToId}>Map To</CustomerSelectTitle>
        <Select<ClientDebtor>
          aria-labelledby={mapToId}
          options={clientDebtors.value?.data}
          isLoading={clientDebtors.loading}
          getOptionLabel={(clientDebtor) => clientDebtor.debtorName}
          getOptionValue={(clientDebtor) => clientDebtor.uuid}
          menuPortalTarget={menuPortalTarget}
          value={mapping}
          styles={{ placeholder: (base) => ({ ...base, color: theme.palette.text.primary }) }}
          onChange={(clientDebtor) => onUpdateMapping(unmapped.companyId, clientDebtor as ClientDebtor)}
          placeholder={placeholder}
          components={{ MenuList }}
        />
      </Grid>
    </Grid>
  );
}

export function InvoiceUploadMapping(props: {
  unmapped: InvoiceUploadUnmapped[];
  onSubmit: (mappings: CustomerMapping[]) => Promise<void>;
  onClose: () => void;
}) {
  let headerId = useId() as string;
  let clientDebtorsAsync = useClientDebtors();
  /**
   * Intentionally putting a ref into state,
   * because we need to re-render after we get the ref
   * so we can give it to react-select
   */
  let [dialogRef, setDialogRef] = useState<HTMLElement | null>(null);
  let [mappings, updateMapping] = useMappingForm();
  let [submitErrored, setSubmitErrored] = useState(false);

  const handleSubmitClick = async () => {
    setSubmitErrored(false);
    let customerMappings: CustomerMapping[] = Object.keys(mappings)
      .filter((key) => mappings[key])
      .map((key) => ({
        companyId: key,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        displayName: props.unmapped.find((item) => item.companyId === key)!.companyName.join(', '),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clientDebtorUuid: mappings[key]!.uuid,
      }));
    try {
      await props.onSubmit(customerMappings);
    } catch (err) {
      setSubmitErrored(true);
    }
  };

  return (
    <InvoiceUploadDialog
      open={true}
      onClose={props.onClose}
      ref={setDialogRef}
      aria-labelledby={headerId}
      modalTitle="Unknown Customer ID(s)"
      closeButtonTestId="invoice-upload-dialog-close-button"
      desktopWidth="wide"
      actionsContent={
        <>
          {submitErrored && (
            <TypeBase isError>We're unable to complete your request now. Please try again later.</TypeBase>
          )}
          {Object.keys(mappings).length === 0 ? (
            <AsyncButton
              data-testid={'submit-mapping'}
              data-cy={'ignore-mapping'}
              color={'secondary'}
              variant={'outlined'}
              action={handleSubmitClick}
            >
              Have C2FO Do This
            </AsyncButton>
          ) : (
            <AsyncButton data-testid={'submit-mapping'} data-cy={'submit-mapping'} action={handleSubmitClick}>
              Submit
            </AsyncButton>
          )}
        </>
      }
    >
      <>
        <InvoiceUploadDialogContent>
          <TypeBase align={'center'} spacingBottom={3}>
            It appears that we didn’t recognize some of your company IDs. Manually map these or let us forward this to
            your account management team (turnaround time 2-4 business days).
          </TypeBase>
          {props.unmapped.map((unmapped, index) => {
            let value = mappings[unmapped.companyId];

            return (
              <div data-cy={`customer-select-${index}`} key={unmapped.companyId}>
                <MappingRow
                  unmapped={unmapped}
                  onUpdateMapping={updateMapping}
                  mapping={value}
                  clientDebtors={clientDebtorsAsync}
                  menuPortalTarget={dialogRef}
                />
              </div>
            );
          })}
        </InvoiceUploadDialogContent>
      </>
    </InvoiceUploadDialog>
  );
}
