import React, { useMemo } from 'react';
import update from 'immutability-helper';
import {
  BarsIcon,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  TypeBase,
  TypeLabel,
  TypeSubsectionHeader,
  makeStyles,
} from '@c2fo/react-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { FactoringInvoice, ReportDisplayField } from '../../schemas';
import { MultiselectFilter } from '../Filters/useMultiselectFilter';
import { ValueFilter } from '../Filters/ValueFilter';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  selectFieldsGrid: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  dragNDropGrid: {
    padding: theme.spacing(1),
  },
  listItem: {
    border: `1px solid ${theme.palette.secondary.main}`,
    margin: 2,
    paddingTop: `calc(0.5 * ${theme.spacing(1)}px)`,
    paddingBottom: `calc(0.5 * ${theme.spacing(1)}px)`,
    '> i': {
      color: theme.palette.secondary.main,
    },
  },
}));

type DraggableProps = {
  id: any;
  text: string;
  index: number;
};

function DraggableColumn(props: DraggableProps) {
  const { id, text, index } = props;
  const classes = useStyles();
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <ListItem className={classes.listItem} style={{ opacity: 1 }}>
            <ListItemText primary={<TypeBase>{text}</TypeBase>} />
            <ListItemIcon>
              <BarsIcon color={'secondary'} />
            </ListItemIcon>
          </ListItem>
        </div>
      )}
    </Draggable>
  );
}

type ColumnListProps = {
  columns?: ReportDisplayField<FactoringInvoice>[] | null;
};

function ColumnList(props: ColumnListProps) {
  return (
    <>
      {props.columns
        ? props.columns?.map((item: ReportDisplayField<FactoringInvoice>, index: number) => (
            <DraggableColumn id={item.name} text={item.displayName} index={index} key={item.name} />
          ))
        : null}
    </>
  );
}

type Props = {
  availableColumns?: ReportDisplayField<FactoringInvoice>[] | null;
  columnFilters: MultiselectFilter<ReportDisplayField<FactoringInvoice>, string>;
};

export function InvoiceReportColumnsStep(props: Props) {
  const { availableColumns, columnFilters } = props;

  const classes = useStyles();

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      // no valid destination, so no action.
      return;
    }
    if (result.destination.index === result.source.index) {
      // no change based on drop location
      return;
    }

    // manage reordering.
    const updatedColumns = update(columnFilters.items, {
      $splice: [
        // remove 1 item at original index
        [result.source.index, 1],
        // add one item at destination index.
        [result.destination.index, 0, columnFilters.items[result.source.index]],
      ],
    });
    columnFilters.onChange(updatedColumns);
  };

  const columnDragList = useMemo(() => <ColumnList columns={columnFilters.items} />, [columnFilters.items]);

  return (
    <>
      <TypeSubsectionHeader data-cy="customReportSubHeading">Step 2 of 3: Choose Your Fields</TypeSubsectionHeader>
      <form>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid container item xs={6} direction={'column'} className={classes.selectFieldsGrid}>
            <TypeBase>Available Columns:</TypeBase>
            <TypeLabel>Select columns to include in report</TypeLabel>
            {availableColumns && (
              <ValueFilter
                showSelectAll={true}
                filter={columnFilters}
                options={availableColumns}
                render={(item) => <span>{item.displayName}</span>}
              />
            )}
          </Grid>
          <Grid container item xs={6} direction={'column'} className={classes.dragNDropGrid}>
            <TypeBase>Selected Columns:</TypeBase>
            <TypeLabel>Drag to order</TypeLabel>
            <DragDropContext onDragEnd={onDragEnd}>
              <List>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columnDragList}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </List>
            </DragDropContext>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
