import { AxiosError } from 'axios';
import { PageableResponse } from '../../schemas';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';

export class BaseBalanceService<Balance> {
  constructor(protected httpService: TpfSupplierApiHttpService, private baseUrl: (divisionUuid: string) => string) {}

  async getBalance(divisionUuid: string): Promise<Balance | null> {
    try {
      const response = await this.httpService.get<PageableResponse<Balance>>(this.baseUrl(divisionUuid));
      return response.data.data[0];
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 404) {
        return null;
      }
      throw err;
    }
  }
}
