import { BankAccount, BankAccountFormData } from '../../schemas/bank-account.schema';
import { PageableResponse } from '../../schemas/http.schema';
import { TpfSupplierApiHttpService } from '../TpfSupplierApiHttpService/TpfSupplierApiHttpService';
import { vgsConfig } from '../../../config';

const basePath = (divisionUuid: string) =>
  `${vgsConfig.VGS_PROXY_URL}/v2/tpf-api/divisions/${divisionUuid}/bank-accounts`;

export class BankAccountService {
  constructor(private httpService: TpfSupplierApiHttpService) {}

  async getBankAccount(divisionUuid: string): Promise<BankAccount | null> {
    let response = await this.httpService.get<PageableResponse<BankAccount>>(basePath(divisionUuid), {
      params: {
        isActive: 'true',
      },
    });
    if (response.data.data.length < 1) {
      return null;
    }
    return response.data.data[0];
  }

  private async getVerificationDocumentUrl(verificationDocument: File) {
    return new Promise<string>((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(verificationDocument);
    });
  }

  async saveBankAccountForm(
    divisionUuid: string,
    form: BankAccountFormData,
    verificationDocument: File,
  ): Promise<BankAccount> {
    let verificationDocumentUrl = await this.getVerificationDocumentUrl(verificationDocument);
    let account: Partial<BankAccount> = {
      ...form,
      lastFourBankAccountNumber: form.accountNumber.substr(form.accountNumber.length - 4, 4),
      accountNumberVgsIdentifier: form.accountNumber,
      verificationImageVgsIdentifier: verificationDocumentUrl,
    };
    let response = await this.httpService.post<BankAccount>(basePath(divisionUuid), {
      data: account,
    });
    return response.data;
  }
}
