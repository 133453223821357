export interface BankAccount {
  accountNumberConnectIdentifier: string;
  address: string;
  authorizedByEmailAddress: string;
  authorizedByIpAddress: string;
  city: string;
  divisionUuid: string;
  isActive: boolean;
  lastFourBankAccountNumber: string;
  name: string;
  nameOnAccount: string;
  postalCode: string;
  routingNumberAch: string;
  routingNumberWire: string;
  state: string;
  status: BankAccountStatus;
  transferDate: string | null;
  uuid: string;
  verificationImageConnectIdentifier: string;
  accountNumberVgsIdentifier: string;
  verificationImageVgsIdentifier: string;
}

export interface BankAccountFormData {
  name: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  routingNumberWire: string;
  routingNumberAch: string;
  nameOnAccount: string;
  accountNumber: string;
}

export enum BankAccountStatus {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  FAILED_VALIDATION = 'FAILED_VALIDATION',
  INVALID_VERIFICATION_DOCUMENT = 'INVALID_VERIFICATION_DOCUMENT',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  VERIFIED = 'VERIFIED',
  NOT_STARTED = 'NOT_STARTED',
}
