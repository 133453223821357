import React from 'react';

interface ErrorBoundaryProps {
  display: React.ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * ErrorBoundary will ensure that something is rendered in your react application
 * if any of the child components within it throws an unexpected error.
 * The `display` prop expects a React Element to display in the event of an error.
 *
 * NOTE: This is only a 'class component' because there is currently no functional
 * equivalent for getDerivedStateFromError or componentDidCatch.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.display;
    }

    return this.props.children;
  }
}
