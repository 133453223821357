import React, { useContext } from 'react';
import { RFHomeContext } from '../../pages/RfHomePage/RFHomeContext';
import { RFHomeDispatcherService } from '../../pages/RfHomePage/service';
import { RFSummaryClosedAccountCard } from './RFSummaryClosedAccountCard';
import { RFSummaryLimitCard } from './RFSummaryLimitCard';
import { RFSummaryOverAdvancedCard } from './RFSummaryOverAdvancedCard';
import { RFSummaryPendingCard } from './RFSummaryPendingCard';
import { RFSummaryRequestCard } from './RFSummaryRequestCard';
import { RequestStatus, TransferMethodPreference } from '../../schemas';
import { SummaryCardLoading } from '../SummaryCard/SummaryCardLoading';
import { useAccountStatus } from '../../hooks/accountStatus';
import { useCurrentDivision } from '../../contexts/DivisionContext';
import { useServices } from '../../services';

interface RFSummaryCardProps {
  isReadOnlyUser: boolean;
}

export const RFSummaryCard: React.FC<RFSummaryCardProps> = (props) => {
  const { state, dispatch } = useContext(RFHomeContext);
  const divisionUuid = useCurrentDivision();
  const { clientService, clientBalanceService, advanceService, invoiceUploadService } = useServices();

  const rfHomeService = new RFHomeDispatcherService(
    clientService,
    clientBalanceService,
    advanceService,
    invoiceUploadService,
    dispatch,
  );

  useAccountStatus({
    isClosed: state.client?.isClosed || false,
    isOverdrawn: state.requestStatus === RequestStatus.OverAdvanced,
  });

  const handleConfirmRequest = async (advanceAmount: number, transactionType: TransferMethodPreference) => {
    await rfHomeService.submitAdvanceRequest(divisionUuid, advanceAmount, transactionType);
  };

  const handleCancelRequest = async () => {
    if (state.advance) {
      await rfHomeService.cancelAdvanceRequest(state.advance, divisionUuid);
    }
  };

  const isStatus = (statusToCheck: RequestStatus): boolean => {
    if (state.isLoading) {
      return false;
    }
    return state.requestStatus === statusToCheck;
  };

  const isRequestReady = (): boolean =>
    (!state.requestStatus || state.requestStatus === RequestStatus.Canceled) && !state.isLoading;

  return (
    <>
      {state.isLoading && <SummaryCardLoading />}
      {state.client?.isClosed && <RFSummaryClosedAccountCard lastUpload={state.upload} balance={state.balance} />}
      {isStatus(RequestStatus.LimitReached) && <RFSummaryLimitCard balance={state.balance} />}
      {isStatus(RequestStatus.OverAdvanced) && <RFSummaryOverAdvancedCard balance={state.balance} />}
      {isStatus(RequestStatus.Pending) && (
        <RFSummaryPendingCard
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          advance={state.advance!}
          balance={state.balance}
          lastUpload={state.upload}
          onCancelRequest={handleCancelRequest}
          isReadOnlyUser={props.isReadOnlyUser}
        />
      )}
      {isRequestReady() && !state.client?.isClosed && (
        <RFSummaryRequestCard
          client={state.client}
          balance={state.balance}
          onConfirmRequest={handleConfirmRequest}
          isReadOnlyUser={props.isReadOnlyUser}
        />
      )}
    </>
  );
};
