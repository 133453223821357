import React from 'react';
import { DateFilter } from '../Filters/useDateFilter';
import { DatePicker } from '../Filters/DatePicker';
import { DebtorSearch } from '../Filters/TypeaheadSearch';
import { FilterLabel } from '../Filters/FilterLabel';
import { InvoiceSupplierStatus } from '../../schemas';
import { MultiselectFilter } from '../Filters/useMultiselectFilter';
import { SupplierStatusFilter } from '../Filters/ValueFilter';
import { Theme, TypeSubsectionHeader, makeStyles } from '@c2fo/react-components';

const useStyles = makeStyles((theme: Theme) => ({
  formLabel: {
    marginTop: theme.spacing(2),
  },
  maxInputWidthItem: {
    width: '50%',
  },
  actionButton: {
    margin: theme.spacing(1),
  },
}));

type Props = {
  statusOptions: InvoiceSupplierStatus[];
  statusFilters: MultiselectFilter<InvoiceSupplierStatus, InvoiceSupplierStatus>;
  debtorFilters: MultiselectFilter<string, string>;
  dueDateFilter: DateFilter;
  invoiceDateFilter: DateFilter;
};

export function InvoiceReportFiltersStep(props: Props) {
  const classes = useStyles();

  return (
    <>
      <TypeSubsectionHeader data-cy="customReportSubHeading">Step 1 of 3: Choose Your Filters</TypeSubsectionHeader>
      <form>
        <div className={classes.formLabel}>
          <FilterLabel>Status</FilterLabel>
        </div>
        <SupplierStatusFilter
          formGroupProps={{ row: true }}
          showSelectAll={true}
          filter={props.statusFilters}
          options={props.statusOptions}
        />
        <div className={classes.formLabel}>
          <FilterLabel>Customer Name(s)</FilterLabel>
        </div>
        <div className={classes.maxInputWidthItem}>
          <DebtorSearch filter={props.debtorFilters} />
        </div>
        <div className={classes.formLabel}>
          <FilterLabel>Due Date</FilterLabel>
        </div>
        <div className={classes.maxInputWidthItem}>
          <DatePicker filter={props.dueDateFilter} testFilterName={'dueDateReport'} />
        </div>
        <div className={classes.formLabel}>
          <FilterLabel>Invoice Date</FilterLabel>
        </div>
        <div className={classes.maxInputWidthItem}>
          <DatePicker filter={props.invoiceDateFilter} testFilterName={'invoiceDateReport'} />
        </div>
      </form>
    </>
  );
}
